
import { observable, action, decorate, computed } from 'mobx';

import settings from '../settings';

const TOKEN_KEY = 'token';
const ID_KEY = 'id';
const NAME_KEY = 'name';
const EMAIL_KEY = 'email';
const PERMISSIONS_KEY = 'permissions';
const KEEP_SESSION_KEY = 'keep_session';

class AuthStore {
  token = null;
  id = null;
  name = null;
  email = null;
  rawPermissions = [];

  permissions = {
    quotes: {
      list: false,
      create: false,
      read: false,
      edit: false,
      delete: false,
    },
    invoices: {
      list: false,
      create: false,
      read: false,
      edit: false,
      delete: false,
      set_paid: false,
    },
    packingLists: {
      list: false,
      create: false,
      read: false,
      edit: false,
      delete: false,
    },
    admin: false,
    read_admin: false
  };

  get isLogged() {
    return !!(this.token && this.id && (this.name || this.email) && this.rawPermissions);
  }

  get displayName() {
    return this.name || this.email;
  }

  constructor() {
    if (localStorage.getItem(KEEP_SESSION_KEY) === '1') {
      this.tryRecoverUser();
    }
  }

  tryRecoverUser() {
    const savedPermissions = localStorage.getItem(PERMISSIONS_KEY);
    const savedName = localStorage.getItem(NAME_KEY);
    this.setUser(
      localStorage.getItem(TOKEN_KEY),
      localStorage.getItem(ID_KEY),
      savedName && savedName !== '' ? savedName : null,
      localStorage.getItem(EMAIL_KEY),
      localStorage.getItem(KEEP_SESSION_KEY) === '1',
      savedPermissions && savedPermissions.length > 0 ?savedPermissions.split(',') : []
    );
  }

  setUser(token, id, name, email, keepSession, permissions) {
    this.token = token;
    this.id = id;
    this.name = name;
    this.email = email;
    this.rawPermissions = permissions && Array.isArray(permissions) ? permissions : [];

    this.checkPermissions();
    this.saveUser(keepSession);
  }

  saveUser(keepSession) {
    localStorage.clear();
    localStorage.setItem(TOKEN_KEY, this.token);
    localStorage.setItem(ID_KEY, this.id);
    localStorage.setItem(NAME_KEY, this.name || '');
    localStorage.setItem(EMAIL_KEY, this.email);
    localStorage.setItem(PERMISSIONS_KEY, this.rawPermissions.join(','));

    if (keepSession) {
      localStorage.setItem(KEEP_SESSION_KEY, '1');
    }
  }

  clearUser() {
    localStorage.clear();
    this.token = null;
    this.id = null;
    this.name = null;
    this.email = null;
    this.permissions = {
      quotes: {
        list: false,
        create: false,
        read: false,
        edit: false,
        delete: false,
      },
      invoices: {
        list: false,
        create: false,
        read: false,
        edit: false,
        delete: false,
        set_paid: false,
      },
      packingLists: {
        list: false,
        create: false,
        read: false,
        edit: false,
        delete: false,
      },
      admin: false,
      read_admin: false,
    };
    this.rawPermissions = [];
  }

  validatePermission(permission) {
    return this.rawPermissions.includes(permission);
  }

  checkPermissions() {
    this.rawPermissions.forEach(permission => {
      switch(permission) {
        case settings.permissions.quotes.read:
          this.permissions.quotes.read = true;
          break;
        case settings.permissions.quotes.create:
          this.permissions.quotes.create = true;
          break;
        case settings.permissions.quotes.list:
          this.permissions.quotes.list = true;
          break;
        case settings.permissions.quotes.delete:
          this.permissions.quotes.delete = true;
          break;
        case settings.permissions.quotes.edit:
          this.permissions.quotes.edit = true;
          break;
        case settings.permissions.invoices.read:
          this.permissions.invoices.read = true;
          break;
        case settings.permissions.invoices.create:
          this.permissions.invoices.create = true;
          break;
        case settings.permissions.invoices.list:
          this.permissions.invoices.list = true;
          break;
        case settings.permissions.invoices.delete:
          this.permissions.invoices.delete = true;
          break;
        case settings.permissions.invoices.edit:
          this.permissions.invoices.edit = true;
          break;
        case settings.permissions.invoices.set_paid:
          this.permissions.invoices.set_paid = true;
          break;
        case settings.permissions.packingLists.read:
          this.permissions.packingLists.read = true;
          break;
        case settings.permissions.packingLists.create:
          this.permissions.packingLists.create = true;
          break;
        case settings.permissions.packingLists.list:
          this.permissions.packingLists.list = true;
          break;
        case settings.permissions.packingLists.delete:
          this.permissions.packingLists.delete = true;
          break;
        case settings.permissions.packingLists.edit:
          this.permissions.packingLists.edit = true;
          break;
        case settings.permissions.admin:
          this.permissions.admin = true;
          break;
        case settings.permissions.read_admin:
          this.permissions.read_admin = true;
          break;
        default: break;
      }
    });
  }
}

decorate(
  AuthStore,
  {
    token: observable,
    name: observable,
    email: observable,
    rawPermissions: observable,
    permissions: observable,
    isLogged: computed,
    displayName: computed,
    setUser: action,
    clearUser: action,
    validatePermission: action
  }
);

export default new AuthStore();
