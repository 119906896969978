
import parseToDecimal from './parseToDecimal';
import parseSeparator from './parseSeparator';

/**
 * Parse value to money format
 * @param value
 * @param decimals
 * @param thousandsSeparator
 * @param decimalSeparator
 * @return {*|string}
 */
const fn = (value, decimals, thousandsSeparator = '.', decimalSeparator = ',') =>
  parseSeparator(
    parseToDecimal(value, decimals, decimalSeparator),
    thousandsSeparator
  );
export default fn;
