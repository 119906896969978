
import './index.scss';

import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import authStore from '../../stores/auth';
import Dropdown from '../Dropdown';

class Menu extends React.Component {
  render() {
    const adminActive = /^\/admin(.)+$/.test(this.props.location.pathname);
    return (
      <nav className="menu">
        {authStore.permissions.quotes.list ? <NavLink className="menu__action" activeClassName="menu__action--active" to="/offers">Ofertas</NavLink> : null}
        {authStore.permissions.invoices.list ? <NavLink className="menu__action" activeClassName="menu__action--active" to="/invoices">Facturas</NavLink> : null}
        {authStore.permissions.packingLists.list ? <NavLink className="menu__action" activeClassName="menu__action--active" to="/shipments">Packing List</NavLink> : null}
        {authStore.permissions.read_admin ? <NavLink className="menu__action" activeClassName="menu__action--active" to="/products">Productos</NavLink> : null}
        {authStore.permissions.read_admin || authStore.permissions.admin ? (
          <div className={`menu__action menu__action--dropdown ${adminActive ? 'menu__action--active' : ''}`}>
            <Dropdown.Container buttonKey="admin"
                                size="nav"
                                title="Administración">
              {authStore.permissions.read_admin ? (
                <Fragment>
                  <Dropdown.Link to="/admin/suppliers">Proveedores</Dropdown.Link>
                  <Dropdown.Link to="/admin/clients">Clientes</Dropdown.Link>
                </Fragment>
              ) : null}
              {authStore.permissions.admin ? <Dropdown.Link to="/admin/users/new">Usuarios</Dropdown.Link> : null}
            </Dropdown.Container>
          </div>
        ) : null}
      </nav>
    );
  }
}

export default withRouter(observer(Menu));
