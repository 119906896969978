
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const LabeledInformation = ({label, value, link, loading}) => (
  <div className="labeled-information">
    <div className="labeled-information__label">
      {loading ? <div className="loading-block loading-block--small" style={{width: '25%'}}/> : label}
    </div>
    {loading ? (
      <div className="loading-block loading-block--mid"/>
    ) : (link ? (
      <a className="labeled-information__value" href={link}>{value}</a>
    ) : (
      <div className="labeled-information__value">{value}</div>
    ))}
  </div>
);

LabeledInformation.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  link: PropTypes.string,
  loading: PropTypes.bool
};

export default LabeledInformation;
