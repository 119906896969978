
import React from 'react';
import { useParams } from 'react-router-dom';

import { useInvoiceContext } from './InvoiceContext';
import OfferSelectionList from './OfferSelectionList';
import SelectedOffer from './SelectedOffer';

export const SelectOffer = () => {
  const params = useParams();
  const [state, ] = useInvoiceContext();
  const quoteId = params.quoteId;

  if (state.quote)
    return (
      <SelectedOffer/>
    );
  else if (!quoteId)
    return (
      <OfferSelectionList/>
    );
  else
    return null;
};

export default SelectOffer;
