
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import React from 'react';
import PropTypes from 'prop-types';

class SelectInput extends React.Component {
  state = {
    modified: false,
    open: false,
    inputActive: false,
    inputFocus: false,
    displayValue: null,
    search: null
  };
  selectRef;
  inputRef;

  constructor(props) {
    super(props);

    this.onSelectOption = this.onSelectOption.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onDone = this.onDone.bind(this);

    this.toggleOptions = this.toggleOptions.bind(this);
    this.openOptions = this.openOptions.bind(this);
    this.closeOptions = this.closeOptions.bind(this);

    this.onTouchDisplay = this.onTouchDisplay.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.inputActive && nextProps.children) {
      const activeValue = React.Children.toArray(nextProps.children).find(child => child.props.value === nextProps.value);
      if (activeValue) {
        return { displayValue: activeValue.props.display || activeValue.props.children };
      } else if (nextProps.value && nextProps.value !== '') {
        return { inputActive: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  onValueChanged(value, writing = false) {
    if (writing) {
      this.setState({modified: true});
    }
    this.props.onChange(value, this.props.name);
  }

  onSelectOption(value, type) {
    if (type === 'text') {
      this.setState({ inputActive: true });
      this.onValueChanged('', true);
      setTimeout(() => this.inputRef && this.inputRef.focus(), 10);
    } else {
      this.setState({ inputActive: false });
      this.onValueChanged(value);
      this.onDone(value, true);
    }
    this.closeOptions();
  }

  onDone(value, modified = undefined) {
    this.props.onDone(this.props.name, value, modified !== undefined ? modified : this.state.modified);
    this.setState({modified: false});
  }

  toggleOptions() {
    if (this.state.open) {
      this.closeOptions();
    } else {
      this.openOptions();
    }
  }

  openOptions() {
    this.setState({open: true});
  }

  closeOptions() {
    this.setState({
      open: false,
      search: null
    });
    this.props.onClose();
  }

  onTouchDisplay(event) {
    if (!this.state.inputActive && !this.props.disabled) {
      this.toggleOptions();
    }
  }

  handleClickOutside(event) {
    if (this.selectRef && !this.selectRef.contains(event.target)) {
      this.closeOptions();
    }
  }

  render() {
    const { information, label, children, searchEnabled } = this.props;
    const { search } = this.state;

    if (this.props.loading) {
      return (
        <div className="input-group">
          <div className="group__display">
            {label ? (
              <div className="input-group__label">
                <div className="loading-block loading-block--small" style={{width: '25%'}}/>
              </div>
            ) : null}
            <div className="input-group__input">
              <div className="loading-block loading-block--mid"/>
            </div>
          </div>
        </div>
      );
    }

    let displayClassNames = 'input-group__display';

    if (this.state.open || this.state.inputFocus) { displayClassNames += ' input-group__display--focus'; }
    if (information && !this.state.focus) { displayClassNames += ' input-group__display--information'; }
    if (!this.state.inputActive) { displayClassNames += ' input-group__display--arrow'; }

    const baseOptions = React.Children.toArray(children);
    let options = baseOptions;

    if (searchEnabled && search && !this.props.onSearchChange) {
      const searchRegex = new RegExp(search.toString().toLowerCase());

      options = baseOptions.filter(option => {
        const { type, value, children } = option.props;
        if (type && type !== 'text' && value && children) {
          const optionValue = value.toString().toLowerCase();
          const optionText = children.toString().toLowerCase();
          return searchRegex.test(optionValue) || searchRegex.test(optionText);
        } else {
          return false;
        }
      });
    }

    const displayOptions = options.map(
      option => {
        return React.cloneElement(option, {
          onSelect: (value) => this.onSelectOption(value, option.props.type)
        });
      }
    );

    return (
      <div className="input-group input-group--select"
           ref={node => this.selectRef = node}>
        <div className={displayClassNames}
             onClick={this.onTouchDisplay}>
          <label className="input-group__label">{label}</label>
          {this.state.inputActive ? (
            <input className="input-group__input"
                   ref={ref => this.inputRef = ref}
                   type="text"
                   value={this.props.value}
                   disabled={this.props.disabled}
                   onChange={event => this.onValueChanged(event.target.value, true)}
                   onFocus={() => this.setState({inputFocus: true})}
                   onBlur={event => {
                     this.setState({inputFocus: false});
                     this.onDone(event.target.value);
                   }}/>
          ) : (
            <div className="input-group__value">{this.state.displayValue || 'Seleccione'}</div>
          )}
          {this.state.inputActive ? (<button className="input-group__button"
                                             onClick={() => {
                                               if (!this.props.disabled) {
                                                 this.setState({inputActive: false});
                                                 this.toggleOptions();
                                               }
                                             }}><CloseIcon/></button>) : null}
        </div>
        {this.state.open ?
          <div className={`input-group__options input-group__options--${this.props.size}`}>
            {searchEnabled ? <input className="input-group__search"
                                    placeholder="Buscar..."
                                    onChange={event => {
                                      const value = event.target.value;
                                      if (this.props.onSearchChange) {
                                        this.props.onSearchChange(value);
                                      }
                                      this.setState({search: value});
                                    }}/> : null}
            {displayOptions}
          </div> : null}
        {this.props.error ? <div className="input-group__error">{this.state.error || this.props.error}</div> : null}
      </div>
    );
  }
}

SelectInput.propTypes = {
  information: PropTypes.bool.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.any.isRequired,
  onDone: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node,
  name: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.any,
  searchEnabled: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['auto', 'mid', 'big']).isRequired,
  onSearchChange: PropTypes.func,
  onClose: PropTypes.func
};

SelectInput.defaultProps = {
  information: false,
  searchEnabled: false,
  disabled: false,
  size: 'auto',
  onDone: () => {},
  onClose: () => {}
};

export default SelectInput;
