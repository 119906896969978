
import './index.scss';

import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import settings from '../../settings';
import api from '../../api';
import { protect, TextInput, PageHeader, PackagesList, StepsIndicator } from '../../components';
import { parseToDecimal } from '../../utils';
import SelectInvoice from './SelectInvoice';
import SelectType from './SelectType';
import ModalItemProductSplitter from '../../components/ModalItemProductSplitter';

class ShipmentForm extends React.Component {
  state = {
    selectedInvoice: null,
    type: null,
    shipment: {
      airline: null,
      manifesto: null,

      shipping_company: null,
      vessel: null,
      container: null,

      ETS: null,
      ETA: null
    },
    packages: [],
    products: [],
    product:{},
    packageIndex: null,
    baseProducts: [],
    searchProducts: [],
    operations: [],
    search: null,
    step: 1,
    createLoading: false,
    createError: false,
    createSuccess: false,
    isOpen: false
  };
  source = api.source.createSource();
  notificationTimeout = null;
  NOTIFICATION_DURATION = 3000;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Embarques', link: '/shipments'},
    {title: 'Nuevo embarque', link: '/shipments/new'}
  ];

  constructor(props) {
    super(props);

    this.getInvoice = this.getInvoice.bind(this);
    this.getOfferProducts = this.getOfferProducts.bind(this);

    this.onValueChanged = this.onValueChanged.bind(this);

    this.onAddPackage = this.onAddPackage.bind(this);
    this.onPackageChanged = this.onPackageChanged.bind(this);
    this.onDeletePackage = this.onDeletePackage.bind(this);

    this.search = this.search.bind(this);
    this.onAddProduct = this.onAddProduct.bind(this);
    this.onSetShowModal = this.onSetShowModal.bind(this);
    this.onProductChangedSplitted = this.onProductChangedSplitted.bind(this);
    this.onAddProductToPackageSplitted = this.onAddProductToPackageSplitted.bind(this);
    this.onProductChanged = this.onProductChanged.bind(this);
    this.onDeleteProduct = this.onDeleteProduct.bind(this);

    this.createShipment = this.createShipment.bind(this);
  }

  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId;
    if (invoiceId) {
      this.getInvoice(invoiceId)
    }
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  getInvoice(id) {
    api.invoice.getInvoice(id, this.source)
      .then(response => {
        const invoice = response.data;
        // this.getOfferProducts(invoice.quote_id);
        const products = response.data.products.map((product, index) => ({...product, baseIndex: index}));
        console.log(products)
        // this.setState({products, baseProducts: products})
        this.setState({selectedInvoice: invoice, products, baseProducts: products});
        // this.setState({selectedInvoice: invoice});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Cannot get invoice', err);
        }
      });
  }

  getOfferProducts(offerId) {
    api.quote.getProducts(offerId, false, this.source)
      .then(response => {
        const products = response.data.products.map((product, index) => ({...product, baseIndex: index}));
        this.setState({products, baseProducts: products})
      })
      .catch(err => !api.source.errorIsCancel(err) && console.error(`Cannot get products for offer ${offerId}`, err));
  }

  onValueChanged(value, name, error) {
    this.setState({
      shipment: {...this.state.shipment, [name]: value}
    });
  }

  // Packages methods

  onAddPackage(packageInfo) {
    console.log(this.state.packages)
    this.setState({
      packages: [
        ...this.state.packages,
        {
          ...packageInfo,
          net_weight: parseToDecimal(packageInfo.net_weight, 3),
          gross_weight: parseToDecimal(packageInfo.gross_weight, 3),
          package: this.state.packages.length + 1,
        }
      ]
    });
  }

  onPackageChanged(value, name, error, index) {
    const actualPackages = [...this.state.packages];
    actualPackages[index][name] = value;
    this.setState({packages: actualPackages});
  }

  onDeletePackage(index) {
    const packages = [...this.state.packages];
    const products = [...this.state.products, ...this.state.packages[index].products];
    packages.splice(index, 1);

    this.setState({packages, products});
  }

  // Products methods

  search(text) {
    this.setState({search: text});
    if (text) {
      api.product.getProducts(this.source, {part_number: text})
        .then(response => {
          const searchProducts = []; // response.data; TODO: PATCH - Non invoiced products
          const filteredBaseProducts = [...this.state.baseProducts].filter(product => {
            if (!product.shipped) {
              const nameLike = product.name && new RegExp(text, 'gmi').test(product.name);
              const descriptionLike = product.description && new RegExp(text, 'gmi').test(product.description);
              const partNumberLike = product.part_number && new RegExp(text, 'gmi').test(product.part_number);
              return nameLike || descriptionLike || partNumberLike;
            } else {
              return false;
            }
          });
          this.setState({products: [...filteredBaseProducts, ...searchProducts], searchProducts});
        })
        .catch(err => !api.source.errorIsCancel(err) && console.error('Error searching products', err));
    } else {
      this.setState({products: [...this.state.baseProducts], searchProducts: []});
    }
  }
  onSetShowModal({isOpen, productIndex, packageIndex}){
    const products = [...this.state.products];
    const newProduct = {...products[productIndex]};
    this.setState({isOpen: isOpen, product:newProduct, packageIndex: packageIndex})
  }
  
  onProductChangedSplitted(product, quantity){
    product["quantity"] = quantity
    console.log(product)
    this.setState({product: product})
  }

  onAddProductToPackageSplitted(product,packageIndex){
    let packages = [...this.state.packages];
    let baseProducts = [...this.state.baseProducts];
    let products = [...this.state.products];
    let index = products.findIndex( item=> item.id == product.id)
    
    baseProducts[index].available = parseFloat(baseProducts[index].available) - parseFloat(product.quantity)
    delete product["available"]
    packages[this.state.packageIndex]["products"].push(product)

    if(baseProducts[index].available <= 0){
      console.log(`The product was deleted`)
      products.splice(index, 1);
      this.setState({baseProducts, products});
    }
    this.setState({packages, isOpen: false, products})
  }

  onAddProduct(packageIndex, packageId, productIndex, subProductIndex) {
    const packages = [...this.state.packages];
    const products = [...this.state.products];
    const newProduct = {...products[productIndex]};

    if (!products[productIndex].invoice_id) {
      newProduct.quantity = '1.00';
      newProduct.unit = 'U';
    }

    packages[packageIndex].products.push(newProduct);
    packages[packageIndex].gross_weight = parseToDecimal(this.calculatePackageWeight('gross_weight', packageIndex, packages), 3);
    packages[packageIndex].net_weight = parseToDecimal(this.calculatePackageWeight('net_weight', packageIndex, packages), 3);

    if (products[productIndex].baseIndex || products[productIndex].baseIndex === 0) {
      const baseProducts = [...this.state.baseProducts];
      baseProducts[products[productIndex].baseIndex].shipped = true;
      this.setState({baseProducts});
      products.splice(productIndex, 1);
    }

    this.setState({packages, products});
  }

  onProductChanged(value, name, error, packageIndex, productIndex) {
    const packages = [...this.state.packages];
    const baseProducts = [...this.state.baseProducts];
    const product = packages[packageIndex].products[productIndex];

    if (product.baseIndex || product.baseIndex === 0) {
      baseProducts[product.baseIndex][name] = value;
    }
    packages[packageIndex].products[productIndex][name] = value;

    if (name === 'gross_weight' || name === 'net_weight' || name === 'quantity') {
      packages[packageIndex].gross_weight = parseToDecimal(this.calculatePackageWeight('gross_weight', packageIndex), 3);
      packages[packageIndex].net_weight = parseToDecimal(this.calculatePackageWeight('net_weight', packageIndex), 3);
    }

    this.setState({packages, baseProducts});
  }

  onDeleteProduct(packageIndex, productIndex) {
    const packages = [...this.state.packages];
    const baseProducts = [...this.state.baseProducts];
    let products = [...this.state.products];
    const product = packages[packageIndex].products[productIndex];
    
    if (product.baseIndex || product.baseIndex === 0) {
      baseProducts[product.baseIndex].shipped = false;
      baseProducts[product.baseIndex].available = parseFloat(product.quantity) + parseFloat(baseProducts[product.baseIndex].available)
    }

    if (this.state.search) {
      products = baseProducts.filter(product => {
        if (!product.shipped) {
          const nameLike = product.name && new RegExp(this.state.search, 'gmi').test(product.name);
          const descriptionLike = product.description && new RegExp(this.state.search, 'gmi').test(product.description);
          const partNumberLike = product.part_number && new RegExp(this.state.search, 'gmi').test(product.part_number);
          return nameLike || descriptionLike || partNumberLike;
        } else {
          return false;
        }
      });
    } else {
      products = baseProducts.filter(product => !product.shipped);
    }

    packages[packageIndex].products.splice(productIndex, 1);
    packages[packageIndex].gross_weight = parseToDecimal(this.calculatePackageWeight('gross_weight', packageIndex, packages), 3);
    packages[packageIndex].net_weight = parseToDecimal(this.calculatePackageWeight('net_weight', packageIndex, packages), 3);

    this.setState({packages, baseProducts, products: [...products, ...this.state.searchProducts]});
  }

  calculatePackageWeight(fieldName, packageIndex, packages) {
    const basePackages = packages || this.state.packages;
    return basePackages[packageIndex].products.reduce((total, product) => total + ((Number(product[fieldName]) || 0) * (Number(product.quantity) || 0)), 0);
  }

  createShipment() {
    const shipment = {
      ...this.state.shipment,
      type: this.state.type,
      packages: this.state.packages.map((packageInfo, index) => {
        return {
          ...packageInfo,
          package: index + 1,
          invoiced_products: packageInfo.products.filter(product => product.invoice_id),
          non_invoiced_products: packageInfo.products.filter(product => !product.invoice_id)
        };
      })
    };
    console.log(shipment)
    
    this.setState({createLoading: true, createError: false, createSuccess: false});
    api.shipment.createShipment(
      this.state.selectedInvoice.id,
      shipment,
      this.source
    )
      .then(response => {
        console.log('Shipment id received', response.data);
        this.setState({createLoading: false, createSuccess: true});
        this.notificationTimeout = setTimeout(() => this.props.history.push(`/shipments/${response?.data}`), this.NOTIFICATION_DURATION);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({createLoading: false, createError: err});
          this.notificationTimeout = setTimeout(() => this.setState({createError: false}), this.NOTIFICATION_DURATION);
        }
      });
  }

  render() {
    let currentStep = this.state.step;
    if (this.state.step === 1) {
      if (this.state.type) {
        currentStep = 3;
      } else if (this.state.selectedInvoice) {
        currentStep = 2;
      }
    } else {
      currentStep = this.state.step + 2
    }

    return <div className={`shipment-form page ${this.state.step === 1 ? 'page--form' : ''}`}>
      <Helmet>
        <title>Nuevo embargue | Metallica Caribbean</title>
        <meta name="description" content="Formulario de creación de nuevo embargue" />
        <meta name="keyboards" content="embargue,metallica,crear,nuevo" />
      </Helmet>

      <div className="page__heading">
        <PageHeader.Container navigation={this.NAVIGATION}
                              title="Nuevo embargue"
                              onBack={() => {
                                if (this.state.step === 1) {
                                  this.props.history.goBack()
                                } else {
                                  this.setState({step: this.state.step - 1});
                                }
                              }}>
          <PageHeader.Actions>
            <PageHeader.Action>
              <StepsIndicator currentStep={currentStep}
                              steps={['Factura', 'Tipo de embarque', 'Detalles', 'Bultos']}/>
            </PageHeader.Action>
          </PageHeader.Actions>
        </PageHeader.Container>
      </div>
      {this.state.isOpen && <ModalItemProductSplitter 
                                showModal={this.onSetShowModal} 
                                Product={this.state.product} 
                                ProductChangedSplitted={this.onProductChangedSplitted} 
                                AddProductToPackageSplitted={this.onAddProductToPackageSplitted}/>}
      <div className="row">
        {this.state.step === 1 ? (
          <Fragment>
            <div className="col-12 page__card">
              <SelectInvoice selectedInvoice={this.state.selectedInvoice}
                             onSelectInvoice={invoice => {
                               this.setState({selectedInvoice: invoice});
                               this.getInvoice(invoice.id);
                               // this.getOfferProducts(invoice.quote_id);
                             }}
                             onDeselectInvoice={() => this.setState({selectedInvoice: null})}/>
            </div>

            {this.state.selectedInvoice ? (
              <div className="col-12 page__card">
                <SelectType selectedType={this.state.type}
                            onSelectType={type => this.setState({type})}
                            onDeselectType={() => this.setState({type: null})}/>
              </div>
            ) : null}
            
            {this.state.type ? (
              <div className="col-12 page__card">
                <div className="card">
                  <h2>Información general</h2>
                  <div className="row row--align-top">
                    {this.state.type === 1 ? (
                      <Fragment>
                        <h3 className="col-12">Información marítima</h3>
                        <div className="col-6 page__block">
                          <TextInput label="Naviera"
                                     name="shipping_company"
                                     value={this.state.shipment.shipping_company}
                                     onChange={this.onValueChanged}
                                     onDone={() => {}}/>
                        </div>
                        <div className="col-6 page__block">
                          <TextInput label="Buque"
                                     name="vessel"
                                     value={this.state.shipment.vessel}
                                     onChange={this.onValueChanged}
                                     onDone={() => {}}/>
                        </div>
                        <div className="col-12 page__block">
                          <TextInput label="Contenedor"
                                     name="container"
                                     value={this.state.shipment.container}
                                     onChange={this.onValueChanged}
                                     onDone={() => {}}/>
                        </div>
                      </Fragment>
                    ) : null}
                    {this.state.type === 2 ? (
                      <Fragment>
                        <h3 className="col-12">Información aérea</h3>
                        <div className="col page__block">
                          <TextInput label="Línea aérea"
                                     name="airline"
                                     value={this.state.shipment.airline}
                                     onChange={this.onValueChanged}
                                     onDone={() => {}}/>
                        </div>
                        <div className="col-4 page__block">
                          <TextInput label="Manifiesto"
                                     name="manifesto"
                                     value={this.state.shipment.manifesto}
                                     onChange={this.onValueChanged}
                                     onDone={() => {}}/>
                        </div>
                      </Fragment>
                    ) : null}
                    <h3 className="col-12">Tiempos</h3>
                    <div className="col-6 page__block">
                      <TextInput type="date"
                                 label="ETS"
                                 name="ETS"
                                 value={this.state.shipment.ETS}
                                 onChange={this.onValueChanged}
                                 onDone={() => {}}/>
                    </div>
                    <div className="col-6 page__block">
                      <TextInput type="date"
                                 label="ETA"
                                 name="ETA"
                                 value={this.state.shipment.ETA}
                                 onChange={this.onValueChanged}
                                 onDone={() => {}}/>
                    </div>
                  </div>

                  <div className="page__submit">
                    <button className="btn btn--primary"
                            onClick={() => this.setState({step: 2})}>Continuar</button>
                  </div>
                </div>
              </div>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <div className="col-12 page__card">
              <PackagesList packages={this.state.packages}
                            products={this.state.products}
                            search={this.search}
                            searchVisible={true}
                            addPackage={this.onAddPackage}
                            packageChanged={this.onPackageChanged}
                            packageChangeDone={(name, value, modified, index) => {
                              if (name === 'net_weight' || name === 'gross_weight')
                                this.onPackageChanged(parseToDecimal(value, 3), name, false, index);
                            }}
                            deletePackage={this.onDeletePackage}
                            selectProduct={index => {
                              /*const products = [...this.state.products];
                              products[index].selected = !products[index].selected;
                              this.setState({products});*/
                            }}
                            addProducts={(packageIndex, packageId) => {
                              // [...this.state.products].forEach((product, index) => product.selected && this.onAddProduct(packageIndex, packageId, index));
                            }}
                            addProduct={this.onAddProduct}
                            showModal={this.onSetShowModal}
                            productChanged={this.onProductChanged}
                            productChangeDone={(name, value, modified, index, productIndex) => {
                              if (name === 'net_weight' || name === 'gross_weight')
                                this.onProductChanged(parseToDecimal(value, 3), name, false, index, productIndex);
                            }}
                            deleteProduct={this.onDeleteProduct}
                            submit={this.createShipment}
                            loadingCreate={this.state.createLoading}
                            successCreate={this.state.createSuccess}
                            errorCreate={this.state.createError}/>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  }
}

export default protect(observer(ShipmentForm), settings.permissions.packingLists.create);
