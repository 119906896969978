
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const NewListItem = ({ children, onAdd }) => (
  <div className="new-list-item">
    <div className="new-list-item__content">{children}</div>
    <div className="new-list-item__footer">
      <button className="btn btn--outline btn--secondary btn--large"
              onClick={onAdd}>Agregar</button>
    </div>
  </div>
);

NewListItem.propTypes = {
  children: PropTypes.node,
  onAdd: PropTypes.func.isRequired
};

NewListItem.defaultProps = {
  children: null,
  onAdd: () => {}
};

export default NewListItem;
