/**
 * Parse date object to text representation
 * @param date
 * @returns {string|null}
 */
const fn = date => {
  if (date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
  } else {
    return null;
  }
};
export default fn;
