
import './ViewHeader.scss';
import { ReactComponent as LeftArrow } from '../../assets/images/left-arrow.svg';
import { ReactComponent as AngleRight } from '../../assets/images/right-arrow.svg';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavigationLink = ({link, children}) => {
  return (
    <Link
      className="page-header__link"
      to={link}
    >
      {children}
    </Link>
  );
};

NavigationLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const Navigation = ({loading, children}) => {
  if (loading)
    return (
      <div className="page-header__navigation">
        <div className="loading-block loading-block--small"
             style={{width: '12%'}}
        />
      </div>
    );

  const childrenArray = React.Children.toArray(children);
  const childrenLength = childrenArray.length;
  return (
    <div className="page-header__navigation">
      {React.Children.map(
        childrenArray,
        (child, index) => {
          const isLast = index === childrenLength - 1;
          return (
            <>
              {child}
              {!isLast && <AngleRight className="page-header__arrow"/>}
            </>
          );
        }
      )}
    </div>
  );
};

Navigation.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
};

Navigation.defaultProps = {
  loading: false,
};

const BackButton = ({onClick}) => (
  <button
    className="page-header__back"
    onClick={onClick}
  >
    <LeftArrow className="page__back-icon"/>
  </button>
);

BackButton.propTypes = {
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  onClick: () => {}
};

const Title = ({loading, children}) => (
  <h1 className="page-header__title col">
    {
      loading
        ? (
          <div
            className="loading-block"
            style={{width: '250px'}}
          />
        )
        : children
    }
  </h1>
);

Title.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
  loading: false,
};

const Filters = ({children}) => <div className="page-header__filters">{children}</div>;

Filters.propTypes = {
  children: PropTypes.node.isRequired,
};

const Filter = ({children}) => <div className="page-header__filter">{children}</div>;

Filter.propTypes = {
  children: PropTypes.node.isRequired,
};

const Actions = ({children}) => <div className="page-header__actions">{children}</div>;

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};

const Action = ({children}) => <div className="page-header__action">{children}</div>;

Action.propTypes = {
  children: PropTypes.node.isRequired,
};

const Types = ({children}) => <div className="page-header__types">{children}</div>;

Types.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewHeader extends React.Component {
  static NavigationLink = NavigationLink;
  static Navigation = Navigation;
  static BackButton = BackButton;
  static Title = Title;
  static Filters = Filters;
  static Filter = Filter;
  static Actions = Actions;
  static Action = Action;
  static Types = Types;

  render() {
    return (
      <div className="page-header">
        {
          React.Children.map(
            this.props.children,
            (child) => React.cloneElement(
              child,
              {
                loading: this.props.loading
              }
            )
          )
        }
      </div>
    );
  }
}

// export const ViewHeader = ({loading, children}) => {
//   return (
//
//   );
// };

ViewHeader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

ViewHeader.defaultProps = {
  loading: false,
};

// ViewHeader.NavigationLink = NavigationLink;
// ViewHeader.Navigation = Navigation;
// ViewHeader.BackButton = BackButton;
// ViewHeader.Title = Title;
// ViewHeader.Filters = Filters;
// ViewHeader.Filter = Filter;
// ViewHeader.Actions = Actions;
// ViewHeader.Action = Action;
// ViewHeader.Types = Types;

export default ViewHeader;
