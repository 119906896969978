
import axios from 'axios';

const URL_PREFIX = 'auth';

/**
 * Sign in
 * @param email
 * @param password
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
export const signIn = (email, password, source = null) => axios.post(
  URL_PREFIX,
  {
    username: email,
    password
  },
  {
    cancelToken: source && source.token,
    headers: {
      Authorization: false
    }
  }
);

export const changePassword = (email, oldPassword, newPassword, source) => axios.patch(
  `${URL_PREFIX}/user/password`,
  {
    username: email,
    password: oldPassword,
    new_password: newPassword
  },
  {
    cancelToken: source && source.token
  }
);

const methods = {
  signIn,
  changePassword
};
export default methods;
