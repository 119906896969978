
import React from 'react';
import PropTypes from 'prop-types';

import Card from '../Card';
import Loader from '../../components/Loader';

const SingleActionCard = ({children, disabled, loading, icon: Icon, actionEvent}) => (
  <Card loading={false}>
    <div className="row row--align-top">
      <div className="col">{children}</div>
      <div className="col col--min-content col--align-center">
        {loading ? (
          <Loader color="black" size="small"/>
        ) : !disabled ? (
          <button className="btn btn--small btn--icon btn--transparent"
                  onClick={() => actionEvent()}><Icon className="btn__icon"/></button>
        ) : null}
      </div>
    </div>
  </Card>
);

SingleActionCard.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  actionEvent: PropTypes.func.isRequired
};

export default SingleActionCard;