import React, { Fragment } from 'react';
import './index.scss'

const LimitAlert = ({limit}) => (
    <div className="alert alert-danger" role="alert">
     El producto tiene unidades restantes {limit}
    </div>
)

const ModalItemProductSplitter = ({showModal, Product, packageIndex, ProductChangedSplitted, AddProductToPackageSplitted}) =>{
    return <Fragment>
        <div className="darkBG" onClick={() => showModal(false)} />
        <div className="centered">
            <div className="modal">
            <div className="modalHeader">
                <h5 className="heading">Producto</h5>
                <h6>{Product.description}</h6>
            </div>
            
            <div className="modalContent">
                <span>Segmentar producto</span>
                <div className="input-group input-group--text ">
                    <div className='input-group__display'>
                        <input className="input-group__input" type="number" name="splitter" step="0.01" 
                        min="0.01" max={parseFloat(Product.available)}
                        value={parseFloat(Product.quantity)} onChange={(e)=>ProductChangedSplitted(Product, e.target.value)} style={{textAlign: 'center'}}/>
                    </div>
                </div>
            </div>
            <div className="modalActions">
                <div className="actionsContainer">
                <button className="deleteBtn" onClick={() => AddProductToPackageSplitted(Product, packageIndex)}>
                    splitter
                </button>
                <button
                    className="cancelBtn"
                    onClick={() => showModal(false)}
                >
                    cancelar
                </button>
                </div>
            </div>
            </div>
        </div>
    </Fragment>
}

export default ModalItemProductSplitter