import axios from 'axios';

const URL_PREFIX = 'admin/roles';

/**
 * Get all roles
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getRoles = (source = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token
  }
);

const apiFunctions = {
  getRoles
};

export default apiFunctions;
export {
  apiFunctions
};