
import axios from 'axios';

import mappers from './mappers';

const URL_PREFIX = 'invoice';

/**
 * Get all invoices
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getInvoices = (source = null, filters = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token,
    params: filters
  }
);

/**
 * Get invoice by its id
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getInvoice = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Create invoice
 * @param quoteId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createInvoice = (quoteId, data, source = null) => axios.post(
  `${URL_PREFIX}/quote/${quoteId}`,
  mappers.mapInvoiceToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update invoice with new data
 * @param id
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateInvoice = (id, data, source = null) => axios.patch(
  `${URL_PREFIX}/${id}`,
  mappers.mapInvoiceToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Download invoice report in excel format
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const downloadExcel = (id, source = null) => axios.get(
  `report/invoice/${id}`,
  {
    cancelToken: source && source.token,
    responseType: 'blob'
  }
);

/**
 * Remove product from invoice
 * @param invoiceId
 * @param productId
 * @param source
 * @return {Promise<AxiosResponse<any>>}
 */
const removeProduct = (invoiceId, productId, source = null) => axios.delete(
  `${URL_PREFIX}/${invoiceId}/product/${productId}`,
  {
    cancelToken: source && source.token,
  }
);

/**
 * Add product to already created invoice
 * @param quoteId
 * @param invoiceId
 * @param productId
 * @param source
 * @return {Promise<AxiosResponse<any>>}
 */
const addProduct = (quoteId, invoiceId, productId, source = null) => axios.patch(
  `quote/${quoteId}/product/${productId}`,
  {
    invoice_id: invoiceId
  },
  {
    cancelToken: source && source.token,
  }
);

const apiFunctions = {
  getInvoices,
  getInvoice,
  createInvoice,
  updateInvoice,
  downloadExcel,
  removeProduct,
  addProduct,
};

export default apiFunctions;
export {
  apiFunctions,
  mappers
};
