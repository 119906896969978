
import axios from 'axios';

const URL_PREFIX = 'dashboard';

/**
 * Get last quotes
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getQuotes = (source = null, filters = null) => axios.get(
  `${URL_PREFIX}/quote`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Gte last invoices
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getInvoices = (source = null, filters = null) => axios.get(
  `${URL_PREFIX}/invoice`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Get most offered products
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getProducts = (source = null, filters = null) => axios.get(
  `${URL_PREFIX}/product`,
  {
    cancelToken: source && source.token
  }
);


const apiFunctions = {
  getQuotes,
  getInvoices,
  getProducts
};

export default apiFunctions;
export {
  apiFunctions
};