
/**
 * Regular expression to validate password that contains:
 * - At least one lower case
 * - At least one upper case
 * - At least one number
 * @type {RegExp}
 */
const REGULAR_EXPRESSION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;

/**
 * Regular expression to validate password that contains at least one lower case
 * @type {RegExp}
 */
const VALIDATE_LOWER_CASE = /^(?=.*[a-z]).+$/;

/**
 * Regular expression to validate password that contains at least one upper case
 * @type {RegExp}
 */
const VALIDATE_UPPER_CASE = /^(?=.*[A-Z]).+$/;

/**
 * Regular expression to validate password that contains at least one number
 * @type {RegExp}
 */
const VALIDATE_NUMBER = /^(?=.*\d).+$/;

/**
 * Function to validate password input to check if it contains a lower case
 * @param password
 * @returns {*|boolean}
 */
const validateLowerCase = password => password && VALIDATE_LOWER_CASE.test(password.toString());

/**
 * Function to validate password input to check if it contains an upper case
 * @param password
 * @returns {*|boolean}
 */
const validateUpperCase = password => password && VALIDATE_UPPER_CASE.test(password.toString());

/**
 * Function to validate password input to check if it contains a number
 * @param password
 * @returns {*|boolean}
 */
const validateNumber = password => password && VALIDATE_NUMBER.test(password.toString());

/**
 * Function to validate password input to check if its between 8 and 16 characters
 * @param password
 * @returns {*|boolean}
 */
const validateLength = password => password && password.length >= 8 && password.length <= 16;

/**
 * Function to validate password input
 * @param password
 * @returns {*|boolean}
 */
const validate = (password) => password && REGULAR_EXPRESSION.test(password.toString());

export default validate;
export {
  validate,
  validateLowerCase,
  validateNumber,
  validateUpperCase,
  validateLength
};