
/**
 * Regular expression to validate number input
 * @type {RegExp}
 */
const REGULAR_EXPRESSION = /^[0-9]+(\.?)+([0-9]+)?$/;

/**
 * Function to validate number input
 * @param number
 * @returns {*|boolean}
 */
const fn = (number) => number && REGULAR_EXPRESSION.test(number.toString());
export default fn;
