
import validateEmail from './validateEmail';

/**
 * Validate email text inputs and provide well error message
 * @param value
 * @returns {string|boolean}
 */
const fn = value => {
    if (!value || value === '') {
        return 'Debe introducir un valor';
    } else if (!validateEmail(value)) {
        return 'Debe introducir un correo electrónico válido';
    } else {
        return false;
    }
};
export default fn;
