
const methods = {
  /**
   * Validate if select input has a selected option
   * @param value
   * @returns {*}
   */
  validateSelectInputNotEmpty: value => value ? false : 'Debe seleccionar una opción',

  /**
   * Validate if input contains an empty value and return a well error message
   * @param value
   * @returns {*}
   */
  validateInputNotEmpty: value => value ? false : 'Debe introducir un valor'
};
export default methods;
