
import './index.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import LoadingButton from '../LoadingButton';
import NewPackageItem from '../NewPackageItem';
import PackageItem from '../PackageItem';
import PackageProductsSelection from '../PackageProductsSelection';

class PackagesList extends React.Component {
  state = {
    search: null
  };
  
  
  render() {
    
    return (
      <div className="row row--align-top">
        <div className="col-6">
          <PackageProductsSelection packages={this.props.packages}
                                    products={this.props.products}
                                    initialLoading={this.props.initialLoading}
                                    searchVisible={this.props.searchVisible}
                                    disabled={this.props.disabled}
                                    searchText={this.state.search}
                                    searchChange={(value) => this.setState({search: value})}
                                    searchDone={this.props.search}
                                    selectProduct={this.props.selectProduct}
                                    addProduct={this.props.addProduct}
                                    addProducts={this.props.addProducts}
                                    showModal={this.props.showModal}
                                    />
                      
        </div>

        <div className="col-6">
          {this.props.packages.map((packageInfo, index) => (
            <PackageItem key={packageInfo.package}
                         loading={packageInfo.loading}
                         package_type={packageInfo.package_type}
                         albaranNumber={packageInfo.albaran_number}
                         package={index + 1}
                         dimension={packageInfo.dimension}
                         grossWeight={packageInfo.gross_weight}
                         netWeight={packageInfo.net_weight}
                         products={packageInfo.products}
                         disabled={this.props.disabled}
                         packageChanged={(value, name, error) => this.props.packageChanged(value, name, error, index)}
                         packageChangeDone={(name, value, modified) => this.props.packageChangeDone(name, value, modified, index)}
                         deletePackage={() => this.props.deletePackage(index)}
                         productChanged={(value, name, error, productIndex) => this.props.productChanged(value, name, error, index, productIndex)}
                         productChangeDone={(name, value, modified, productIndex) => this.props.productChangeDone(name, value, modified, index, productIndex)}
                         deleteProduct={productIndex => this.props.deleteProduct(index, productIndex)}/>
          ))}
          {!this.props.disabled ? (
            <NewPackageItem addPackage={packageInfo => this.props.addPackage({...packageInfo, package: this.props.packages.length + 1})}
                            initialLoading={this.props.initialLoading}/>
          ) : null}
          {this.props.submit ? (
            <div className="page__submit">
              <LoadingButton className="btn--primary"
                             loadingColor="black"
                             loading={this.props.loadingCreate}
                             success={this.props.successCreate ? 'Embarque creado' : false}
                             error={this.props.errorCreate}
                             onClick={() => this.props.submit()}>Crear embarque</LoadingButton>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

PackagesList.propTypes = {
  disabled: PropTypes.bool.isRequired,
  packages: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  initialLoading: PropTypes.bool,
  loadingCreate: PropTypes.bool,
  successCreate: PropTypes.bool,
  errorCreate: PropTypes.bool,
  searchVisible: PropTypes.bool,
  search: PropTypes.func,
  submit: PropTypes.func,
  addPackage: PropTypes.func.isRequired,
  packageChanged: PropTypes.func.isRequired,
  packageChangeDone: PropTypes.func,
  deletePackage: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  addProducts: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  productChanged: PropTypes.func.isRequired,
  productChangeDone: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

PackagesList.defaultProps = {
  disabled: false,
  packages: [],
  products: [],
  initialLoading: false,
  loadingCreate: false,
  successCreate: false,
  errorCreate: false,
  addPackage: () => {},
  packageChanged: () => {},
  packageChangeDone: () => {},
  deletePackage: () => {},
  addProduct: () => {},
  addProducts: () => {},
  selectProduct: () => {},
  productChanged: () => {},
  productChangeDone: () => {},
  deleteProduct: () => {},
  showModal: ()=>{},
  isOpen: false
};

export default PackagesList;
