
import './index.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import Loader from '../Loader';
import { TableBodyRow, TableBodyCell } from '../Table';

class PackageProductItem extends React.Component {
  render() {
    const {
      part_number,
      description,
      unit,
      quantity,
      gross_weight,
      net_weight,
      loading
    } = this.props;
    return (
      <TableBodyRow>
        <TableBodyCell nowrap={true}>{part_number}</TableBodyCell>
        <TableBodyCell>{description}</TableBodyCell>
        <TableBodyCell nowrap={true}>{unit}</TableBodyCell>
        <TableBodyCell>
          <TextInput name="quantity"
                     value={quantity}
                     information={true}
                     disabled={this.props.disabled}
                     onChange={this.props.productChanged}
                     onDone={this.props.productChangeDone}/>
        </TableBodyCell>
        <TableBodyCell>
          <TextInput name="gross_weight"
                     value={gross_weight}
                     information={true}
                     disabled={this.props.disabled}
                     onChange={this.props.productChanged}
                     onDone={this.props.productChangeDone}/>
        </TableBodyCell>
        <TableBodyCell>
          <TextInput name="net_weight"
                     value={net_weight}
                     information={true}
                     disabled={this.props.disabled}
                     onChange={this.props.productChanged}
                     onDone={this.props.productChangeDone}/>
        </TableBodyCell>
        <TableBodyCell className="table__cell--actions">
          {loading ? (
            <Loader color="black" size="small"/>
          ) : !this.props.disabled ? (
            <button className="btn btn--icon btn--transparent btn--small"
                    onClick={() => this.props.deleteProduct()}><CloseIcon className="btn__icon"/></button>
          ) : null}
        </TableBodyCell>
      </TableBodyRow>
    );
  }
}

PackageProductItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  part_number: PropTypes.any.isRequired,
  name: PropTypes.any,
  description: PropTypes.string,
  unit: PropTypes.any.isRequired,
  quantity: PropTypes.any.isRequired,
  gross_weight: PropTypes.any.isRequired,
  net_weight: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  productChanged: PropTypes.func.isRequired,
  productChangeDone: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired
};

PackageProductItem.defaultProps = {
  disabled: false,
  loading: false,
  packageChanged: () => {},
  productChangeDone: () => {},
  deleteProduct: () => {}
};

export default PackageProductItem;
