
import './index.scss';
import { ReactComponent as RightArrowIcon } from '../../assets/images/right-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left-caret-arrow.svg';

import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';

class Pagination extends React.Component {
  state = {
    inputPage: null
  };

  constructor(props) {
    super(props);

    this.inputPageChanged = this.inputPageChanged.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  inputPageChanged(value) {
    this.setState({inputPage: value});
  }

  onPageChanged(value) {
    const page = Number(value);
    if (page && page >= 1 && page <= this.props.totalPages) {
      this.props.onChange(page);
    }
  }

  render() {
    const { currentPage, totalPages, onChange, margin } = this.props;
    let pages = [];
    let minPage = currentPage - 5;
    let maxPage = currentPage + 5;
    const maxDifference = maxPage - totalPages;
    const minDifference = 1 - minPage;

    if (maxDifference > 0) {
      // si max se pasa, agrega diferencia en menor
      maxPage = totalPages;
      minPage -= maxDifference;

      if (minPage < 1) {
        minPage = 1;
      }
    } else if (minDifference > 0) {
      // si menor se pasa, agrega diferencia en mayor
      minPage = 1;
      maxPage += minDifference;

      if (maxPage > totalPages) {
        maxPage = totalPages;
      }
    }

    for (let page = minPage; page <= maxPage; page++) {
      pages.push(<button className={`btn btn--transparent pagination__page ${currentPage === page ? 'pagination__page--active' : ''}`}
                         key={page}
                         onClick={() => onChange(page)}>{page}</button>);
    }

    return (
      <div className={`pagination row row--align-center row--no-wrap pagination--margin-${margin}`}>
        <div className="pagination__status col col--min-content">
          Página {currentPage} de {totalPages}
        </div>
        <div className="pagination__page-selector col">
          <button className="btn btn--icon pagination__page"
                  onClick={() => {
                    const newPage = this.props.currentPage - 1;
                    if (newPage >= 1) {
                      this.props.onChange(newPage);
                    }
                  }}><LeftArrowIcon className="pagination__arrow"/></button>
          {pages}
          <button className="btn btn--icon pagination__page"
                  onClick={() => {
                    const newPage = this.props.currentPage + 1;
                    if (newPage <= this.props.totalPages) {
                      this.props.onChange(newPage);
                    }
                  }}><RightArrowIcon className="pagination__arrow"/></button>
        </div>
        <div className="pagination__page-input col col--min-content">
          <span>Ir a página</span>
          <TextInput type="number"
                     inline={true}
                     information={true}
                     placeholder={currentPage}
                     value={this.state.inputPage}
                     onChange={this.inputPageChanged}
                     onDone={(name, value, modified) => modified && this.onPageChanged(value)}/>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  margin: PropTypes.oneOf(['page', 'select']).isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onChange: PropTypes.func
};

Pagination.defaultProps = {
  margin: 'page',
  onChange: () => {}
};

export default Pagination;
