
import './SelectOffer.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import api from '../../api';
import { EmptyNotification, ListPagination, TextInput } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell } from '../../components/Table';
import { Card } from '../../containers';
import authStore from '../../stores/auth';

class SelectQuote extends React.Component {
  state = {
    quotes: [],
    quotesLoaded: false,
    loadingQuotes: false,
    error: false,
    description: null,
    page: 1,
    pages: 1
  };
  source = api.source.createSource();

  constructor(props) {
    super(props);

    this.getOffers = this.getOffers.bind(this);
    this.selectQuote = this.selectQuote.bind(this);
    this.deselectQuote = this.deselectQuote.bind(this);
  }

  componentDidMount() {
    const quoteId = this.props.match.params.quoteId;

    if (!quoteId) {
      this.getOffers();
    }
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  getOffers(selectedPage) {
    this.setState({loadingQuotes: true});
    api.quote.getQuotes(this.source, {description: this.state.description, page: selectedPage || this.state.page, invoiced: '0'})
      .then(response => {
        const quotes = response.data.data;
        const pages = response.data.pages;
        this.setState({quotes, pages: pages, quotesLoaded: true, loadingQuotes: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingQuotes: false, error: err});
        }
      });
  }

  selectQuote(quote) {
    this.props.onSelectQuote(quote);
  }

  deselectQuote() {
    if (!this.state.quotesLoaded) {
      this.getOffers();
    }
    this.props.onDeselectQuote();
  }

  render() {
    if (this.props.selectedQuote) {
      return (
        <div className="select-offer page__card">
          <Card title="Oferta"
                actions={[
                  <button className="btn btn--icon btn--small btn--transparent"
                          onClick={() => this.deselectQuote()}><CloseIcon className="btn__icon"/></button>
                ]}>
            <div className="select-offer__offers">
              <div className="select-offer__offer">
                <div className="row row--align-center">
                  <div className="col-5">
                    <div className="row">
                      <div className="col-12 select-offer__label select-offer__label--title">{this.props.selectedQuote.id}</div>
                      <div className="col-12 select-offer__label">{this.props.selectedQuote.client}</div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <div className="col-12 select-offer__label">{this.props.selectedQuote.model}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    } else {
      return (
        <div className="card card--instructions">
          <div className="row row--align-center">
            <div className="col-8">
              <h2>Oferta</h2>
              <span className="card__instructions">Selecciona la oferta a la cual anexar la factura</span>
            </div>
            <div className="col-4">
              <TextInput placeholder="Buscar"
                         value={this.state.description}
                         onChange={(value) => this.setState({description: value})}
                         onDone={(name, value, modified) => modified && this.getOffers()}/>
            </div>
            <div className="col-12 select-offer__offers">
              <Table loading={this.state.loadingQuotes}
                     loadingRows={5}
                     loadingCells={3}
                     errorState={<EmptyNotification icon={DisconnectIcon}
                                                    title={this.state.error || ''}
                                                    details="Ha ocurrido un error obteniendo las ofertas."/>}
                     emptyState={<EmptyNotification icon={NotFoundIcon}
                                                    title="No se encontrarón ofertas"
                                                    details="No se encontraron ofertas pendientes de facturación."
                                                    action={authStore.permissions.quotes.create ? 'Nueva oferta' : null}
                                                    onAction={() => authStore.permissions.quotes.create && this.props.history.push(`/offers/new`)}/>}
                     headerCells={
                       <Fragment>
                         <TableHeaderCell>Código</TableHeaderCell>
                         <TableHeaderCell>Cliente</TableHeaderCell>
                         <TableHeaderCell>Modelo</TableHeaderCell>
                       </Fragment>
                     }
                     pagination={<ListPagination margin="select"
                                                 totalPages={this.state.pages}
                                                 currentPage={this.state.page}
                                                 onChange={page => {
                                                   this.setState({page});
                                                   this.getOffers(page);
                                                 }}/>}>
                {this.state.error || this.state.quotes.map(quote => (
                  <TableBodyRow key={quote.id}
                                onSelect={() => this.selectQuote(quote)}>
                    <TableBodyCell nowrap={true}>{quote.id}</TableBodyCell>
                    <TableBodyCell>{quote.client}</TableBodyCell>
                    <TableBodyCell>{quote.model}</TableBodyCell>
                  </TableBodyRow>
                ))}
              </Table>
            </div>
          </div>
        </div>
      );
    }
  }
}

SelectQuote.propTypes = {
  selectedQuote: PropTypes.any,
  onSelectQuote: PropTypes.func.isRequired,
  onDeselectQuote: PropTypes.func.isRequired
};

SelectQuote.defaultProps = {
  selectedQuote: null,
  onSelectQuote: () => {},
  onDeselectQuote: () => {}
};

export default withRouter(SelectQuote);
