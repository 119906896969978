
import './ProductItem.scss';
import { ReactComponent as AddBorderedIcon } from '../../assets/images/add-bordered.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/caret-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/caret-down.svg';

import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import settings from '../../settings';
import {
  parseToDecimal,
  parseDateReadable,
} from '../../utils';
import ProductsListContext from './ProductsListContext';
import SelectInput from '../SelectInput/input';
import SelectOption from '../SelectInput/option';
import TextInput from '../TextInput';

const Actions = ({ children }) => (
  <td className="product-item__cell product-item__cell--actions">
    {children}
  </td>
);

Actions.propTypes = {
  children: PropTypes.node,
};

export const BaseProduct = (props) => {
  const {
    newItem,
    isSubItem,
    selected,
    disabled,
    data,
    onSelect,
    onAddSubItem,
    onValueChange,
    onInputDone,
    children,
  } = props;
  const {
    showItemNumber,
    showInvoice,
  } = useContext(ProductsListContext);
  const history = useHistory();
  const [expandDescription, setExpandDescription] = useState(false);

  let className = 'product-item';
  if (isSubItem)
    className += ' product-item--sub-item';
  if (selected)
    className += ' product-item--selected';
  if (newItem)
    className += ' product-item--new';

  const productDescription = useMemo(
    () => {
      if (!data.description)
        return null;

      return !expandDescription && data.description.length > 50
        ? `${data.description.toString().slice(0, 50)}...`
        : data.description;
    },
    [
      data.description,
      expandDescription,
    ]
  );

  const lastPriceGreater = useMemo(
    () => {
      if (!data.lastPrice || !data.total)
        return null;

      const totalNumber = Number(data.total) || 0;
      const lastPriceNumber = Number(data.lastPrice.last_price) || 0;

      if (totalNumber > lastPriceNumber)
        return true;
      else if (totalNumber < lastPriceNumber)
        return false;
      else
        return null;
    },
    [
      data.total,
      data.lastPrice
    ]
  );

  return (
    <tr className={className}>
      {
        onSelect && (
          <td className="product-item__cell product-item__cell--selection">
            <input
              type="checkbox"
              checked={selected}
              onChange={(event) => onSelect(event.target.checked)}
            />
          </td>
        )
      }
      {
        onAddSubItem && (
          <td className="product-item__cell product-item__cell--add-subitem">
            <button
              className="product-item__add-subitem"
              onClick={onAddSubItem}
            >
              <AddBorderedIcon/>
            </button>
          </td>
        )
      }
      {
        showItemNumber && (
          <td className="product-item__cell product-item__cell--item-number">{data.item_no}</td>
        )
      }
      <td className="product-item__cell product-item__cell--code">
        {
          data.part_number || ''
        }
        {
          showInvoice && data.invoice_id && (
            <button
              className="btn btn--success btn--badge"
              onClick={(event) => {
                history.push(`/invoices/${data.invoice_id}`);
                event.stopPropagation();
              }}
            >
              {data.invoice_id}
            </button>
          )
        }
      </td>
      <td className={`product-item__cell ${isSubItem ? 'product-item__cell--sub-item' : null}`}>
        {
          newItem
            ? (
              <SelectInput
                name='product_id'
                size="big"
                value={data.name}
                disabled={disabled}
                searchEnabled={true}
                information={!newItem}
                onChange={onValueChange}
              >
                {
                  ([]).map((product, index) => (
                    <SelectOption
                      key={product.id}
                      display={product.part_number}
                      value={product.id}
                    >
                      {product.part_number} - {product.description}
                    </SelectOption>
                  ))
                }
              </SelectInput>
            )
            : (
              <div
                className={
                  'product-item__description'
                  + (productDescription && data.description.length > 50 ? ' product-item__description--expandable' : '')
                }
                onClick={() => setExpandDescription(!expandDescription)}
              >
                {productDescription}
              </div>
            )
        }
      </td>
      <td className="product-item__cell product-item__cell--code">{data.harmonized_code}</td>
      <td className="product-item__cell product-item__cell--type">
        <SelectInput
          name="unit"
          value={data.unit}
          disabled={disabled}
          information={true}
          onChange={onValueChange}
          onDone={onInputDone}
        >
          {
            settings.units
              .map((unit) => (
                <SelectOption
                  key={unit}
                  value={unit}
                >
                  {unit}
                </SelectOption>
              ))
          }
        </SelectInput>
      </td>
      <td className="product-item__cell product-item__cell--amount">
        <TextInput
          type="number"
          name="cost"
          value={data.cost}
          disabled={disabled}
          information={true}
          onChange={onValueChange}
          onDone={onInputDone}
        />
      </td>
      <td className="product-item__cell product-item__cell--amount">
        <TextInput
          type="number"
          name="margin"
          value={data.margin}
          disabled={disabled}
          information={true}
          onChange={onValueChange}
          onDone={onInputDone}
        />
      </td>
      <td className="product-item__cell product-item__cell--amount">
        <TextInput
          type="number"
          name="price"
          value={data.price}
          disabled={disabled}
          information={true}
          tooltipIcon={lastPriceGreater === true ? ArrowUpIcon : (lastPriceGreater === false ? ArrowDownIcon : null)}
          onChange={onValueChange}
          onDone={onInputDone}
        >
          {
            data.lastPrice && (
              <>
                <div>Último precio: {parseToDecimal(data.lastPrice.last_price)} €</div>
                {
                  data.lastPrice.last_cost && (
                    <div>Último costo: {parseToDecimal(data.lastPrice.last_cost)} €</div>
                  )
                }
                {
                  data.lastPrice.quote_id && (
                    <div>Oferta: <Link to={`/offers/${data.lastPrice.quote_id}`}>{data.lastPrice.quote_id}</Link></div>
                  )
                }
                {
                  data.lastPrice.invoice && (
                    <div>Factura: {<Link to={`/invoices/${data.lastPrice.invoice}`}>{data.lastPrice.invoice}</Link>}</div>
                  )
                }
                <div>Fecha: {parseDateReadable(data.lastPrice.last_date, false, '/')}</div>
              </>
            )
          }
        </TextInput>
      </td>
      <td className="product-item__cell product-item__cell--amount">
        <TextInput
          type="number"
          name="quantity"
          value={data.quantity}
          disabled={disabled}
          information={true}
          onChange={onValueChange}
          onDone={onInputDone}
        />
      </td>
      <td className="product-item__cell product-item__cell--amount">{data.total}</td>
      {children}
    </tr>
  );
};

BaseProduct.propTypes = {
  newItem: PropTypes.bool,
  isSubItem: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.shape({
    item_no: PropTypes.any,
    invoice_id: PropTypes.any,
    name: PropTypes.any,
    part_number: PropTypes.any,
    description: PropTypes.any,
    harmonized_code: PropTypes.any,
    unit: PropTypes.any,
    quantity: PropTypes.any,
    cost: PropTypes.any,
    lastPrice: PropTypes.shape({
      last_price: PropTypes.any,
      last_cost: PropTypes.any,
      quote_id: PropTypes.string,
      invoice: PropTypes.string,
      last_date: PropTypes.any,
    })
  }).isRequired,
  onAddSubItem: PropTypes.func,
  onSelect: PropTypes.func,
  onValueChange: PropTypes.func,
  onInputDone: PropTypes.func,
  children: PropTypes.node,
};

BaseProduct.defaultProps = {
  newItem: false,
  isSubItem: false,
  selected: false,
  disabled: false,
  onValueChange: () => {},
  onInputDone: () => {},
};

BaseProduct.Actions = Actions;

export default observer(BaseProduct);
