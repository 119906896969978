
import axios from 'axios';

const URL_PREFIX = 'auth/user';

/**
 * Create user
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createUser = (data, source = null) => axios.post(
  URL_PREFIX,
  data,
  {cancelToken: source && source.token}
);

const methods = {
  createUser
};
export default methods;
