
import React, { useContext } from 'react';

export const InvoiceContext = React.createContext({});

export const InvoiceProducer = InvoiceContext.Provider;

export const InvoiceConsumer = InvoiceContext.Consumer;

export const useInvoiceContext = () => {
  return useContext(InvoiceContext);
};

export default InvoiceContext;
