
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({size, color, fill, onClick, children}) => (
  <div className={`badge badge--${size} badge--${color} ${fill ? 'badge--fill' : ''}`}
       onClick={event => {
         if (onClick) {
           onClick();
           event.stopPropagation();
         }
       }}>{children}</div>
);

Badge.propTypes = {
  size: PropTypes.oneOf(['small', 'mid']).isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'delete', 'success']).isRequired,
  fill: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

Badge.defaultProps = {
  size: 'mid',
  color: 'primary',
  fill: false
};

export default Badge;