
import './index.scss';

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { authStore } from '../../stores';
import { Header } from '../../components';
import SupplierForm from "../SupplierForm";

import ClientForm from '../ClientForm';
import ClientsList from '../ClientsList';
import Client from '../Client';

import ProductForm from '../ProductForm';
import ProductsList from '../ProductsList';
import Product from '../Product';

import SuppliersList from '../SuppliersList';
import Supplier from '../Supplier';

import OffersList from '../OffersList';
import NewOffer from '../NewOffer';
import Offer from '../Offer';
import InvoiceForm from '../InvoiceForm/InvoiceForm';
import InvoiceFormOld from '../InvoiceForm/InvoiceFormOld';
import InvoicesList from '../InvoicesList';
import Invoice from '../Invoice';
import ShipmentsList from '../ShipmentsList';
import ShipmentForm from '../ShipmentForm';
import Shipment from '../Shipment';

import ChangePassword from '../ChangePassword';

import UserForm from '../UserForm';

import NoOfferFound from '../NoOfferFound';
import NoInvoiceFound from '../NoInvoiceFound';
import NoClientFound from '../NoClientFound';
import NoProductFound from '../NoProductFound';
import NoSupplierFound from '../NoSupplierFound';
import NoShipmentFound from '../NoShipmentFound';

import Dashboard from '../Dashboard';

class App extends React.Component {
  render() {
    const { match } = this.props;
    if (authStore.isLogged) {
      return (
        <div className="app">
          <Header/>
          <section className="app__content">
            <Switch>
              <Route path={`${match.path}change-password`} exact component={ChangePassword} />

              <Route path={`${match.path}admin/suppliers/not-found`} exact component={NoSupplierFound} />
              <Route path={`${match.path}admin/suppliers/new`} exact component={SupplierForm} />
              <Route path={`${match.path}admin/suppliers/:id`} exact component={Supplier} />
              <Route path={`${match.path}admin/suppliers`} exact component={SuppliersList} />

              <Route path={`${match.path}admin/clients/new`} exact component={ClientForm} />
              <Route path={`${match.path}admin/clients/not-found`} exact component={NoClientFound} />
              <Route path={`${match.path}admin/clients/:id`} exact component={Client} />
              <Route path={`${match.path}admin/clients`} exact component={ClientsList} />

              <Route path={`${match.path}admin/users/new`} exact component={UserForm} />

              <Route path={`${match.path}products/new`} exact component={ProductForm} />
              <Route path={`${match.path}products/not-found`} exact component={NoProductFound} />
              <Route path={`${match.path}products/:id`} exact component={Product} />
              <Route path={`${match.path}products`} exact component={ProductsList} />

              <Route path={`${match.path}offers/new/from/:id`} exact component={NewOffer} />
              <Route path={`${match.path}offers/new`} exact component={NewOffer} />
              <Route path={`${match.path}offers/not-found`} exact component={NoOfferFound} />
              <Route path={`${match.path}offers/:id`} exact component={Offer} />
              <Route path={`${match.path}offers`} exact component={OffersList} />

              <Route path={`${match.path}invoices`} exact component={InvoicesList} />
              <Route path={`${match.path}invoices/new`} exact component={InvoiceForm} />
              <Route path={`${match.path}invoices/old`} exact component={InvoiceFormOld} />
              <Route path={`${match.path}invoices/not-found`} exact component={NoInvoiceFound} />
              <Route path={`${match.path}offers/:quoteId/invoices/new`} exact component={InvoiceForm} />
              <Route path={`${match.path}invoices/:id`} exact component={Invoice} />

              <Route path={`${match.path}shipments`} exact component={ShipmentsList} />
              <Route path={`${match.path}shipments/new`} exact component={ShipmentForm} />
              <Route path={`${match.path}shipments/not-found`} exact component={NoShipmentFound} />
              <Route path={`${match.path}shipments/:id`} exact component={Shipment} />
              <Route path={`${match.path}invoices/:invoiceId/shipments/new`} exact component={ShipmentForm} />

              <Route path={`${match.path}`} exact component={Dashboard} />

              <Route path="*" render={() => <Redirect to="/"/>}/>
            </Switch>
          </section>
        </div>
      );
    } else {
      return <Redirect to="/sign-in"/>
    }
  }
}

export default observer(App);
