
/**
 * Map product to API format
 * @param invoice
 * @returns {{}}
 */
export const mapInvoiceToAPI = invoice => ({
  ...invoice,
  incoterm_price: invoice.incoterm_price && Number(invoice.incoterm_price),
  packing: invoice.packing && Number(invoice.packing),
  freight: invoice.freight && Number(invoice.freight),
  insurance: invoice.insurance && Number(invoice.insurance),
  discount: invoice.discount && Number(invoice.discount),
  financing_period: invoice.financing_period && Number(invoice.financing_period),
  financing_percentage: invoice.financing_percentage && Number(invoice.financing_percentage)
});

const methods = {
  mapInvoiceToAPI
};
export default methods;
