
import React from 'react';
import PropTypes from 'prop-types';

import { parseInputValue } from '../../utils';

class TextAreaInput extends React.Component {
  state = {
    error: null,
    focus: false,
  };
  inputReference;

  constructor(props) {
    super(props);

    this.valueChange = this.valueChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  setFocus() {
    this.inputReference.focus();
  }

  onFocus(event) {
    this.setState({focus: true});
  }

  onBlur() {
    this.setState({focus: false});
  }

  valueChange(event) {
    const name = event.target.name;
    const value = parseInputValue(event);
    const errorText = this.props.validateFn(value);

    this.setState({error: errorText});
    this.props.onChange(value, name, errorText);
  }

  render() {
    const { information, label, placeholder, value, disabled, name } = this.props;
    let classNames = 'input-group';

    if (information) {
      classNames += ' input-group--information';
    }

    if (this.state.error || this.props.error) {
      classNames += ' input-group--error';
    } else if (this.state.focus) {
      classNames += ' input-group--focus';
    }

    return (
      <div className={classNames}>
        <div className="input-group__container">
          {label ? <label className="input-group__label" onClick={this.setFocus}>{label}</label> : null}
          <textarea ref={input => this.inputReference = input}
                    className="input-group__input"
                    name={name}
                    onChange={this.valueChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}/>
        </div>
        <div className="input-group__error">{this.state.error || this.props.error}</div>
      </div>
    );
  }
}

TextAreaInput.propTypes = {
  information: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.any.isRequired,
  validateFn: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.any,
  name: PropTypes.string
};

TextAreaInput.defaultProps = {
  information: false,
  validateFn: () => false, // False means that no error has ocurred
  disabled: false,
  error: null
};

export default TextAreaInput;
