
/**
 * Regular expression to validate email input
 * @type {RegExp}
 */
// eslint-disable-next-line
const REGULAR_EXPRESSION = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Function to validate email input
 * @param email
 * @returns {*|boolean}
 */
const fn = (email) => email && REGULAR_EXPRESSION.test(email.toString().toLowerCase());
export default fn;
