
import './index.scss';
import { ReactComponent as AddIcon } from '../../assets/images/add.svg';
import { ReactComponent as AddBorderedIcon } from '../../assets/images/add-bordered.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/close.svg';
import { ReactComponent as TransferIcon } from '../../assets/images/transfer.svg';
import { ReactComponent as RetryIcon } from '../../assets/images/retry.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/caret-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/caret-down.svg';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import settings from '../../settings';
import TextInput from '../TextInput';
import SelectInput from '../SelectInput/input';
import SelectOption from '../SelectInput/option';
import Loader from '../Loader';
import parseToDecimal from '../../utils/parseToDecimal';
import parseDateReadable from '../../utils/parseDateReadable';
import Dropdown from '../Dropdown';

class ProductItem extends React.Component {
  state = {
    showFullDescription: false
  };

  constructor(props) {
    super(props);   
    this.getPossibleAction = this.getPossibleAction.bind(this);
  }


  getPossibleAction() {
    if (this.props.error) {
      return <button className="btn btn--error btn--small btn--icon"
                     onClick={() => this.props.onRetry()}><RetryIcon className="btn__icon"/></button>
    } else if (this.props.loading) {
      return <Loader color="black" size="small"/>;
    } else if (this.props.newItem) {
      return <button className="btn btn--secondary btn--small btn--icon" onClick={() => this.props.onNew()}><AddIcon className="btn__icon"/></button>;
    } else if (!this.props.disabled || this.props.actionsVisible) {
      const deleteButton = (
        <>
        <button className="btn btn--transparent btn--small btn--icon"
                onClick={() => !this.props.disabled && this.props.onDelete()}><DeleteIcon className="btn__icon"/></button>
        {(this.props.totalProducts > 1 && this.props.index > 0 && !this.props.isOptional) &&
          <button className="btn btn--transparent btn--small btn--icon"
                onClick={() => this.props.onUp()}><ArrowUpIcon className="btn__icon"/></button>
        }
        {(this.props.totalProducts > 1 && this.props.index !== this.props.totalProducts - 1 && !this.props.isOptional) &&
          <button className="btn btn--transparent btn--small btn--icon"
                  onClick={() => this.props.onDown()}><ArrowDownIcon className="btn__icon"/></button>
          
        }
        </>
      );
      const invoiceTransferButon = (
        <Dropdown.Container
          color="gray"
          size="small"
          icon={TransferIcon}
          showCaret={true}
          inline={true}
        >
          {
            (this.props.availableInvoices || []).map(invoice => (
              <Dropdown.Button
                key={invoice}
                onClick={() => {
                  this.props.onTransfer('add_to_invoice', invoice);
                }}
              >
                {invoice}
              </Dropdown.Button>
            ))
          }
        </Dropdown.Container>
      );

      const transferButton = (
        <button
          className="btn btn--transparent btn--small btn--icon"
          onClick={() => !this.props.disabled && this.props.onTransfer('remove_from_invoice', this.props.invoiceId)}
        >
          <TransferIcon className="btn__icon"/>
        </button>
      );

      return <>
        {
          this.props.deleteVisible && deleteButton
        }
        {
          this.props.transferVisible
          && (
            (this.props.availableInvoices && this.props.availableInvoices.length > 0 && !this.props.invoiceId)
              ? invoiceTransferButon
              : (
                (this.props.isOptional || this.props.invoiceId) && transferButton
              )
          )
        }
      </>
    } else {
      return null;
    }
  }

  render() {
    const {
      selected,
      disabled,
      itemNumber,
      invoiceId,
      name,
      partNumber,
      description,
      harmonizedCode,
      unitItem,
      quantity,
      cost,
      price,
      margin,
      total,
      subItem,
      newItem,
      products,
      loading,
      allowSubItem,
      costVisible,
      invoiceVisible,
      onValueChange,
      onInputDone,
      onAddSubItem,
      onSelectionChange
    } = this.props;
    const { showFullDescription } = this.state;
    let classNames = 'product-item';

    if(subItem) {
      classNames += ' product-item--sub-item';
    } else if(newItem) {
      classNames += ' product-item--new';
    }

    if (selected)
      classNames += ' product-item--selected';

    let lastPriceGreater = null;

    if (this.props.lastPrice && total) {
      const totalNumber = Number(total) || 0;
      const lastPriceNumber = Number(this.props.lastPrice.last_price) || 0;

      if (totalNumber > lastPriceNumber) {
        lastPriceGreater = true;
      } else if (totalNumber < lastPriceNumber) {
        lastPriceGreater = false;
      }
    }

    return (
      <tr className={classNames} onContextMenu={this._handleContextMenu}>
          <td className="product-item__cell product-item__cell--selection">
            {allowSubItem && !subItem && !newItem && !loading && !disabled ? (
              <input type="checkbox"
                    checked={selected}
                    onChange={(event) => onSelectionChange(event.target.checked)}/>
            ) : null}
          </td>
          <td className="product-item__cell product-item__cell--add-subitem">
            {allowSubItem && !subItem && !newItem && !loading && !disabled && this.props.newVisible ? <button className="product-item__add-subitem"
                                                                        onClick={() => !this.props.disabled && onAddSubItem()}><AddBorderedIcon/></button> : null}
          </td>
          {itemNumber ? <td className="product-item__cell product-item__cell--item-number">{itemNumber}</td> : null}
          <td className="product-item__cell product-item__cell--code">
            {partNumber || ''}
            {
              invoiceVisible && invoiceId && (
                <button
                  className="btn btn--success btn--badge"
                  onClick={(event) => {
                    this.props.history.push(`/invoices/${invoiceId}`);
                    event.stopPropagation();
                  }}
                >{invoiceId}</button>
              )
            }
          </td>
          <td className={`product-item__cell ${subItem ? 'product-item__cell--sub-item' : null}`}>
            <div>{newItem ? (
              <SelectInput name='product_id'
                          value={name}
                          searchEnabled={true}
                          onChange={onValueChange}
                          onClose={() => this.setState({productSearch: null})}
                          information={!newItem}
                          disabled={this.props.disabled}
                          size="big">
                {products.map((product, index) => (
                  <SelectOption value={product.id}
                                key={product.id}
                                display={product.part_number}>{product.part_number} - {product.description}</SelectOption>
                ))}
              </SelectInput>
              // eslint-disable-next-line
            ) : (description ? (
              <div className={`product-item__description ${description.length > 50 ? 'product-item__description--expandable' : ''}`}
                  onClick={() => this.setState({showFullDescription: !showFullDescription})}>
                {!newItem && !showFullDescription && description.length > 50 ? `${description.toString().slice(0, 50)}...` : description}
              </div>
            ) : null)}</div>
          </td>
          <td className="product-item__cell product-item__cell--code">{harmonizedCode}</td>
          <td className="product-item__cell product-item__cell--type">
            <SelectInput name="unit"
                        onChange={onValueChange}
                        onDone={onInputDone}
                        value={unitItem}
                        information={!newItem}
                        disabled={this.props.disabled}>
              {settings.units.map(unit => <SelectOption value={unit} key={unit}>{unit}</SelectOption>)}
            </SelectInput>
          </td>
          <td className="product-item__cell product-item__cell--amount">
            {costVisible || newItem ? (
              <TextInput type="number"
                        name="cost"
                        value={cost}
                        onChange={onValueChange}
                        onDone={onInputDone}
                        information={!newItem}
                        disabled={this.props.disabled}/>
            ) : null}
          </td>
          <td className="product-item__cell product-item__cell--amount">
            <TextInput type="number"
                      name="margin"
                      value={margin}
                      onChange={onValueChange}
                      onDone={onInputDone}
                      information={!newItem}
                      disabled={this.props.disabled}/>
          </td>
          <td className="product-item__cell product-item__cell--amount">
            <TextInput type="number"
                      name="price"
                      value={price}
                      onChange={onValueChange}
                      onDone={onInputDone}
                      information={!newItem}
                      tooltipIcon={lastPriceGreater === true ? ArrowUpIcon : (lastPriceGreater === false ? ArrowDownIcon : null)}
                      disabled={this.props.disabled}>
              {this.props.lastPrice ? (
                <Fragment>
                  <div>Último precio: {parseToDecimal(this.props.lastPrice.last_price)} €</div>
                  {this.props.lastPrice.last_cost ? <div>Último costo: {parseToDecimal(this.props.lastPrice.last_cost)} €</div> : null}
                  {this.props.lastPrice.quote_id ?
                    <div>Oferta: <Link to={`/offers/${this.props.lastPrice.quote_id}`}>{this.props.lastPrice.quote_id}</Link></div> : null}
                  {this.props.lastPrice.invoice ?
                    <div>Factura: {<Link to={`/invoices/${this.props.lastPrice.invoice}`}>{this.props.lastPrice.invoice}</Link>}</div> : null}
                  <div>Fecha: {parseDateReadable(this.props.lastPrice.last_date, false, '/')}</div>
                </Fragment>
              ) : null}
            </TextInput>
          </td>
          <td className="product-item__cell product-item__cell--amount">
            <TextInput type="number"
                      name="quantity"
                      value={quantity}
                      onChange={onValueChange}
                      onDone={onInputDone}
                      information={!newItem}
                      disabled={this.props.disabled}/>
          </td>
          <td className="product-item__cell product-item__cell--amount">{total}</td>
          <td className="product-item__cell product-item__cell--actions">
            {this.getPossibleAction()}
          </td>
      </tr>
    );
  }
}

ProductItem.propTypes = {
  isOptional: PropTypes.bool,
  selected: PropTypes.bool,
  id: PropTypes.any,
  invoiceId: PropTypes.string,
  availableInvoices: PropTypes.array,
  itemNumber: PropTypes.any,
  name: PropTypes.any,
  partNumber: PropTypes.any,
  description: PropTypes.any,
  harmonizedCode: PropTypes.any,
  unitItem: PropTypes.any,
  quantity: PropTypes.any,
  cost: PropTypes.any,
  margin: PropTypes.any,
  price: PropTypes.any,
  total: PropTypes.any,
  newItem: PropTypes.bool,
  products: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  onInputDone: PropTypes.func,
  onNew: PropTypes.func,
  onDelete: PropTypes.func,
  onTransfer: PropTypes.func,
  onAddSubItem: PropTypes.func,
  onRetry: PropTypes.func,
  onSelectionChange: PropTypes.func,
  subItem: PropTypes.bool,
  lastPrice: PropTypes.any,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  allowSubItem: PropTypes.bool,
  costVisible: PropTypes.bool,
  transferVisible: PropTypes.bool,
  deleteVisible: PropTypes.bool,
  newVisible: PropTypes.bool,
  invoiceVisible: PropTypes.bool,
};

ProductItem.defaultProps = {
  isOptional: false,
  selected: false,
  itemNumber: '',
  name: '',
  partNumber: '',
  description: null,
  harmonizedCode: '',
  unitItem: '',
  quantity: '0.00',
  cost: '0.00',
  price: '0.00',
  margin: '0.00',
  total: '0.00',
  newItem: true,
  subItem: false,
  products: [],
  lastPrice: null,
  error: false,
  loading: false,
  disabled: false,
  allowSubItem: true,
  costVisible: true,
  transferVisible: false,
  deleteVisible: true,
  newVisible: true,
  invoiceVisible: false,
  availableInvoices: [],
  onValueChange: () => {},
  onInputDone: () => {},
  onNew: () => {},
  onDelete: () => {},
  onTransfer: () => {},
  onAddSubItem: () => {},
  onRetry: () => {},
  onSelectionChange: (selected) => {}
};

export default withRouter(ProductItem);
