
import './index.scss';
import logo from '../../assets/images/logo.png';

import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import api from '../../api';
import authStore from '../../stores/auth';
import { TextInput, Checkbox, LoadingButton } from '../../components';
import { validateEmailInput, validateInputNotEmpty } from '../../utils';

class SignIn extends React.Component {
  state = {
    email: null,
    password: null,
    keepSession: true,
    emailError: null,
    passwordError: null,
    signInLoading: false,
    signInSuccess: false,
    signInError: false
  };
  source = api.source.createSource();
  NOTIFICATION_DURATION = 3000;
  notificationTimeout;
  TITLE = 'Metallica Caribbean';
  QUOTE = 'Panel de administración';
  INVALID_CREDENTIALS_MESSAGE = 'Credenciales inválidos';
  BLOQUED_USER_MESSAGE = 'Usuario bloqueado';

  constructor(props) {
    super(props);

    this.onValueChanged = this.onValueChanged.bind(this);
    this.signIn = this.signIn.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  onValueChanged(value, name, error) {
    this.setState({
      [name]: value,
      [`${name}Error`]: error
    });
  }

  setNotificationTimeout(success = true, value = true) {
    const fieldName = success ? 'signInSuccess' : 'signInError';
    this.setState({[fieldName]: value});
    this.notificationTimeout = setTimeout(() => this.setState({
      [fieldName]: false
    }), this.NOTIFICATION_DURATION);
  }

  signIn() {
    if (this.state.email && this.state.password && !this.state.emailError && !this.state.passwordError) {
      this.setState({signInLoading: true, signInError: false, signInSuccess: false});
      api.auth.signIn(this.state.email, this.state.password, this.source)
        .then(response => {
          const accessToken = response.data.access_token;
          const user = response.data.user;
          if (user.enabled) {
            authStore.setUser(
              accessToken,
              user.id,
              user.name,
              user.email,
              this.state.keepSession,
              user.permissions
            );
            this.props.history.push('/');
          } else {
            this.setNotificationTimeout(false, this.BLOQUED_USER_MESSAGE);
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            this.setState({signInLoading: false});
            this.setNotificationTimeout(
              false,
              err === 'unauthorized' ? this.INVALID_CREDENTIALS_MESSAGE : err
            );
          }
        });
    }
  }

  render() {
    return (
      <div className="sign-in">
        <Helmet>
          <title>Inicio de sesión | Metallica Caribbean</title>
          <meta name="description" content="Formulario de inicio de sesión en Metallica Caribbean S.A." />
          <meta name="keyboards" content="metallica,inicio, sesión,login" />
        </Helmet>

        <div className="sign-in__illustration">
          <div className="sign-in__quote">
            <h1>{this.TITLE}</h1>
            <span>{this.QUOTE}</span>
          </div>
        </div>

        <div className="sign-in__content">
          <div className="sign-in__logo"><img src={logo} alt="Logo"/></div>
          <span>Ingrese su correo electrónico y contraseña para acceder.</span>
          <div className="sign-in__input">
            <TextInput type="email"
                       label="Correo electrónico"
                       name="email"
                       value={this.state.email}
                       error={this.state.emailError}
                       validateFn={validateEmailInput}
                       onChange={this.onValueChanged}/>
          </div>
          <div className="sign-in__input">
            <TextInput type="password"
                       label="Contraseña"
                       name="password"
                       value={this.state.password}
                       error={this.state.passwordError}
                       validateFn={validateInputNotEmpty}
                       onChange={this.onValueChanged}
                       onEnterPressed={() => this.signIn()}/>
          </div>
          <div className="sign-in__input">
            <Checkbox name="keepSession"
                      label="Mantenme conectado"
                      value={this.state.keepSession}
                      onChange={this.onValueChanged}/>
          </div>
          <div className="sign-in__submit">
            <LoadingButton loadingColor="black"
                           loaderSize="small"
                           className="btn btn--primary"
                           loading={this.state.signInLoading}
                           success={this.state.signInSuccess}
                           error={this.state.signInError}
                           onClick={() => this.signIn()}>Iniciar sesión</LoadingButton>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(SignIn);