
import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import api from '../../api';
import { parseNumericValueIn } from '../../utils';
import { LocalProductsStore } from '../../stores';
import { InvoiceProducer } from './InvoiceContext';
// import { useMounted } from '../../hooks';
import {
  ViewHeader,
  StepsIndicator, LoadingButton
} from '../../components';
import SelectOffer from './SelectOffer';
import DetailsForm from './DetailsForm';
import SelectProducts from './SelectProducts';

const STEPS = [
  'Oferta',
  'Detalles',
  'Productos'
];

const productsStore = new LocalProductsStore();
const availableProductsStore = new LocalProductsStore([], false);

const initialState = {
  step: 1,
  quoteId: null,
  quote: null,
  invoice: {
    fiscal_number: null,
    contract_number: null,
    contract_date: null,
    BL_number: null,
    AWB_number: null,
    description: null,
    boarding_place: null,
    is_commercial: null,
    replacement: null,
    incoterm: null,
    payment_method: null,
    due_date: null,
    paid_date: null,
    packing: null,
    freight: null,
    insurance: null,
    incoterm_price: null,
    discount: null,
    financing_period: null,
    financing_percentage: null
  },
  products: [],
  availableProducts: [],
  submitState: {
    loading: false,
    error: null,
    success: null,
  }
};

const reducer = (state, action) => {
  switch(action.type) {
    case 'GO_STEP_BACK':
      if (state.step === 3)
        return {
        ...state,
          step: 2,
          products: state.quote.products || [],
          availableProducts: []
        };
      else
        return state;
    case 'DESELECT_OFFER':
      return {...initialState};
    case 'SELECT_OFFER':
      return {
        ...state,
        step: 2,
        quote: action.data,
        quoteId: action.data.id,
      };
    case 'FETCH_OFFER_SUCCESS':
      productsStore.configure(
        (action.data.products || []).filter(product => !product.invoice_id),
        false,
        'autosave/invoices/new/products'
      );
      return {
        ...state,
        step: 2,
        quote: action.data,
        invoice: {
          ...state.invoice,
          boarding_place: action.data.boarding_place,
          incoterm: action.data.incoterm,
          incoterm_price: parseNumericValueIn(action.data.incoterm_price),
          incoterm_year: action.data.incoterm_year,
          payment_method: action.data.payment_method,
          packing: parseNumericValueIn(action.data.packing),
          freight: parseNumericValueIn(action.data.freight),
          insurance: parseNumericValueIn(action.data.insurance),
          discount: parseNumericValueIn(action.data.discount),
          financing_period: action.data.financing_period,
          financing_percentage: parseNumericValueIn(action.data.financing_percentage)
        },
        products: (action.data.products || []).filter(product => !product.invoice_id),
      };
    case 'INVOICE_VALUE_CHANGE':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.data,
        }
      };
    case 'INVOICE_FORM_SUBMIT':
      return {...state, step: 3};
    case 'SUBMIT_INVOICE':
      return {
        ...state,
        submitState: {
          loading: true,
          error: null,
          success: null,
        }
      };
    case 'SUBMIT_INVOICE_ERROR':
      return {
        ...state,
        submitState: {
          loading: false,
          error: action.data,
          success: null,
        }
      };
    case 'SUBMIT_INVOICE_SUCCESS':
      return {
        ...state,
        submitState: {
          loading: false,
          error: null,
          success: 'Factura creada exitosamente',
        }
      };
    case 'TIMEOUT_SUBMIT_NOTIFICATION':
      return {
        ...state,
        submitState: {
          loading: false,
          error: null,
          success: null,
        }
      };
    default:
      return state;
  }
};

export const InvoiceForm = (props) => {
  const params = useParams();
  const history = useHistory();
  // const isMounted = useMounted();
  const [state, dispatch] = useReducer(reducer, {...initialState, quoteId: params.quoteId});
  const quoteId = params.quoteId || state.quoteId;

  // Load Offer
  useEffect(
    () => {
      const source = api.source.createSource();
      const fetchQuote = async () => {
        try {
          const response = await api.quote.getQuote(quoteId, source);
          const quote = response.data;
          dispatch({
            type: 'FETCH_OFFER_SUCCESS',
            data: quote
          });
        } catch(error) {
          if (!api.source.errorIsCancel(error))
            console.error(error);
        }
      };

      if (quoteId)
        fetchQuote();

      return () => source.cancel('unmount');
    },
    [quoteId]
  );

  const setNotificationTimeout = (success = false, invoiceId = null) => {
    setTimeout(
      () => {
        if (success)
          history.push(`/invoices/${invoiceId}`);
        else
          dispatch({ type: 'TIMEOUT_SUBMIT_NOTIFICATION' });
      },
      3000
    );
  }

  // Create Invoice
  const createInvoice = async () => {
    if (!state.quote)
      return;

    try {
      dispatch({ type: 'SUBMIT_INVOICE' });
      const response = await api.invoice.createInvoice(
        quoteId,
        {
          ...state.invoice,
          quote_product_id: productsStore.products.map(product => product.id),
        },
        null
      );
      dispatch({ type: 'SUBMIT_INVOICE_SUCCESS' });
      setNotificationTimeout(true, response.data);
    } catch(error) {
      if (!api.source.errorIsCancel(error)) {
        console.error(error);
        dispatch({ type: 'SUBMIT_INVOICE_ERROR', data: error });
        setNotificationTimeout(false);
      }
    }
  };

  return (
    <div className={`new-invoice page page--form ${state.step === 3 ? 'new-invoice--full' : ''}`}>
      <Helmet>
        <title>Nueva factura | Metallica Caribbean</title>
        <meta name="description" content="Formulario de creación de factura" />
        <meta name="keyboards" content="factura,metallica,crear,nuevo" />
      </Helmet>

      <div className="row page__content">
        <div className="col-12">
          <div className="page__heading">
            <ViewHeader loading={false}>
              <ViewHeader.Navigation>
                <ViewHeader.NavigationLink link="/">Inicio</ViewHeader.NavigationLink>
                <ViewHeader.NavigationLink link="/invoices">Facturas</ViewHeader.NavigationLink>
                <ViewHeader.NavigationLink link="/invoices/new">Nueva factura</ViewHeader.NavigationLink>
              </ViewHeader.Navigation>
              <ViewHeader.BackButton
                onClick={
                  () =>
                    state.step === 1
                    || state.step === 2
                      ? history.goBack()
                      : dispatch({type: 'GO_STEP_BACK'})
                }
              />
              <ViewHeader.Title>Nueva factura</ViewHeader.Title>
              <ViewHeader.Actions>
                <ViewHeader.Action>
                  <StepsIndicator
                    currentStep={state.step}
                    steps={STEPS}

                  />
                </ViewHeader.Action>
              </ViewHeader.Actions>
            </ViewHeader>
          </div>
        </div>

        <InvoiceProducer value={[state, dispatch]}>
          {
            (state.step === 1 || state.step === 2) && (
              <div className="col-12">
                <SelectOffer/>
              </div>
            )
          }
          {
            state.step === 2 && state.quote && (
              <div className="col-12">
                <DetailsForm/>
              </div>
            )
          }
          {
            state.step === 3 && (
              <div className="col-12">
                <SelectProducts
                  productsStore={productsStore}
                  availableProductsStore={availableProductsStore}
                />
              </div>
            )
          }
          {
            state.step === 3 && (
              <div className="col-12">
                <div className="page__submit">
                  <LoadingButton
                    className="btn btn--primary"
                    loadingColor="black"
                    loading={state.submitState.loading}
                    success={state.submitState.success}
                    error={state.submitState.error}
                    onClick={() => createInvoice()}
                  >
                    Crear factura
                  </LoadingButton>
                </div>
              </div>
            )
          }
        </InvoiceProducer>
      </div>
    </div>
  );
};

InvoiceForm.propTypes = {};

InvoiceForm.defaultProps = {};

export default observer(InvoiceForm);
