
import axios from 'axios';

const CancelToken = axios.CancelToken;

const methods = {
  /**
   * Create new source to cancel requests
   * @returns {CancelTokenSource}
   */
  createSource: () => CancelToken.source(),

  /**
   * Validate if error is generated by a cancelled request
   * @param error
   * @returns {boolean}
   */
  errorIsCancel: error => axios.isCancel(error)
};
export default methods;
