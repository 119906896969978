
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BaseProduct from './BaseProduct';

export const NewProduct = (props) => {
  const {
    isSubItem,
    data,
  } = props;
  return (
    <BaseProduct
      newItem
      isSubItem={isSubItem}
      data={data}
    >
      <BaseProduct.Actions>

      </BaseProduct.Actions>
    </BaseProduct>
  );
};

NewProduct.propTypes = {
  isSubItem: PropTypes.bool,
  data: PropTypes.shape({
    item_no: PropTypes.any,
    name: PropTypes.any,
    part_number: PropTypes.any,
    description: PropTypes.any,
    harmonized_code: PropTypes.any,
    unit: PropTypes.any,
    quantity: PropTypes.any,
    cost: PropTypes.any,
    lastPrice: PropTypes.shape({
      last_price: PropTypes.any,
      last_cost: PropTypes.any,
      quote_id: PropTypes.string,
      invoice: PropTypes.string,
      last_date: PropTypes.any,
    })
  }).isRequired,
};

NewProduct.defaultProps = {
  isSubItem: false,
};

export default observer(NewProduct);
