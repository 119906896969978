
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import Card from '../../containers/Card';

class NewPackageItem extends React.Component {
  state = {
    albaran_number: null,
    package: null,
    dimension: null,
    gross_weight: 0,
    package_type: "Bulto",
    net_weight: 0
  };

  constructor(props) {
    super(props);

    this.resetNewPackageForm = this.resetNewPackageForm.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);

    this.onAddProduct = this.onAddProduct.bind(this);

    this.onPackageTypeChange = this.onPackageTypeChange.bind(this);
  }

  resetNewPackageForm() {
    this.setState({
      package_type: "Bulto",
      albaran_number: null,
      package: null,
      dimension: null,
      gross_weight: null,
      net_weight: null
    });
  }

  onValueChanged(value, name, error) {
    this.setState({[name]: value});
  }

  onAddProduct() {
    const packageForm = {
      package_type: this.state.package_type,
      albaran_number: this.state.albaran_number,
      package: this.state.package,
      dimension: this.state.dimension,
      gross_weight: this.state.gross_weight,
      net_weight: this.state.net_weight,
      products: []
    };
    console.log(packageForm)
    this.props.addPackage(packageForm);
    this.resetNewPackageForm();
  }

  onPackageTypeChange(value){
    console.log(value)
    this.setState({package_type: value})
  }

  render() {
    return (
      <div className="new-package">
        <Card loading={this.props.initialLoading}>
          <Card.Title>
            <div className="input-group input-group--select">
              <select id="type" className="input-group__display input-group__display--arrow" onChange={(e)=>this.onPackageTypeChange(e.target.value)} value={this.state.package_type}>
                <option value="Bulto">Bulto</option>
                <option value="Contenedor">Contenedor</option>
              </select>
            </div>
          </Card.Title>
          <Card.Body>
            <div className="row row--align-top">
              <div className="col">
                <TextInput label="Número Albaran"
                           name="albaran_number"
                           loading={this.props.initialLoading}
                           information={this.props.initialLoading}
                           value={this.state.albaran_number}
                           onChange={this.onValueChanged}/>
              </div>
              <div className="col">
                <TextInput label="Dimensiones"
                           name="dimension"
                           loading={this.props.initialLoading}
                           information={this.props.initialLoading}
                           value={this.state.dimension}
                           onChange={this.onValueChanged}/>
              </div>
              <div className="col">
                <TextInput label="Peso bruto (Kg)"
                           name="gross_weight"
                           loading={this.props.initialLoading}
                           information={this.props.initialLoading}
                           value={this.state.gross_weight}
                           onChange={this.onValueChanged}/>
              </div>
              <div className="col">
                <TextInput label="Peso neto (Kg)"
                           name="net_weight"
                           loading={this.props.initialLoading}
                           information={this.props.initialLoading}
                           value={this.state.net_weight}
                           onChange={this.onValueChanged}/>
              </div>
            </div>
            <div className="page__submit new-package__add">
              {!this.props.initialLoading ? (
                <button className="btn btn--secondary btn--outline" onClick={() => this.onAddProduct()}>Agregar</button>
              ) : null}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

NewPackageItem.propTypes = {
  initialLoading: PropTypes.bool,
  addPackage: PropTypes.func.isRequired
};

NewPackageItem.defaultProps = {
  initialLoading: false,
  addPackage: () => {}
};

export default NewPackageItem;
