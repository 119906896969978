
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import { useInvoiceContext } from './InvoiceContext';
import { ProductsListOld } from '../../components';
import ProductsList from '../../components/ProductsList';
import { Card } from '../../containers';
import { ReactComponent as TransferIcon } from '../../assets/images/transfer.svg';

const SelectProducts = (props) => {
  const { productsStore, availableProductsStore } = props;
  const [state, ] = useInvoiceContext();

  const getTotalExtras = () => {
    return (Number(state.invoice.freight) || 0) +
      (Number(state.invoice.insurance) || 0) +
      (Number(state.invoice.origin_inspection) || 0) +
      (Number(state.invoice.incoterm_price) || 0);
  }

  const getDiscounted = () => {
    return (productsStore.total + getTotalExtras()) * (Number(state.invoice.discount) || 0) / 100;
  }

  const getTotal = () => {
    return productsStore.total + getTotalExtras() - getDiscounted();
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Productos asociados</Card.Title>
          <Card.Instructions>Revisa los productos asociados a la factura.</Card.Instructions>
        </Card.Header>
        <Card.Actions>
          {
            productsStore.containsSelectedProducts > 0 && (
              <Card.Action>
                <button
                  className="btn btn--secondary btn--small"
                  onClick={() => {
                    const products = [...productsStore.selectedProducts];
                    productsStore.deleteSelectedProducts();
                    products.forEach(product => availableProductsStore.addProduct({...product, selected: false}));
                  }}
                >
                  Remover de la factura
                </button>
              </Card.Action>
            )
          }
        </Card.Actions>
        <Card.Body>
          <ProductsList
            showItemNumber
            allowSelection
            allSelected={productsStore.allProductsSelected}
            onSelectAll={(selected) => productsStore.toggleProductsSelection()}
          >
            {
              productsStore.products.map((product, productIndex) => (
                <Fragment key={product.product_id}>
                  <ProductsList.Product
                    selected={product.selected}
                    data={product}
                    onValueChange={(value, name) => productsStore.setProductValue(name, value, productIndex)}
                    onSelect={(selected) => productsStore.setProductValue('selected', selected, productIndex)}
                  >
                    <ProductsList.Product.Actions>
                      <button
                        className="btn btn--transparent btn--small btn--icon"
                        onClick={() => {
                          const product = {...productsStore.JSON[productIndex], selected: false};
                          productsStore.deleteProduct(productIndex);
                          availableProductsStore.addProduct(product);
                        }}
                      >
                        <TransferIcon className="btn__icon"/>
                      </button>
                    </ProductsList.Product.Actions>
                  </ProductsList.Product>
                  {
                    product.sub_products
                    && product.sub_products.length > 0
                    && product.sub_products.map((subProduct, subProductIndex) => (
                      <ProductsList.Product
                        key={subProduct.product_id}
                        isSubItem
                        data={subProduct}
                        onValueChange={(value, name) => productsStore.setProductValue(name, value, productIndex, subProductIndex)}
                      />
                    ))
                  }
                </Fragment>
              ))
            }
            <ProductsList.SummaryItem
              label="Productos"
              value={productsStore.total}
            />
            <ProductsList.SummaryItem
              label="Extras"
              value={getTotalExtras()}
            />
            <ProductsList.SummaryItem
              label="Descuento"
              value={getDiscounted()}
            />
            <ProductsList.SummaryItem
              label="Total"
              value={getTotal()}
            />
          </ProductsList>
        </Card.Body>
      </Card>

      {
        availableProductsStore.products.length > 0 && (
          <Card>
            <Card.Header>
              <Card.Title>Productos disponibles</Card.Title>
              <Card.Instructions>Seleccione los productos que desea agregar a la factura</Card.Instructions>
            </Card.Header>
            <Card.Actions>
              {
                availableProductsStore.containsSelectedProducts > 0 && (
                  <Card.Action>
                    <button
                      className="btn btn--secondary btn--small"
                      onClick={() => {
                        const products = [...availableProductsStore.selectedProducts];
                        availableProductsStore.deleteSelectedProducts();
                        products.forEach(product => productsStore.addProduct({...product, selected: false}));
                      }}
                    >
                      Agregar a la factura
                    </button>
                  </Card.Action>
                )
              }
            </Card.Actions>
            <Card.Body>
              <ProductsList
                showItemNumber
                allowSelection
                allSelected={availableProductsStore.allProductsSelected}
                onSelectAll={(selected) => availableProductsStore.toggleProductsSelection()}
              >
                {
                  availableProductsStore.products.map((product, productIndex) => (
                    <Fragment key={product.product_id}>
                      <ProductsList.Product
                        selected={product.selected}
                        data={product}
                        onValueChange={(value, name) => availableProductsStore.setProductValue(name, value, productIndex)}
                        onSelect={(selected) => availableProductsStore.setProductValue('selected', selected, productIndex)}
                      >
                        <ProductsList.Product.Actions>
                          <button
                            className="btn btn--transparent btn--small btn--icon"
                            onClick={() => {
                              const product = {...availableProductsStore.JSON[productIndex], selected: false};
                              availableProductsStore.deleteProduct(productIndex);
                              productsStore.addProduct(product);
                            }}
                          >
                            <TransferIcon className="btn__icon"/>
                          </button>
                        </ProductsList.Product.Actions>
                      </ProductsList.Product>
                      {
                        product.sub_products
                        && product.sub_products.length > 0
                        && product.sub_products.map((subProduct, subProductIndex) => (
                          <ProductsList.Product
                            key={subProduct.product_id}
                            isSubItem
                            data={subProduct}
                            onValueChange={(value, name) => availableProductsStore.setProductValue(name, value, productIndex, subProductIndex)}
                          />
                        ))
                      }
                    </Fragment>
                  ))
                }
              </ProductsList>
            </Card.Body>
          </Card>
        )
      }
    </>
  );
};

export default observer(SelectProducts);
