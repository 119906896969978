
import './index.scss';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import api from '../../api';
import authStore from '../../stores/auth';
import settings from '../../settings';
import { PageHeader, TextInput, protect, EmptyNotification, ListPagination } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell } from '../../components/Table';

class ClientsList extends React.Component {
  state = {
    name: null,
    clients: [],
    page: 1,
    pages: 1,
    perPage: 10,
    loading: false,
    error: false
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Clientes', link: '/admin/clients'}
  ];

  constructor(props) {
    super(props);
    this.getClients = this.getClients.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
  }

  componentDidMount() {
    this.getClients();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  pageChanged(page) {
    this.setState({page});
    this.getClients(page);
  }

  getClients(selectedPage) {
    this.setState({loading: true});

    api.client.getClients(
      this.source,
      {per_page: this.state.perPage, page: selectedPage || 1, name: this.state.name}
    )
      .then(response => {
        const clients = response.data.data;
        const pages = response.data.pages;
        this.setState({clients, pages, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({
            loading: false,
            error: err,
            clients: [],
            page: 1,
            pages: 1
          });
        }
      });
  }

  render() {
    return (
      <div className="clients page page--list">
        <Helmet>
          <title>Clientes | Metallica Caribbean</title>
          <meta name="description" content="Listado de clientes" />
          <meta name="keyboards" content="clientes,metallica,listado" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title="Clientes">
                <PageHeader.Filters>
                  <PageHeader.Filter>
                    <TextInput name="name"
                               placeholder="Buscar..."
                               value={this.state.name}
                               onChange={value => this.setState({name: value})}
                               onDone={(fieldName, value, modified) => modified && this.getClients()}/>
                  </PageHeader.Filter>
                </PageHeader.Filters>

                <PageHeader.Actions>
                  {authStore.permissions.admin ? (
                    <PageHeader.Action>
                      <button className="btn btn--primary"
                              onClick={() => this.props.history.push('/admin/clients/new')}>Nuevo cliente</button>
                    </PageHeader.Action>
                  ) : null}
                </PageHeader.Actions>
              </PageHeader.Container>
            </div>
          </div>

          <div className="col-12">
            <div className="card">
              <Table loadingRows={10}
                     loadingCells={3}
                     loading={this.state.loading}
                     emptyState={<EmptyNotification icon={NotFoundIcon}
                                                    title="No se encontrarón clientes"
                                                    details="Puedes cambiar los filtros de búsqueda en la parte superior o crear un cliente."
                                                    action={authStore.permissions.admin ? 'Nuevo cliente' : null}
                                                    onAction={() => authStore.permissions.admin && this.props.history.push(`/admin/clients/new`)}/>}
                     errorState={<EmptyNotification icon={DisconnectIcon}
                                                    title={this.state.error}
                                                    details="Ha ocurrido un error obteniendo los clientes."/>}
                     headerCells={
                       <Fragment>
                         <TableHeaderCell>Nombre</TableHeaderCell>
                         <TableHeaderCell className="clients__header-cell--address">Dirección</TableHeaderCell>
                         <TableHeaderCell className="clients__header-cell--contacts">Personas de contacto</TableHeaderCell>
                     </Fragment>}
                     pagination={<ListPagination margin="page"
                                                 currentPage={this.state.page}
                                                 totalPages={this.state.pages}
                                                 onChange={this.pageChanged}/>}>
                {this.state.error || this.state.clients.map((client, index) => (
                  <TableBodyRow key={client.id}
                                onSelect={() => this.props.history.push(`/admin/clients/${client.id}`)}>
                    <TableBodyCell>{client.name}</TableBodyCell>
                    <TableBodyCell>{client.address}</TableBodyCell>
                    <TableBodyCell>
                      {client.contacts.map((contact, index) => <div key={index}>{contact.name} / {contact.phone}</div>)}
                    </TableBodyCell>
                  </TableBodyRow>
                ))}
              </Table>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default protect(ClientsList, settings.permissions.read_admin);