
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ color, size }) => (
  <div className={`spinner spinner--${color} spinner--${size}`}>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

Loader.propTypes = {
  color: PropTypes.oneOf(['white', 'black']).isRequired,
  size: PropTypes.oneOf(['small', 'mid']).isRequired,
};

Loader.defaultProps = {
  color: 'white',
  size: 'mid'
};

export default Loader;
