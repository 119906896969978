
/**
 * Regex to separate number every three digits
 * @type {RegExp}
 */
const REGEX = /\B(?=(\d{3})+(?!\d))/g;

/**
 * Include string as thousands separator
 * @param value
 * @param separator
 * @returns {*|string}
 */
const fn = (value, separator = ',') => value && value.toString().replace(REGEX, separator);
export default fn;

