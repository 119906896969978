
/**
 * Map product to API format
 * @param product
 * @returns {{manufacturer_id: (string|boolean|number), cost: (*|number), name: *, part_number: *, description: *, harmonized_code: (string|boolean|*|number), gross_weight: (string|boolean|string|number), net_weight: (string|boolean|string|number)}}
 */
export const mapProductToAPI = product => ({
  name: product.name,
  available: product.available,
  part_number: product.part_number,
  description: product.description,
  manufacturer_id: product.manufacturer_id && Number(product.manufacturer_id),
  price: product.price,
  harmonized_code: product.harmonized_code,
  gross_weight: product.gross_weight,
  net_weight: product.net_weight
});

const methods = {
  mapProductToAPI
};
export default methods;
