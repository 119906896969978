
import './Toggle.scss';
import { ReactComponent as AngleDownIcon } from '../../assets/images/angle-arrow-down.svg';

import React from 'react';
import PropTypes from 'prop-types';

class Toggle extends React.Component {
  
  render() {
    const { icon: Icon, title, outline, onToggle, active, showCaret, disabled } = this.props;
    let className = 'dropdown__toggle';

    if (active) {
      className += ' dropdown__toggle--active';
    }
    if (outline) {
      className += ' dropdown__toggle--outline';
    }
    if (disabled)
      className += ' dropdown__toggle--disabled';
    return (
      <button className={className}
              onClick={event => {
                if (onToggle) { onToggle() }
                event.stopPropagation();
              }}>
        {this.props.children}
        {Icon ? <Icon className="dropdown__icon" /> : null}
        {title ? <span className="dropdown__title">{title}</span> : null}
        {showCaret ? <AngleDownIcon className="dropdown__caret"/> : null}
      </button>
    );
  }
}

Toggle.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  active: PropTypes.bool.isRequired,
  showCaret: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  outline: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

Toggle.defaultProps = {
  icon: null,
  title: null,
  active: false,
  showCaret: true,
  disabled: false,
  outline: false,
  onToggle: () => {}
};

export default Toggle;
