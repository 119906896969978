
import './SelectedOffer.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import React from 'react';

import { useInvoiceContext } from './InvoiceContext';
import { Card } from '../../containers';

const SelectedOffer = () => {
  const [state, dispatch] = useInvoiceContext();

  return (
    <Card>
      <Card.Title>Oferta</Card.Title>
      <Card.Action>
        <button
          className="btn btn--icon btn--small btn--transparent"
          onClick={() => dispatch({type: 'DESELECT_OFFER'})}
        >
          <CloseIcon className="btn__icon"/>
        </button>
      </Card.Action>
      <Card.Body>
        <div className="selected-offer__offer">
          <div className="row row--align-center">
            <div className="col-5">
              <div className="row">
                <div className="col-12 selected-offer__label selected-offer__label--title">{state.quote.id}</div>
                <div className="col-12 selected-offer__label">{state.quote.client}</div>
              </div>
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-12 selected-offer__label">{state.quote.model}</div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SelectedOffer;
