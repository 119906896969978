
import { observable, action, decorate, computed, runInAction } from 'mobx';

import api from '../../api';
import OnlineProductsStore from './OnlineList';
import { parseNumericValueIn } from '../../utils';

class OnlineOfferStore {
  modified = false;

  offer = {
    id: null,
    invoice: [],
    official_id: null,
    old: null,

    client: null,
    client_id: null,
    reference: null,

    order: null,
    model: null,
    serial_number: null,

    currency: null,
    incoterm: null,
    payment_method: null,

    boarding_place: null,
    delivery_time: null,
    duration: null,
    warranty: null,

    financing_period: null,
    financing_percentage: null,

    freight: null,
    insurance: null,
    incoterm_price: null,
    origin_inspection: null,
    margin: null,
    discount: null,

    total_product: null,
    discounted: null,
    total_extras: null,
    total: null,
    financed_amount: null,
    total_with_financing: null,

    creation_date: null
  };
  related_quotes = [];

  errors = {
    delivery_time: false,
    duration: false,
    warranty: false
  };

  productsStore = new OnlineProductsStore();
  optionalProductsStore = new OnlineProductsStore(null, 0, [], true);

  source = api.source.createSource();
  modifyState = {
    field: null,
    error: null,
    success: false
  };
  fetchState = {
    loading: false,
    error: null,
    success: false
  };

  get reindexState() {
    return this.productsStore.reindexState;
  }

  get fetchProductsState() {
    return this.productsStore.fetchState;
  }

  get fetchOptionalProductsState() {
    return this.optionalProductsStore.fetchState;
  }

  get deleteSelectedProductsState() {
    return this.productsStore.deleteSelectedProductsState;
  }

  get deleteSelectedOptionalProductsState() {
    return this.optionalProductsStore.deleteSelectedProductsState;
  }

  get products() {
    return this.productsStore.products || [];
  }

  get optionalProducts() {
    return this.optionalProductsStore.products || [];
  }

  get selectedProducts() {
    return this.productsStore.selectedProducts;
  }

  get selectedOptionalProducts() {
    return this.optionalProductsStore.selectedProducts;
  }

  get containsSelectedProducts() {
    return this.productsStore.containsSelectedProducts;
  }

  get containsSelectedOptionalProducts() {
    return this.optionalProductsStore.containsSelectedProducts;
  }

  get productsTotal() {
    return this.productsStore.total;
  }

  get extras() {
    if (this.modified) {
      return (Number(this.offer.freight) || 0) +
        (Number(this.offer.insurance) || 0) +
        (Number(this.offer.origin_inspection) || 0) +
        (Number(this.offer.incoterm_price) || 0);
    } else {
      return this.offer.total_extras;
    }
  }

  get discounted() {
    if (this.modified) {
      return (this.productsTotal + this.extras) * (Number(this.offer.discount) || 0) / 100;
    } else {
      return this.offer.discounted;
    }
  }

  get total() {
    if (this.modified) {
      return this.productsTotal + this.extras - this.discounted;
    } else {
      return this.offer.total;
    }
  }

  fetchOffer(id) {
    this.fetchState = {loading: true, error: null, success: false};
    api.quote.getQuote(id, this.source)
      .then(action(
        'fetchSuccess',
        response => {
          const quote = response.data;
          this.fetchState = {loading: false, error: null, success: true};
          this.setOffer(quote);
        }
      ))
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          runInAction(() => this.fetchState = {loading: false, error: err, success: false});
        }
      });
  }

  setOffer(offer) {
    this.offer = {
      id: offer.id,
      invoice: offer.invoice,
      official_id: offer.official_id,
      old: offer.old,

      client: offer.client,
      client_id: offer.client_id,
      reference: offer.reference,

      order: offer.order,
      model: offer.model,
      serial_number: offer.serial_number,

      currency: offer.currency,
      incoterm: offer.incoterm,
      payment_method: offer.payment_method,

      boarding_place: offer.boarding_place,
      delivery_time: offer.delivery_time,
      duration: offer.duration,
      warranty: offer.warranty,

      financing_period: offer.financing_period,
      financing_percentage: parseNumericValueIn(offer.financing_percentage),

      freight: parseNumericValueIn(offer.freight),
      insurance: parseNumericValueIn(offer.insurance),
      incoterm_price: parseNumericValueIn(offer.incoterm_price),
      origin_inspection: parseNumericValueIn(offer.origin_inspection),
      margin: '0.00',
      discount: parseNumericValueIn(offer.discount),

      total_product: offer.total_product,
      discounted: offer.discounted,
      total_extras: offer.total_extras,
      total: offer.total,
      financed_amount: offer.financed_amount,
      total_with_financing: offer.total_with_financing,

      creation_date: offer.date,
      incoterm_year: offer.incoterm_year
    };
    this.related_quotes = offer.related_quotes;
    this.productsStore.configure(offer.id, offer.total_product, offer.products);
    this.optionalProductsStore.configure(offer.id, offer.total_product, offer.optional_products);
  }

  setValue(value, name, error) {
    this.offer[name] = value;
    this.modified = true;

    switch(name) {
      case 'delivery_time':
      case 'duration':
      case 'warranty':
        this.setError(name, error);
        break;
      case 'margin':
        this.productsStore.updateMargin(value);
        this.optionalProductsStore.updateMargin(value);
        this.setError(name, error);
        break;
      default: break;
    }
  }

  setValueDone(name, value, modified) {
    if (modified && !this.errors[name]) {
      this.modifyState = {field: name, error: null, success: false};
      return api.quote.updateQuote(this.offer.id, {[name]: value})
        .then(action(
          'modifySuccess',
          () => {
            if (this.modifyState.field === name) {
              this.modifyState = {field: null, error: null, success: true};
            }
          }
        ))
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            console.error('Cannot update quote', err);
            runInAction(() => this.modifyState = {field: null, error: err, success: true});
          }
        });
    }
  }

  setError(name, error) {
    this.errors[name] = error;
  }

  addProduct(product, index, relation, subIndex, retry) {
    this.modified = true;
    this.productsStore.addProduct(product, index, relation, subIndex, retry);
  }

  addOptionalProduct(product, index, relation, subIndex, retry) {
    this.modified = true;
    this.optionalProductsStore.addProduct(product, index, relation, subIndex, retry);
  }

  moveProduct(index, newIndex){
    this.productsStore.moveProducts(index,newIndex)
  }

  moveOptionalProduct(index, newIndex){
    this.optionalProductsStore.moveProducts(index, newIndex)
  }

  setProductValue(name, value, index, subIndex) {
    this.modified = true;
    this.productsStore.setProductValue(name, value, index, subIndex);
  }

  setOptionalProductValue(name, value, index, subIndex) {
    this.modified = true;
    this.optionalProductsStore.setProductValue(name, value, index, subIndex);
  }

  deleteProduct(index, subIndex, productId) {
    this.modified = true;
    this.productsStore.deleteProductOverride(index, subIndex, productId);
  }

  deleteOptionalProduct(index, subIndex, productId) {
    this.modified = true;
    this.optionalProductsStore.deleteProductOverride(index, subIndex, productId);
  }

  transferOptionalProduct(index, productId) {
    const promiseId = new Date().getTime();
    this.optionalProductsStore.setProductData({
      loading: promiseId,
      disabled: true,
    }, index);

    api.quote.transferOptionalProduct(this.offer.id, productId, this.source)
      .then(response => {
        if (response.data) {
          const productIndex = this.optionalProductsStore.products.findIndex(product => product.loading === promiseId);
          if (productIndex >= 0) {
            const product = this.optionalProductsStore.products[productIndex];
            this.productsStore.addProductFromTransfer({
              ...product,
              ...response.data,
              loading: false,
              disabled: false,
            });
          }
          this.optionalProductsStore.deleteProductById(productId);
        }
      })
      .catch(err => !api.source.errorIsCancel(err) &&
        this.optionalProductsStore.setProductValueById({loading: false, error: true}, productId));
  }

  deleteSelectedProducts() {
    this.productsStore.deleteSelectedProducts();
  }

  deleteSelectedOptionalProducts() {
    this.optionalProductsStore.deleteSelectedProducts();
  }

  toggleProductsSelection() {
    this.productsStore.toggleProductsSelection();
  }

  toggleOptionalProductsSelection() {
    this.optionalProductsStore.toggleProductsSelection();
  }

  reindexProducts() {
    this.productsStore.reindex();
  }

  refreshProducts() {
    this.productsStore.fetchProducts();
  }

  refreshOptionalProducts() {
    this.optionalProductsStore.fetchProducts();
  }
}

decorate(
  OnlineOfferStore,
  {
    modified: observable,

    offer: observable,
    related_quotes: observable,
    errors: observable,

    modifyState: observable,
    reindexState: computed,
    fetchProductsState: computed,
    fetchState: observable,
    deleteSelectedProductsState: computed,

    fetchOptionalProductsState: computed,
    deleteSelectedOptionalProductsState: computed,
    optionalProducts: computed,
    selectedOptionalProducts: computed,
    containsSelectedOptionalProducts: computed,
    deleteSelectedOptionalProducts: action.bound,
    refreshOptionalProducts: action.bound,
    toggleOptionalProductsSelection: action.bound,

    productsTotal: computed,
    extras: computed,
    discounted: computed,
    total: computed,
    products: computed,
    selectedProducts: computed,
    containsSelectedProducts: computed,

    fetchOffer: action,
    setOffer: action,
    setValue: action.bound,
    setValueDone: action,
    setError: action,

    addProduct: action.bound,
    setProductValue: action.bound,
    deleteProduct: action.bound,
    deleteSelectedProducts: action.bound,
    toggleProductsSelection: action.bound,

    setOptionalProductValue: action.bound,
    addOptionalProduct: action.bound,
    deleteOptionalProduct: action.bound,
    transferOptionalProduct: action.bound,

    reindexProducts: action.bound,
    refreshProducts: action.bound,

    moveProduct: action.bound,
    moveOptionalProduct: action.bound
  }
);

export default OnlineOfferStore;
