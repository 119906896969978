
import './index.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import React from 'react';
import PropTypes from 'prop-types';

class Tooltip extends React.Component {
  state = {
    show: false
  };
  showTimeout = null;
  hideTimeout = null;
  SHOW_WAIT_TIME = 1000;
  HIDE_WAIT_TIME = 100;

  constructor(props) {
    super(props);
    this.showContent = this.showContent.bind(this);
    this.hideContent = this.hideContent.bind(this);
  }

  showContent() {
    clearTimeout(this.hideTimeout);
    this.showTimeout = setTimeout(() => this.setState({show: true}), this.SHOW_WAIT_TIME);
  }

  hideContent() {
    clearTimeout(this.showTimeout);
    this.hideTimeout = setTimeout(() => {
      this.setState({show: false});
    }, this.HIDE_WAIT_TIME);
  }

  render() {
    return (
      <div className="tooltip">
        {React.cloneElement(this.props.trigger, {
          onMouseEnter: this.showContent,
          onMouseLeave: this.hideContent
        })}
        {this.state.show ? (
          <div className={`tooltip__content tooltip__content--${this.props.position} tooltip__content--${this.props.size}`}
               onMouseEnter={this.showContent}
               onMouseLeave={this.hideContent}>
            {this.props.size !== 'min' ? (
              <div className="row row--align-center">
                <div className="col tooltip__title">{this.props.title}</div>
                <div className="col col--min-content">
                  <button className="btn btn--micro btn--icon btn--transparent"
                          onClick={event => {
                            this.hideContent();
                            event.stopPropagation();
                          }}><CloseIcon className="btn__icon"/></button>
                </div>
                <div className="col-12 tooltip__info">{this.props.children}</div>
              </div>
            ) : this.props.children}
          </div>
        ) : null}
      </div>
    );
  }
}

Tooltip.propTypes = {
  title: PropTypes.any,
  trigger: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['min', 'small', 'mid', 'big']).isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'dropdown-right', 'dropdown-left']).isRequired
};

Tooltip.defaultProps = {
  size: 'min',
  position: 'dropdown-left'
};

export default Tooltip