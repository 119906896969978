
import './index.scss';
import { ReactComponent as LeftArrow } from '../../assets/images/left-arrow.svg';
import { ReactComponent as AngleRight } from '../../assets/images/right-arrow.svg';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Container = ({navigation, title, onBack, loading, children}) => {
  const lastIndex = navigation ? navigation.length - 1 : 0;
  const navigationLinks = navigation && navigation.reduce((result, link, index) => {
    result.push(<Link key={`L${index}`} className="page-header__link" to={link.link}>{link.title}</Link>);

    if (index < lastIndex) {
      result.push(<AngleRight key={`A${index}`} className="page-header__arrow"/>);
    }
    return result;
  }, []);

  return (
    <div className="page-header">
      {navigation ? (
        <div className="page-header__navigation">{loading ? <div className="loading-block loading-block--small"
                                                                 style={{width: '12%'}}/> : navigationLinks.map((element) => element)}</div>
      ) : null}
      {onBack ? <button className="page-header__back" onClick={() => onBack()}><LeftArrow className="page__back-icon"/></button> : null}
      <h1 className="page-header__title col">{loading ? <div className="loading-block"
                                                             style={{width: '250px'}}/> : title}</h1>
      {loading || children}
    </div>
  );
};

Container.propTypes = {
  navigation: PropTypes.array,
  title: PropTypes.string,
  onBack: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node
};

const Filters = ({children}) => (
  <div className="page-header__filters">{children}</div>
);

const Filter = ({children}) => <div className="page-header__filter">{children}</div>;

const Actions = ({children}) => (
  <div className="page-header__actions">{children}</div>
);

const Action = ({children}) => <div className="page-header__action">{children}</div>;

const Types = ({children}) => (
  <div className="page-header__types">{children}</div>
);

const components = {
  Container,
  Types,
  Filters,
  Filter,
  Actions,
  Action
};
export default components;
