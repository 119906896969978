
import './assets/fonts/fonts.css';
import './assets/styles/index.scss';
import './page.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import MainApp from './views/App';
import SignIn from './views/SignIn';

/**
 * Main application
 */
class Application extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/sign-in" component={SignIn}/>
          <Route path="/" component={MainApp}/>
        </Switch>
      </Router>
    );
  }
}

ReactDOM.render(
  <Application/>,
  document.getElementById('root')
);

serviceWorker.unregister();
