
import './index.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import PackageProductItem from '../PackageProductItem';
import Loader from '../Loader';
import { Table, TableHeaderCell } from '../Table';

class PackageItem extends React.Component {
  render() {
    const {
      package_type,
      albaranNumber,
      package: packageNumber,
      dimension,
      grossWeight,
      netWeight,
      loading
    } = this.props;
    return (
      <div className="card package-item page__card">
        <div className="row row--align-center">
          <div className="col"><h2>{package_type} #{packageNumber}</h2></div>
          {loading ? <div className="col col--min-content"><Loader color="black" size="small"/></div> : null}
          {!this.props.disabled ? (
            <div className="col col--min-content package-item__loader">
              <button className="btn btn--icon btn--transparent btn--small"
                      onClick={() => this.props.deletePackage()}><CloseIcon className="btn__icon"/></button>
            </div>
          ) : null}
        </div>
        <div className="row package-item__details">
          <div className="col">
            <TextInput label="Número Albaran"
                       name="albaran_number"
                       value={albaranNumber}
                       onChange={this.props.packageChanged}
                       onDone={this.props.packageChangeDone}
                       information={true}
                       disabled={this.props.disabled}/>
          </div>
          <div className="col">
            <TextInput label="Dimensiones"
                       name="dimension"
                       value={dimension}
                       onChange={this.props.packageChanged}
                       onDone={this.props.packageChangeDone}
                       information={true}
                       disabled={this.props.disabled}/>
          </div>
          <div className="col">
            <TextInput label="Peso bruto (Kg)"
                       name="gross_weight"
                       value={grossWeight}
                       onChange={this.props.packageChanged}
                       onDone={this.props.packageChangeDone}
                       information={true}
                       disabled={this.props.disabled}/>
          </div>
          <div className="col">
            <TextInput label="Peso neto (Kg)"
                       name="net_weight"
                       value={netWeight}
                       onChange={this.props.packageChanged}
                       onDone={this.props.packageChangeDone}
                       information={true}
                       disabled={this.props.disabled}/>
          </div>
        </div>

        <div className="package-item__products">
          <Table headerCells={
            <Fragment>
              <TableHeaderCell># Pieza</TableHeaderCell>
              <TableHeaderCell>Descripción</TableHeaderCell>
              <TableHeaderCell>Unidad</TableHeaderCell>
              <TableHeaderCell>Cantidad</TableHeaderCell>
              <TableHeaderCell>Peso bruto (Kg)</TableHeaderCell>
              <TableHeaderCell>Peso neto (Kg)</TableHeaderCell>
              <TableHeaderCell/>
            </Fragment>
          }>
            {this.props.products.map((product, index) => (
              <PackageProductItem key={index}
                                  disabled={this.props.disabled}
                                  part_number={product.part_number}
                                  name={product.name}
                                  description={product.description}
                                  unit={product.unit}
                                  quantity={product.quantity}
                                  gross_weight={product.gross_weight}
                                  net_weight={product.net_weight}
                                  loading={product.loading}
                                  productChanged={(value, name, error) => this.props.productChanged(value, name, error, index)}
                                  productChangeDone={(name, value, modified) => this.props.productChangeDone(name, value, modified, index)}
                                  deleteProduct={() => this.props.deleteProduct(index)}/>
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

PackageItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  albaranNumber: PropTypes.any,
  package: PropTypes.any.isRequired,
  dimension: PropTypes.any,
  grossWeight: PropTypes.any,
  netWeight: PropTypes.any,
  products: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  packageChanged: PropTypes.func.isRequired,
  packageChangeDone: PropTypes.func.isRequired,
  deletePackage: PropTypes.func.isRequired,
  productChanged: PropTypes.func.isRequired,
  productChangeDone: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired
};

PackageItem.defaultProps = {
  disabled: false,
  products: [],
  loading: false,
  packageChanged: () => {},
  packageChangeDone: () => {},
  deletePackage: () => {},
  productChanged: () => {},
  productChangeDone: () => {},
  deleteProduct: () => {}
};

export default PackageItem;
