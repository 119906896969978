
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Card } from '../../containers';
import { TextInput, SelectInput, SelectOption } from '../../components';
import { validateInputNotEmpty, validateNumberInput } from '../../utils';
import settings from '../../settings';

const NewOfferForm = ({offerStore, onNext, clients}) => (
  <div className="row row--align-top">
    <div className="col-12">
      <Card>
        <Card.Title>Información general</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-6 new-offer__input">
              <SelectInput label="Cliente"
                           name="client_id"
                           value={offerStore.offer.client_id}
                           searchEnabled={true}
                           onChange={offerStore.setValue}
                           disabled={false}
                           error={offerStore.errors.client_id}>
                {clients.map((client, index) => <SelectOption value={client.id} key={index}>{client.name}</SelectOption>)}
              </SelectInput>
            </div>
            <div className="col-6 new-offer__input">
              <TextInput label="Referencia"
                         name="reference"
                         value={offerStore.offer.reference}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}/>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>

    <div className="col-12">
      <Card>
        <Card.Title>Solicitud</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-4 new-offer__input">
              <TextInput label="Pedido"
                         type="text"
                         name="order"
                         value={offerStore.offer.order}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}/>
            </div>
            <div className="col-4 new-offer__input">
              <TextInput label="Modelo"
                         type="text"
                         name="model"
                         value={offerStore.offer.model}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}/>
            </div>
            <div className="col-4 new-offer__input">
              <TextInput label="Número de serie"
                         type="text"
                         name="serial_number"
                         value={offerStore.offer.serial_number}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}/>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>

    <div className="col-12">
      <Card>
        <Card.Title>Costos</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-4 new-offer__input">
              <SelectInput label="Moneda"
                           name="currency"
                           value={offerStore.offer.currency}
                           onChange={offerStore.setValue}
                           disabled={false}
                           error={offerStore.errors.currency}>
                <SelectOption value="USD">USD</SelectOption>
                <SelectOption value="EURO">Euro</SelectOption>
              </SelectInput>
            </div>
            <div className="col-4 new-offer__input">
              <SelectInput label="Condición"
                           name="incoterm"
                           value={offerStore.offer.incoterm}
                           onChange={offerStore.setValue}
                           disabled={false}
                           error={offerStore.errors.incoterm}>
                {settings.conditions.map(condition => <SelectOption key={condition}
                                                                    value={condition}>{condition}</SelectOption>)}
              </SelectInput>
            </div>

            <div className="col-4 new-offer__input">
              <SelectInput label="Condición Año"
                           name="incoterm_year"
                           value={offerStore.offer.incoterm_year}
                           onChange={offerStore.setValue}
                           disabled={false}
                           error={offerStore.errors.incoterm_year}>
                {settings.conditions_year.map(cy => <SelectOption key={cy}
                                                                    value={cy}>{cy}</SelectOption>)}
              </SelectInput>
            </div>

            <div className="col-12 new-offer__input">
              <SelectInput label="Forma de pago"
                           name="payment_method"
                           value={offerStore.offer.payment_method}
                           onChange={offerStore.setValue}
                           disabled={false}
                           error={offerStore.errors.payment_method}>
                {settings.PAYMENT_METHODS.map((method, index) => <SelectOption value={method} key={index}>{method}</SelectOption>)}
                <SelectOption type="text">OTRO</SelectOption>
              </SelectInput>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>

    <div className="col-12">
      <Card>
        <Card.Title>Entrega</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-6 new-offer__input">
              <SelectInput label="Punto de embarque"
                           name="boarding_place"
                           value={offerStore.offer.boarding_place}
                           onChange={offerStore.setValue}
                           disabled={false}
                           error={offerStore.errors.boarding_place}>
                {settings.boardingPlaces.map(place => <SelectOption key={place}
                                                                    value={place}>{place}</SelectOption>)}
                <SelectOption type="text">OTRO</SelectOption>
              </SelectInput>
            </div>
            <div className="col-6 new-offer__input">
              <TextInput label="Tiempo de entrega"
                         name="delivery_time"
                         value={offerStore.offer.delivery_time}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}
                         error={offerStore.errors.delivery_time}/>
            </div>
            <div className="col-6 new-offer__input">
              <TextInput label="Validez de la oferta"
                         type="text"
                         name="duration"
                         value={offerStore.offer.duration}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}
                         error={offerStore.errors.duration}/>
            </div>
            <div className="col-6 new-offer__input">
              <TextInput label="Garantía"
                         name="warranty"
                         value={offerStore.offer.warranty}
                         onChange={offerStore.setValue}
                         validateFn={validateInputNotEmpty}
                         disabled={false}
                         error={offerStore.errors.warranty}/>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>

    <div className="col-12">
      <Card>
        <Card.Title>Financiamiento</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col new-offer__input">
              <TextInput label="Periodo"
                         name="financing_period"
                         value={offerStore.offer.financing_period}
                         onChange={offerStore.setValue}
                         validateFn={validateNumberInput}
                         disabled={false}/>
            </div>
            <div className="col new-offer__input">
              <TextInput label="Porciento (%)"
                         name="financing_percentage"
                         value={offerStore.offer.financing_percentage}
                         onChange={offerStore.setValue}
                         validateFn={validateNumberInput}
                         disabled={false}/>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>

    <div className="col-12 page__card">
      <div className="new-offer__next">
        <button className="btn btn--primary btn--large" onClick={() => {
            onNext()
          }}>Continuar</button>
      </div>
    </div>

  </div>
);

NewOfferForm.propTypes = {
  offerStore: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired
};

export default observer(NewOfferForm);
