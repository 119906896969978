
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

const LoadingButton = ({className, loading, loadingColor, onClick, error, success, children, loaderSize}) => {
  const content = () => {
    if (error) {
      return error instanceof Error ? error.message ? error.message : 'unauthorized' : error;
    } else if (success) {
      return success;
    } else {
      return children;
    }
  };

  className += ' btn loading-button';
  if (error) {
    className += ' btn--error';
  } else if (success) {
    className += ' btn--success';
  }

  return (
    <button className={className}
            onClick={() => onClick && onClick()}
            disabled={error || success || loading}>
      <div className={`loading-button__content ${loading ? 'loading-button__content--hide' : ''}`}>{content()}</div>
      {loading ? <div className="loading-button__loader"><Loader color={loadingColor} size={loaderSize}/></div> : null}
    </button>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingColor: PropTypes.oneOf(['white', 'black']),
  loaderSize: PropTypes.oneOf(['mid', 'small']),
  onClick: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
};

LoadingButton.defaultProps = {
  className: '',
  loadingColor: 'white',
  loaderSize: 'mid',
};

export default LoadingButton;
