
import './index.scss';

import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import api from '../../api';
import settings from '../../settings';
import { LocalOfferStore, utilsStore } from '../../stores';
import { Toolbar, ProductsListOld, PageHeader, protect, StepsIndicator, LoadingButton } from '../../components';
import NewOfferForm from './form';
import { Card } from '../../containers';

class NewOffer extends React.Component {
  state = {
    step: 1,
    loadingNewRevision: false
  };
  offerStore = new LocalOfferStore(true);
  redirectTimeout;
  NOTIFICATION_DURATION = 3000;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Ofertas', link: '/offers'},
    {title: 'Nueva oferta', link: '/offers/new'}
  ];

  constructor(props) {
    super(props);
    this.getOffer = this.getOffer.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getOffer(this.props.match.params.id);
    } else {
      this.offerStore.recoverFromAutosave();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.redirectTimeout);
  }

  getOffer(id) {
    this.setState({loadingNewRevision: true});
    api.quote.getQuote(id, this.source)
      .then(response => {
        this.offerStore.setOffer(response.data);
        this.offerStore.recoverFromAutosave();
        this.setState({loadingNewRevision: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error(err);
        }
      });
  }

  render() {
    return (
      <div className="new-offer">
        <Helmet>
          <title>Nueva oferta | Metallica Caribbean</title>
          <meta name="description" content="Formulario de creación de nueva oferta" />
          <meta name="keyboards" content="oferta,metallica,crear,nuevo" />
        </Helmet>

        <div className="row new-offer__container">
          <div className={`new-offer__content ${this.state.step === 1 ? 'new-offer__content--form' : ''}`}>
            <div className="new-offer__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title="Nueva oferta"
                                    onBack={() => this.state.step === 2 ? this.setState({step: 1}) : this.props.history.goBack()}>
                <PageHeader.Actions>
                  <PageHeader.Action>
                    <StepsIndicator currentStep={this.state.step}
                                    steps={['Detalles', 'Productos']}/>
                  </PageHeader.Action>
                </PageHeader.Actions>
              </PageHeader.Container>
            </div>

            {this.state.step === 1 ?
              (
                <NewOfferForm offerStore={this.offerStore}
                              clients={utilsStore.clients}
                              onNext={() => this.offerStore.formIsValid ? this.setState({step: 2}) : this.offerStore.validate()}/>
              ) :
              (
                <div className="row row--align-top">
                  <div className="col-12">
                    <Card>
                      <Card.Title col="8">Productos</Card.Title>
                      <Card.Actions col="4">
                        {
                          this.offerStore.productsStore.containsSelectedProducts > 0 && (
                            <Card.Action>
                              <LoadingButton loadingColor="black"
                                             className="btn--outline btn--gray btn--small btn--icon"
                                             loading={false}
                                             loaderSize="small"
                                             onClick={this.offerStore.productsStore.deleteSelectedProducts}>
                                Eliminar productos
                              </LoadingButton>
                            </Card.Action>
                          )
                        }
                      </Card.Actions>
                      <Card.Body>
                        <div className="">
                          <ProductsListOld allProductsSelected={this.offerStore.productsStore.allProductsSelected}
                                           itemNumberVisible={false}
                                           clientId={this.offerStore.offer.client_id}
                                           eligibleProducts={utilsStore.products}
                                           products={this.offerStore.products}
                                           margin={this.offerStore.offer.margin}
                                           totalProducts={this.offerStore.productsTotal}
                                           totalExtras={this.offerStore.extras}
                                           discounted={this.offerStore.discounted}
                                           total={this.offerStore.total}
                                           addProduct={this.offerStore.addProduct}
                                           productChanged={this.offerStore.setProductValue}
                                           deleteProduct={this.offerStore.deleteProduct}
                                           toggleProductsSelection={this.offerStore.productsStore.toggleProductsSelection}
                                           moveProduct={this.offerStore.moveProduct}
                                           />
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12">
                    <Card>
                      <Card.Title col="8">Productos Opcionales</Card.Title>
                      <Card.Actions col="4">
                        {
                          this.offerStore.optionalProductsStore.containsSelectedProducts > 0 && (
                            <Card.Action>
                              <LoadingButton loadingColor="black"
                                             className="btn--outline btn--gray btn--small btn--icon"
                                             loading={false}
                                             loaderSize="small"
                                             onClick={this.offerStore.optionalProductsStore.deleteSelectedProducts}>
                                Eliminar productos
                              </LoadingButton>
                            </Card.Action>
                          )
                        }
                      </Card.Actions>
                      <Card.Body>
                        <div className="">
                          <ProductsListOld allProductsSelected={this.offerStore.optionalProductsStore.allProductsSelected}
                                           itemNumberVisible={false}
                                           transferVisible={true}
                                           clientId={this.offerStore.offer.client_id}
                                           eligibleProducts={utilsStore.products}
                                           products={this.offerStore.optionalProducts}
                                           margin={this.offerStore.offer.margin}
                                           summaryVisible={false}
                                           addProduct={this.offerStore.addOptionalProduct}
                                           productChanged={this.offerStore.setOptionalProductValue}
                                           deleteProduct={this.offerStore.deleteOptionalProduct}
                                           transferProduct={this.offerStore.transferProduct}
                                           toggleProductsSelection={this.offerStore.optionalProductsStore.toggleProductsSelection}
                                           moveProduct={this.offerStore.moveOptionalProduct}
                                           />
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              )}
          </div>

          {this.state.step === 2 ?
            <div className="new-offer__toolbar">

              <Toolbar insurance={this.offerStore.offer.insurance}
                       freight={this.offerStore.offer.freight}
                       incoterm={this.offerStore.offer.incoterm}
                       incoterm_price={this.offerStore.offer.incoterm_price}
                       margin={this.offerStore.offer.margin}
                       origin_inspection={this.offerStore.offer.origin_inspection}
                       discount={this.offerStore.offer.discount}
                       totalProducts={this.offerStore.productsTotal}
                       totalExtras={this.offerStore.extras}
                       discounted={this.offerStore.discounted}
                       total={this.offerStore.total}
                       onChange={this.offerStore.setValue}
                       error={this.offerStore.createState.error}
                       success={this.offerStore.createState.success ? 'Oferta creada' : false}
                       loading={this.offerStore.createState.loading}
                       onNext={() => this.offerStore.create().then(id => this.redirectTimeout = setTimeout(() => this.props.history.push(`/offers/${id}`), this.NOTIFICATION_DURATION))}/>
            </div> :
            null
          }

        </div>
      </div>
    );
  }
}

export default protect(observer(NewOffer), settings.permissions.quotes.create);
