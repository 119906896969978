
const DATE_EXPRESSION = /^\d{4}-\d{2}-\d{2}/;

/**
 * Parse text date to Date object
 * @param value
 * @returns {null|Date}
 */
const fn = value => {
  const date = value && value.toString();
  if (date && DATE_EXPRESSION.test(date)) {
    const year = Number(date.slice(0, 4));
    const month = Number(date.slice(5, 7)) - 1;
    const day = Number(date.slice(8, 10));
    return new Date(year, month, day);
  } else {
    return null;
  }
};
export default fn;
