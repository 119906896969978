
import React from 'react';
import PropTypes from 'prop-types';

const SelectOption = ({children, value, active, onSelect}) => {
  let classNames = 'input-group__option';
  if (active) { classNames += ' input-group__option--active'; }

  return (
    <div className={classNames}
         onClick={() => {
           onSelect(value);
         }}>{children}</div>
  );
};

SelectOption.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any,
  active: PropTypes.bool.isRequired,
  display: PropTypes.any,
  onSelect: PropTypes.func,
  type: PropTypes.oneOf(['select', 'text']).isRequired
};

SelectOption.defaultProps = {
  active: false,
  type: 'select'
};

export default SelectOption;
