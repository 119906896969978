
/**
 * Tegex to find all dots
 * @type {RegExp}
 */
const DOTS_REGEX = /(\.)/g;

/**
 * Parse value to selected decimal places
 * @param value
 * @param decimals
 * @param separator
 * @returns {string}
 */
const fn = (value, decimals = 2, separator = null) => {
  const parsedNumber = Number.parseFloat(value ? value.toString() : '0').toFixed(decimals);
  return separator ? parsedNumber.replace(DOTS_REGEX, separator) : parsedNumber;
};
export default fn;
