
import './index.scss';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, {Fragment} from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import settings from '../../settings';
import api from '../../api';
import protect from '../../components/protect';
import { parseDateReadable } from '../../utils';
import { ListPagination, PageHeader, TextInput, Dropdown, EmptyNotification, QuotePreview, InvoicePreview, Tooltip } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell, TableLinkBodyCell } from '../../components/Table';
import authStore from '../../stores/auth';
import FileSaver from 'file-saver';

class ShipmentsList extends React.Component {
  state = {
    shipments: [],
    filters: {
      description: null,
      perPage: 10,
      initialDate: null,
      finalDate: null
    },
    page: 1,
    pages: 1,
    loading: false,
    error: false
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Embargues', link: '/shipment'}
  ];

  constructor(props) {
    super(props);

    this.getFilters = this.getFilters.bind(this);
    this.filtersChanged = this.filtersChanged.bind(this);
    this.getShipments = this.getShipments.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
  }

  componentDidMount() {
    this.getShipments();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  pageChanged(page) {
    this.setState({page});
    this.getShipments(page);
  }

  filtersChanged(value, name) {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value
      }
    });
  }

  getFilters(selectedPage) {
    const { perPage, description, finalDate, initialDate } = this.state.filters;
    return {
      per_page: perPage,
      page: selectedPage || 1,
      description,
      initial_date: initialDate,
      final_date: finalDate
    };
  }

  getShipments(selectedPage = null, filters = {}) {
    this.setState({loading: true});

    api.shipment.getShipments(this.source, {...this.getFilters(selectedPage), ...filters})
      .then(response => {
        const shipments = response.data.data;
        const pages = response.data.pages;
        this.setState({shipments, pages, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loading: false, error: err});
        }
      });
  }

  downloadExcel(id) {
    api.shipment.downloadExcel(id, this.source)
      .then(response => {
        const fileName = `Embarque ${id}.xlsx`;
        FileSaver.saveAs(response.data, fileName);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error downloading shipment', err);
        }
      });
  }

  render() {
    return (
      <div className="shipments page page--list">
        <Helmet>
          <title>Embarques | Metallica Caribbean</title>
          <meta name="description" content="Listado de embarques" />
          <meta name="keyboards" content="embarques,metallica,listado" />
        </Helmet>

        <div className="page__heading">
          <PageHeader.Container navigation={this.NAVIGATION}
                                title="Embarques">
            <PageHeader.Filters>
              <PageHeader.Filter>
                <TextInput name="description"
                           placeholder="Buscar..."
                           value={this.state.filters.description}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getShipments()}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput type="date"
                           name="initialDate"
                           placeholder="Desde..."
                           value={this.state.filters.initialDate}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getShipments(null, {initial_date: value})}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput type="date"
                           name="finalDate"
                           placeholder="Hasta..."
                           value={this.state.filters.finalDate}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getShipments(null, {final_date: value})}/>
              </PageHeader.Filter>
            </PageHeader.Filters>

            {authStore.permissions.packingLists.create ? (
              <PageHeader.Actions>
                <PageHeader.Action>
                  <button className="btn btn--primary"
                          onClick={() => this.props.history.push(`/shipments/new`)}>Nuevo embarque</button>
                </PageHeader.Action>
              </PageHeader.Actions>
            ) : null}
          </PageHeader.Container>
        </div>

        <div className="card">
          <Table loadingRows={10}
                 loadingCells={7}
                 loading={this.state.loading}
                 emptyState={<EmptyNotification icon={NotFoundIcon}
                                                title="No se encontrarón embarques"
                                                details="Puedes cambiar los filtros de búsqueda en la parte superior o crear un embarque."
                                                action={authStore.permissions.packingLists.create ? 'Nuevo embarque' : null}
                                                onAction={() => authStore.permissions.packingLists.create && this.props.history.push(`/shipments/new`)}/>}
                 errorState={<EmptyNotification icon={DisconnectIcon}
                                                title={this.state.error}
                                                details="Ha ocurrido un error obteniendo los embarques."/>}
                 headerCells={
                   <Fragment>
                     <TableHeaderCell>Código</TableHeaderCell>
                     <TableHeaderCell>Oferta</TableHeaderCell>
                     <TableHeaderCell>Factura</TableHeaderCell>
                     <TableHeaderCell>Cliente</TableHeaderCell>
                     <TableHeaderCell>Fecha</TableHeaderCell>
                     <TableHeaderCell className="shipments__header-cell--manifiesto">Manifiesto</TableHeaderCell>
                     <TableHeaderCell/>
                 </Fragment>}
                 pagination={<ListPagination margin="page"
                                             currentPage={this.state.page}
                                             totalPages={this.state.pages}
                                             onChange={this.pageChanged}/>}>
            {this.state.error || this.state.shipments.map((shipment, index) => (
              <TableBodyRow key={shipment.id}
                            onSelect={() => authStore.permissions.packingLists.read && this.props.history.push(`/shipments/${shipment.id}`)}>
                <TableBodyCell>{shipment.id}</TableBodyCell>
                <TableBodyCell>
                  <Tooltip title={shipment.quote_id}
                           size="mid"
                           position="dropdown-left"
                           trigger={<button className="btn btn--link btn--table btn--nowrap"
                                            onClick={event => {
                                              this.props.history.push(`/offers/${shipment.quote_id}`);
                                              event.stopPropagation();
                                            }}>{shipment.quote_id}</button>}>
                    <QuotePreview quoteId={shipment.quote_id}/>
                  </Tooltip>
                </TableBodyCell>
                <TableBodyCell>
                  <Tooltip title={shipment.invoice_id}
                           size="mid"
                           position="dropdown-left"
                           trigger={<button className="btn btn--link btn--table btn--nowrap"
                                            onClick={event => {
                                              this.props.history.push(`/invoices/${shipment.invoice_id}`);
                                              event.stopPropagation();
                                            }}>{shipment.invoice_id}</button>}>
                    <InvoicePreview invoiceId={shipment.invoice_id}/>
                  </Tooltip>
                </TableBodyCell>
                <TableLinkBodyCell url={`/admin/clients/${shipment.client_id}`}>{shipment.client}</TableLinkBodyCell>
                <TableBodyCell nowrap={true}>{parseDateReadable(shipment.creation_date, true)}</TableBodyCell>
                <TableBodyCell>{shipment.manifesto}</TableBodyCell>
                <TableBodyCell className="shipments__body-cell--actions" nowrap={true}>
                  {authStore.permissions.packingLists.read ? (
                    <Dropdown.Container icon={MenuIcon}
                                        color="gray"
                                        size="small"
                                        outline={false}
                                        showCaret={false}
                                        inline={true}>
                      <Dropdown.Button onClick={() => this.downloadExcel(shipment.id)}>Descargar</Dropdown.Button>
                    </Dropdown.Container>
                  ) : null}
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

export default protect(observer(ShipmentsList), settings.permissions.packingLists.list);
