
import axios from 'axios';

import mappers from './mappers';

const URL_PREFIX = 'manufacturer';

/**
 * Get all suppliers
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getSuppliers = (source = null, filters = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token,
    params: filters
  }
);

/**
 * Get supplier by its id
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getSupplier = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Create supplier
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createSupplier = (data, source = null) => axios.post(
  URL_PREFIX,
    mappers.mapSupplierToAPI(data),
    {
        cancelToken: source && source.token
    }
);

/**
 * Update supplier by its id
 * @param id
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateSupplier = (id, data, source = null) => axios.patch(
  `${URL_PREFIX}/${id}`,
  mappers.mapSupplierToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Create phone on supplier by its id
 * @param supplierId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createPhone = (supplierId, data, source = null) => axios.post(
  `${URL_PREFIX}/${supplierId}/phone`,
  mappers.mapPhoneToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update phone info
 * @param supplierId
 * @param phoneId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updatePhone = (supplierId, phoneId, data, source = null) => axios.patch(
  `${URL_PREFIX}/${supplierId}/phone/${phoneId}`,
  mappers.mapPhoneToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete supplier phone by its id
 * @param supplierId
 * @param phoneId
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deletePhone = (supplierId, phoneId, source = null) => axios.delete(
  `${URL_PREFIX}/${supplierId}/phone/${phoneId}`,
  {
    cancelToken: source && source.token
  }
);

const createContact = (supplierId, data, source = null) => axios.post(
  `${URL_PREFIX}/${supplierId}/contact_info`,
  mappers.mapContactToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update contact info by its id
 * @param supplierId
 * @param contactId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateContact = (supplierId, contactId, data, source = null) => axios.patch(
  `${URL_PREFIX}/${supplierId}/contact_info/${contactId}`,
  mappers.mapContactToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete contact phone by its id
 * @param supplierId
 * @param contactId
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deleteContact = (supplierId, contactId, source = null) => axios.delete(
  `${URL_PREFIX}/${supplierId}/contact_info/${contactId}`,
  {
    cancelToken: source && source.token
  }
);

const apiFunctions = {
  createSupplier,
  getSuppliers,
  getSupplier,
  updateSupplier,

  createPhone,
  updatePhone,
  deletePhone,

  createContact,
  updateContact,
  deleteContact
};

export default apiFunctions;
export {
  apiFunctions,
  mappers
};