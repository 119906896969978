
import './index.scss';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Step from '../Step';

const Line = ({active}) => (
  <div className={`steps-indicator__line ${active ? 'steps-indicator__line--active' : ''}`}/>
);

Line.propTypes = {
  active: PropTypes.bool.isRequired
};

Line.defaultProps = {
  active: false
};

const StepsIndicator = ({steps, currentStep}) => {
  const stepsLength = steps.length;
  return (
    <div className="steps-indicator">
      {steps.map((step, index) => {
        const lastStep = (stepsLength - 1) === index;
        let state = 'incomplete';
        if (currentStep === (index + 1)) {
          state = 'active';
        } else if (currentStep > (index + 1)) {
          state = 'completed';
        }
        return (
          <Fragment key={index}>
            <div className="steps-indicator__step"><Step label={step} number={index + 1} state={state}/></div>
            {!lastStep ? (<Line active={currentStep >= (index + 1)}/>) : null}
            {!lastStep ? (<Line active={currentStep >= (index + 2)}/>) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

StepsIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired
};

StepsIndicator.defaultProps = {
  steps: []
};

export default StepsIndicator;