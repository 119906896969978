
import { observable, action, decorate, computed, toJS } from 'mobx';

import { parseToDecimal } from '../../utils';
import { calculateMargin, calculatePrice, calculateTotal } from '../../utils/offers';

import OperationsStore from './Operations';

class LocalProductsStore {
  products = [];
  operations;
  autosave = true;
  autosaveKey = 'autosave/offers/new/products';

  get total() {
    return this.calculateProductsTotal();
  }

  get JSON() {
    return toJS(this.products);
  }

  /**
   * Get current selected products
   * @return {*[]}
   */
  get selectedProducts() {
    if (!this.products)
      return [];

    return this.products.filter(product => product.selected);
  }

  /**
   * Get current selected products indexes
   * @return {number[]}
   */
  get selectedProductsIndexes() {
    if (!this.products)
      return [];

    return this.products
      .map((product, index) => ({index, selected: product.selected}))
      .filter(product => product.selected)
      .map(product => product.index);
  }

  /**
   * Get current selected products count
   * @return {number}
   */
  get containsSelectedProducts() {
    return this.selectedProducts.length;
  }

  get allProductsSelected() {
    if (!this.products)
      return false;

    const productsLength = this.products.length;
    const selectedProductsLength = this.selectedProducts.length;

    return productsLength > 0 ? selectedProductsLength === productsLength : false;
  }

  constructor(products = [], autosave = false, autosaveKey = null) {
    this.configure(products, autosave, autosaveKey);
  }

  configure(products, autosave = false, autosaveKey = null, operations = false) {
    this.autosave = autosave;
    this.products = products;
    if (autosaveKey)
      this.autosaveKey = autosaveKey;
    if (operations) {
      this.baseProducts = products;
      this.operations = new OperationsStore(products);
    }
  }

  calculateProductsTotal() {
    return this.products.reduce((total, product) => {
      const totalProducts = product.sub_products ?
        product.sub_products.reduce((subProductsTotal, subProduct) => subProductsTotal + (Number(subProduct.total) || 0), 0) :
        0;
      return total + (Number(product.total) || 0) + totalProducts;
    }, 0);
  }

  setProductValue(name, value, index, subIndex = null) {
    const product = subIndex || subIndex === 0 ? this.products[index].sub_products[subIndex] : this.products[index];
    let newPrice;

    switch (name) {
      case 'cost':
        newPrice = calculatePrice(value, product.margin);
        product.cost = value;
        product.price = parseToDecimal(newPrice);
        product.total = parseToDecimal(calculateTotal(newPrice, product.quantity));
        break;
      case 'margin':
        newPrice = calculatePrice(product.cost, value);
        product.margin = value;
        product.price = parseToDecimal(newPrice);
        product.total = parseToDecimal(calculateTotal(newPrice, product.quantity));
        break;
      case 'price':
        product.price = value;
        product.margin = parseToDecimal(calculateMargin(value, product.cost));
        product.total = parseToDecimal(calculateTotal(value, product.quantity));
        break;
      case 'quantity':
        product.quantity = value;
        product.total = parseToDecimal(calculateTotal(product.price, value));
        break;
      default:
        product[name] = value;
        break;
    }
    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  setProductData(data, index, subIndex = null) {
    if (subIndex || subIndex === 0) {
      this.products[index].sub_products[subIndex] = {...this.products[index].sub_products[subIndex], ...data};
    } else {
      this.products[index] = {...this.products[index], ...data};
    }
    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  setProductsData(data, indexes) {
    indexes.forEach(index => {
      this.products[index] = {...this.products[index], ...data};
    });
    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  addProduct(product, index = null) {
    if (index || index === 0) {
      this.products[index].sub_products = [...this.products[index].sub_products, product];
    } else {
      this.products.push({...product, sub_products: []});
    }
    const sortedProducts = this.products.sort((p1, p2) => (p1.item_no || 0) - (p2.item_no || 0));
    this.products.replace(sortedProducts);
    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  deleteProduct(index, subIndex) {
    if (subIndex || subIndex === 0) {
      this.products[index].sub_products.splice(subIndex, 1);
    } else if (index || index === 0) {
      this.products.splice(index, 1);
    }
    const sortedProducts = this.products.sort((p1, p2) => (p1.item_no || 0) - (p2.item_no || 0));
    this.products.replace(sortedProducts);
    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  deleteProducts(indexes) {
    while (indexes.length)
      this.products.splice(indexes.pop(), 1);

    const sortedProducts = this.products.sort((p1, p2) => (p1.item_no || 0) - (p2.item_no || 0));
    this.products.replace(sortedProducts);

    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  moveProducts(index ,newIndex){
    let tmpProd1 = this.products[index];
    let tmpProd2 = this.products[newIndex];

    this.products[index] = tmpProd2;
    this.products[newIndex] = tmpProd1;

    const sortedProducts = this.products.sort((p1, p2) => (p1.item_no || 0) - (p2.item_no || 0));
    this.products.replace(sortedProducts);
    if (this.autosave) {
      this.autosaveProducts();
    }
  }

  deleteSelectedProducts() {
    this.deleteProducts(this.selectedProductsIndexes);
  }

  selectAllProducts() {
    this.products = this.products.map(product => ({...product, selected: true}));
  }

  deselectAllProducts() {
    this.products = this.products.map(product => ({...product, selected: false}));
  }

  toggleProductsSelection() {
    if (this.allProductsSelected)
      this.deselectAllProducts();
    else
      this.selectAllProducts();
  }

  updateMargin(margin) {
    this.products.forEach((product, productIndex) => {
      this.setProductValue('margin', margin, productIndex);
      if (product.sub_products) {
        product.sub_products.forEach((subProduct, index) => {
          this.setProductValue('margin', margin, productIndex, index);
        });
      }
    });
  }

  autosaveProducts() {
    if (this.autosave) {
      try {
        localStorage.setItem(this.autosaveKey, JSON.stringify(this.products));
      } catch(err) {
        console.error('Error parsing JSON to save', err);
      }
    }
  }

  recoverFromAutosave() {
    try {
      const savedProducts = localStorage.getItem(this.autosaveKey);
      if (savedProducts) {
        this.products = JSON.parse(savedProducts);
      }
    } catch(err) {
      console.error('Error parsing saved JSON', err);
    }
  }

  saveOperation(id, type, data) {
    this.operations.push({id, type, data});
  }
}

decorate(
  LocalProductsStore,
  {
    products: observable,
    autosave: observable,
    total: computed,
    JSON: computed,
    selectedProducts: computed,
    containsSelectedProducts: computed,
    selectedProductsIndexes: computed,
    allProductsSelected: computed,
    configure: action,
    setProductValue: action,
    setProductData: action,
    setProductsData: action,
    addProduct: action,
    deleteProduct: action.bound,
    deleteProducts: action.bound,
    moveProducts: action.bound,
    deleteSelectedProducts: action.bound,
    selectAllProducts: action,
    deselectAllProducts: action,
    toggleProductsSelection: action.bound,
    updateMargin: action
  }
);

export default LocalProductsStore;
