
import axios from 'axios';

import mappers from './mappers';

const URL_PREFIX = 'client';

/**
 * Get all clients
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getClients = (source = null, filters = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token,
    params: filters
  }
);

/**
 * Gte client by its id
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getClient = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Create client
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createClient = (data, source = null) => axios.post(
  URL_PREFIX,
  data,
  {
    cancelToken: source && source.token
  }
);

/**
 * Update client by its id
 * @param id
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateClient = (id, data, source = null) => axios.patch(
  `${URL_PREFIX}/${id}`,
  mappers.mapClientToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Create contact on client by its id
 * @param clientId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createContact = (clientId, data, source = null) => axios.post(
  `${URL_PREFIX}/${clientId}/contact_info`,
  mappers.mapContactToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update contact by its id
 * @param clientId
 * @param contactId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateContact = (clientId, contactId, data, source = null) => axios.patch(
  `${URL_PREFIX}/${clientId}/contact_info/${contactId}`,
  mappers.mapContactToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete contact by its id
 * @param clientId
 * @param contactId
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deleteContact = (clientId, contactId, source = null) => axios.delete(
  `${URL_PREFIX}/${clientId}/contact_info/${contactId}`,
  {
    cancelToken: source && source.token
  }
);

const apiFunctions = {
  getClients,
  getClient,
  createClient,
  updateClient,

  createContact,
  updateContact,
  deleteContact
};

export default apiFunctions;
export {
  apiFunctions,
  mappers
};