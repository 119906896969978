
import './index.scss';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import settings from '../../settings';
import protect from '../../components/protect';
import authStore from '../../stores/auth';
import api from '../../api';
import { parseDateReadable, parseToMoney } from '../../utils';
import { ListPagination, PageHeader, Dropdown, TextInput, EmptyNotification } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell, TableLinkBodyCell } from '../../components/Table';
import FileSaver from 'file-saver';

class OffersList extends React.Component {
  state = {
    filters: {
      description: null,
      perPage: 10,
      initialDate: null,
      finalDate: null
    },
    offers: [],
    page: 1,
    pages: 1,
    loading: false,
    error: false
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Ofertas', link: '/offers'}
  ];

  constructor(props) {
    super(props);
    this.getOffers = this.getOffers.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
    this.filtersChanged = this.filtersChanged.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
  }

  componentDidMount() {
    this.getOffers();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  pageChanged(page) {
    this.setState({page});
    this.getOffers(page);
  }

  getFilters(selectedPage) {
    const { perPage, description, finalDate, initialDate } = this.state.filters;
    return {
      per_page: perPage,
      page: selectedPage || 1,
      description,
      initial_date: initialDate,
      final_date: finalDate
    };
  }

  getOffers(selectedPage = null, filters = {}) {
    this.setState({loading: true});

    api.quote.getQuotes(this.source, {...this.getFilters(selectedPage), ...filters})
      .then(response => {
        const offers = response.data.data;
        const pages = response.data.pages;
        this.setState({offers, pages, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({
            loading: false,
            error: err,
            offers: [],
            page: 1,
            pages: 1
          });
        }
      });
  }

  filtersChanged(value, name) {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value
      }
    });
  }

  downloadExcel(id) {
    api.quote.downloadExcel(id, this.source)
      .then(response => {
        const fileName = `Oferta ${id}.xlsx`;
        FileSaver.saveAs(response.data, fileName);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error downloading quote', err);
        }
      });
  }

  render() {
    return (
      <div className="offers page page--list">
        <Helmet>
          <title>Ofertas | Metallica Caribbean</title>
          <meta name="description" content="Listado de ofertas" />
          <meta name="keyboards" content="ofertas,metallica,listado" />
        </Helmet>

        <div className="page__heading">
          <PageHeader.Container navigation={this.NAVIGATION}
                                title="Ofertas">
            <PageHeader.Filters>
              <PageHeader.Filter>
                <TextInput name="description"
                           placeholder="Buscar..."
                           value={this.state.filters.description}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getOffers()}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput type="date"
                           name="initialDate"
                           placeholder="Desde..."
                           value={this.state.filters.initialDate}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getOffers(null, {initial_date: value})}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput type="date"
                           name="finalDate"
                           placeholder="Hasta..."
                           value={this.state.filters.finalDate}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getOffers(null, {final_date: value})}/>
              </PageHeader.Filter>
            </PageHeader.Filters>
            <PageHeader.Actions>
              {authStore.permissions.quotes.create ? (
                <PageHeader.Action>
                  <button className="btn btn--primary"
                          onClick={() => this.props.history.push(`/offers/new`)}>Nueva oferta</button>
                </PageHeader.Action>
              ) : null}
            </PageHeader.Actions>
          </PageHeader.Container>
        </div>

        <div className="card">
          <Table loadingRows={10}
                 loadingCells={6}
                 loading={this.state.loading}
                 emptyState={<EmptyNotification icon={NotFoundIcon}
                                                title="No se encontrarón ofertas"
                                                details="Puedes cambiar los filtros de búsqueda en la parte superior o crear una oferta."
                                                action={authStore.permissions.quotes.create ? 'Nueva oferta' : null}
                                                onAction={() => authStore.permissions.quotes.create && this.props.history.push(`/offers/new`)}/>}
                 errorState={<EmptyNotification icon={DisconnectIcon}
                                                title={this.state.error}
                                                details="Ha ocurrido un error obteniendo las ofertas."/>}
                 headerCells={<Fragment>
                   <TableHeaderCell>Código</TableHeaderCell>
                   <TableHeaderCell className="offers__header-cell--client-name">Cliente</TableHeaderCell>
                   <TableHeaderCell>Fecha</TableHeaderCell>
                   <TableHeaderCell className="offers__header-cell--model">Modelo</TableHeaderCell>
                   <TableHeaderCell>Monto</TableHeaderCell>
                   <TableHeaderCell/>
                 </Fragment>}
                 pagination={<ListPagination margin="page"
                                             currentPage={this.state.page}
                                             totalPages={this.state.pages}
                                             onChange={this.pageChanged}/>}>
            {this.state.error || this.state.offers.map((offer) => {
              const newInvoiceAvailable = authStore.permissions.invoices.create && !offer.invoice;
              return (
                <TableBodyRow key={offer.id}
                              onSelect={() => authStore.permissions.quotes.read && this.props.history.push(`/offers/${offer.id}`)}>
                  <TableBodyCell>
                    {offer.id}
                    {offer.official_id ? <button className="btn btn--error btn--outline btn--badge"
                                                 onClick={event => {
                                                   this.props.history.push(`/offers/${offer.official_id}`);
                                                   event.stopPropagation();
                                                 }}>{offer.official_id}</button> : null}
                  </TableBodyCell>
                  <TableLinkBodyCell url={`/admin/clients/${offer.client_id}`}>{offer.client}</TableLinkBodyCell>
                  <TableBodyCell>{parseDateReadable(offer.date, true)}</TableBodyCell>
                  <TableBodyCell>{offer.model}</TableBodyCell>
                  <TableBodyCell>{parseToMoney(offer.total)} €</TableBodyCell>
                  <TableBodyCell className="offers__body-cell--actions" nowrap={true}>
                    {
                      offer.invoice
                      && offer.invoice.length > 0
                      && authStore.permissions.invoices.read
                      && (
                        offer.invoice.length > 1
                          ? (
                            <Dropdown.Container
                              color="success"
                              size="small"
                              title="Facturas"
                              outline={false}
                              showCaret={true}
                              inline={true}
                            >
                              {
                                offer.invoice.map(invoice => (
                                  <Dropdown.Button
                                    key={invoice}
                                    onClick={(event) => {
                                      this.props.history.push(`/invoices/${invoice}`);
                                      // event.stopPropagation();
                                    }}
                                  >
                                    {invoice}
                                  </Dropdown.Button>
                                ))
                              }
                            </Dropdown.Container>
                          )
                          : (
                            <button
                              className="btn btn--success btn--table"
                              onClick={event => {
                                this.props.history.push(`/invoices/${offer.invoice[0]}`);
                                event.stopPropagation();
                              }}
                            >
                              {offer.invoice[0]}
                            </button>
                          )
                      )
                    }
                    {authStore.permissions.quotes.read || authStore.permissions.quotes.create || newInvoiceAvailable ? (
                      <Dropdown.Container icon={MenuIcon}
                                          color="gray"
                                          size="small"
                                          outline={false}
                                          showCaret={false}
                                          inline={true}>
                        {authStore.permissions.quotes.read ? (
                          <Dropdown.Button onClick={() => this.downloadExcel(offer.id)}>Descargar</Dropdown.Button>
                        ) : null}
                        {authStore.permissions.quotes.create ? <Dropdown.Button onClick={() => this.props.history.push(`/offers/new/from/${offer.id}`)}>Nueva revisión</Dropdown.Button> : null}
                        {newInvoiceAvailable ? <Dropdown.Button onClick={() => this.props.history.push(`/offers/${offer.id}/invoices/new`)}>Nueva factura</Dropdown.Button> : null}
                      </Dropdown.Container>
                    ) : null}
                  </TableBodyCell>
                </TableBodyRow>
              );
            })}
          </Table>
        </div>
      </div>
    );
  }
}

export default protect(observer(OffersList), settings.permissions.quotes.list);
