
import React from 'react';
import PropTypes from 'prop-types';

const Header = ({col, children}) => (
  <div className={col ? `col-${col}` : 'col'}>
    {
      React.Children.map(
        React.Children.toArray(children),
        (child) => React.cloneElement(child, {wrapper: false})
      )
    }
  </div>
);

Header.propTypes = {
  col: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  children: PropTypes.node.isRequired,
};

const Title = ({col, wrapper, loading, children}) => {
  const content =
    loading
      ? <div className="loading-block" style={{width: '25%'}}/>
      : <h2>{children}</h2>;

  if (wrapper)
    return (<div className={col ? `col-${col}` : 'col'}>{content}</div>);

  return content;
};

Title.propTypes = {
  col: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  wrapper: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
  loading: false,
  wrapper: true
};

const Actions = ({col, children}) => (
  <div className={`${col ? `col-${col}` : 'col'} card__actions`}>
    <div className="row row--align-center row--justify-right">
      {children}
    </div>
  </div>
);

Actions.propTypes = {
  col: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  children: PropTypes.node.isRequired,
};

const Action = ({col, children}) => (
  <div className={col ? `col-${col}` : 'col col--min-content'}>
    {children}
  </div>
);

Action.propTypes = {
  col: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  children: PropTypes.node.isRequired,
};

const Instructions = ({col, wrapper, loading, children}) => {
  const content =
    loading
      ? (
        <div
          className="loading-block loading-block--small"
          style={{width: '45%'}}
        />
      )
      : (
        <span className="card__instructions">{children}</span>
      );

  if (wrapper)
    return (
      <div className={`col-${col}`}>{content}</div>
    );

  return content;
};

Instructions.propTypes = {
  col: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  wrapper: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Instructions.defaultProps = {
  col: '12',
  loading: false,
  wrapper: true
};

const Body = ({children}) => <div className="col-12 card__body">{children}</div>;

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

class Card extends React.Component {
  static Header = Header;
  static Title = Title;
  static Actions = Actions;
  static Action = Action;
  static Instructions = Instructions;
  static Body = Body;

  render() {
    return (
      <div className="card">
        <div className="row row--align-top">
          {
            React.Children.map(
              this.props.children,
              (child) => React.cloneElement(
                child,
                {
                  loading: this.props.loading
                }
              )
            )
          }
        </div>
      </div>
    );
  }
}

// const Card = ({title, instructions, actions, loading, children}) => (
//   <div className="card">
//     <div className="row row--align-top">
//       {title ? <div className="col"><h2>{loading ? <div className="loading-block" style={{width: '25%'}}/> : title}</h2></div> : null}
//       {actions ? React.Children.toArray(actions).map((action, index) => <div key={index} className="col col--min-content card__actions">{action}</div>) : null}
//       {instructions ? (
//         <div className="col-12">
//           {loading ? <div className="loading-block loading-block--small"style={{width: '45%'}}/> : <span className="card--instructions">{instructions}</span>}
//         </div>
//       ) : null}
//       <div className="col-12">{children}</div>
//     </div>
//   </div>
// );

Card.propTypes = {
  title: PropTypes.any,
  instructions: PropTypes.any,
  loading: PropTypes.bool,
  actions: PropTypes.node,
  children: PropTypes.any
};

Card.defaultProps = {
  instructions: false,
  loading: false
};

export default Card;
