
import { ReactComponent as InvoicesIcon } from '../../assets/images/invoices.svg';

import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import EmptyNotification from '../../components/EmptyNotification';

const NoInvoiceFound = (props) => {
  if (props.history.action === 'REPLACE') {
    return (
      <div className="invoice page">
        <Helmet>
          <title>Factura no encontrada | Metallica Caribbean</title>
          <meta name="description" content="No se encontró la factura solicitada"/>
          <meta name="keyboards" content="factura,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="card">
              <EmptyNotification icon={InvoicesIcon}
                                 title="Factura no encontrada"
                                 details="Verifique que el código de la factura sea el correcto."
                                 action="Regresar"
                                 onAction={() => props.history.goBack()}/>
            </div>
          </div>
        </div>

      </div>
    );
  } else {
    return <Redirect to="/"/>
  }
};

export default NoInvoiceFound;