
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import api from '../../api';
import authStore from '../../stores/auth';
import { useInvoiceContext } from './InvoiceContext';
// import { useMounted } from '../../hooks';
import { Card } from '../../containers';
import { EmptyNotification, ListPagination, TextInput } from '../../components';
import { Table, TableBodyCell, TableBodyRow, TableHeaderCell } from '../../components/Table';

const SearchInput = ({onDone}) => {
  const [value, setValue] = useState(null);
  return (
    <TextInput
      placeholder="Buscar"
      value={value}
      onChange={setValue}
      onDone={(name, value, modified) => modified && onDone(value)}
    />
  );
};

SearchInput.propTypes = {
  onDone: PropTypes.func,
};

SearchInput.defaultProps = {
  onDone: () => {},
};

const ErrorNotification = ({error}) => (
  <EmptyNotification
    icon={DisconnectIcon}
    title={error || ''}
    details="Ha ocurrido un error obteniendo las ofertas."
  />
);

ErrorNotification.propTypes = {
  error: PropTypes.instanceOf(Error)
};

const OffersNotFoundNotification = () => {
  const history = useHistory();
  return (
    <EmptyNotification
      icon={NotFoundIcon}
      title="No se encontrarón ofertas"
      details="No se encontraron ofertas pendientes de facturación."
      action={authStore.permissions.quotes.create ? 'Nueva oferta' : null}
      onAction={() => authStore.permissions.quotes.create && history.push(`/offers/new`)}
    />
  );
};

export const OfferSelectionList = (props) => {
  // const {onSelect} = props;
  // const isMounted = useMounted();
  const [, dispatch] = useInvoiceContext();
  const [data, setData] = useState({quotes: [], pages: 1});
  const [filters, setFilters] = useState({page: 1, description: null});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      const source = api.source.createSource();
      const fetchQueues = async () => {
        setLoading(true);

        try {
          const response = await api.quote.getQuotes(
            source,
            {
              description: filters.description,
              page: filters.page,
              products_available: '0'
            }
          );
          const quotes = response.data.data;
          const pages = response.data.pages;

          setData({quotes, pages});
          setError(null);
        } catch(error) {
          if (!api.source.errorIsCancel(error)) {
            console.error(error);
            setError(error);
          }
        } finally {
          setLoading(false);
        }
      };
      fetchQueues();

      return () => source.cancel('unmount');
    },
    [
      filters.page,
      filters.description,
    ]
  );

  return (
    <Card>
      <Card.Header col="8">
        <Card.Title>Oferta</Card.Title>
        <Card.Instructions>Selecciona la oferta a la cual anexar la factura</Card.Instructions>
      </Card.Header>
      <Card.Action col="4">
        <SearchInput onDone={(description) => setFilters({...filters, description})}/>
      </Card.Action>
      <Card.Body>
        <Table
          loading={loading}
          loadingRows={5}
          loadingCells={3}
          errorState={<ErrorNotification error={error} />}
          emptyState={<OffersNotFoundNotification />}
          headerCells={
            <>
              <TableHeaderCell>Código</TableHeaderCell>
              <TableHeaderCell>Cliente</TableHeaderCell>
              <TableHeaderCell>Modelo</TableHeaderCell>
            </>
          }
          pagination={
            <ListPagination
              margin="select"
              totalPages={data.pages}
              currentPage={filters.page}
              onChange={page => setFilters({...filters, page})
              }
            />
          }
        >
          {
            error
            || data.quotes
              .map(quote => (
                <TableBodyRow
                  key={quote.id}
                  onSelect={() => dispatch({type: 'SELECT_OFFER', data: quote})}
                >
                  <TableBodyCell nowrap={true}>{quote.id}</TableBodyCell>
                  <TableBodyCell>{quote.client}</TableBodyCell>
                  <TableBodyCell>{quote.model}</TableBodyCell>
                </TableBodyRow>
              ))
          }
        </Table>
      </Card.Body>
    </Card>
  );
};

OfferSelectionList.propTypes = {
  // quoteId: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};

OfferSelectionList.defaultProps = {
  onSelect: () => {},
};

export default OfferSelectionList;
