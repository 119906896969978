
/**
 * Application settings
 */
const settings = {
  baseUrl: {
    production: 'https://mcsa-api.8co.dev:5000',
    development: 'https://mcsa-api.8co.dev:5000',
    local: 'http://localhost:5000'
  },
  permissions: {
    quotes: {
      list: 'list_quotes',
      create: 'create_quote',
      read: 'read_quote',
      edit: 'edit_quote',
      delete: 'delete_quote'
    },
    invoices: {
      list: 'list_invoices',
      create: 'create_invoice',
      read: 'read_invoice',
      edit: 'edit_invoice',
      delete: 'delete_invoice',
      set_paid: 'set_invoice_paid'
    },
    packingLists: {
      list: 'list_packings',
      create: 'create_packing',
      read: 'read_packing',
      edit: 'edit_packing',
      delete: 'delete_packing'
    },
    admin: 'administration',
    read_admin: 'read_admin'
  },
  unauthorizedUrl: '/sign-in',
  notificationTimeout: 1500,
  PAYMENT_METHODS: [
    'POR TRANSFERENCIA, 80% CONTRA LA PRESENTACIÓN DE LOS DOCUMENTOS DE EMBARQUE Y 20% CONTRA LA RECEPCIÓN DE LA MERCANCÍA',
    '100% POR CARTA DE CRÉDITO, EMITIDA POR BFI, IRREVOCABLE, NO CONFIRMADA, 100% PAGADERA A 360 DÍAS DE LA FECHA DEL CONOCIMIENTO DE EMBARQUE B/L'
  ],
  units: [
    'U',
    'm',
    'Kg',
    'L',
    'ton'
  ],
  conditions: [
    'CFR',
    'CIF',
    'CIP',
    'CPT',
    'DAF',
    'DAP',
    'DAT',
    'DDP',
    'DDU',
    'DEQ',
    'DES',
    'EXW',
    'FAS',
    'FCA',
    'FOB'
  ],
  conditions_year:[
    '2010',
    '2020'
  ],
  boardingPlaces: [
    'HAMBURGO, ALEMANIA',
    'VALENCIA, ESPAÑA',
    'COLÓN, PANAMÁ',
    'TOCUMEN, PANAMÁ'
  ]
};

export default settings;
