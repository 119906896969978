
import './ProductsList.scss';

import React, { Fragment, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { parseToMoney } from '../../utils';
import ProductsListContext from './ProductsListContext';
import BaseProduct from './BaseProduct';
import Product from './Product';
import NewProduct from './NewProduct';

const LoadingItem = () => {
  const {
    showItemNumber
  } = useContext(ProductsListContext);
  return (
    <tr>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      {showItemNumber ? <td><div className="loading-block loading-block--mid"/></td> : null}
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
    </tr>
  );
};

const Loading = () => (
  <>
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
  </>
);

const SummaryItem = (props) => {
  const {
    label,
    value
  } = props;
  const {
    showItemNumber
  } = useContext(ProductsListContext);
  return (
    <tr>
      <td
        colSpan={showItemNumber ? 10 : 9}
        className="products-list__subtitle"
      >
        {label}
      </td>
      <td
        colSpan="2"
        className="products-list__amount"
      >
        {parseToMoney(value, 2)} €
      </td>
    </tr>
  );
};

SummaryItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const ProductsList = (props) => {
  const {
    allowSelection,
    allowSubItem,
    showItemNumber,
    showInvoice,
    allSelected,
    onSelectAll,
    children,
  } = props;

  const contextValue = useMemo(
    () => ({
      showItemNumber,
      showInvoice,
    }),
    [
      showItemNumber,
      showInvoice
    ]
  );

  return (
    <ProductsListContext.Provider value={contextValue}>
      <table className="products-list">
        <thead>
        <tr>
          {
            allowSelection && (
              <th className="products-list__header products-list__header--min">
                <input
                  type="checkbox"
                  checked={allSelected}
                  onChange={(e) => onSelectAll(e.target.checked)}
                />
              </th>
            )
          }
          {
            allowSubItem && (
              <th className="products-list__header products-list__header--min"/>
            )
          }
          {
            showItemNumber && (
              <th className="products-list__header">#</th>
            )
          }
          <th className="products-list__header">Código</th>
          <th className="products-list__header">Descripción</th>
          <th className="products-list__header">C.A.</th>
          <th className="products-list__header">Unidad</th>
          <th className="products-list__header">Costo</th>
          <th className="products-list__header">Margen</th>
          <th className="products-list__header">Precio</th>
          <th className="products-list__header">Cantidad</th>
          <th className="products-list__header">Importe</th>
          <th className="products-list__header"/>
        </tr>
        </thead>
        <tbody>
        { children }
        </tbody>
      </table>
    </ProductsListContext.Provider>
  );
};

ProductsList.propTypes = {
  allowSelection: PropTypes.bool,
  allowSubItem: PropTypes.bool,
  showItemNumber: PropTypes.bool,
  showInvoice: PropTypes.bool,
  allSelected: PropTypes.bool,
  onSelectAll: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
};

ProductsList.defaultProps = {
  allowSelection: false,
  allowSubItem: false,
  showItemNumber: false,
  showInvoice: false,
  allSelected: false,
  onSelectAll: () => {}
};

ProductsList.LoadingItem = LoadingItem;
ProductsList.Loading = Loading;
ProductsList.SummaryItem = SummaryItem;
ProductsList.Product = Product;
ProductsList.NewProduct = NewProduct;

export default observer(ProductsList);
