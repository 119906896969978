
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TextInput from '../TextInput';
import LoadingButton from '../LoadingButton';
import { parseToMoney, validateNumberInput } from '../../utils';

const Toolbar = (props) => (
  <div className="toolbar">
    <div className="toolbar__content">
      <div className="toolbar__section">
        <div className="toolbar__input">
          <TextInput label="Flete"
                     type="number"
                     name="freight"
                     placeholder="0.00"
                     value={props.freight}
                     onChange={props.onChange}
                     validateFn={validateNumberInput}
                     disabled={false}
                     flat={true}/>
        </div>
        <div className="toolbar__input">
          <TextInput label="Seguro"
                     type="number"
                     name="insurance"
                     placeholder="0.00"
                     value={props.insurance}
                     onChange={props.onChange}
                     validateFn={validateNumberInput}
                     disabled={false}
                     flat={true}/>
        </div>
        {props.incoterm || props.incoterm === 0 ? (
          <div className="toolbar__input">
            <TextInput label={props.incoterm}
                       type="number"
                       name="incoterm_price"
                       placeholder="0.00"
                       value={props.incoterm_price}
                       onChange={props.onChange}
                       validateFn={validateNumberInput}
                       disabled={false}
                       flat={true}/>
          </div>
        ) : null}
        <div className="toolbar__input">
          <TextInput label="Inspección en origen"
                     type="number"
                     name="origin_inspection"
                     placeholder="0.00"
                     value={props.origin_inspection}
                     onChange={props.onChange}
                     validateFn={validateNumberInput}
                     disabled={false}
                     flat={true}/>
        </div>
      </div>
      <div className="toolbar__section">
        <div className="toolbar__input">
          <TextInput label="Margen"
                     type="number"
                     name="margin"
                     placeholder="0.00"
                     value={props.margin}
                     onChange={props.onChange}
                     validateFn={validateNumberInput}
                     disabled={false}
                     flat={true}/>
        </div>
        <div className="toolbar__input">
          <TextInput label="Descuento"
                     type="number"
                     name="discount"
                     placeholder="0.00"
                     value={props.discount}
                     onChange={props.onChange}
                     validateFn={validateNumberInput}
                     disabled={false}
                     flat={true}/>
        </div>
      </div>
      <div className="toolbar__section toolbar__section--information">
        <div className="toolbar__information">
          <div className="toolbar__label">Productos</div>
          <div className="toolbar__value">{parseToMoney(props.totalProducts)} €</div>
        </div>
        <div className="toolbar__information">
          <div className="toolbar__label">Extras</div>
          <div className="toolbar__value">{parseToMoney(props.totalExtras)} €</div>
        </div>
        <div className="toolbar__information">
          <div className="toolbar__label">Descuento</div>
          <div className="toolbar__value">{parseToMoney(props.discounted)} €</div>
        </div>
        <div className="toolbar__information">
          <div className="toolbar__label">Total</div>
          <div className="toolbar__value">{parseToMoney(props.total)} €</div>
        </div>
      </div>
      <div className="toolbar__section toolbar__section--next-button">
        <LoadingButton className="btn--primary btn--full"
                       loadingColor="black"
                       onClick={() => props.onNext()}
                       loading={props.loading}
                       success={props.success}
                       error={props.error}>Crear oferta</LoadingButton>
      </div>
    </div>
  </div>
);

Toolbar.propTypes = {
  freight: PropTypes.any,
  insurance: PropTypes.any,
  incoterm: PropTypes.any,
  incoterm_price: PropTypes.any,
  origin_inspection: PropTypes.any,
  margin: PropTypes.any,
  discount: PropTypes.any,
  totalProducts: PropTypes.any,
  totalExtras: PropTypes.any,
  discounted: PropTypes.any,
  total: PropTypes.any,
  loading: PropTypes.any,
  success: PropTypes.any,
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default observer(Toolbar);
