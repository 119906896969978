
const DATE_EXPRESSION = /^\d{4}-\d{2}-\d{2}/;
const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];
const SHORT_MONTHS = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
];

/**
 * Parse date string with format yyyy-mm-dd... to human readable format
 * @param value
 * @param shortMonths
 * @param separator
 * @returns {string|*|string}
 */
const fn = (value, shortMonths = false, separator = false) => {
  const date = value && value.toString();

  if (date && DATE_EXPRESSION.test(date)) {
    const year = date.slice(0, 4);
    const month = Number(date.slice(5, 7));
    const day = Number(date.slice(8, 10));

    if (separator) {
      return `${`0${day}`.slice(-2)}${separator}${`0${month}`.slice(-2)}${separator}${`0${year}`.slice(-2)}`;
    } else {
      return `${day} de ${shortMonths ? SHORT_MONTHS[month - 1] : MONTHS[month - 1]} de ${year}`;
    }
  } else {
    return date;
  }
};
export default fn;
