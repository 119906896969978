
import axios from 'axios';

import mappers from './mappers';

const URL_PREFIX = 'shipment';

/**
 * Get all shipments
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getShipments = (source = null, filters = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token,
    params: filters
  }
);

/**
 * Get shipment by its id
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getShipment = (id, source = null) => new Promise(
  (resolve, reject) => axios.get(
    `${URL_PREFIX}/${id}`,
    {
      cancelToken: source && source.token
    }
  )
    .then(response => {
      const shipment = response.data;
      shipment.packages = shipment.packages.map(mappers.mapPackageFromAPI);
      resolve(shipment);
    })
    .catch(err => reject(err))
);

/**
 * Get no shipper products
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getNoShippedProducts = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}/product`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Create shipment for id
 * @param invoiceId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createShipment = (invoiceId, data, source = null) => axios.post(
  `${URL_PREFIX}/invoice/${invoiceId}`,
  mappers.mapShipmentToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update shipment by its id
 * @param id
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateShipment = (id, data, source = null) => axios.patch(
  `${URL_PREFIX}/${id}`,
  mappers.mapShipmentToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Add packate to shipment
 * @param shipmentId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const addPackage = (shipmentId, data, source = null) => axios.post(
  `${URL_PREFIX}/${shipmentId}/package`,
  mappers.mapPackageToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update package by its id
 * @param shipmentId
 * @param packageId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updatePackage = (shipmentId, packageId, data, source = null) => axios.patch(
  `${URL_PREFIX}/${shipmentId}/package/${packageId}`,
  mappers.mapPackageToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete package by its id
 * @param shipmentId
 * @param packageId
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deletePackage = (shipmentId, packageId, source = null) => axios.delete(
  `${URL_PREFIX}/${shipmentId}/package/${packageId}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Add product to package on shipment by its id
 * @param shipmentId
 * @param packageId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const addProduct = (shipmentId, packageId, data, source = null) => axios.post(
  `${URL_PREFIX}/${shipmentId}/package/${packageId}/content`,
  mappers.mapProductToAPI(data, true),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update package product by its id
 * @param shipmentId
 * @param packageId
 * @param productId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateProduct = (shipmentId, packageId, productId, data, source = null) => axios.patch(
  `${URL_PREFIX}/${shipmentId}/package/${packageId}/content/${productId}`,
  mappers.mapProductToAPI(data, true, true),
  {
    cancelToken: source && source.token
  }
);

const deleteProduct = (shipmentId, packageId, productId, source = null) => axios.delete(
  `${URL_PREFIX}/${shipmentId}/package/${packageId}/content/${productId}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Download shipment report in excel format
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const downloadExcel = (id, source = null) => axios.get(
  `report/shipment/${id}`,
  {
    cancelToken: source && source.token,
    responseType: 'blob'
  }
);

const apiFunctions = {
  getShipments,
  getShipment,
  getNoShippedProducts,

  createShipment,
  updateShipment,
  addPackage,
  updatePackage,
  deletePackage,

  addProduct,
  updateProduct,
  deleteProduct,

  downloadExcel
};

export default apiFunctions;
export {
  apiFunctions,
  mappers
};
