
/**
 * Map offer to API format
 * @param offer
 * @returns {{insurance: (number|(IObservableFactory & IObservableFactories & {enhancer: IEnhancer<any>})|string), margin: (*|number), incoterm_price: (number|(IObservableFactory & IObservableFactories & {enhancer: IEnhancer<any>})), freight: (number|(IObservableFactory & IObservableFactories & {enhancer: IEnhancer<any>})|string), financing_percentage: (null|number|(IObservableFactory & IObservableFactories & {enhancer: IEnhancer<any>})), discount: (number|(IObservableFactory & IObservableFactories & {enhancer: IEnhancer<any>})|string), products: (*|[]), origin_inspection: (number|(IObservableFactory & IObservableFactories & {enhancer: IEnhancer<any>}))}}
 */
export const mapOfferToAPI = offer => ({
  ...offer,
  financing_period: offer.hasOwnProperty('financing_period') ? Number(offer.financing_period) || 0 : undefined,
  financing_percentage: offer.hasOwnProperty('financing_percentage') ? offer.financing_percentage || '0.00' : undefined,
  products: offer.hasOwnProperty('products') ? (offer.products ? offer.products.map(mapProductToAPI) : []) : undefined,
  optional_products: offer.hasOwnProperty('optional_products') ? (offer.optional_products ? offer.optional_products.map(mapProductToAPI) : []) : undefined,
  freight: offer.hasOwnProperty('freight') ? offer.freight || '0.00' : undefined,
  insurance: offer.hasOwnProperty('insurance') ? offer.insurance || '0.00' : undefined,
  incoterm_price: offer.hasOwnProperty('incoterm_price') ? offer.incoterm_price || '0.00' : undefined,
  origin_inspection: offer.hasOwnProperty('origin_inspection') ? offer.origin_inspection || '0.00' : undefined,
  discount: offer.hasOwnProperty('discount') ? offer.discount || '0.00' : undefined,
});

/**
 * Map product to api format
 * @param product
 * @param includeSubProducts
 * @returns {{margin: (*|number), total: (*|number), quantity: (*|number), price: number | string | string, is_optional: *}}
 */
export const mapProductToAPI = (product, includeSubProducts = true) => {
  const newProduct = {
    product_id: product.product_id,
    name: product.name,
    available: product.available,
    description: product.description,
    unit: product.unit,
    part_number: product.part_number,
    harmonized_code: product.harmonized_code,
    item_no: product.item_no,
    quantity: product.quantity,
    price: product.price,
    margin: product.margin,
    cost: product.cost,
    net_weight: product.net_weight,
    gross_weight: product.gross_weight,
    is_optional: product.hasOwnProperty('is_optional') ? !!(product.is_optional) : undefined,
  };

  if (includeSubProducts) {
    newProduct.sub_products = product.sub_products ? product.sub_products.map(subProduct => mapProductToAPI(subProduct, false)) : []
  }
  return newProduct;
};

/**
 * Map offer value to API value format
 * @param fieldName
 * @param value
 * @returns {*}
 */
export const mapProductValueToAPI = (fieldName, value) => {
  switch (fieldName) {
    case 'quantity':
    case 'margin':
    case 'price':
    case 'total':
      return Number(value);
    default: return value;
  }
};

const methods = {
  mapProductToAPI,
  mapProductValueToAPI,
  mapOfferToAPI
};
export default methods;
