
import './InvoiceForm.scss';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import api from '../../api';
import settings from '../../settings';
import { parseNumericValueIn } from '../../utils';
import { PageHeader, TextInput, SelectInput, SelectOption, ProductsListOld, protect, LoadingButton, Checkbox, StepsIndicator } from '../../components';
import SelectOfferOld from './SelectOfferOld';
import { authStore, utilsStore, LocalProductsStore } from '../../stores';

class InvoiceFormOld extends React.Component {
  state = {
    quote: null,
    invoice: {
      fiscal_number: null,
      contract_number: null,
      contract_date: null,
      BL_number: null,
      AWB_number: null,
      description: null,
      boarding_place: null,
      is_commercial: null,
      replacement: null,
      incoterm: null,
      payment_method: null,
      due_date: null,
      paid_date: null,
      packing: null,
      freight: null,
      insurance: null,
      incoterm_price: null,
      discount: null,
      financing_period: null,
      financing_percentage: null
    },
    productsLogs: [],
    step: 1,
    getQuoteError: false,
    createLoading: false,
    createSuccess: false,
    createError: false
  };
  source = api.source.createSource();
  productsStore = new LocalProductsStore();
  NOTIFICATION_DURATION = 3000;
  notificationTimeout;

  constructor(props) {
    super(props);

    this.getQuote = this.getQuote.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onSelectQuote = this.onSelectQuote.bind(this);
    this.createInvoice = this.createInvoice.bind(this);
    this.setQuote = this.setQuote.bind(this);
    this.getTotalExtras = this.getTotalExtras.bind(this);
    this.getDiscounted = this.getDiscounted.bind(this);
    this.getTotal = this.getTotal.bind(this);
  }

  componentDidMount() {
    const quoteId = this.props.match.params.quoteId;
    if (quoteId) {
      this.getQuote(quoteId);
    }
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  getQuote(id) {
    api.quote.getQuote(id, this.source)
      .then(response => {
        const quote = response.data;
        this.setQuote(quote);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error(err);
        }
      });
  }

  onValueChanged(value, name, error) {
    this.setState({invoice: {
        ...this.state.invoice,
        [name]: value
      }});
  }

  onSelectQuote(quote) {
    this.getQuote(quote.id);
  }

  setQuote(quote) {
    this.setState({
      quote,
      invoice: {
        ...this.state.invoice,
        boarding_place: quote.boarding_place,
        incoterm: quote.incoterm,
        incoterm_price: parseNumericValueIn(quote.incoterm_price),
        payment_method: quote.payment_method,
        packing: parseNumericValueIn(quote.packing),
        freight: parseNumericValueIn(quote.freight),
        insurance: parseNumericValueIn(quote.insurance),
        discount: parseNumericValueIn(quote.discount),
        financing_period: quote.financing_period,
        financing_percentage: parseNumericValueIn(quote.financing_percentage)
      }
    });
    this.productsStore.configure(quote.products, false, 'autosave/invoices/new/products', true);
  }

  createInvoice() {
    if (this.state.quote) {
      this.setState({createLoading: true});
      if (this.productsStore.operations)
        this.productsStore.operations.calculateOperations(this.productsStore.products);
      api.invoice.createInvoice(
        this.state.quote.id,
        {
          ...this.state.invoice,
          operations: this.productsStore.operations.getJSON()
        },
        this.source
      )
        .then(response => {
          console.log('Invoice id received', response.data);
          this.setState({createLoading: false});
          this.setNotificationTimeout(true, 'Factura creada exitosamente', response?.data);
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            this.setState({createLoading: false});
            this.setNotificationTimeout(false, err);
          }
        });
    }
  }

  setNotificationTimeout(success = false, value = true, invoiceId = null) {
    const fieldName = success ? 'createSuccess' : 'createError';
    this.setState({[fieldName]: value});
    this.notificationTimeout = setTimeout(
      () => {
        if (success) {
          this.props.history.push(`/invoices/${invoiceId}`);
        } else {
          this.setState({[fieldName]: false});
        }
      },
      this.NOTIFICATION_DURATION
    );
  }

  getTotalExtras() {
    return (Number(this.state.invoice.freight) || 0) +
      (Number(this.state.invoice.insurance) || 0) +
      (Number(this.state.invoice.origin_inspection) || 0) +
      (Number(this.state.invoice.incoterm_price) || 0);
  }

  getDiscounted() {
    return (this.productsStore.total + this.getTotalExtras()) * (Number(this.state.invoice.discount) || 0) / 100;
  }

  getTotal() {
    return this.productsStore.total + this.getTotalExtras() - this.getDiscounted();
  }

  render() {
    return (
      <div className={`new-invoice page page--form ${this.state.step === 2 ? 'new-invoice--full' : ''}`}>
        <Helmet>
          <title>Nueva factura | Metallica Caribbean</title>
          <meta name="description" content="Formulario de creación de factura" />
          <meta name="keyboards" content="factura,metallica,crear,nuevo" />
        </Helmet>

        <div className="row page__content">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={[
                {title: 'Inicio', link: '/'},
                {title: 'Facturas', link: '/invoices'},
                {title: 'Nueva factura', link: '/invoices/new'}
              ]}
                                    title="Nueva factura"
                                    onBack={() => this.state.step === 1 ? this.props.history.goBack() : this.setState({step: 1})}>
                <PageHeader.Actions>
                  <PageHeader.Action>
                    <StepsIndicator currentStep={this.state.step === 1 ? (this.state.quote ? 2 : 1) : this.state.step + 1}
                                    steps={['Oferta', 'Detalles', 'Productos']}/>
                  </PageHeader.Action>
                </PageHeader.Actions>
              </PageHeader.Container>
            </div>
          </div>

          {this.state.step === 1 ? (
            <Fragment>
              <div className="col-12 page__card">
                <SelectOfferOld selectedQuote={this.state.quote}
                             onSelectQuote={this.onSelectQuote}
                             onDeselectQuote={() => this.setState({quote: null})}/>
              </div>

              {this.state.quote ? (
                <Fragment>
                  <div className="col-12 page__card">
                    <div className="card">
                      <h2>Referencia</h2>
                      <div className="row row--align-top">
                        <div className="col-4 form__input">
                          <TextInput label="Número Fiscal"
                                     type="text"
                                     name="fiscal_number"
                                     value={this.state.invoice.fiscal_number}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <TextInput label="Número de contrato"
                                     type="text"
                                     name="contract_number"
                                     value={this.state.invoice.contract_number}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <TextInput label="Fecha de contrato"
                                     type="date"
                                     name="contract_date"
                                     placeholder="2020-12-24"
                                     value={this.state.invoice.contract_date}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-6 form__input">
                          <TextInput label="Número BL"
                                     type="text"
                                     name="BL_number"
                                     value={this.state.invoice.BL_number}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-6 form__input">
                          <TextInput label="Número AWB"
                                     type="text"
                                     name="AWB_number"
                                     value={this.state.invoice.AWB_number}
                                     onChange={this.onValueChanged}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 page__card">
                    <div className="card">
                      <h2>Mercancia</h2>
                      <div className="row row--align-top">
                        <div className="col-12 form__input">
                          <TextInput label="Descripción de mercancia"
                                     type="text"
                                     name="description"
                                     value={this.state.invoice.description}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <SelectInput label="País de origen"
                                       name="boarding_place"
                                       value={this.state.invoice.boarding_place}
                                       onChange={this.onValueChanged}>
                            {settings.boardingPlaces.map(place => <SelectOption key={place}
                                                                                value={place}>{place}</SelectOption>)}
                            <SelectOption type="text">OTRO</SelectOption>
                          </SelectInput>
                        </div>
                        <div className="col-4 form__input">
                          <Checkbox label="Mercancia sin valor comercial"
                                    name="is_commercial"
                                    value={this.state.invoice.is_commercial}
                                    onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <Checkbox label="Mercancia en reposición"
                                    name="replacement"
                                    value={this.state.invoice.replacement}
                                    onChange={this.onValueChanged}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 page__card">
                    <div className="card">
                      <h2>Pago</h2>
                      <div className="row row--align-top">
                        <div className="col-4 form__input">
                          <SelectInput label="Condición"
                                       name="incoterm"
                                       value={this.state.invoice.incoterm}
                                       onChange={this.onValueChanged}>
                            {settings.conditions.map(condition => <SelectOption key={condition}
                                                                                value={condition}>{condition}</SelectOption>)}
                          </SelectInput>
                        </div>
                        <div className="col-4 form__input">
                          <TextInput label="Fecha de pago estimada"
                                     type="date"
                                     name="due_date"
                                     placeholder="2020-12-24"
                                     value={this.state.invoice.due_date}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <Checkbox label="Pagado"
                                    name="paid_date"
                                    value={this.state.invoice.paid_date}
                                    onChange={this.onValueChanged}
                                    disabled={!authStore.permissions.invoices.set_paid}/>
                        </div>
                        <div className="col-12 form__input">
                          <SelectInput label="Forma de pago"
                                       name="paymentMethod"
                                       value={this.state.invoice.payment_method}
                                       onChange={this.onValueChanged}>
                            {settings.PAYMENT_METHODS.map((method, index) => <SelectOption value={method} key={index}>{method}</SelectOption>)}
                            <SelectOption type="text">OTRO</SelectOption>
                          </SelectInput>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 page__card">
                    <div className="card">
                      <h2>Costos</h2>
                      <div className="row row--align-top">
                        <div className="col-4 form__input">
                          <TextInput label="Embalaje"
                                     type="number"
                                     name="packing"
                                     value={this.state.invoice.packing}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <TextInput label="Flete"
                                     type="number"
                                     name="freight"
                                     value={this.state.invoice.freight}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-4 form__input">
                          <TextInput label="Seguro"
                                     type="number"
                                     name="insurance"
                                     value={this.state.invoice.insurance}
                                     onChange={this.onValueChanged}/>
                        </div>
                        {this.state.invoice.incoterm ? (
                          <div className="col-4 form__input">
                            <TextInput label={`Costo ${this.state.invoice.incoterm}`}
                                       type="number"
                                       name="incoterm"
                                       value={this.state.invoice.incoterm_price}
                                       onChange={this.onValueChanged}/>
                          </div>
                        ) : null}
                        <div className="col-4 form__input">
                          <TextInput label="Descuento"
                                     type="number"
                                     name="discount"
                                     value={this.state.invoice.discount}
                                     onChange={this.onValueChanged}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 page__card">
                    <div className="card">
                      <h2>Financiamiento</h2>
                      <div className="row row--align-top">
                        <div className="col-6 form__input">
                          <TextInput label="Periodo"
                                     type="text"
                                     name="financing_period"
                                     value={this.state.invoice.financing_period}
                                     onChange={this.onValueChanged}/>
                        </div>
                        <div className="col-6 form__input">
                          <TextInput label="Porcentaje (%)"
                                     type="number"
                                     name="financing_percentage"
                                     value={this.state.invoice.financing_percentage}
                                     onChange={this.onValueChanged}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 page__card">
                    <div className="page__submit">
                      <button className="btn btn--primary"
                              onClick={() => this.setState({step: 2})}>Continuar</button>
                    </div>
                  </div>
                </Fragment>
              ) : null}

            </Fragment>
          ) : (
            <Fragment>
              <div className="col-12 page__card">
                <div className="card card--instructions">
                  <h2>Productos</h2>
                  <span className="card__instructions">Revisa los productos asociados a la factura.</span>
                  <ProductsListOld itemNumberVisible={!!this.props.match.params.quoteId}
                                eligibleProducts={utilsStore.products}
                                products={this.productsStore.products}
                                margin={'0.00'}
                                totalProducts={this.productsStore.total}
                                totalExtras={this.getTotalExtras()}
                                discounted={this.getDiscounted()}
                                total={this.getTotal()}
                                addProduct={this.productsStore.addProduct}
                                productChanged={this.productsStore.setProductValue}
                                deleteProduct={this.productsStore.deleteProduct}/>
                </div>
              </div>
              <div className="col-12 page__card">
                <div className="page__submit">
                  <LoadingButton className="btn btn--primary"
                                 loadingColor="black"
                                 loading={this.state.createLoading}
                                 success={this.state.createSuccess}
                                 error={this.state.createError}
                                 onClick={() => this.createInvoice()}>Crear factura</LoadingButton>
                </div>
              </div>
            </Fragment>
          )}

        </div>
      </div>
    );
  }
}

export default protect(observer(InvoiceFormOld), settings.permissions.invoices.create);
