
import { ReactComponent as DisconnectIcon } from '../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import api from '../api';
import settings from '../settings';
import { LoadingLabel, PageHeader, TextInput, Subtitle, protect, EmptyNotification, Phone, NewPhone } from '../components';
import { Table, TableBodyRow, TableBodyCell, TableHeaderCell } from '../components/Table';
import { Card } from '../containers';
import { Contact, NewContact } from '../components/suppliers';
import { authStore } from '../stores';

class Supplier extends React.Component {
  state = {
    supplier: null,
    products: [],
    loading: false,
    loadingProducts: false,
    error: false,
    errorProducts: false,
    modifyingField: false,
    errorModifyingField: false,
    successModifyingField: false
  };
  source = api.source.createSource();
  MODIFY_NOTIFICATION_DURATION = 3000;
  MODIFY_SUCCESS_MESSAGE = 'Guardado';

  constructor(props) {
    super(props);

    this.getSupplier = this.getSupplier.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.getDisplayName = this.getDisplayName.bind(this);

    this.setPhoneLoading = this.setPhoneLoading.bind(this);
    this.setContactLoading = this.setContactLoading.bind(this);

    this.onInputDone = this.onInputDone.bind(this)
  }

  componentDidMount() {
    this.getSupplier();
    this.getSupplierProducts();
  }

  getDisplayName() {
    return this.state.supplier ? this.state.supplier.name : this.props.match.params.id;
  }

  getSupplier() {
    this.setState({loading: true});
    api.supplier.getSupplier(this.props.match.params.id, this.source)
      .then(response => {
        const supplier = response.data;
        this.setState({supplier, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loading: false, error: err});
        }
      });
  }

  getSupplierProducts() {
    this.setState({loadingProducts: true});
    api.product.getProducts(this.source, {manufacturer: this.props.match.params.id})
      .then(response => {
        const products = response.data;
        this.setState({products, loadingProducts: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingProducts: false, errorProducts: err});
        }
      });
  }

  onValueChange(value, name, error, index) {
    this.setState({
      supplier: {...this.state.supplier, [name]: value}
    });
  }

  setPhoneLoading(loading, index) {
    const phones = [...this.state.supplier.phones];
    phones[index].loading = loading;
    this.setState({supplier: {...this.state.supplier, phones}});
  }

  setContactLoading(loading, index) {
    const contacts = [...this.state.supplier.contacts];
    contacts[index].loading = loading;
    this.setState({supplier: {...this.state.supplier, contacts}});
  }

  onInputDone(name, value, modified) {
    if (modified) {
      this.setState({modifyingField: name, errorModifyingField: false, successModifyingField: false});
      clearTimeout(this.modifyNotificationTimeout);

      api.supplier.updateSupplier(
        this.props.match.params.id,
        {[name]: value},
        this.source
      )
        .then(() => this.setNotification(true, name))
        .catch(err => !api.source.errorIsCancel(err) && this.setNotification(false, err));
    }
  }

  setNotification(success = true, data) {
    if (success && this.state.modifyingField === data) {
      this.setState({modifyingField: false, errorModifyingField: false, successModifyingField: this.MODIFY_SUCCESS_MESSAGE});
      this.modifyNotificationTimeout = setTimeout(() =>
        this.setState({successModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
    } else {
      this.setState({modifyingField: false, errorModifyingField: data, successModifyingField: false});
      this.modifyNotificationTimeout = setTimeout(() =>
        this.setState({errorModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
    }
  }

  render() {
    if (this.state.error === 'not-found') {
      return <Redirect to="/admin/suppliers/not-found"/>;
    }
    return (
      <div className="supplier page">
        <Helmet>
          <title>{this.getDisplayName()} | Metallica Caribbean</title>
          <meta name="description" content={`Vista a detalle del proveedor ${this.getDisplayName()}`} />
          <meta name="keyboards" content="proveedor,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          {this.state.error ? (
            <div className="col-12">
              <div className="card">
                <EmptyNotification icon={DisconnectIcon}
                                   title={this.state.error}
                                   details="Ha ocurrido un error obteniendo el proveedor."
                                   action="Regresar"
                                   onAction={() => this.props.history.goBack()}/>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="col-12">
                <div className="page__heading">
                  <PageHeader.Container navigation={[
                    {title: 'Inicio', link: '/'},
                    {title: 'Proveedores', link: '/admin/suppliers'},
                    {title: this.state.supplier && this.state.supplier.name, link: `/admin/suppliers/${this.state.supplier && this.state.supplier.id}`}
                  ]}
                                        loading={this.state.loading}
                                        title={this.state.supplier && this.state.supplier.name}
                                        onBack={() => this.props.history.goBack()}>


                    <PageHeader.Actions>
                      {this.state.modifyingField || this.state.errorModifyingField || this.state.successModifyingField ? (
                        <PageHeader.Action>
                          <LoadingLabel loading={!!(this.state.modifyingField)}
                                        error={this.state.errorModifyingField}
                                        success={this.state.successModifyingField}>Guardando cambios</LoadingLabel>
                        </PageHeader.Action>
                      ) : null}
                    </PageHeader.Actions>
                  </PageHeader.Container>
                </div>
              </div>

              <div className="col-5">
                <div className="row row--align-top">
                  <div className="col-12 page__card">
                    <Card title="Información general"
                          loading={this.state.loading}>
                      <div className="row row--align-top">
                        <div className="col-12 page__input">
                          <TextInput label="Nombre"
                                     loading={this.state.loading}
                                     information={true}
                                     disabled={!authStore.permissions.admin}
                                     name="name"
                                     placeholder="Nombre de cliente"
                                     value={this.state.supplier && this.state.supplier.name}
                                     onChange={this.onValueChange}
                                     onDone={this.onInputDone}/>
                        </div>
                        <div className="col-12 page__input">
                          <TextInput type="email"
                                     label="Correo electrónico"
                                     loading={this.state.loading}
                                     information={true}
                                     disabled={!authStore.permissions.admin}
                                     name="email"
                                     value={this.state.supplier && this.state.supplier.email}
                                     onChange={this.onValueChange}
                                     onDone={this.onInputDone}/>
                        </div>
                        <div className="col-12 page__input">
                          <TextInput type="multiline"
                                     label="Dirección"
                                     loading={this.state.loading}
                                     information={true}
                                     disabled={!authStore.permissions.admin}
                                     name="address"
                                     placeholder="Calle, Ciudad, Estado, País"
                                     value={this.state.supplier && this.state.supplier.address}
                                     onChange={this.onValueChange}
                                     onDone={this.onInputDone}/>
                        </div>
                      </div>
                    </Card>
                  </div>

                  {!this.state.loading ? (
                    <Fragment>
                      {this.state.supplier && this.state.supplier.phones.length > 0 ? <div className="col-12"><Subtitle title="Números telefónicos"/></div> : null}
                      {this.state.supplier && this.state.supplier.phones.map((phone, index) => (
                        <div className="col-12 page__card" key={phone.id}>
                          <Phone type={phone.type}
                                 phone={phone.phone}
                                 loading={!!phone.loading}
                                 disabled={!authStore.permissions.admin}
                                 phoneChange={(value, name, error) => {
                                   const phones = [...this.state.supplier.phones];
                                   phones[index][name] = value;
                                   this.setState({supplier: {...this.state.supplier, phones}});
                                 }}
                                 phoneChanged={(name, value) => {
                                   this.setPhoneLoading(true, index);
                                   api.supplier.updatePhone(this.props.match.params.id, this.state.supplier.phones[index].id, {[name]: value}, this.source)
                                     .then(response => this.setPhoneLoading(false, index))
                                     .catch(err => {
                                       if (!api.source.errorIsCancel(err)) {
                                         console.error(err);
                                         this.setPhoneLoading(false, index);
                                       }
                                     });
                                 }}
                                 deletePressed={() => {
                                   this.setPhoneLoading(true, index);
                                   api.supplier.deletePhone(this.props.match.params.id, this.state.supplier.phones[index].id, this.source)
                                     .then(response => {
                                       const phones = [...this.state.supplier.phones];
                                       phones.splice(index, 1);
                                       this.setState({supplier: {...this.state.supplier, phones}});
                                     })
                                     .catch(err => {
                                       if (!api.source.errorIsCancel(err)) {
                                         console.error(err);
                                         this.setPhoneLoading(false, index);
                                       }
                                     });
                                 }}/>
                        </div>
                      ))}
                      {authStore.permissions.admin ? (
                        <div className="col-12 page__card">
                          <NewPhone add={phone => {
                            const phones = [...this.state.supplier.phones];
                            const newPhoneIndex = phones.length;
                            this.setState({supplier: {...this.state.supplier, phones: [...phones, {...phone, loading: true}]}});
                            api.supplier.createPhone(this.props.match.params.id, phone, this.source)
                              .then(response => {
                                const phones = [...this.state.supplier.phones];
                                phones[newPhoneIndex] = {...phones[newPhoneIndex], id: response.data, loading: false};
                                this.setState({supplier: {...this.state.supplier, phones}});
                              })
                              .catch(err => {
                                if (!api.source.errorIsCancel(err)) {
                                  const phones = [...this.state.supplier.phones];
                                  phones.splice(newPhoneIndex, 1);
                                  this.setState({supplier: {...this.state.supplier, phones}});
                                }
                              });
                          }}/>
                        </div>
                      ) : null}

                      {this.state.supplier && this.state.supplier.contacts.length > 0 ? <div className="col-12"><Subtitle title="Personas de contacto"/></div> : null}
                      {this.state.supplier && this.state.supplier.contacts.map((contact, index) => (
                        <div className="col-12 page__card" key={contact.id}>
                          <Contact loading={!!contact.loading}
                                   disabled={!authStore.permissions.admin}
                                   name={contact.name}
                                   position={contact.position}
                                   change={(value, name, error) => {
                                     const contacts = [...this.state.supplier.contacts];
                                     contacts[index][name] = value;
                                     this.setState({supplier: {...this.state.supplier, contacts}});
                                   }}
                                   changeDone={(name, value) => {
                                     this.setContactLoading(true, index);
                                     api.supplier.updateContact(this.props.match.params.id, this.state.supplier.contacts[index].id, {[name]: value}, this.source)
                                       .then(response => this.setContactLoading(false, index))
                                       .catch(err => {
                                         if (!api.source.errorIsCancel(err)) {
                                           console.error(err);
                                           this.setContactLoading(false, index);
                                         }
                                       });
                                   }}
                                   deletePressed={() => {
                                     this.setContactLoading(true, index);
                                     api.supplier.deleteContact(this.props.match.params.id, this.state.supplier.contacts[index].id, this.source)
                                       .then(response => {
                                         const contacts = [...this.state.supplier.contacts];
                                         contacts.splice(index, 1);
                                         this.setState({supplier: {...this.state.supplier, contacts}});
                                       })
                                       .catch(err => {
                                         if (!api.source.errorIsCancel(err)) {
                                           console.error(err);
                                           this.setContactLoading(false, index);
                                         }
                                       });
                                   }}/>
                        </div>
                      ))}
                      {authStore.permissions.admin ? (
                        <div className="col-12 page__card">
                          <NewContact add={contact => {
                            const contacts = [...this.state.supplier.contacts];
                            const newContactIndex = contacts.length;
                            this.setState({supplier: {...this.state.supplier, contacts: [...contacts, {...contact, loading: true}]}});
                            api.supplier.createContact(this.props.match.params.id, contact, this.source)
                              .then(response => {
                                const contacts = [...this.state.supplier.contacts];
                                contacts[newContactIndex] = {...contacts[newContactIndex], id: response.data, loading: false};
                                this.setState({supplier: {...this.state.supplier, contacts}});
                              })
                              .catch(err => {
                                if (!api.source.errorIsCancel(err)) {
                                  const contacts = [...this.state.supplier.contacts];
                                  contacts.splice(newContactIndex, 1);
                                  this.setState({supplier: {...this.state.supplier, contacts}});
                                }
                              });
                          }}/>
                        </div>
                      ) : null}
                    </Fragment>
                  ) : null}

                </div>
              </div>

              <div className="col-7 page__card">
                <Card title="Productos"
                      loading={this.state.loadingProducts}>
                  <Table loading={this.state.loadingProducts}
                         loadingRows={5}
                         loadingCells={5}
                         emptyState={<EmptyNotification icon={NotFoundIcon}
                                                        title="Aún no has agregado productos de este proveedor"
                                                        details="Presiona el botón debajo para crear un producto."
                                                        action="Nuevo producto"onAction={() => this.props.history.push(`/products/new`)}/>}
                         errorState={<EmptyNotification icon={DisconnectIcon}
                                                        title={this.state.errorProducts}
                                                        details="Ha ocurrido un error obteniendo los productos de este proveedor."/>}
                         headerCells={
                           <Fragment>
                             <TableHeaderCell>Código</TableHeaderCell>
                             <TableHeaderCell>Descripción</TableHeaderCell>
                             <TableHeaderCell>Precio</TableHeaderCell>
                             <TableHeaderCell>Peso bruto (Kg)</TableHeaderCell>
                             <TableHeaderCell>Peso neto (Kg)</TableHeaderCell>
                           </Fragment>
                         }>
                    {this.state.errorProducts || this.state.products.map(product => (
                      <TableBodyRow key={product.id}
                                    onSelect={() => this.props.history.push(`/products/${product.id}`)}>
                        <TableBodyCell>{product.part_number}</TableBodyCell>
                        <TableBodyCell>{product.description}</TableBodyCell>
                        <TableBodyCell>{product.price} €</TableBodyCell>
                        <TableBodyCell>{product.gross_weight}</TableBodyCell>
                        <TableBodyCell>{product.net_weight}</TableBodyCell>
                      </TableBodyRow>
                    ))}
                  </Table>
                </Card>
              </div>
            </Fragment>
          )}

        </div>
      </div>
    );
  }
}

export default protect(Supplier, settings.permissions.read_admin);