
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BaseProduct from './BaseProduct';

export const Product = (props) => {
  const {
    isSubItem,
    selected,
    disabled,
    data,
    onSelect,
    onAddSubItem,
    onValueChange,
    onInputDone,
    children,
  } = props;
  return (
    <BaseProduct
      isSubItem={isSubItem}
      data={data}
      selected={selected}
      disabled={disabled}
      onSelect={onSelect}
      onAddSubItem={onAddSubItem}
      onValueChange={onValueChange}
      onInputDone={onInputDone}
    >
      {children}
    </BaseProduct>
  );
};

Product.propTypes = {
  isSubItem: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.shape({
    item_no: PropTypes.any,
    invoice_id: PropTypes.any,
    name: PropTypes.any,
    part_number: PropTypes.any,
    description: PropTypes.any,
    harmonized_code: PropTypes.any,
    unit: PropTypes.any,
    quantity: PropTypes.any,
    cost: PropTypes.any,
    lastPrice: PropTypes.shape({
      last_price: PropTypes.any,
      last_cost: PropTypes.any,
      quote_id: PropTypes.string,
      invoice: PropTypes.string,
      last_date: PropTypes.any,
    })
  }).isRequired,
  onAddSubItem: PropTypes.func,
  onSelect: PropTypes.func,
  onValueChange: PropTypes.func,
  onInputDone: PropTypes.func,
  children: PropTypes.node,
};

Product.defaultProps = {
  isSubItem: false,
  selected: false,
  disabled: false
};

Product.Actions = BaseProduct.Actions;

export default observer(Product);

