
import './table.scss';

import React from 'react';
import PropTypes from 'prop-types';

import LoadingRow from './LoadingRow';

const Table = ({
                 className,
                 loadingRows,
                 loadingCells,
                 headerCells,
                 children,
                 emptyState,
                 errorState,
                 loading,
                 pagination
               }) => {
  if (children instanceof Error && !loading) {
    return <div className="table"><div className="table__content">{errorState}</div></div>;
  } else if ((children && children.length === 0) && !loading) {
    return <div className="table"><div className="table__content">{emptyState}</div></div>;;
  } else {
    return (
      <div className={`table ${loading ? 'table--loading' : ''}`}>
        <table className={`table__content table__table`}>
          {headerCells ? (
            <thead>
            <tr>{headerCells}</tr>
            </thead>
          ) : null}
          <tbody>
          {loading ? [...Array(loadingRows).keys()].map((_, index) => <LoadingRow loadingCells={loadingCells} key={index}/>) : children}
          </tbody>
        </table>

        {pagination ? (
          <div className="table__pagination">{pagination}</div>
        ) : null}
      </div>
    );
  }
};

Table.propTypes = {
  className: PropTypes.string,
  loadingRows: PropTypes.number,
  loadingCells: PropTypes.number,
  headerCells: PropTypes.node,
  pagination: PropTypes.node,
  children: PropTypes.node,
  emptyState: PropTypes.node,
  errorState: PropTypes.node,
  loading: PropTypes.bool
};

Table.defaultProps = {
  loadingRows: 10
};

export default Table;