
import './Options.scss';

import React from 'react';
import PropTypes from 'prop-types';

class Options extends React.Component {
  render() {
    return (
      <div className={`dropdown__options dropdown__options--${this.props.position}`}>
        {this.props.children}
      </div>
    );
  }
}

Options.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired
};

Options.defaultProps = {
  position: 'left'
};

export default Options;
