
import { observable, action, decorate, computed, createAtom } from 'mobx';

import api from '../api';

class UtilsStore {

  _products = [];
  _clients = [];
  _suppliers = [];

  productsLoaded = false;
  clientsLoaded = false;
  suppliersLoaded = false;

  get products() {
    if (!this.productsLoaded) {
      this.refreshProducts();
    }
    this.invalidateProducts();
    return this._products;
  }

  get clients() {
    if (!this.clientsLoaded) {
      this.refreshClients();
    }
    this.invalidateClients();
    return this._clients;
  }

  get suppliers() {
    if (!this.suppliersLoaded) {
      this.refreshSuppliers();
    }
    this.invalidateSuppliers();
    return this._suppliers;
  }

  invalidateProducts() {
    this.productsLoaded = false;
  }

  invalidateClients() {
    this.clientsLoaded = false;
  }

  invalidateSuppliers() {
    this.suppliersLoaded = false;
  }

  refreshProducts() {
    api.product.getProducts()
      .then(action(response => {
        this._products = response && response.data ? response.data : [];
        this.productsLoaded = true;
      }))
      .catch(err => console.error('Error getting products', err));
  }

  refreshClients() {
    api.client.getClients()
      .then(action(response => {
        this._clients = response.data;
        this.clientsLoaded = true;
      }))
      .catch(err => console.error('Error getting clients', err));
  }

  refreshSuppliers() {
    api.supplier.getSuppliers()
      .then(action(response => {
        this._suppliers = response.data;
        this.suppliersLoaded = true;
      }))
      .catch(err => console.error('Error getting suppliers', err));
  }
}

decorate(
  UtilsStore,
  {
    _products: observable,
    _clients: observable,
    _suppliers: observable,
    products: computed,
    clients: computed,
    suppliers: computed,
    invalidateProducts: action,
    invalidateClients: action,
    invalidateSuppliers: action,
    refreshProducts: action,
    refreshClients: action,
    refreshSuppliers: action
  }
);

export default new UtilsStore();
