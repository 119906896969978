
import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../containers/Card';
import TextInput from '../TextInput';
import { validateInputNotEmpty } from '../../utils';

class NewContact extends React.Component {
  state = {
    name: null,
    position: null,
    errors: {
      name: null,
      position: null
    }
  };

  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
  }

  inputChange(value, name, error) {
    this.setState({[name]: value, errors: {...this.state.errors, [name]: value}});
  }

  render() {
    return (
      <Card>
        <div className="row row--align-top">
          <div className="col page__input">
            <TextInput label="Nombre"
                       name="name"
                       value={this.state.name}
                       onChange={this.inputChange}
                       validateFn={validateInputNotEmpty}/>
          </div>
          <div className="col page__input">
            <TextInput label="Posición"
                       name="position"
                       value={this.state.position}
                       onChange={this.inputChange}
                       validateFn={validateInputNotEmpty}/>
          </div>
          <div className="col-12">
            <div className="page__submit">
              <button className="btn btn--secondary btn--small"
                      onClick={() => this.props.add({name: this.state.name, position: this.state.position})}>Agregar</button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

NewContact.propTypes = {
  add: PropTypes.func.isRequired
};

export default NewContact;