
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

class LoadingLabel extends React.Component {
  render() {
    const { children, loading, error, success } = this.props;
    let labelState = '';
    let content = children;

    if (loading) {
      labelState = '';
      content = children;
    } else if (error) {
      labelState = 'loading-label__label--error';
      content = error;
    } else if (success) {
      labelState = 'loading-label__label--success';
      content = success;
    }

    return <div className="loading-label">
      {loading ? <div className="loading-label__loader"><Loader color="black"/></div> : null}
      <div className={`loading-label__label ${labelState}`}>{content}</div>
    </div>;
  }
}

LoadingLabel.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.any,
  success: PropTypes.any
};

LoadingLabel.defaultProps = {
  loading: true,
  error: null,
  success: null
};

export default LoadingLabel;