
import React from 'react';
import PropTypes from 'prop-types';

import Card from '../containers/Card';
import {validateInputNotEmpty} from '../utils';
import SelectInput from './SelectInput/input';
import SelectOption from './SelectInput/option';
import TextInput from './TextInput';

class NewPhone extends React.Component {
  state = {
    type: 'mobile',
    phone: null,
    errors: {
      type: null,
      phone: null
    }
  };

  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
  }

  inputChange(value, name, error) {
    this.setState({[name]: value, errors: {...this.state.errors, [name]: value}});
  }

  render() {
    return (
      <Card>
        <div className="row row--align-top">
          <div className="col-6 page__input">
            <SelectInput label="Tipo"
                         name="type"
                         value={this.state.type}
                         onChange={this.inputChange}>
              <SelectOption value="mobile">Móvil</SelectOption>
              <SelectOption value="phone">Teléfono</SelectOption>
              <SelectOption value="fax">Fax</SelectOption>
            </SelectInput>
          </div>
          <div className="col-6 page__input">
            <TextInput label="Número de teléfono"
                       name="phone"
                       value={this.state.phone}
                       error={this.state.errors.phone}
                       onChange={this.inputChange}
                       validateFn={validateInputNotEmpty}/>
          </div>
          <div className="col-12">
            <div className="page__submit">
              <button className="btn btn--secondary btn--small"
                      onClick={() => this.props.add({phone: this.state.phone, type: this.state.type})}>Agregar</button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

NewPhone.propTypes = {
  add: PropTypes.func.isRequired
};

export default NewPhone;