
import './Container.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Toggle from "./Toggle";
import Options from './Options';

class Container extends React.Component {
  state = {
    showOptions: false
  };
  containerRef = null;

  constructor(props) {
    super(props);

    this.setContainerRef = this.setContainerRef.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside, false);
  }

  setContainerRef(node) {
    this.containerRef = node;
  }

  onClickOutside(event) {
    if (this.containerRef && !this.containerRef.contains(event.target)) {
      this.setState({ showOptions: false });
    }
  }

  toggleOptions() {
    this.setState({
      showOptions: !(this.state.showOptions)
    });
  }

  render() {
    const { icon, title, size, color, outline, disabled, children, showCaret, inline, position } = this.props;
    const options = React.Children.toArray(children).map(
      option => React.cloneElement(option, {
        onClick: () => {
          if (option && option.props && option.props.onClick) {
            option.props.onClick();
          }
          this.toggleOptions();
        }
      })
    );

    const getClassNames = () => {
      let classNames = `dropdown dropdown--${color} dropdown--${size}`;
      if (inline)
        classNames += ' dropdown--inline';
      return classNames;
    };

    return (
      <div className={getClassNames()}
           ref={this.setContainerRef}>
        <Toggle icon={icon}
                title={title}
                outline={outline}
                onToggle={() => !disabled && this.toggleOptions()}
                active={this.state.showOptions}
                showCaret={showCaret}
                disabled={disabled}
                position={position}/>

        {this.state.showOptions ? (
          <Options position={position}>
            {options}
          </Options>
        ) : null}
      </div>
    );
  }
}

Container.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'mid', 'nav']).isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'gray', 'success', 'transparent']).isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  outline: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  showCaret: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

Container.defaultProps = {
  icon: null,
  title: null,
  size: 'mid',
  color: 'primary',
  position: 'left',
  disabled: false,
  showCaret: true,
  inline: false,
  outline: false,
};

export default Container;
