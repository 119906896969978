
import { ReactComponent as AddIcon } from '../../assets/images/add.svg';

import React from 'react';
import PropTypes from 'prop-types';

const Subtitle = ({title, showOnNew, onNew}) => (
  <div className="page__subtitle">
    <h2>{title}</h2>
    {showOnNew ? (
      <button onClick={onNew}><AddIcon/></button>
    ) : null}
  </div>
);

Subtitle.propTypes = {
  title: PropTypes.string.isRequired,
  showOnNew: PropTypes.bool,
  onNew: PropTypes.func
};

Subtitle.defaultProps = {
  title: null,
  showOnNew: false,
  onNew: null
};

export default Subtitle;
