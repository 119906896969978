
import './index.scss';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';

import React from 'react';
import PropTypes from 'prop-types';

const Step = ({number, label, state}) => (
  <div className={`step step--${state}`}>
    <div className="step__number">{state === 'completed' ? <CheckIcon/> : number}</div>
    <div className="step__label">{label}</div>
  </div>
);

Step.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.oneOf(['incomplete', 'active', 'completed']).isRequired
};

Step.defaultProps = {
  state: 'incomplete'
};

export default Step;