
import './index.scss';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';

import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import FileSaver from 'file-saver';

import api from '../../api';
import {
  PageHeader,
  SelectInput,
  SelectOption,
  TextInput,
  LabeledInformation,
  Dropdown,
  ProductsListOld,
  LoadingLabel,
  Checkbox,
  protect,
  EmptyNotification, LoadingButton
} from '../../components';
import { Card } from '../../containers';
import parseDateReadable from '../../utils/parseDateReadable';
import settings from '../../settings';
import { authStore, OnlineProductsStore, utilsStore } from '../../stores';

class Invoice extends React.Component {
  MODIFY_NOTIFICATION_DURATION = 3000;

  state = {
    invoice: null,
    products: [],
    invoiceModified: false,
    loadingDownload: false,
    loadingInvoice: false,
    loadInvoiceError: false,
    modifyingField: false,
    errorModifyingField: false,
    successModifyingField: false
  };
  source = api.source.createSource();
  modifyNotificationTimeout;
  productsStore = new OnlineProductsStore();

  constructor(props) {
    super(props);

    this.loadInvoice = this.loadInvoice.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onInputDone = this.onInputDone.bind(this);
    this.togglePaid = this.togglePaid.bind(this);
    this.onDownloadExcel = this.onDownloadExcel.bind(this);

    this.getExtras = this.getExtras.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.getDiscounted = this.getDiscounted.bind(this);
  }

  componentDidMount() {
    this.loadInvoice();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.modifyNotificationTimeout);
  }

  loadInvoice() {
    const id = this.props.match.params.id;
    this.setState({loadingInvoice: true});
    api.invoice.getInvoice(id, this.source)
      .then(response => {
        const invoice = response.data;
        const quoteId = response.data.quote_id;
        const productsTotal = response.data.sub_total;
        const products = response.data.products;

        this.productsStore.configure(quoteId, productsTotal, products);
        this.setState({loadingInvoice: false, invoice, loadInvoiceError: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.log(err);
          this.setState({loadingInvoice: false, loadInvoiceError: err});
        }
      });
  }

  onValueChanged(value, name, error) {
    this.setState({
      invoice: {
        ...this.state.invoice,
        [name]: value
      },
      invoiceModified: true
    });
  }

  onInputDone(fieldName, fieldValue, modified) {
    if (modified) {
      // Set loading
      this.setState({
        modifyingField: fieldName,
        errorModifyingField: false,
        successModifyingField: false
      });
      clearTimeout(this.modifyNotificationTimeout);

      // Update invoice
      api.invoice.updateInvoice(
        this.props.match.params.id,
        {[fieldName]: fieldValue}
      )
        .then(() => {
          if (this.state.modifyingField === fieldName) {
            this.setState({
              modifyingField: false,
              errorModifyingField: false,
              successModifyingField: 'Guardado'
            });
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({successModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            console.error('Cannot update quote');
            this.setState({
              modifyingField: false,
              errorModifyingField: err,
              successModifyingField: false
            });
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({errorModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        });
    }
  }

  togglePaid(state) {
    api.invoice.updateInvoice(
      this.state.invoice.id,
      {paid_date: state},
      this.source
    )
      .then(response => {
        if (response.data) {
          this.setState({
            invoice: {...this.state.invoice, paid_date: state}
          });
        }
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error updating invoice', err);
        }
      });
  }

  onDownloadExcel() {
    const id = this.props.match.params.id;

    this.setState({loadingDownload: true});
    api.invoice.downloadExcel(id, this.source)
      .then(response => {
        const fileName = `Factura ${id}.xlsx`;
        this.setState({loadingDownload: false});
        FileSaver.saveAs(response.data, fileName);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingDownload: false});
        }
      });
  }

  getExtras() {
    if (this.state.invoiceModified || this.productsStore.productsModified) {
      return (Number(this.state.invoice.packing) || 0) +
        (Number(this.state.invoice.freight) || 0) +
        (Number(this.state.invoice.insurance) || 0) +
        (Number(this.state.invoice.incoterm_price) || 0);
    } else {
      return this.state.invoice && this.state.invoice.total_extras;
    }
  }

  getDiscounted() {
    if (this.state.invoiceModified || this.productsStore.productsModified) {
      return (this.productsStore.total + this.getExtras()) * (Number(this.state.invoice.discount) || 0) / 100;
    } else {
      return this.state.invoice && this.state.invoice.discounted;
    }
  }

  getTotal() {
    if (this.state.invoiceModified || this.productsStore.productsModified) {
      return this.productsStore.total + this.getExtras() - this.getDiscounted();
    } else {
      return this.state.invoice && this.state.invoice.total;
    }
  }

  render() {
    if (this.state.loadInvoiceError === 'not_found') {
      return <Redirect to="/invoices/not-found"/>
    }
    const invoiceIsPaid = !!(this.state.invoice && this.state.invoice.paid_date);
    return (
      <div className="invoice page">
        <Helmet>
          <title>{this.props.match.params.id} | Metallica Caribbean</title>
          <meta name="description" content={`Vista a detalle de la factura ${this.props.match.params.id}`} />
          <meta name="keyboards" content="factura,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          {this.state.loadInvoiceError ? (
            <div className="col-12">
              <div className="card">
                <EmptyNotification icon={DisconnectIcon}
                                   title={this.state.loadInvoiceError}
                                   details="Ha ocurrido un error obteniendo la factura."
                                   action="Regresar"
                                   onAction={() => this.props.history.goBack()}/>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="col-12">
                <div className="page__heading">
                  <PageHeader.Container navigation={[
                    {title: 'Inicio', link: '/'},
                    {title: 'Facturas', link: '/invoices'},
                    {title: this.props.match.params.id, link: `/invoices/${this.props.match.params.id}`}
                  ]}
                                        loading={this.state.loadingInvoice}
                                        title={this.props.match.params.id}
                                        onBack={() => this.setState({redirectBack: true})}>

                    <PageHeader.Types>
                      <Dropdown.Container title={invoiceIsPaid ? 'Pagada' : 'No pagada'}
                                          color="gray"
                                          size="small"
                                          outline={true}
                                          disabled={!authStore.permissions.invoices.set_paid}>
                        <Dropdown.Button onClick={() => this.togglePaid(true)}>Pagada</Dropdown.Button>
                        <Dropdown.Button onClick={() => this.togglePaid(false)}>No pagada</Dropdown.Button>
                      </Dropdown.Container>
                    </PageHeader.Types>

                    <PageHeader.Actions>

                      {this.state.modifyingField || this.state.errorModifyingField || this.state.successModifyingField ? (
                        <PageHeader.Action>
                          <LoadingLabel loading={!!(this.state.modifyingField)}
                                        error={this.state.errorModifyingField}
                                        success={this.state.successModifyingField}>Guardando cambios</LoadingLabel>
                        </PageHeader.Action>
                      ) : null}

                      <PageHeader.Action>
                        <LoadingButton loadingColor="black"
                                       loaderSize="small"
                                       className="btn--gray btn--icon btn--outline"
                                       loading={this.state.loadingDownload}
                                       onClick={this.onDownloadExcel}><DownloadIcon className="btn__icon"/></LoadingButton>
                      </PageHeader.Action>

                      <PageHeader.Action>
                        {this.state.invoice && this.state.invoice.shipments && this.state.invoice.shipments.length > 0 ? (
                          <button className="btn btn--success"
                                  onClick={() => this.props.history.push(`/shipments/${this.state.invoice.shipments[0]}`)}>{this.state.invoice.shipments[0]}</button>
                        ) : (
                          <button className="btn btn--primary"
                                  onClick={() => this.props.history.push(`/invoices/${this.props.match.params.id}/shipments/new`)}>Crear packing</button>
                        )}
                      </PageHeader.Action>

                    </PageHeader.Actions>
                  </PageHeader.Container>
                </div>
              </div>

              <div className="col-8">
                <div className="row">
                  <div className="col-12">
                    <Card loading={this.state.loadingInvoice}>
                      <Card.Title>Información general</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-6 form__input">
                            <LabeledInformation label="Cliente"
                                                value={this.state.invoice && this.state.invoice.client}
                                                link={`/admin/clients/${this.state.invoice && this.state.invoice.client_id}`}
                                                loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <LabeledInformation label="Fecha de modificación"
                                                value={parseDateReadable(this.state.invoice && this.state.invoice.date)}
                                                loading={this.state.loadingInvoice}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.state.loadingInvoice}>
                      <Card.Title>Referencia</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-4 form__input">
                            <TextInput label="Número Fiscal"
                                       type="text"
                                       name="fiscal_number"
                                       value={this.state.invoice && this.state.invoice.fiscal_number}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-4 form__input">
                            <TextInput label="Número de contrato"
                                       type="text"
                                       name="contract_number"
                                       value={this.state.invoice && this.state.invoice.contract_number}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-4 form__input">
                            <TextInput label="Fecha de contrato"
                                       type="date"
                                       name="contract_date"
                                       value={this.state.invoice && this.state.invoice.contract_date}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label="Número BL"
                                       type="text"
                                       name="BL_number"
                                       value={this.state.invoice && this.state.invoice.BL_number}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label="Número AWB"
                                       type="text"
                                       name="AWB_number"
                                       value={this.state.invoice && this.state.invoice.AWB_number}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.state.loadingInvoice}>
                      <Card.Title>Mercancia</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-12 form__input">
                            <TextInput label="Descripción de mercancia"
                                       type="multiline"
                                       name="description"
                                       value={this.state.invoice && this.state.invoice.description}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <SelectInput label="País de origen"
                                         name="boarding_place"
                                         value={this.state.invoice && this.state.invoice.boarding_place}
                                         onChange={this.onValueChanged}
                                         onDone={this.onInputDone}
                                         disabled={!authStore.permissions.invoices.edit}
                                         information={true}
                                         loading={this.state.loadingInvoice}>
                              <SelectOption value="HAMBURGO, ALEMANIA">HAMBURGO, ALEMANIA</SelectOption>
                              <SelectOption value="VALENCIA, ESPAÑA">VALENCIA, ESPAÑA</SelectOption>
                              <SelectOption value="COLÓN, PANAMÁ">COLÓN, PANAMÁ</SelectOption>
                              <SelectOption value="TOCUMEN, PANAMÁ">TOCUMEN, PANAMÁ</SelectOption>
                              <SelectOption type="text">OTRO</SelectOption>
                            </SelectInput>
                          </div>
                          <div className="col-3 form__input">
                            <Checkbox label="Mercancia sin valor comercial"
                                      name="is_commercial"
                                      value={this.state.invoice && this.state.invoice.is_commercial}
                                      onChange={(value, name) => {
                                        this.onValueChanged(value, name);
                                        this.onInputDone(name, value, true);
                                      }}
                                      disabled={!authStore.permissions.invoices.edit}/>
                          </div>
                          <div className="col-3 form__input">
                            <Checkbox label="Mercancia en reposición"
                                      name="replacement"
                                      value={this.state.invoice && this.state.invoice.replacement}
                                      onChange={(value, name) => {
                                        this.onValueChanged(value, name);
                                        this.onInputDone(name, value, true);
                                      }}
                                      disabled={!authStore.permissions.invoices.edit}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.state.loadingInvoice}>
                      <Card.Title>Pago</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-5 form__input">
                            <SelectInput label="Condición"
                                         name="incoterm"
                                         value={this.state.invoice && this.state.invoice.incoterm}
                                         onChange={this.onValueChanged}
                                         onDone={this.onInputDone}
                                         information={true}
                                         disabled={!authStore.permissions.invoices.edit}
                                         loading={this.state.loadingInvoice}>
                              {settings.conditions.map(condition => <SelectOption key={condition} value={condition}>{condition}</SelectOption>)}
                            </SelectInput>
                          </div>
                          <div className="col-7 form__input">
                            <TextInput label="Fecha de pago estimada"
                                       type="date"
                                       name="due_date"
                                       value={this.state.invoice && this.state.invoice.due_date}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-12 form__input">
                            <SelectInput label="Forma de pago"
                                         name="payment_method"
                                         value={this.state.invoice && this.state.invoice.payment_method}
                                         onChange={this.onValueChanged}
                                         onDone={this.onInputDone}
                                         disabled={!authStore.permissions.invoices.edit}
                                         information={true}
                                         loading={this.state.loadingInvoice}>
                              {settings.PAYMENT_METHODS.map((method, index) => <SelectOption value={method} key={index}>{method}</SelectOption>)}
                              <SelectOption type="text">OTRO</SelectOption>
                            </SelectInput>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <Card loading={this.state.loadingInvoice}>
                      <Card.Title>Costos</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-6 form__input">
                            <TextInput label="Embalaje"
                                       type="text"
                                       name="packing"
                                       value={this.state.invoice && this.state.invoice.packing}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label="Flete"
                                       type="text"
                                       name="freight"
                                       value={this.state.invoice && this.state.invoice.freight}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label="Seguro"
                                       type="text"
                                       name="insurance"
                                       value={this.state.invoice && this.state.invoice.insurance}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label={`Costo ${this.state.invoice && this.state.invoice.incoterm}`}
                                       type="text"
                                       name="incoterm_price"
                                       value={this.state.invoice && this.state.invoice.incoterm_price}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label="Descuento (%)"
                                       type="text"
                                       name="discount"
                                       value={this.state.invoice && this.state.invoice.discount}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.state.loadingInvoice}>
                      <Card.Title>Financiamiento</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-6 form__input">
                            <TextInput label="Periodo"
                                       type="text"
                                       name="financing_period"
                                       value={this.state.invoice && this.state.invoice.financing_period}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                          <div className="col-6 form__input">
                            <TextInput label="Porcentaje (%)"
                                       type="text"
                                       name="financing_percentage"
                                       value={this.state.invoice && this.state.invoice.financing_percentage}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       disabled={!authStore.permissions.invoices.edit}
                                       information={true}
                                       loading={this.state.loadingInvoice}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <Card loading={this.state.loadingInvoice}>
                  <Card.Title>Productos</Card.Title>
                  <Card.Body>
                    <ProductsListOld itemNumberVisible={true}
                                     transferVisible={true}
                                     disabled={!authStore.permissions.invoices.edit}
                                     loading={this.state.loadingInvoice}
                                     eligibleProducts={utilsStore.products}
                                     products={this.productsStore.products || []}
                                     margin={'0.00'}
                                     totalProducts={this.productsStore.total}
                                     totalExtras={this.getExtras()}
                                     discounted={this.getDiscounted()}
                                     total={this.getTotal()}
                                     productChanged={this.productsStore.setProductValue}
                                     productChangeDone={this.productsStore.productChanged}
                                     addProduct={this.productsStore.addProduct}
                                     deleteProduct={this.productsStore.deleteProduct}
                                     retry={this.productsStore.retryOperation}
                                     transferProduct={(...args) => this.productsStore.transferProduct(...args, true)}
                                     clientId={''}/>
                  </Card.Body>
                </Card>
              </div>

            </Fragment>
          )}
        </div>

      </div>
    );
  }
}

export default protect(observer(Invoice), settings.permissions.invoices.read);
