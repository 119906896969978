
import parseToDecimal from './parseToDecimal';

/**
 * Parse numeric values for input
 * @param value
 * @returns {string}
 */
const fn = value => (value || value === 0) ? parseToDecimal(value).toString() : '0.00';
export default fn;
