
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import './index.scss';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import api from '../../api';
import settings from '../../settings';
import { TextInput, ListPagination, PageHeader, protect, EmptyNotification } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell } from '../../components/Table';
import authStore from '../../stores/auth';

class SuppliersList extends React.Component {
  state = {
    name: null,
    email: null,
    suppliers: [],
    page: 1,
    pages: 1,
    perPage: 10,
    loading: false,
    error: false
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Proveedores', link: '/admin/suppliers'}
  ];

  constructor(props) {
    super(props);

    this.getSuppliers = this.getSuppliers.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
  }

  componentDidMount() {
    this.getSuppliers();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  handleFiltersChange(value, name) {
    this.setState({[name]: value});
  }

  pageChanged(page) {
    this.setState({page});
    this.getSuppliers(page);
  }

  getFilters(selectedPage) {
    const { perPage, email, name } = this.state;
    return {
      per_page: perPage,
      page: selectedPage || 1,
      name,
      email
    };
  }

  getSuppliers(selectedPage = null) {
    this.setState({loading: true});

    api.supplier.getSuppliers(this.source, this.getFilters(selectedPage))
      .then(response => {
        const suppliers = response.data.data;
        const pages = response.data.pages;

        this.setState({suppliers, pages, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loading: false, error: err});
        }
      });
  }

  render() {
    return (
      <div className="suppliers page page--list">
        <Helmet>
          <title>Proveedores | Metallica Caribbean</title>
          <meta name="description" content="Listado de proveedores" />
          <meta name="keyboards" content="proveedores,metallica,listado" />
        </Helmet>

        <div className="page__heading">
          <PageHeader.Container navigation={this.NAVIGATION}
                                title="Proveedores">
            <PageHeader.Filters>
              <PageHeader.Filter>
                <TextInput name="name"
                           onChange={this.handleFiltersChange}
                           onDone={(fieldName, value, modified) => modified && this.getSuppliers()}
                           placeholder="Nombre"
                           value={this.state.name}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput name="email"
                           onChange={this.handleFiltersChange}
                           onDone={(fieldName, value, modified) => modified && this.getSuppliers()}
                           placeholder="Correo electrónico"
                           value={this.state.email}/>
              </PageHeader.Filter>
            </PageHeader.Filters>
            <PageHeader.Actions>
              {authStore.permissions.admin ? (
                <PageHeader.Action>
                  <button className="btn btn--primary"
                          onClick={() => this.props.history.push(`/admin/suppliers/new`)}>Nuevo proveedor</button>
                </PageHeader.Action>
              ) : null}
            </PageHeader.Actions>
          </PageHeader.Container>
        </div>

        <div className="card">
          <Table loadingRows={10}
                 loadingCells={5}
                 loading={this.state.loading}
                 emptyState={<EmptyNotification icon={NotFoundIcon}
                                                title="No se encontrarón proveedores"
                                                details="Puedes cambiar los filtros de búsqueda en la parte superior o crear un proveedor."
                                                action={authStore.permissions.admin ? 'Nuevo proveedor' : null}
                                                onAction={() => authStore.permissions.admin && this.props.history.push(`/admin/suppliers/new`)}/>}
                 errorState={<EmptyNotification icon={DisconnectIcon}
                                                title={this.state.error}
                                                details="Ha ocurrido un error obteniendo los proveedores."/>}
                 headerCells={
                   <Fragment>
                     <TableHeaderCell>Nombre</TableHeaderCell>
                     <TableHeaderCell>Correo electrónico</TableHeaderCell>
                     <TableHeaderCell className="suppliers__cell--address">Dirección</TableHeaderCell>
                     <TableHeaderCell className="suppliers__cell--phones">Números telefónicos</TableHeaderCell>
                     <TableHeaderCell className="suppliers__cell--contacts">Contactos</TableHeaderCell>
                 </Fragment>}
                 pagination={<ListPagination margin="page"
                                             currentPage={this.state.page}
                                             totalPages={this.state.pages}
                                             onChange={this.pageChanged}/>}>
            {this.state.error || this.state.suppliers.map((supplier, index) => (
              <TableBodyRow key={supplier.id}
                            onSelect={() => this.props.history.push(`/admin/suppliers/${supplier.id}`)}>
                <TableBodyCell>{supplier.name}</TableBodyCell>
                <TableBodyCell>{supplier.email}</TableBodyCell>
                <TableBodyCell>{supplier.address}</TableBodyCell>
                <TableBodyCell>
                  {supplier.phones.map(phone => <div key={phone.id}><a href={`tel:${phone.phone}`}>{phone.phone}</a></div>)}
                </TableBodyCell>
                <TableBodyCell>
                  {supplier.contacts.map(contact => <div key={contact.id}>{contact.name} / {contact.position}</div>)}
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

export default protect(SuppliersList, settings.permissions.read_admin);
