
import axios from 'axios';

import mappers from './mappers';

const URL_PREFIX = 'quote';

/**
 * Get all quotes
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getQuotes = (source = null, filters = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token,
    params: filters
  }
);

/**
 * Get quote by its new id
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getQuote = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Create quote
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createQuote = (data, source = null) => axios.post(
  URL_PREFIX,
  mappers.mapOfferToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Update quote with new data
 * @param id
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateQuote = (id, data, source = null) => axios.patch(
  `${URL_PREFIX}/${id}`,
  mappers.mapOfferToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Re-index quote products
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const reindexQuoteProducts = (id, source = null) => axios.patch(
  `${URL_PREFIX}/${id}/reindex`,
  {
    cancelToken: source && source.token
  }
);

// TODO: Improve
/**
 * Get quote products
 * @param quoteId
 * @param isOptional
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getProducts = (quoteId, isOptional = false, source = null) => axios.get(
  isOptional ? `${URL_PREFIX}/${quoteId}/optional-product` : `${URL_PREFIX}/${quoteId}/product`,
  {
    cancelToken: source && source.token
  }
);

// TODO: Improve
/**
 * Add product to quote
 * @param quoteId
 * @param data
 * @param isOptional
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const addProduct = (quoteId, data, isOptional = false, source = null) => axios.post(
  isOptional ? `${URL_PREFIX}/${quoteId}/optional-product` : `${URL_PREFIX}/${quoteId}/product`,
  mappers.mapProductToAPI(data),
  {
    cancelToken: source && source.token
  }
);

/**
 * Add subproduct
 * @param quoteId
 * @param relationId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const addSubproduct = (quoteId, relationId, data, source = null) => axios.post(
  `${URL_PREFIX}/${quoteId}/product/${relationId}`,
  mappers.mapProductToAPI(data, false),
  {
    cancelToken: source && source.token
  }
);

const checkPrice = (clientId, productId, source) => axios.get(
  `${URL_PREFIX}/price_check`,
  {
    cancelToken: source && source.token,
    params: {
      client_id: clientId,
      product_id: productId
    }
  }
);

// TODO: Improve
/**
 * Update product by its id
 * @param quoteId
 * @param productId
 * @param data
 * @param isOptional
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateProduct = (quoteId, productId, data, isOptional = false, source = null) => {
  return axios.patch(
    isOptional ? `${URL_PREFIX}/${quoteId}/optional-product/${productId}` : `${URL_PREFIX}/${quoteId}/product/${productId}`,
    mappers.mapProductToAPI(data, false),
    {
      cancelToken: source && source.token
    }
  )
};

/**
 * Update sub-product by its id
 * @param quoteId
 * @param productId
 * @param subProductId
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const updateSubProduct = (quoteId, productId, subProductId, data, source = null) => axios.patch(
  `${URL_PREFIX}/${quoteId}/product/${productId}/sub_product/${subProductId}`,
  mappers.mapProductToAPI(data, false),
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete product from quote
 * @param quoteId
 * @param id
 * @param isOptional
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deleteProduct = (quoteId, id, isOptional= false, source = null) => axios.delete(
  isOptional ? `${URL_PREFIX}/${quoteId}/optional-product/${id}` : `${URL_PREFIX}/${quoteId}/product/${id}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Transfer optional product
 * @param quoteId
 * @param id
 * @param source
 * @return {Promise<AxiosResponse<any>>}
 */
const transferOptionalProduct = (quoteId, id, source = null) => axios.patch(
  `${URL_PREFIX}/${quoteId}/optional-product/${id}`,
  {
    pass_to_quote: true,
  },
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete products from quote
 * @param quoteId
 * @param productsIds
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deleteProducts = (quoteId, productsIds, source = null) => axios.post(
  `${URL_PREFIX}/${quoteId}/product/_delete`,
  productsIds,
  {
    cancelToken: source && source.token
  }
);

/**
 * Delete product from quote
 * @param quoteId
 * @param productId
 * @param subProductId
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const deleteSubProduct = (quoteId, productId, subProductId, source = null) => axios.delete(
  `${URL_PREFIX}/${quoteId}/product/${productId}/sub_product/${subProductId}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Download excel
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const downloadExcel = (id, source = null) => axios.get(
  `report/quote/${id}`,
  {
    cancelToken: source && source.token,
    responseType: 'blob'
  }
);

/**
 * Change products index
 * @param quoteId
 * @param data
 * @param isOptional
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
 const moveProducts = (quoteId, data, isOptional = false, source = null) => axios.post(
  isOptional ? `${URL_PREFIX}/${quoteId}/optional-product-index` : `${URL_PREFIX}/${quoteId}/product-index`,
  data,
  {
    cancelToken: source && source.token
  }
);

const apiFunctions = {
  createQuote,
  getQuotes,
  getQuote,
  addProduct,
  addSubproduct,
  getProducts,
  updateQuote,
  checkPrice,
  updateProduct,
  updateSubProduct,
  deleteProduct,
  deleteProducts,
  deleteSubProduct,
  transferOptionalProduct,
  downloadExcel,
  reindexQuoteProducts,
  moveProducts
};

export default apiFunctions;
export {
  apiFunctions,
  mappers
};
