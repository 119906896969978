
import { ReactComponent as DisconnectIcon } from '../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import api from '../api';
import settings from '../settings';
import authStore from '../stores/auth';
import { LoadingLabel, PageHeader, TextInput, Subtitle, protect, EmptyNotification, Badge } from '../components';
import { Contact, NewContact } from '../components/clients';
import { Table, TableBodyCell, TableBodyRow, TableHeaderCell } from '../components/Table';
import { Card } from '../containers';

class Client extends React.Component {
  state = {
    client: null,
    quotes: [],
    invoices: [],
    loading: false,
    loadingQuotes: false,
    loadingInvoices: false,
    error: false,
    errorQuotes: null,
    errorInvoices: null,
    modifyingField: false,
    errorModifyingField: false,
    successModifyingField: false
  };
  source = api.source.createSource();
  MODIFY_NOTIFICATION_DURATION = 3000;
  MODIFY_SUCCESS_MESSAGE = 'Guardado';
  PREVIEW_QUOTES_AMOUNT = 5;
  PREVIEW_INVOICES_AMOUNT = 5;

  constructor(props) {
    super(props);

    this.getDisplayName = this.getDisplayName.bind(this);
    this.getClient = this.getClient.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onInputDone = this.onInputDone.bind(this);

    this.getClientQuotes = this.getClientQuotes.bind(this);
    this.getClientInvoices = this.getClientInvoices.bind(this);

    this.setContactLoading = this.setContactLoading.bind(this);
  }

  componentDidMount() {
    this.getClient();
    this.getClientQuotes();
    this.getClientInvoices();
  }

  getClient() {
    this.setState({loading: true});
    api.client.getClient(this.props.match.params.id, this.source)
      .then(response => {
        const client = response.data;
        this.setState({client, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loading: false, error: err});
        }
      });
  }

  getClientQuotes() {
    this.setState({loadingQuotes: true});
    api.quote.getQuotes(this.source, {client: this.props.match.params.id, per_page: this.PREVIEW_QUOTES_AMOUNT})
      .then(response => {
        const quotes = response.data.data;
        this.setState({quotes, loadingQuotes: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingQuotes: false, errorQuotes: err});
        }
      });
  }

  getClientInvoices() {
    this.setState({loadingInvoices: true});
    api.invoice.getInvoices(this.source, {client: this.props.match.params.id, per_page: 10})
      .then(response => {
        const invoices = response.data.data;
        this.setState({invoices, loadingInvoices: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingInvoices: false, errorInvoices: err});
        }
      });
  }

  onValueChange(value, name, error) {
    this.setState({
      client: {...this.state.client, [name]: value}
    });
  }

  onInputDone(name, value, modified) {
    if (modified) {
      this.setState({
        modifyingField: name,
        errorModifyingField: false,
        successModifyingField: false
      });
      clearTimeout(this.modifyNotificationTimeout);

      api.client.updateClient(
        this.props.match.params.id,
        {[name]: value},
        this.source
      )
        .then(() => {
          if (this.state.modifyingField === name) {
            this.setState({modifyingField: false, errorModifyingField: false, successModifyingField: this.MODIFY_SUCCESS_MESSAGE});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({successModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            this.setState({modifyingField: false, errorModifyingField: err, successModifyingField: false});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({errorModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        });
    }
  }

  setContactLoading(loading, index) {
    const contacts = [...this.state.client.contacts];
    contacts[index].loading = loading;
    this.setState({client: {...this.state.client, contacts}});
  }

  getDisplayName() {
    return this.state.client ? this.state.client.name : this.props.match.params.id;
  }

  render() {
    if (this.state.error === 'not-found') {
      return <Redirect to="/admin/clients/not-found"/>;
    }
    return (
      <div className="client page">
        <Helmet>
          <title>{this.getDisplayName()} | Metallica Caribbean</title>
          <meta name="description" content={`Vista a detalle del cliente ${this.getDisplayName()}`} />
          <meta name="keyboards" content="cliente,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          {this.state.error ? (
            <div className="col-12">
              <div className="card">
                <EmptyNotification icon={DisconnectIcon}
                                   title={this.state.error}
                                   details="Ha ocurrido un error obteniendo el cliente."
                                   action="Regresar"
                                   onAction={() => this.props.history.goBack()}/>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="col-12">
                <div className="page__heading">
                  <PageHeader.Container navigation={[
                    {title: 'Inicio', link: '/'},
                    {title: 'Clientes', link: '/admin/clients'},
                    {title: this.state.client && this.state.client.name, link: `/admin/clients/${this.state.client && this.state.client.id}`}
                  ]}
                                        loading={this.state.loading}
                                        title={this.state.client && this.state.client.name}
                                        onBack={() => this.props.history.goBack()}>


                    <PageHeader.Actions>
                      {this.state.modifyingField || this.state.errorModifyingField || this.state.successModifyingField ? (
                        <PageHeader.Action>
                          <LoadingLabel loading={!!(this.state.modifyingField)}
                                        error={this.state.errorModifyingField}
                                        success={this.state.successModifyingField}>Guardando cambios</LoadingLabel>
                        </PageHeader.Action>
                      ) : null}
                    </PageHeader.Actions>
                  </PageHeader.Container>
                </div>
              </div>

              <div className="col-6">
                <div className="row row--align-top">
                  <div className="col-12 page__card">
                    <Card title="Información general"
                          loading={this.state.loading}>
                      <div className="row row--align-top">
                        <div className="col-12 page__input">
                          <TextInput label="Nombre"
                                     loading={this.state.loading}
                                     disabled={!authStore.permissions.admin}
                                     information={true}
                                     name="name"
                                     placeholder="Nombre de cliente"
                                     value={this.state.client && this.state.client.name}
                                     onChange={this.onValueChange}
                                     onDone={this.onInputDone}/>
                        </div>
                        <div className="col-12 page__input">
                          <TextInput type="multiline"
                                     label="Dirección"
                                     loading={this.state.loading}
                                     disabled={!authStore.permissions.admin}
                                     information={true}
                                     name="address"
                                     placeholder="Calle, Ciudad, Estado, País"
                                     value={this.state.client && this.state.client.address}
                                     onChange={this.onValueChange}
                                     onDone={this.onInputDone}/>
                        </div>
                      </div>
                    </Card>
                  </div>

                  {!this.state.loading ? <div className="col-12"><Subtitle title="Personas de contacto"/></div> : null}
                  {this.state.client && this.state.client.contacts.map((contact, index) => (
                    <div className="col-12 page__card" key={contact.id}>
                      <Contact name={contact.name}
                               position={contact.position}
                               phone={contact.phone}
                               email={contact.email}
                               loading={!!contact.loading}
                               disabled={!authStore.permissions.admin}
                               change={(value, name, error) => {
                                 const contacts = [...this.state.client.contacts];
                                 contacts[index][name] = value;
                                 this.setState({client: {...this.state.client, contacts}});
                               }}
                               changeDone={(name, value, error) => {
                                 this.setContactLoading(true, index);
                                 api.client.updateContact(
                                   this.props.match.params.id,
                                   this.state.client.contacts[index].id,
                                   {[name]: value},
                                   this.source)
                                   .then(response => this.setContactLoading(false, index))
                                   .catch(err => {
                                     if (!api.source.errorIsCancel(err)) {
                                       console.error(err);
                                       this.setContactLoading(false, index);
                                     }
                                   });
                               }}
                               deletePressed={() => {
                                 this.setContactLoading(true, index);
                                 api.client.deleteContact(this.props.match.params.id, this.state.client.contacts[index].id, this.source)
                                   .then(response => {
                                     const contacts = [...this.state.client.contacts];
                                     contacts.splice(index, 1);
                                     this.setState({client: {...this.state.client, contacts}});
                                   })
                                   .catch(err => {
                                     if (!api.source.errorIsCancel(err)) {
                                       console.error(err);
                                       this.setContactLoading(false, index);
                                     }
                                   });
                               }}/>
                    </div>
                  ))}
                  {authStore.permissions.admin ? (
                    <div className="col-12 page__card"><NewContact add={contact => {
                      const contacts = [...this.state.client.contacts];
                      const newContactIndex = contacts.length;
                      this.setState({client: {...this.state.client, contacts: [...contacts, {...contact, loading: true}]}});
                      api.client.createContact(this.props.match.params.id, contact, this.source)
                        .then(response => {
                          const contacts = [...this.state.client.contacts];
                          contacts[newContactIndex] = {...contacts[newContactIndex], id: response.data, loading: false};
                          this.setState({client: {...this.state.client, contacts}});
                        })
                        .catch(err => {
                          if (!api.source.errorIsCancel(err)) {
                            const contacts = [...this.state.client.contacts];
                            contacts.splice(newContactIndex, 1);
                            this.setState({client: {...this.state.client, contacts}});
                          }
                        });
                    }}/></div>
                  ) : null}

                </div>
              </div>

              <div className="col-6">
                <div className="row row--align-top">
                  <div className="col-12 page__card">
                    <Card title="Últimas ofertas"
                          loading={this.state.loadingQuotes}>
                      <Table loading={this.state.loadingQuotes}
                             loadingRows={this.PREVIEW_QUOTES_AMOUNT}
                             loadingCells={4}
                             emptyState={authStore.permissions.quotes.create ? (
                               <EmptyNotification icon={NotFoundIcon}
                                                  title="Aún no has creado ofertas para este proveedor"
                                                  details="Presiona el botón debajo para crear una oferta."
                                                  action="Nueva oferta"
                                                  onAction={() => this.props.history.push(`/offers/new`)}/>
                             ) : (
                               <EmptyNotification icon={NotFoundIcon}
                                                  title="No se han creado ofertas para este proveedor"
                                                  details="Aquí verás las ofertas que se creen para este proveedor."/>
                             )}
                             errorState={<EmptyNotification icon={DisconnectIcon}
                                                            title={this.state.errorProducts}
                                                            details="Ha ocurrido un error obteniendo las ofertas de este cliente."/>}
                             headerCells={
                               <Fragment>
                                 <TableHeaderCell>Oferta/Factura</TableHeaderCell>
                                 <TableHeaderCell>Pedido</TableHeaderCell>
                                 <TableHeaderCell>Monto</TableHeaderCell>
                               </Fragment>
                             }>
                        {this.state.errorQuotes || this.state.quotes.map(quote => (
                          <TableBodyRow key={quote.id}
                                        onSelect={() => {
                                          if (authStore.permissions.quotes.read) {
                                            this.props.history.push(`/offers/${quote.id}`);
                                          }
                                        }}>
                            <TableBodyCell nowrap={true}>
                              {quote.id}
                              {quote.invoice ? (
                                <Badge size="small"
                                       color="success"
                                       fill={true}
                                       onClick={() => {
                                         if (authStore.permissions.invoices.read) {
                                           this.props.history.push(`/invoices/${quote.invoice}`);
                                         }
                                       }}>{quote.invoice}</Badge>
                              ) : null}
                            </TableBodyCell>
                            <TableBodyCell>{quote.order}</TableBodyCell>
                            <TableBodyCell nowrap={true}>{quote.total} €</TableBodyCell>
                          </TableBodyRow>
                        ))}
                      </Table>
                    </Card>
                  </div>

                  <div className="col-12 page__card">
                    <Card title="Últimas facturas"
                          loading={this.state.loadingInvoices}>
                      <Table loading={this.state.loadingInvoices}
                             loadingRows={this.PREVIEW_INVOICES_AMOUNT}
                             loadingCells={4}
                             emptyState={authStore.permissions.invoices.create ? (
                               <EmptyNotification icon={NotFoundIcon}
                                                  title="Aún no has creado facturas para este proveedor"
                                                  details="Presiona el botón debajo para crear una factura."
                                                  action="Nueva factura"
                                                  onAction={() => this.props.history.push(`/invoices/new`)}/>
                             ) : (
                               <EmptyNotification icon={NotFoundIcon}
                                                  title="Aún no has creado ofertas para este proveedor"
                                                  details="Presiona el botón debajo para crear una oferta."
                                                  action="Nueva oferta"
                                                  onAction={() => this.props.history.push(`/products/new`)}/>
                             )}
                             errorState={<EmptyNotification icon={DisconnectIcon}
                                                            title={this.state.errorProducts}
                                                            details="Ha ocurrido un error obteniendo las facturas de este cliente."/>}
                             headerCells={
                               <Fragment>
                                 <TableHeaderCell>Código</TableHeaderCell>
                                 <TableHeaderCell>Descripción</TableHeaderCell>
                                 <TableHeaderCell>Monto</TableHeaderCell>
                               </Fragment>
                             }>
                        {this.state.errorInvoices || this.state.invoices.map(invoice => (
                          <TableBodyRow key={invoice.id}
                                        onSelect={() => {
                                          if (authStore.permissions.invoices.read) {
                                            this.props.history.push(`/invoices/${invoice.id}`);
                                          }
                                        }}>
                            <TableBodyCell nowrap={true}>
                              {invoice.id}
                              {invoice.paid_date ? (
                                <Badge size="small"
                                       color="success"
                                       fill={false}>Pagada</Badge>
                              ) : null}
                            </TableBodyCell>
                            <TableBodyCell>{invoice.description}</TableBodyCell>
                            <TableBodyCell nowrap={true}>{invoice.total} €</TableBodyCell>
                          </TableBodyRow>
                        ))}
                      </Table>
                    </Card>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

        </div>
      </div>
    );
  }
}

export default protect(observer(Client), settings.permissions.read_admin);