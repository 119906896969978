
import parseToDecimal from '../../utils/parseToDecimal';

export const mapShipmentToAPI = shipment => ({
  airline: shipment.airline,
  manifesto: shipment.manifesto,
  shipping_company: shipment.shipping_company,
  vessel: shipment.vessel,
  container: shipment.container,
  type: shipment.type ? Number(shipment.type) : undefined,
  ETS: shipment.ETS,
  ETA: shipment.ETA,
  packages: shipment.hasOwnProperty('packages') ? shipment.packages.map(mapPackageToAPI) : undefined
});

export const mapPackageToAPI = packageInfo => ({
  package_type: packageInfo.package_type,
  albaran_number: packageInfo.albaran_number,
  package: packageInfo.package,
  dimension: packageInfo.dimension,
  gross_weight: packageInfo.gross_weight,
  net_weight: packageInfo.net_weight,
  invoiced_products: packageInfo.hasOwnProperty('invoiced_products') ? packageInfo.invoiced_products.map(product => mapProductToAPI(product, true)) : undefined,
  non_invoiced_products: packageInfo.hasOwnProperty('non_invoiced_products') ? packageInfo.non_invoiced_products.map(mapProductToAPI) : undefined
});

export const mapProductToAPI = (product, invoiced = true, includeWeights = false) => {
  return ({
    [invoiced ? 'quote_invoice_id' : 'product_id']: invoiced ? product.quote_invoice_id : product.product_id,
    name: !invoiced ? product.name : undefined,
    description: !invoiced ? product.description : undefined,
    unit: !invoiced ? product.unit : undefined,
    part_number: invoiced ? product.part_number : undefined,
    available: product.available,
    quantity: Number(product.quantity) || 1,
    net_weight: !invoiced || includeWeights ? product.net_weight : undefined,
    gross_weight: !invoiced || includeWeights ? product.gross_weight : undefined
  });
};

export const mapPackageFromAPI = packageInfo => ({
  id: packageInfo.id,
  shipping_id: packageInfo.shipping_id,
  package_type: packageInfo.package_type,
  albaran_number: packageInfo.albaran_number,
  package: packageInfo.package,
  dimension: packageInfo.dimension,
  gross_weight: parseToDecimal(packageInfo.gross_weight, 3),
  net_weight: parseToDecimal(packageInfo.net_weight, 3),
  products: [
    ...packageInfo.content.map(mapProductFromAPI),
    ...packageInfo.non_invoiced_products.map(product => mapProductFromAPI(product, false))
  ]
});

export const mapProductFromAPI = (product, invoiced = true) => {
  return {
    id: product.id,
    available: product.product_info.available,
    package_id: product.package_id,
    part_number: product.part_number,
    quote_invoice_id: product.quote_invoice_id,
    quote_id: product.product_info.quote_id,
    invoice_id: product.product_info.invoice_id,
    product_id: product.product_info.product_id,
    name: product.product_info.name,
    description: product.product_info.description,
    unit: product.product_info.unit,
    harmonized_code: product.product_info.harmonized_code,
    item_no: product.product_info.item_no,
    quantity: product.quantity,
    margin: product.product_info.margin,
    cost: product.product_info.cost,
    price: product.product_info.price,
    total: product.product_info.total,
    is_optional: product.product_info.is_optional,
    net_weight: product.product_info.net_weight,
    gross_weight: product.product_info.gross_weight,
    invoiced
  };
};

const methods = {
  mapShipmentToAPI,
    mapPackageToAPI,
    mapProductToAPI,
    mapProductFromAPI,
    mapPackageFromAPI
};
export default methods;
