
import axios from 'axios';

import mappers from './mappers';

const URL_PREFIX = 'product';

/**
 * Get all products
 * @param source
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
const getProducts = (source = null, filters = null) => axios.get(
  URL_PREFIX,
  {
    cancelToken: source && source.token,
    params: filters
  }
);

/**
 * Get product by its id
 * @param id
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const getProduct = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Get quotes where this product has been listed
 * @param id
 * @param source
 * @return {Promise<AxiosResponse<any>>}
 */
const getProductQuotes = (id, source = null) => axios.get(
  `${URL_PREFIX}/${id}/quote`,
  {
    cancelToken: source && source.token
  }
);

/**
 * Create product
 * @param data
 * @param source
 * @returns {Promise<AxiosResponse<T>>}
 */
const createProduct = (data, source = null) => axios.post(
  URL_PREFIX,
  mappers.mapProductToAPI(data),
  {
    cancelToken: source && source.token
  }
);

const updateProduct = (id, data, source = null) => axios.patch(
  `${URL_PREFIX}/${id}`,
  mappers.mapProductToAPI(data),
  {
    cancelToken: source && source.token
  }
);

const deleteProduct = (id, source = null) => axios.delete(
  `${URL_PREFIX}/${id}`,
  {
    cancelToken: source && source.token
  }
)

const apiFunctions = {
  createProduct,
  getProducts,
  getProduct,
  getProductQuotes,
  updateProduct,
  deleteProduct
};

export default apiFunctions;
export {
  apiFunctions,
  mappers
};
