
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({label, name, value, onChange, disabled}) => {
  const onCheckboxChange = () => !disabled && onChange(!value, name, false);
  return (
    <div className="checkbox">
      <input type="checkbox"
             name={name}
             onChange={onCheckboxChange}
             disabled={disabled}
             checked={!!value}/>
      <label onClick={onCheckboxChange}>{label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired
};

Checkbox.defaultProps = {
  disabled: false
};

export default Checkbox;