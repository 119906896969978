
import './SelectInvoice.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import api from '../../api';
import { ListPagination, TextInput, EmptyNotification } from '../../components';
import { Table, TableHeaderCell, TableBodyCell, TableBodyRow } from '../../components/Table';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';
import authStore from '../../stores/auth';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';

class SelectInvoice extends React.Component {
  state = {
    invoices: [],
    invoicesLoaded: false,
    loadingInvoices: false,
    error: false,
    description: null,
    page: 1,
    pages: 1
  };
  source = api.source.createSource();

  constructor(props) {
    super(props);

    this.getInvoices = this.getInvoices.bind(this);
    this.selectInvoice = this.selectInvoice.bind(this);
    this.deselectInvoice = this.deselectInvoice.bind(this);
  }

  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId;

    if (!invoiceId) {
      this.getInvoices();
    }
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  getInvoices(selectedPage) {
    this.setState({loadingInvoices: true});
    api.invoice.getInvoices(this.source, {description: this.state.description, page: selectedPage || this.state.page, shipped: '0'})
      .then(response => {
        const invoices = response.data.data;
        const pages = response.data.pages;
        this.setState({invoices, pages: pages, loadingInvoices: false, invoicesLoaded: true});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({error: err, loadingInvoices: false});
        }
      });
  }

  selectInvoice(invoice) {
    this.props.onSelectInvoice(invoice);
  }

  deselectInvoice() {
    if (!this.state.invoicesLoaded) {
      this.getInvoices();
    }
    this.props.onDeselectInvoice();
  }

  render() {
    if (this.props.selectedInvoice) {
      return (
        <div className="card select-invoice page__card">
          <div className="row row--align-top">
            <div className="col-6"><h2>Factura</h2></div>
            <div className="col-6 select-invoice__close">
              <button className="btn btn--icon btn--small btn--transparent"
                      onClick={() => this.deselectInvoice()}><CloseIcon className="btn__icon"/></button>
            </div>
            <div className="col-12 select-invoice__invoices">
              <div className="select-invoice__invoice">
                <div className="row row--align-center">
                  <div className="col-5">
                    <div className="row">
                      <div className="col-12 select-invoice__label select-invoice__label--title">{this.props.selectedInvoice.id}</div>
                      <div className="col-12 select-invoice__label">{this.props.selectedInvoice.client}</div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <div className="col-12 select-invoice__label">{this.props.selectedInvoice.quote_id}</div>
                      {this.props.selectedInvoice.contract_number ? <div className="col-12 select-invoice__label">{this.props.selectedInvoice.contract_number}</div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if(this.state.invoicesLoaded || this.state.loadingInvoices) {
      return (
        <div className="card card--instructions">
          <div className="row row--align-center">
            <div className="col-8">
              <h2>Factura</h2>
              <span className="card__instructions">Selecciona la factura a la cual anexar el embarque</span>
            </div>
            <div className="col-4">
              <TextInput placeholder="Buscar"
                         name="description"
                         value={this.state.description}
                         onChange={(value, name, error) => {this.setState({description: value})}}
                         onDone={(name, value, modified) => this.getInvoices()}/>
            </div>
            <div className="col-12 select-invoice__invoices">
              <Table loading={this.state.loadingInvoices}
                     loadingCells={4}
                     loadingRows={10}
                     headerCells={
                       <Fragment>
                         <TableHeaderCell>Código</TableHeaderCell>
                         <TableHeaderCell>Oferta</TableHeaderCell>
                         <TableHeaderCell>Cliente</TableHeaderCell>
                         <TableHeaderCell>Contrato</TableHeaderCell>
                       </Fragment>
                     }
                     errorState={<EmptyNotification icon={DisconnectIcon}
                                                    title={this.state.error || ''}
                                                    details="Ha ocurrido un error obteniendo las facturas."/>}
                     emptyState={<EmptyNotification icon={NotFoundIcon}
                                                    title="No se encontrarón facturas"
                                                    details="No se encontraron facturas pendientes de embarque."
                                                    action={authStore.permissions.invoices.create ? 'Nueva factura' : null}
                                                    onAction={() => authStore.permissions.invoices.create && this.props.history.push(`/invoices/new`)}/>}
                     pagination={<ListPagination margin="select"
                                                 totalPages={this.state.pages}
                                                 currentPage={this.state.page}
                                                 onChange={page => {
                                                   this.setState({page});
                                                   this.getInvoices(page);
                                                 }}/>}>
                {this.state.error || this.state.invoices.map(invoice => (
                  <TableBodyRow key={invoice.id}
                                onSelect={() => this.selectInvoice(invoice)}>
                    <TableBodyCell>{invoice.id}</TableBodyCell>
                    <TableBodyCell>{invoice.quote_id}</TableBodyCell>
                    <TableBodyCell>{invoice.client}</TableBodyCell>
                    <TableBodyCell>{invoice.contract_number}</TableBodyCell>
                  </TableBodyRow>
                ))}
              </Table>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

SelectInvoice.propTypes = {
  selectedInvoice: PropTypes.any,
  onSelectInvoice: PropTypes.func.isRequired,
  onDeselectInvoice: PropTypes.func.isRequired
};

SelectInvoice.defaultProps = {
  selectedInvoice: null,
  onSelectInvoice: () => {},
  onDeselectInvoice: () => {}
};

export default withRouter(SelectInvoice);