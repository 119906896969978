
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import TableBodyCell from './BodyCell';

const TableLinkBodyCell = ({className, url, history, children}) => (
  <TableBodyCell className={className}>
    <button className="btn btn--link btn--table"
            onClick={event => {
              history.push(url);
              event.stopPropagation();
            }}>{children}</button>
  </TableBodyCell>
);

TableLinkBodyCell.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default withRouter(TableLinkBodyCell);