
import './index.scss';
import { ReactComponent as AddIcon } from '../../assets/images/add.svg';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import Dropdown from '../Dropdown';
import Loader from '../Loader';
import Card from '../../containers/Card';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell } from '../Table';
import { parseToDecimal } from '../../utils';


const Product = ({selected,
                    index,
                   partNumber,
                   name,
                   description,
                   unit,
                   quantity,
                   available,
                   grossWeight,
                   netWeight,
                   subItem,
                   loading,
                   packages,
                   disabled,
                   addProduct,
                   selectProduct,showModal, isOpen}) =>{
                    return (
                      <TableBodyRow onSelect={selectProduct} selected={selected}>
                        <TableBodyCell>{partNumber}</TableBodyCell>
                        <TableBodyCell className="table__cell--description">{description}</TableBodyCell>
                        <TableBodyCell>{unit}</TableBodyCell>
                        <TableBodyCell>{parseToDecimal(quantity)}</TableBodyCell>
                        <TableBodyCell>{parseToDecimal(available)}</TableBodyCell>
                        <TableBodyCell>{parseToDecimal(grossWeight, 3)}</TableBodyCell>
                        <TableBodyCell>{parseToDecimal(netWeight, 3)}</TableBodyCell>
                        <TableBodyCell className="table__cell--actions">
                          {loading ? (
                            <Loader color="black" size="small"/>
                          ) : !disabled ? (
                            <Dropdown.Container icon={AddIcon}
                                                color="gray"
                                                size="small"
                                                outline={false}
                                                showCaret={true}
                                                position="right">
                              {packages.map((packageInfo, packageIndex) => <Dropdown.Button key={packageInfo.package}
                                                                                            onClick={() =>{
                                                                                              console.log(`packageinfo: ${packageInfo},indexProduct: ${index}, packageIndex: ${packageIndex}`)
                                                                                              showModal({isOpen: true, productIndex: index, packageId:packageInfo.id, packageIndex:packageIndex})
                                                                                              // addProduct(packageIndex, packageInfo.id)
                                                                                              console.log(packageInfo)
                                                                                            }}>{packageInfo.package_type} #{packageInfo.package}</Dropdown.Button>)}
                            </Dropdown.Container>
                          ) : null}
                        </TableBodyCell>
                      </TableBodyRow>
                    )
                   }

Product.propTypes = {
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  partNumber: PropTypes.any.isRequired,
  name: PropTypes.any,
  description: PropTypes.any,
  unit: PropTypes.any.isRequired,
  quantity: PropTypes.any.isRequired,
  grossWeight: PropTypes.any.isRequired,
  netWeight: PropTypes.any.isRequired,
  subItem: PropTypes.bool.isRequired,
  packages: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  addProduct: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

Product.defaultProps = {
  disabled: false,
  subItem: false,
  loading: false,
  packages: [],
  addProduct: () => {},
  selectProduct: () => {},
  showModal: ()=>{},
  isOpen: false
};

const PackageProductsSelection = ({
                                    searchVisible,
                                    searchText,
                                    searchChange,
                                    searchDone,
                                    products,
                                    packages,
                                    initialLoading,
                                    disabled,
                                    addProduct,
                                    addProducts,
                                    selectProduct,showModal, isOpen}) => (
  <div className="package-products-selection">
    <Card loading={initialLoading}>
      <Card.Title col="8">Productos</Card.Title>
      <Card.Actions col="4">
        {
          products.find(product => product.selected) && !disabled && (
            <Card.Action>
              <Dropdown.Container title="Agregar productos"
                                  color="secondary"
                                  size="small"
                                  outline={false}
                                  showCaret={true}
                                  position="right">
                {packages.map((packageInfo, packageIndex) => (
                  <Dropdown.Button key={packageInfo.package}
                                   onClick={() =>{
                                    
                                    showModal(true, packageIndex)
                                    // showModal(true)
                                    // addProducts(packageIndex, packageInfo.id)
                                   }}>Bulto {packageInfo.package}</Dropdown.Button>
                                   
                ))}
              </Dropdown.Container>
            </Card.Action>
          )
        }
      </Card.Actions>
      <Card.Body>
        <div className="row">
          {searchVisible ? (
            <div className="col-12">
              <TextInput name="search"
                         placeholder="Buscar"
                         value={searchText}
                         loading={initialLoading}
                         information={initialLoading}
                         disabled={disabled}
                         onChange={searchChange}
                         onDone={(name, value, modified) => modified && searchDone(value)}/>
            </div>
          ) : null}
          {!initialLoading ? (
            <div className="col-12 package-products-selection__products">
              <Table headerCells={
                <Fragment>
                  <TableHeaderCell># Pieza</TableHeaderCell>
                  <TableHeaderCell>Nombre</TableHeaderCell>
                  <TableHeaderCell>Unidad</TableHeaderCell>
                  <TableHeaderCell>Cantidad</TableHeaderCell>
                  <TableHeaderCell>Disponible</TableHeaderCell>
                  <TableHeaderCell>Peso bruto (Kg)</TableHeaderCell>
                  <TableHeaderCell>Peso neto (Kg)</TableHeaderCell>
                  <TableHeaderCell/>
                </Fragment>
              }>
                {products.map((product, index) => {
                  return (
                  
                    <Product key={product.quote_invoice_id}
                             index={index}
                             partNumber={product.part_number}
                             name={product.name}
                             description={product.description}
                             unit={product.unit}
                             quantity={product.quantity}
                             available={product.available}
                             grossWeight={product.gross_weight}
                             netWeight={product.net_weight}
                             packages={packages}
                             loading={product.loading}
                             selected={product.selected}
                             selectProduct={() => selectProduct(index)}
                             addProduct={(packageIndex, packageId) =>addProduct(packageIndex, packageId, index)}
                             showModal={showModal}/>
                             
                  )
                })}
              </Table>
            </div>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  </div>
);

PackageProductsSelection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  initialLoading: PropTypes.bool,
  addProduct: PropTypes.func.isRequired,
  addProducts: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  searchVisible: PropTypes.bool,
  searchText: PropTypes.any,
  searchChange: PropTypes.func.isRequired,
  searchDone: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

PackageProductsSelection.defaultProps = {
  disabled: false,
  products: [],
  packages: [],
  initialLoading: false,
  addProduct: () => {},
  addProducts: () => {},
  selectProduct: () => {},
  searchChange: () => {},
  searchDone: () => {},
  showModal: ()=>{},
  isOpen: false
};

/*
Subproduct code
{product.sub_products && product.sub_products.map((subProduct, subProductIndex) => (
                    <Product key={subProduct.part_number}
                             partNumber={subProduct.part_number}
                             name={subProduct.name}
                             description={subProduct.description}
                             unit={subProduct.unit}
                             quantity={subProduct.quantity}
                             grossWeight={subProduct.gross_weight}
                             netWeight={subProduct.net_weight}
                             subItem={true}
                             packages={this.props.packages}
                             addProduct={(packageIndex, packageId) => this.props.addProduct(packageIndex, packageId, index, subProductIndex)}/>
                  ))}
 */

/*
{products.find(product => product.selected) ? (
          <div className="col col--min-content package-products-selection__add-btn">

          </div>
        ) : null}
 */

export default PackageProductsSelection;
