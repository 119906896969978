
import React from 'react';
import PropTypes from 'prop-types';

const TableBodyCell = ({className, nowrap, loading, children}) => (
  <td className={`${className || ''}${loading ? ' table__cell--loading' : ''}${nowrap ? ' table__cell--nowrap' : ''}`}>{children}</td>
);

TableBodyCell.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  nowrap: PropTypes.bool,
  children: PropTypes.node
};

export default TableBodyCell;