
import React from 'react';
import PropTypes from 'prop-types';

const TableHeaderCell = ({className, children}) => (
  <th className={className}>{children}</th>
);

TableHeaderCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default TableHeaderCell;