
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {observer} from 'mobx-react';

import api from '../../api';
import { PageHeader, TextInput, LoadingButton, protect } from '../../components';
import { validateInputNotEmpty, validateEmailInput, validatePasswordInput } from '../../utils';
import settings from '../../settings';

const Role = ({name, permissions, active, onClick}) => (
  <div className={`user-form__role ${active ? 'user-form__role--active' : ''}`} onClick={onClick}>
    <h4>{name}</h4>
    <span>{Array.isArray(permissions) ? permissions.join(', ') : permissions}</span>
  </div>
);

Role.propTypes = {
  name: PropTypes.string.isRequired,
  permissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

Role.defaultProps = {
  active: false
};

class UserForm extends React.Component {
  state = {
    name: null,
    email: null,
    password: null,
    roles: [],
    errors: {
      name: null,
      email: null,
      password: null
    },
    createLoading: false,
    createError: false,
    createSuccess: false
  };
  source = api.source.createSource();
  notificationTimeout = null;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Usuarios', link: '/users'},
    {title: 'Nuevo usuario', link: '/users/new'}
  ];

  constructor(props) {
    super(props);

    this.getRoles = this.getRoles.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSelectRole = this.onSelectRole.bind(this);
    this.createUser = this.createUser.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
  }

  componentDidMount() {
    this.getRoles();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  getRoles() {
    api.roles.getRoles(this.source)
      .then(response => {
        const roles = response.data.map(role => ({
          ...role,
          mappedPermissions: role.permissions.map(permission => permission.permission_name)
        }));
        this.setState({roles});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error(err);
        }
      });
  }

  onValueChange(value, name, error) {
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: error
      }
    });
  }

  onSelectRole(index) {
    const roles = [...this.state.roles];
    roles[index].active = !roles[index].active;
    this.setState({roles});
  }

  setNotificationTimeout(success = false, value = true) {
    const fieldName = success ? 'createSuccess' : 'createError';
    this.setState({[fieldName]: value});
    this.notificationTimeout = setTimeout(
      () => this.setState({[fieldName]: false}),
      settings.notificationTimeout
    );
  }

  createUser() {
    this.setState({createLoading: true, createSuccess: false, createError: false});
    api.user.createUser(
      {
        email: this.state.email,
        password: this.state.password,
        name: this.state.name,
        roles: this.state.roles.filter(role => role.active).map(role => role.id)
      },
      this.source
    )
      .then(response => {
        const roles = [...this.state.roles].map(role => ({...role, active: false}));
        this.setNotificationTimeout(true, 'Usuario creado');
        this.setState({
          roles,
          name: null,
          email: null,
          password: null
        });
      })
      .catch(err => !api.source.errorIsCancel(err) && this.setNotificationTimeout(false, err))
      .finally(() => this.setState({createLoading: false}));
  }

  render() {
    return (
      <div className="user-form page page--form">
        <Helmet>
          <title>Nuevo usuario | Metallica Caribbean</title>
          <meta name="description" content="Formulario de creación de nuevo usuario" />
          <meta name="keyboards" content="usuario,metallica,crear,nuevo" />
        </Helmet>

        <div className="page__heading">
          <PageHeader.Container navigation={this.NAVIGATION}
                                title="Nuevo usuario"
                                onBack={() => this.props.history.goBack()}>
          </PageHeader.Container>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="row row--align-top">
                <div className="col-6 page__input">
                  <TextInput label="Nombre"
                             name="name"
                             placeholder="Nombre del usuario"
                             value={this.state.name}
                             error={this.state.errors.name}
                             onChange={this.onValueChange}
                             validateFn={validateInputNotEmpty}/>
                </div>
                <div className="col-6 page__input">
                  <TextInput type="email"
                             label="Correo electrónico"
                             name="email"
                             placeholder="usuario@empresa.com"
                             value={this.state.email}
                             error={this.state.errors.email}
                             onChange={this.onValueChange}
                             validateFn={validateEmailInput}/>
                </div>
                <div className="col-12 page__input">
                  <TextInput type="password"
                             label="Contraseña"
                             name="password"
                             placeholder=""
                             value={this.state.password}
                             error={this.state.errors.password}
                             onChange={this.onValueChange}
                             validateFn={validatePasswordInput}/>
                </div>

                <div className="col-12">
                  <h3>Permisos</h3>
                  <div className="row row--align-top user-form__roles">
                    {this.state.roles.map((role, index) => (
                      <div className="col-6 page__block"
                           key={role.id}>
                        <Role name={role.name}
                              permissions={role.mappedPermissions}
                              active={role.active}
                              onClick={() => this.onSelectRole(index)}/>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-12">
                  <div className="page__submit">
                    <LoadingButton loading={this.state.createLoading}
                                   className="btn btn--primary"
                                   success={this.state.createSuccess}
                                   error={this.state.createError}
                                   onClick={() => this.createUser()}
                                   loadingColor="black">Crear usuario</LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default protect(observer(UserForm), settings.permissions.admin);