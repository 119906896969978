
import { validate, validateLength } from './validatePassword';

/**
 * Validate password inputs and provide well error message
 * @param value
 * @returns {string|boolean}
 */
const fn = value => {
  if (!value || value === '') {
    return 'Debe introducir un valor';
  } else if (!validateLength(value)) {
    return 'Debe introducir una contraseña de entre 8 y 16 dígitos';
  } else if (!validate(value)) {
    return 'Debe introducir una contraseña conformada por letras mayúsculas, minúsculas y números';
  } else {
    return false;
  }
};
export default fn;
