
/**
 * Parse input value
 * @param event
 */
const fn = (event) => {
  const target = event.target;

  if (target.type === 'checkbox') {
    return target.checked;
  } else {
    return target.value === '' ? null : target.value;
  }
};
export default fn;
