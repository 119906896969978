
import './index.scss';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import api from '../../api';
import settings from '../../settings';
import { parseToDecimal, parseToMoney } from '../../utils';
import { TextInput, ListPagination, PageHeader, protect, EmptyNotification } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell, TableLinkBodyCell } from '../../components/Table';
import authStore from '../../stores/auth';

class ProductsList extends React.Component {
  state = {
    name: null,
    description: null,
    partNumber: null,
    products: [],
    page: 1,
    pages: 1,
    perPage: 10,
    loading: false,
    error: false
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Productos', link: '/products'}
  ];

  constructor(props) {
    super(props);

    this.getFilters = this.getFilters.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
  }

  componentDidMount() {
    this.getProducts();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  handleFiltersChange(value, name) {
    this.setState({[name]: value});
  }

  pageChanged(page) {
    this.setState({page});
    this.getProducts(page);
  }

  getFilters(selectedPage) {
    const { perPage, description, partNumber, name } = this.state;
    return {
      per_page: perPage,
      page: selectedPage || 1,
      description,
      part_number: partNumber,
      name
    };
  }

  getProducts(selectedPage = null) {
    this.setState({loading: true});

    api.product.getProducts(this.source, this.getFilters(selectedPage))
      .then(response => {
        const products = response.data.data;
        const pages = response.data.pages;

        this.setState({products, pages, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loading: false, error: err});
        }
      });
  }

  render() {
    return (
      <div className="products page page--list">
        <Helmet>
          <title>Productos | Metallica Caribbean</title>
          <meta name="description" content="Listado de productos" />
          <meta name="keyboards" content="productos,metallica,listado" />
        </Helmet>

        <div className="page__heading">
          <PageHeader.Container navigation={this.NAVIGATION}
                                title="Productos">
            <PageHeader.Filters>
              <PageHeader.Filter>
                <TextInput name="partNumber"
                           placeholder="Número de parte"
                           value={this.state.partNumber}
                           onChange={this.handleFiltersChange}
                           onDone={(fieldName, value, modified) => modified && this.getProducts()}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput name="name"
                           placeholder="Nombre"
                           value={this.state.name}
                           onChange={this.handleFiltersChange}
                           onDone={(fieldName, value, modified) => modified && this.getProducts()}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput name="description"
                           placeholder="Descripción"
                           value={this.state.description}
                           onChange={this.handleFiltersChange}
                           onDone={(fieldName, value, modified) => modified && this.getProducts()}/>
              </PageHeader.Filter>
            </PageHeader.Filters>
            <PageHeader.Actions>
              {authStore.permissions.admin ? (
                <PageHeader.Action>
                  <button className="btn btn--primary"
                          onClick={() => this.props.history.push(`/products/new`)}>Nuevo producto</button>
                </PageHeader.Action>
              ) : null}
            </PageHeader.Actions>
          </PageHeader.Container>
        </div>

        <div className="card">
          <Table loadingRows={10}
                 loadingCells={7}
                 loading={this.state.loading}
                 emptyState={<EmptyNotification icon={NotFoundIcon}
                                                title="No se encontrarón productos"
                                                details="Puedes cambiar los filtros de búsqueda en la parte superior o crear un producto."
                                                action={authStore.permissions.admin ? 'Nuevo producto' : null}
                                                onAction={() => authStore.permissions.admin && this.props.history.push(`/products/new`)}/>}
                 errorState={<EmptyNotification icon={DisconnectIcon}
                                                title={this.state.error}
                                                details="Ha ocurrido un error obteniendo los clientes."/>}
                 headerCells={
                   <Fragment>
                     <TableHeaderCell># Parte</TableHeaderCell>
                     <TableHeaderCell>Código armonizado</TableHeaderCell>
                     <TableHeaderCell className="products__header-cell--description">Descripción</TableHeaderCell>
                     <TableHeaderCell>Proveedor</TableHeaderCell>
                     <TableHeaderCell>Peso bruto</TableHeaderCell>
                     <TableHeaderCell>Peso neto</TableHeaderCell>
                     <TableHeaderCell>Costo</TableHeaderCell>
                 </Fragment>}
                 pagination={<ListPagination margin="page"
                                             currentPage={this.state.page}
                                             totalPages={this.state.pages}
                                             onChange={this.pageChanged}/>}>
            {this.state.error || this.state.products.map((product, index) => (
              <TableBodyRow key={product.part_number}
                            onSelect={() => this.props.history.push(`/products/${product.id}`)}>
                <TableBodyCell>{product.part_number}</TableBodyCell>
                <TableBodyCell>{product.harmonized_code}</TableBodyCell>
                <TableBodyCell>{product.description}</TableBodyCell>
                <TableLinkBodyCell url={`/admin/suppliers/${product.manufacturer_id}`}>{product.manufacturer}</TableLinkBodyCell>
                <TableBodyCell nowrap={true}>{parseToDecimal(product.gross_weight)} kg</TableBodyCell>
                <TableBodyCell nowrap={true}>{parseToDecimal(product.net_weight)} kg</TableBodyCell>
                <TableBodyCell nowrap={true}>{parseToMoney(product.price)} €</TableBodyCell>
              </TableBodyRow>
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

export default protect(ProductsList, settings.permissions.read_admin);
