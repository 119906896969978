
import Container from './Container';
import Link from './Link';
import Button from './Button';

const components = {
  Container,
  Link,
  Button
};
export default components;
