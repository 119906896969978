import './index.scss';

import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import api from '../../api';
import ProductItem from '../ProductItem';
import { parseToDecimal, parseToMoney } from '../../utils';
import {
  calculateMargin,
  calculatePrice,
  calculateTotal,
} from '../../utils/offers';

class ProductsListOld extends React.Component {
  source = api.source.createSource();

  constructor(props) {
    super(props);
    this.resetNewProduct = this.resetNewProduct.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onAddProduct = this.onAddProduct.bind(this);
    this.onDeleteProduct = this.onDeleteProduct.bind(this);
    this.onTransferProduct = this.onTransferProduct.bind(this);
    this.checkPrice = this.checkPrice.bind(this);
    this.getTableBodyContent = this.getTableBodyContent.bind(this);

    this.state = {
      newProduct: this.resetNewProduct(),
      newSubProduct: null,
      newSubProductIndex: null
    };
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  resetNewProduct() {
    return {
      product_id: null,
      name: null,
      part_number: null,
      description: null,
      harmonized_code: null,
      unit: 'U',
      price: '0.00',
      cost: '0.00',
      quantity: '1.00',
      margin: parseToDecimal(this.props.margin),
      total: '0.00'
    };
  }

  onValueChange(value, name, error, subProduct = false) {
    let product = {...(subProduct ? this.state.newSubProduct : this.state.newProduct)};
    const key = subProduct ? 'newSubProduct' : 'newProduct';
    let newPrice;

    switch (name) {
      case 'product_id':
        const baseProduct = this.props.eligibleProducts.find(product => product.id === value);
        if (baseProduct) {
          newPrice = calculatePrice(baseProduct.price, this.props.margin);
          product = {
            ...product,
            product_id: value,
            name: baseProduct.name,
            description: baseProduct.description,
            harmonized_code: baseProduct.harmonized_code,
            part_number: baseProduct.part_number,
            cost: parseToDecimal(baseProduct.price),
            margin: parseToDecimal(this.props.margin),
            price: parseToDecimal(newPrice),
            quantity: '1.00',
            total: parseToDecimal(calculateTotal(newPrice, 1))
          };
          this.checkPrice(value, subProduct);
        }
        break;
      case 'cost':
        newPrice = calculatePrice(value, product.margin);
        product.cost = value;
        product.price = parseToDecimal(newPrice);
        product.total = parseToDecimal(calculateTotal(newPrice, product.quantity));
        break;
      case 'margin':
        newPrice = calculatePrice(product.cost, value);
        product.margin = value;
        product.price = parseToDecimal(newPrice);
        product.total = parseToDecimal(calculateTotal(newPrice, product.quantity));
        break;
      case 'price':
        product.price = value;
        product.margin = parseToDecimal(calculateMargin(value, product.cost));
        product.total = parseToDecimal(calculateTotal(value, product.quantity));
        break;
      case 'quantity':
        product.quantity = value;
        product.total = parseToDecimal(calculateTotal(product.price, value));
        break;
      default:
        product[name] = value;
        break;
    }

    this.setState({[key]: product});
  }

  onAddProduct(index = null) {
    const selectedProduct = index || index === 0 ? this.state.newSubProduct : this.state.newProduct;
    if (selectedProduct.product_id) {
      const product = {
        ...selectedProduct,
        cost: parseToDecimal(selectedProduct.cost),
        margin: parseToDecimal(selectedProduct.margin),
        price: parseToDecimal(selectedProduct.price),
        quantity: parseToDecimal(selectedProduct.quantity),
        total: parseToDecimal(selectedProduct.total),
      };

      this.props.addProduct(product, index, selectedProduct.id);

      if (index || index === 0) {
        this.setState({
          newSubProduct: {...this.state.newSubProduct, ...this.resetNewProduct()}
        });
      } else {
        this.setState({newProduct: this.resetNewProduct()});
      }
    }
  }

  onTransferProduct(index) {
    const product = this.props.products[index];
    this.props.transferProduct(index, product.id);
  }

  onDeleteProduct(index, subIndex) {
    let product = null;

    if (subIndex || subIndex === 0) {
      product = this.props.products[index].sub_products[subIndex];
    } else {
      product = this.props.products[index];
    }
    this.props.deleteProduct(index, subIndex, product.id);
  }

  checkPrice(productId, subProduct = false) {
    api.quote.checkPrice(
      this.props.clientId,
      productId,
      this.source
    )
      .then(response => {
        if (response && response.data) {
          this.setState({
            [subProduct ? 'newSubProduct' : 'newProduct']: {
              ...(subProduct ? this.state.newSubProduct : this.state.newProduct),
              last_price: response.data
            }
          });
        }
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error getting last price', err);
        }
      });
  }

  getTableBodyContent() {
    if (this.props.loading) {
      return [0,1,2,3,4,5,6,7,8,9].map(n => <tr key={n}>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        {this.props.itemNumberVisible ? <td><div className="loading-block loading-block--mid"/></td> : null}
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
        <td className="products-list__loading-cell"><div className="loading-block loading-block--mid"/></td>
      </tr>);
    } else {
      const {newProduct, newSubProduct, newSubProductIndex} = this.state;
      const {
        itemNumberVisible,
        products,
        totalProducts,
        totalExtras,
        discounted,
        total,
        summaryVisible,
        transferVisible,
        deleteVisible,
        // actionsVisible,
        allowSubItem
      } = this.props;
      return (
        <>
          {products.map((product, index) => (
            <Fragment key={index}>
              <ProductItem 
                           index={index}
                           totalProducts={products.length}
                           id={product.product_id}
                           isOptional={this.props.isOptional}
                           selected={product.selected}
                           itemNumber={itemNumberVisible ? product.item_no || true : false}
                           transferVisible={transferVisible}
                           deleteVisible={deleteVisible}
                           newVisible={this.props.newVisible}
                           invoiceVisible={this.props.invoiceVisible}
                           name={product.name}
                           partNumber={product.part_number}
                           invoiceId={product.invoice_id}
                           description={product.description}
                           harmonizedCode={product.harmonized_code}
                           unitItem={product.unit}
                           quantity={product.quantity}
                           cost={product.cost}
                           price={product.price}
                           margin={product.margin}
                           total={product.total}
                           products={this.props.products}
                           availableInvoices={this.props.invoices}
                           newItem={false}
                           error={product.error}
                           loading={!!product.loading}
                           disabled={!!product.disabled || this.props.disabled}
                           allowSubItem={allowSubItem}
                           costVisible={true}
                           onValueChange={(value, name) => this.props.productChanged(name, value, index)}
                           onInputDone={(fieldName, value, modified) => this.props.productChangeDone(fieldName, value, modified, product.id, index)}
                           onAddSubItem={() => {
                             if (this.state.newSubProductIndex === index) {
                               this.setState({newSubProductIndex: null});
                             } else {
                               this.setState({
                                 newSubProductIndex: index,
                                 newSubProduct: {
                                   ...this.resetNewProduct(),
                                   id: product.id
                                 },
                               });
                             }
                           }}
                           onDelete={() => this.onDeleteProduct(index, null, product.id)}
                           onTransfer={(action, invoiceId) => this.props.transferProduct(index, product.id, action, product.quote_id, invoiceId)}
                           onRetry={() => product.operation && this.props.retry(product.operation.type, product.operation.data, index)}
                           onSelectionChange={(selected) => this.props.productChanged('selected', selected, index)}
                           onUp={()=>
                            {
                              console.log("up")
                              this.props.moveProduct(index, index-1)
                            }
                           }
                           onDown={()=>
                            {
                              console.log("down")
                              this.props.moveProduct(index, index+1)
                            }
                           }
                           
              />
              {product.sub_products && product.sub_products.map((subproduct, subIndex) => (
                <ProductItem id={subproduct.product_id}
                             selected={product.selected}
                             itemNumber={itemNumberVisible}
                             deleteVisible={deleteVisible}
                             name={subproduct.name}
                             partNumber={subproduct.part_number}
                             description={subproduct.description}
                             harmonizedCode={subproduct.harmonized_code}
                             unitItem={subproduct.unit}
                             quantity={subproduct.quantity}
                             cost={subproduct.cost}
                             price={subproduct.price}
                             margin={subproduct.margin}
                             total={subproduct.total}
                             key={subIndex}
                             products={this.props.products}
                             newItem={false}
                             costVisible={true}
                             error={subproduct.error}
                             loading={!!subproduct.loading}
                             disabled={!!subproduct.disabled || this.props.disabled}
                             onValueChange={(value, name) => this.props.productChanged(name, value, index, subIndex)}
                             onInputDone={(fieldName, value, modified) => this.props.productChangeDone(fieldName, value, modified, subproduct.id, index, subIndex)}
                             onDelete={() => this.onDeleteProduct(index, subIndex, subproduct.id)}
                             onRetry={() => subproduct.operation && this.props.retry(subproduct.operation.type, subproduct.operation.data, index, subIndex)}
                             subItem={true}/>
              ))}
              {(newSubProductIndex || newSubProductIndex === 0) && newSubProductIndex === index && !this.props.disabled && this.props.newVisible ? (
                <ProductItem selected={false}
                             itemNumber={itemNumberVisible}
                             name={newSubProduct.product_id}
                             partNumber={newSubProduct.part_number}
                             description={newSubProduct.description}
                             harmonizedCode={newSubProduct.harmonized_code}
                             unitItem={newSubProduct.unit}
                             quantity={newSubProduct.quantity}
                             cost={newSubProduct.cost}
                             price={newSubProduct.price}
                             margin={newSubProduct.margin}
                             total={newSubProduct.total}
                             lastPrice={newSubProduct.last_price}
                             newItem={true}
                             onValueChange={(value, name, error) => this.onValueChange(value, name, error, true)}
                             subItem={true}
                             products={this.props.eligibleProducts}
                             onNew={() => this.onAddProduct(index)}/>
              ) : null}
            </Fragment>
          ))}
          {!this.props.disabled && this.props.newVisible ? (
            <ProductItem selected={false}
                         lastIndex={this.props.products}
                         itemNumber={itemNumberVisible}
                         name={newProduct.product_id}
                         partNumber={newProduct.part_number}
                         description={newProduct.description}
                         harmonizedCode={newProduct.harmonized_code}
                         unitItem={newProduct.unit}
                         quantity={newProduct.quantity}
                         cost={newProduct.cost}
                         price={newProduct.price}
                         margin={newProduct.margin}
                         total={newProduct.total}
                         newItem={true}
                         onValueChange={this.onValueChange}
                         subItem={false}
                         products={this.props.eligibleProducts}
                         onNew={(position, idx)=> {
                          this.onAddProduct()
                         }}
                         lastPrice={newProduct.last_price}/>
          ) : null}
          {summaryVisible && (
            <>
              <tr>
                <td colSpan={itemNumberVisible ? 10 : 9} className="products-list__subtitle">Productos</td>
                <td colSpan="2" className="products-list__amount">{parseToMoney(totalProducts, 2)} €</td>
              </tr>
              <tr>
                <td colSpan={itemNumberVisible ? 10 : 9} className="products-list__subtitle">Extras</td>
                <td colSpan="2" className="products-list__amount">{parseToMoney(totalExtras, 2)} €</td>
              </tr>
              <tr>
                <td colSpan={itemNumberVisible ? 10 : 9} className="products-list__subtitle">Descuento</td>
                <td colSpan="2" className="products-list__amount">{parseToMoney(discounted, 2)} €</td>
              </tr>
              <tr>
                <td colSpan={itemNumberVisible ? 10 : 9} className="products-list__subtitle">Total</td>
                <td colSpan="2" className="products-list__amount">{parseToMoney(total, 2)} €</td>
              </tr>
            </>
          )}
        </>
      );
    }
  }

  render() {
    const {itemNumberVisible} = this.props;

    return (
      <table className="products-list">
        <thead>
        <tr>
          <th className="products-list__header products-list__header--min">
            <input type="checkbox"
                   checked={this.props.allProductsSelected}
                   onChange={() => this.props.toggleProductsSelection()}/>
          </th>
          <th className="products-list__header products-list__header--min"/>
          {itemNumberVisible ? <th className="products-list__header">#</th> : null}
          <th className="products-list__header">Código</th>
          <th className="products-list__header">Descripción</th>
          <th className="products-list__header">C.A.</th>
          <th className="products-list__header">Unidad</th>
          <th className="products-list__header">Costo</th>
          <th className="products-list__header">Margen</th>
          <th className="products-list__header">Precio</th>
          <th className="products-list__header">Cantidad</th>
          <th className="products-list__header">Importe</th>
          <th className="products-list__header"/>
        </tr>
        </thead>
        <tbody>
        {this.getTableBodyContent()}
        </tbody>
      </table>
    );
  }
}

ProductsListOld.propTypes = {
  isOptional: PropTypes.bool,
  allProductsSelected: PropTypes.bool,
  allowSubItem: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  itemNumberVisible: PropTypes.bool.isRequired,
  costVisible: PropTypes.bool,
  loading: PropTypes.bool,
  checkPrice: PropTypes.bool,
  eligibleProducts: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  margin: PropTypes.any,
  totalProducts: PropTypes.any,
  totalExtras: PropTypes.any,
  discounted: PropTypes.any,
  total: PropTypes.any,
  invoices: PropTypes.arrayOf(PropTypes.string),
  summaryVisible: PropTypes.bool.isRequired,
  transferVisible: PropTypes.bool.isRequired,
  deleteVisible: PropTypes.bool,
  newVisible: PropTypes.bool,
  invoiceVisible: PropTypes.bool,
  productChanged: PropTypes.func.isRequired,
  productChangeDone: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  transferProduct: PropTypes.func.isRequired,
  retry: PropTypes.func.isRequired,
  toggleProductsSelection: PropTypes.func.isRequired,
};

ProductsListOld.defaultProps = {
  isOptional: false,
  allProductsSelected: false,
  allowSubItem: true,
  disabled: false,
  itemNumberVisible: false,
  costVisible: false,
  checkPrice: false,
  eligibleProducts: [],
  products: [],
  invoices: [],
  margin: 0,
  totalProducts: 0,
  totalExtras: 0,
  discounted: 0,
  total: 0,
  summaryVisible: true,
  transferVisible: false,
  deleteVisible: true,
  newVisible: true,
  invoiceVisible: false,
  productChanged: () => {},
  productChangeDone: () => {},
  addProduct: () => {},
  deleteProduct: () => {},
  transferProduct: () => {},
  toggleProductsSelection: () => {},
  retry: () => {},
  moveProduct: () => {}
};

export default observer(ProductsListOld);
