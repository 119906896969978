
import React from 'react';

import settings from '../../settings';
import { authStore } from '../../stores';
import { useInvoiceContext } from './InvoiceContext';
import { Card } from '../../containers';
import { Checkbox, SelectInput, SelectOption, TextInput } from '../../components';

const DetailsForm = (props) => {
  const [state, dispatch] = useInvoiceContext();

  /**
   * Handle input value change
   * @param value
   * @param name
   * @return {*}
   */
  const onInputValueChange =
    (value, name) =>
      dispatch({
        type: 'INVOICE_VALUE_CHANGE',
        data: {
          [name]: value
        }
      });

  return (
    <>
      <Card>
        <Card.Title>Referencia</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-4 form__input">
              <TextInput
                label="Número Fiscal"
                type="text"
                name="fiscal_number"
                value={state.invoice.fiscal_number}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-4 form__input">
              <TextInput
                label="Número de contrato"
                type="text"
                name="contract_number"
                value={state.invoice.contract_number}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-4 form__input">
              <TextInput
                label="Fecha de contrato"
                type="date"
                name="contract_date"
                placeholder="2020-12-24"
                value={state.invoice.contract_date}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-6 form__input">
              <TextInput
                label="Número BL"
                type="text"
                name="BL_number"
                value={state.invoice.BL_number}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-6 form__input">
              <TextInput
                label="Número AWB"
                type="text"
                name="AWB_number"
                value={state.invoice.AWB_number}
                onChange={onInputValueChange}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Mercancia</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-12 form__input">
              <TextInput
                label="Descripción de mercancia"
                type="text"
                name="description"
                value={state.invoice.description}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-4 form__input">
              <SelectInput
                label="País de origen"
                name="boarding_place"
                value={state.invoice.boarding_place}
                onChange={onInputValueChange}
              >
                {
                  settings.boardingPlaces
                    .map((place) => (
                      <SelectOption
                        key={place}
                        value={place}
                      >
                        {place}
                      </SelectOption>
                    ))
                }
                <SelectOption type="text">OTRO</SelectOption>
              </SelectInput>
            </div>
            <div className="col-4 form__input">
              <Checkbox
                label="Mercancia sin valor comercial"
                name="is_commercial"
                value={state.invoice.is_commercial}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-4 form__input">
              <Checkbox
                label="Mercancia en reposición"
                name="replacement"
                value={state.invoice.replacement}
                onChange={onInputValueChange}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Pago</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-4 form__input">
              <SelectInput
                label="Condición"
                name="incoterm"
                value={state.invoice.incoterm}
                onChange={onInputValueChange}
              >
                {
                  settings.conditions
                    .map((condition) => (
                      <SelectOption
                        key={condition}
                        value={condition}
                      >
                        {condition}
                      </SelectOption>
                    ))
                }
              </SelectInput>
            </div>
            <div className="col-4 form__input">
              <SelectInput
                label="Condición Año"
                name="incoterm_year"
                value={state.invoice.incoterm_year}
                onChange={onInputValueChange}
              >
                {
                  settings.conditions_year
                    .map((cy) => (
                      <SelectOption
                        key={cy}
                        value={cy}
                      >
                        {cy}
                      </SelectOption>
                    ))
                }
              </SelectInput>
            </div>
            <div className="col-4 form__input">
              <TextInput
                label="Fecha de pago estimada"
                type="date"
                name="due_date"
                placeholder="2020-12-24"
                value={state.invoice.due_date}
                onChange={onInputValueChange}
              />
            </div>
            
            <div className="col-12 form__input">
              <SelectInput
                label="Forma de pago"
                name="payment_method"
                value={state.invoice.payment_method}
                onChange={onInputValueChange}
              >
                {
                  settings.PAYMENT_METHODS
                    .map((method) => (
                      <SelectOption
                        key={method}
                        value={method}
                      >
                        {method}
                      </SelectOption>
                    ))
                }
                <SelectOption type="text">OTRO</SelectOption>
              </SelectInput>
            </div>
            <div className="col-4 form__input">
              <Checkbox
                label="Pagado"
                name="paid_date"
                value={state.invoice.paid_date}
                onChange={onInputValueChange}
                disabled={!authStore.permissions.invoices.set_paid}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Costos</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-4 form__input">
              <TextInput
                label="Embalaje"
                type="number"
                name="packing"
                value={state.invoice.packing}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-4 form__input">
              <TextInput
                label="Flete"
                type="number"
                name="freight"
                value={state.invoice.freight}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-4 form__input">
              <TextInput
                label="Seguro"
                type="number"
                name="insurance"
                value={state.invoice.insurance}
                onChange={onInputValueChange}
              />
            </div>
            {state.invoice.incoterm ? (
              <div className="col-4 form__input">
                <TextInput
                  label={`Costo ${state.invoice.incoterm}`}
                  type="number"
                  name="incoterm"
                  value={state.invoice.incoterm_price}
                  onChange={onInputValueChange}
                />
              </div>
            ) : null}
            <div className="col-4 form__input">
              <TextInput
                label="Descuento"
                type="number"
                name="discount"
                value={state.invoice.discount}
                onChange={onInputValueChange}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Financiamiento</Card.Title>
        <Card.Body>
          <div className="row row--align-top">
            <div className="col-6 form__input">
              <TextInput
                label="Periodo"
                type="text"
                name="financing_period"
                value={state.invoice.financing_period}
                onChange={onInputValueChange}
              />
            </div>
            <div className="col-6 form__input">
              <TextInput
                label="Porcentaje (%)"
                type="number"
                name="financing_percentage"
                value={state.invoice.financing_percentage}
                onChange={onInputValueChange}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <div className="page__submit">
        <button
          className="btn btn--primary"
          onClick={() => dispatch({ type: 'INVOICE_FORM_SUBMIT' })}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default DetailsForm;

