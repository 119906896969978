
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const EmptyNotification = ({icon: Icon, title, details, action, onAction}) => (
  <div className="empty-notification">
    <div className="empty-notification__content">
      <Icon className="empty-notification__icon"/>
      <h2 className="empty-notification__title">{title instanceof Error ? title.message : title}</h2>
      {details ? <p className="empty-notification__details">{details}</p> : null}
      {action && onAction ? <button className="empty-notification__action btn btn--primary"
                                    onClick={() => onAction()}>{action}</button> : null}
    </div>
  </div>
);

EmptyNotification.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.any,
  details: PropTypes.string,
  action: PropTypes.string,
  onAction: PropTypes.func
};

export default EmptyNotification;