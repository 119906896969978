
import { ReactComponent as DisconnectIcon } from '../assets/images/disconnect.svg';

import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import settings from '../settings';
import protect from '../components/protect';
import api from '../api';
import utilsStore from '../stores/utils';
import {
  PageHeader,
  SelectInput,
  SelectOption,
  TextInput,
  LabeledInformation,
  LoadingLabel,
  EmptyNotification, Badge
} from '../components';
import { Table, TableHeaderCell, TableBodyCell, TableBodyRow } from '../components/Table';
import { Card } from '../containers';
import { parseDateReadable, parseToMoney, validateInputNotEmpty, validateNumberInput } from '../utils';
import authStore from '../stores/auth';
import { ReactComponent as NotFoundIcon } from '../assets/images/search.svg';

class Product extends React.Component {
  state = {
    product: null,
    listedOffers: [],
    loadingProduct: false,
    loadingOffers: false,
    errorLoadingProduct: false,
    errorLoadingOffers: false,
    modifyingField: false,
    errorModifyingField: false,
    successModifyingField: false
  };
  source = api.source.createSource();
  MODIFY_NOTIFICATION_DURATION = 3000;
  MODIFY_SUCCESS_MESSAGE = 'Guardado';
  modifyNotificationTimeout;

  constructor(props) {
    super(props);

    // Helper methods
    this.getProduct = this.getProduct.bind(this);
    this.getListedOffers = this.getListedOffers.bind(this);

    // Form actions
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onInputDone = this.onInputDone.bind(this);
  }

  componentDidMount() {
    this.getProduct();
    this.getListedOffers();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.modifyNotificationTimeout);
  }

  getProduct() {
    const id = this.props.match.params.id;
    this.setState({loadingProduct: true});
    api.product.getProduct(id, this.source)
      .then(response => {
        const product = response.data;
        this.setState({product, loadingProduct: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingProduct: false, errorLoadingProduct: err})
        }
      });
  }

  getListedOffers() {
    const productId = this.props.match.params.id;
    this.setState({loadingOffers: true});
    api.product.getProductQuotes(productId, this.source)
      .then(response => {
        const listedOffers = response.data;
        this.setState({listedOffers, loadingOffers: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingOffers: false, errorLoadingOffers: err})
        }
      });
  }

  // Actions

  onValueChanged(value, name, error) {
    this.setState({product: {...this.state.product, [name]: value}});
  }

  onInputDone(fieldName, value, modified) {
    if (modified) {
      // Set loading
      this.setState({modifyingField: fieldName, errorModifyingField: false, successModifyingField: false});
      clearTimeout(this.modifyNotificationTimeout);

      // Update product
      api.product.updateProduct(this.props.match.params.id, {[fieldName]: value}, this.source)
        .then(() => {
          if (this.state.modifyingField === fieldName) {
            this.setState({modifyingField: false, errorModifyingField: false, successModifyingField: this.MODIFY_SUCCESS_MESSAGE});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({successModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            console.error('Cannot update quote');
            this.setState({modifyingField: false, errorModifyingField: err, successModifyingField: false});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({errorModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        });
    }
  }

  onDeleteAction(productId){
    console.log("Eliminar:", productId)
    this.setState({loadingOffers: true, loadingProduct:true});
    api.product.deleteProduct(productId, this.source)
      .then(response => {
        this.setState({loadingOffers: false, loadingProduct:false});
        this.props.history.goBack();
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingOffers: false, loadingProduct:false})
        }
      });
  }

  render() {
    if (this.state.errorLoadingProduct === 'not_found') {
      return <Redirect to="/products/not-found"/>
    }
    return (
      <div className="product page">
        <Helmet>
          <title>{this.state.product ? this.state.product.part_number : ''} | Metallica Caribbean</title>
          <meta name="description" content={`Vista a detalle del producto ${this.state.product ? this.state.product.part_number : ''}`} />
          <meta name="keyboards" content="producto,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          {this.state.errorLoadingProduct ? (
            <div className="col-12">
              <div className="card">
                <EmptyNotification icon={DisconnectIcon}
                                   title={this.state.error}
                                   details="Ha ocurrido un error obteniendo el producto."
                                   action="Regresar"
                                   onAction={() => this.props.history.goBack()}/>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="col-12">
                <div className="page__heading">
                  <PageHeader.Container navigation={[
                    {title: 'Inicio', link: '/'},
                    {title: 'Productos', link: '/products'},
                    {title: this.state.product ? this.state.product.part_number : '', link: `/products/${this.state.product ? this.state.product.part_number : ''}`}
                  ]}
                                        loading={this.state.loadingProduct}
                                        title={this.state.product ? this.state.product.part_number : ''}
                                        onBack={() => this.props.history.goBack()}>


                    <PageHeader.Actions>
                      {this.state.modifyingField || this.state.errorModifyingField || this.state.successModifyingField ? (
                        <PageHeader.Action>
                          <LoadingLabel loading={!!(this.state.modifyingField)}
                                        error={this.state.errorModifyingField}
                                        success={this.state.successModifyingField}>Guardando cambios</LoadingLabel>
                        </PageHeader.Action>
                      ) : null}
                    </PageHeader.Actions>
                  </PageHeader.Container>
                </div>
              </div>

              <div className="col-5 page__card">
                <Card title="Información de producto"
                      loading={this.state.loadingProduct}>
                  <div className="row row--align-top">
                    <div className="col-6 product__data">
                      <LabeledInformation label="Código de producto"
                                          loading={this.state.loadingProduct}
                                          value={this.state.product && this.state.product.part_number}/>
                    </div>
                    <div className="col-6 product__data">
                      <TextInput label="Código armonizado"
                                 name="harmonized_code"
                                 value={this.state.product && this.state.product.harmonized_code}
                                 placeholder="Código armonizado"
                                 onChange={this.onValueChanged}
                                 onDone={this.onInputDone}
                                 validateFn={validateInputNotEmpty}
                                 information={true}
                                 disabled={!authStore.permissions.admin}
                                 loading={this.state.loadingProduct}/>
                    </div>
                    <div className="col-12 product__data">
                      <TextInput label="Descripción"
                                 type="multiline"
                                 name="description"
                                 value={this.state.product && this.state.product.description}
                                 placeholder="Descripción de producto"
                                 onChange={this.onValueChanged}
                                 onDone={this.onInputDone}
                                 information={true}
                                 disabled={!authStore.permissions.admin}
                                 loading={this.state.loadingProduct}/>
                    </div>
                    <div className="col-6 product__data">
                      <SelectInput label="Proveedor"
                                   name="manufacturer_id"
                                   value={this.state.product && this.state.product.manufacturer_id}
                                   onChange={this.onValueChanged}
                                   onDone={this.onInputDone}
                                   information={true}
                                   disabled={!authStore.permissions.admin}
                                   searchEnabled={true}
                                   loading={this.state.loadingProduct}>
                        {utilsStore.suppliers.map((supplier, index) => (
                          <SelectOption value={supplier.id} key={index}>{supplier.name}</SelectOption>
                        ))}
                      </SelectInput>
                    </div>
                    <div className="col-6 product__data">
                      <TextInput type="number"
                                 label="Costo (€)"
                                 name="price"
                                 value={this.state.product && this.state.product.price}
                                 onChange={this.onValueChanged}
                                 onDone={this.onInputDone}
                                 validateFn={validateNumberInput}
                                 placeholder="0,000.00 €"
                                 information={true}
                                 disabled={!authStore.permissions.admin}
                                 loading={this.state.loadingProduct}/>
                    </div>
                    <div className="col-6 product__data">
                      <TextInput type="number"
                                 label="Peso bruto (kg)"
                                 name="gross_weight"
                                 value={this.state.product && this.state.product.gross_weight}
                                 onChange={this.onValueChanged}
                                 onDone={this.onInputDone}
                                 validateFn={validateNumberInput}
                                 placeholder="0,000.00 kg"
                                 information={true}
                                 disabled={!authStore.permissions.admin}
                                 loading={this.state.loadingProduct}/>
                    </div>
                    <div className="col-6 product__data">
                      <TextInput type="number"
                                 label="Peso neto (kg)"
                                 name="net_weight"
                                 value={this.state.product && this.state.product.net_weight}
                                 onChange={this.onValueChanged}
                                 onDone={this.onInputDone}
                                 validateFn={validateNumberInput}
                                 placeholder="0,000.00 kg"
                                 information={true}
                                 disabled={!authStore.permissions.admin}
                                 loading={this.state.loadingProduct}/>
                    </div>
                    <div className='col-6 product__data'>
                      <button 
                        className="empty-notification__action btn btn--error"
                        onClick={() => this.onDeleteAction(this.state.product.id)}>Eliminar
                      </button>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col-7">
                <Card title="Relación de ventas"
                      loading={this.state.loadingOffers}>
                  <Table loading={this.state.loadingOffers}
                         loadingRows={5}
                         loadingCells={5}
                         emptyState={authStore.permissions.quotes.create ? (
                           <EmptyNotification icon={NotFoundIcon}
                                              title="Aún no has creado ofertas con este producto"
                                              details="Presiona el botón debajo para crear una oferta."
                                              action="Nueva oferta"
                                              onAction={() => this.props.history.push(`/offers/new`)}/>
                         ) : (
                           <EmptyNotification icon={NotFoundIcon}
                                              title="No se han creado ofertas con este producto"
                                              details="Aquí verás las ofertas que se creen con este producto."/>
                         )}
                         errorState={<EmptyNotification icon={DisconnectIcon}
                                                        title={this.state.errorProducts}
                                                        details="Ha ocurrido un error obteniendo las ofertas relacionadas."/>}
                         headerCells={
                           <Fragment>
                             <TableHeaderCell>Código</TableHeaderCell>
                             <TableHeaderCell>Pedido</TableHeaderCell>
                             <TableHeaderCell>Cliente</TableHeaderCell>
                             <TableHeaderCell>Fecha</TableHeaderCell>
                             <TableHeaderCell>Precio</TableHeaderCell>
                           </Fragment>
                         }>
                    {!this.state.errorLoadingOffers && this.state.listedOffers && this.state.listedOffers.map(quote => (
                      <TableBodyRow key={quote.quote.id}
                                    onSelect={() => {
                                      if (authStore.permissions.quotes.read) {
                                        this.props.history.push(`/offers/${quote.quote.id}`);
                                      }
                                    }}>
                        <TableBodyCell nowrap={true}>
                          {quote.quote.id}
                          {quote.invoice_id ? (
                            <Badge size="small"
                                   color="success"
                                   fill={true}
                                   onClick={() => {
                                     if (authStore.permissions.invoices.read) {
                                       this.props.history.push(`/invoices/${quote.invoice_id}`);
                                     }
                                   }}>{quote.invoice_id}</Badge>
                          ) : null}
                        </TableBodyCell>
                        <TableBodyCell>{quote.quote.order}</TableBodyCell>
                        <TableBodyCell>{quote.quote.client}</TableBodyCell>
                        <TableBodyCell>{parseDateReadable(quote.quote.date, true)}</TableBodyCell>
                        <TableBodyCell nowrap={true}>{parseToMoney(quote.product_price)} €</TableBodyCell>
                      </TableBodyRow>
                    ))}
                  </Table>
                </Card>
              </div>
            </Fragment>
          )}

        </div>
      </div>
    );
  }
}

export default protect(observer(Product), settings.permissions.read_admin);
