
import { observable, action, decorate, computed, runInAction } from 'mobx';

import api from '../../api';
import { parseToDecimal } from '../../utils';
import LocalProductsStore from './LocalList';

// TODO: Improve isOptional
class OnlineProductsStore extends LocalProductsStore {
  productsModified = false;
  isOptional = false;

  quoteId;
  offerTotal;

  source = api.source.createSource();

  reindexState = {
    loading: false,
    error: null,
    success: false
  };
  fetchState = {
    loading: false,
    error: null,
    success: false
  };
  deleteSelectedProductsState = {
    loading: false,
    error: null,
    success: false
  };
  moveProductsState = {
    loading: false,
    error: null,
    success: false
  }

  get total() {
    if (this.offerTotal && !this.productsModified) {
      return Number(this.offerTotal) || 0;
    } else {
      return super.calculateProductsTotal();
    }
  }

  constructor(quoteId, total, products, isOptional) {
    super(products);
    this.quoteId = quoteId;
    this.isOptional = isOptional;
    this.offerTotal = parseToDecimal(total);
  }

  configure(quoteId, total, products) {
    super.configure(products);
    this.quoteId = quoteId;
    this.offerTotal = parseToDecimal(total);
  }

  setProducts(products) {
    super.configure(products);
  }

  retryOperation(type, data, index, subIndex) {
    switch(type) {
      case 'change':
        this.productChanged(data.fieldName, data.value, data.modified, data.productId, index, subIndex);
        break;
      case 'add':
        this.addProduct(data.product, index, data.relationId, subIndex, true);
        break;
      case 'delete':
        this.deleteProduct(index, subIndex, data.productId);
        break;
      default: break;
    }
  }

  productChanged(fieldName, value, modified, productId, index, subIndex) {
    if (modified) {
      this.productsModified = true;
      super.setProductData({
        loading: true,
        disabled: true,
        error: false,
        operation: {
          type: 'change',
          data: {fieldName, value, modified, productId}
        }
      }, index, subIndex);

      if (subIndex || subIndex === 0) {
        const parentProductId = this.products[index].id;
        api.quote.updateSubProduct(
          this.quoteId,
          parentProductId,
          productId,
          {
            [fieldName]: value
          },
          this.source
        )
          .then(response => response.data &&
            this.setProductValueById({loading: false, disabled: false, operation: null}, parentProductId, productId)) // Set loading false
          .catch(err => !api.source.errorIsCancel(err) && this.setProductValueById({loading: false, error: true}, parentProductId, productId));
      } else {
        api.quote.updateProduct(
          this.quoteId,
          productId,
          {
            [fieldName]: value
          },
          this.isOptional,
          this.source
        )
          .then(response => response.data &&
            this.setProductValueById({loading: false, disabled: false, operation: null}, productId))
          .catch(err => !api.source.errorIsCancel(err) && this.setProductValueById({loading: false, error: true}, productId));
      }
    }
  }

  addProduct(product, index, relationId, subIndex, retry) {
    const promiseId = new Date().getTime();
    const operationData = {type: 'add', data: {product, relationId}};

    this.productsModified = true;
    if (retry) {
      super.setProductData({operation: operationData, loading: promiseId, error: false}, index, subIndex);
    } else {
      super.addProduct({
        ...product,
        loading: promiseId,
        disabled: true,
        operation: operationData
      }, index);
    }

    if (relationId) {
      api.quote.addSubproduct(this.quoteId, relationId, product, this.source)
        .then(response => response.data &&
          this.setProductValueByLoadingId({loading: false, ...response.data, disabled: false, operation: null}, relationId, promiseId))
        .catch(err => !api.source.errorIsCancel(err) &&
          this.setProductValueByLoadingId({loading: false, error: true}, relationId, promiseId));
    } else {
      api.quote.addProduct(this.quoteId, product, this.isOptional, this.source)
        .then(response => response.data &&
          this.setProductValueByLoadingId({loading: false, ...response.data, disabled: false, operation: null}, promiseId))
        .catch(err => !api.source.errorIsCancel(err) &&
          this.setProductValueByLoadingId({loading: false, error: true}, promiseId));
    }
  }

  addProductFromTransfer(product) {
    this.productsModified = true;
    super.addProduct({
      ...product,
      disabled: false,
    });
  }

  moveProducts(index ,newIndex){
    this.moveProductsState = { loading: true, error: null, success: false };
    let tmpProd1 = this.products[index];
    let tmpProd2 = this.products[newIndex];

    this.products[index] = tmpProd2;
    this.products[newIndex] = tmpProd1;

    let idsIndex = [];
    for(let i=0;i<this.products.length;i++){
      idsIndex.push(
        {
          id: this.products[i].id,
          order_no: i+1
        }
      )
    }
    
    api.quote.moveProducts(this.quoteId, idsIndex, this.isOptional, this.source)
      .then(response => {
        if (response.data) {
          console.log(response.data)
          this.moveProductsState = { loading: false, error: null, success: true };
        }
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {

          runInAction(() => {
            this.moveProductsState = { loading: false, error: true, success: false };
          });
        }
      });
    
  }

  // TODO: For preventing collisions with base class method
  deleteProductOverride(index, subIndex, productId) {
    const promiseId = new Date().getTime();

    this.productsModified = true;
    this.setProductData({
      loading: promiseId,
      disabled: true,
      operation: {
        type: 'delete',
        data: {productId}
      }
    }, index, subIndex);

    if (subIndex || subIndex === 0) {
      const parentProductId = this.products[index].id;
      api.quote.deleteSubProduct(this.quoteId, parentProductId, productId, this.source)
        .then(response => response.data && this.deleteProductById(parentProductId, productId))
        .catch(err => !api.source.errorIsCancel(err) &&
          this.setProductValueById({loading: false, error: true}, parentProductId, productId));
    } else {
      api.quote.deleteProduct(this.quoteId, productId, this.isOptional, this.source)
        .then(response => response.data && this.deleteProductById(productId))
        .catch(err => !api.source.errorIsCancel(err) &&
          this.setProductValueById({loading: false, error: true}, productId));
    }
  }

  transferProduct(index, productId, action, quoteId, invoiceId, removeProduct = false) {
    this.productsModified = true;
    super.setProductData({
      loading: true,
      disabled: true,
      error: false,
    }, index, null);

    switch(action) {
      case 'add_to_invoice':
        api.invoice.addProduct(quoteId, invoiceId, productId, this.source)
          .then(response => response.data &&
            this.setProductValueById({loading: false, disabled: false, operation: null, invoice_id: invoiceId}, productId))
          .catch(err => !api.source.errorIsCancel(err) && this.setProductValueById({loading: false, error: null}, productId));
        break;
      case 'remove_from_invoice':
        api.invoice.removeProduct(invoiceId, productId, this.source)
          .then(response => {
            if (response.data) {
              if (removeProduct) {
                this.deleteProductById(productId);
              } else {
                this.setProductValueById({loading: false, disabled: false, operation: null, invoice_id: null}, productId);
              }
            }
          })
          .catch(err => !api.source.errorIsCancel(err) && this.setProductValueById({loading: false, error: null}, productId));
        break;
      default: break;
    }
  }

  setProductValueById(data, productId, subProductId) {
    const productIndex = this.products.findIndex(product => product.id === productId);

    if (productIndex || productIndex === 0) {
      if (subProductId || subProductId === 0) {
        const product = this.products[productIndex];
        const subProductIndex = product.sub_products && product.sub_products.findIndex(subProduct => subProduct.id === subProductId);

        if (subProductIndex || subProductIndex === 0) {
          super.setProductData(data, productIndex, subProductIndex);
        }
      } else {
        super.setProductData(data, productIndex);
      }
    }
  }

  setProductValueByLoadingId(data, productId, subProductId) {
    if (subProductId) {
      const productIndex = this.products.findIndex(product => product.id === productId);
      if (productIndex || productIndex === 0) {
        const product = this.products[productIndex];
        const subProductIndex = product.sub_products && product.sub_products.findIndex(subProduct => subProduct.loading === subProductId);

        if (subProductIndex || subProductIndex === 0) {
          super.setProductData(data, productIndex, subProductIndex);
        }
      }
    } else {
      const productIndex = this.products.findIndex(product => product.loading === productId);
      if (productIndex || productIndex === 0) {
        super.setProductData(data, productIndex);
      }
    }
  }

  deleteProductById(productId, subProductId) {
    const productIndex = this.products.findIndex(product => product.id === productId);
    if (productIndex || productIndex === 0) {
      if (subProductId || subProductId === 0) {
        const product = this.products[productIndex];
        const subProductIndex = product.sub_products && product.sub_products.findIndex(subProduct => subProduct.id === subProductId);

        if (subProductIndex || subProductIndex === 0) {
          super.deleteProduct(productIndex, subProductIndex);
        }
      } else {
        super.deleteProduct(productIndex);
      }
    }
  }

  deleteSelectedProducts() {
    const promiseId = new Date().getTime();
    const selectedProductsIds = this.selectedProducts.map(product => product.id);
    const selectedProductsIndexes = this.selectedProductsIndexes;
    this.setProductsData({ loading: promiseId, disabled: true, }, selectedProductsIndexes);
    this.productsModified = true;
    this.deleteSelectedProductsState = { loading: true, error: null, success: false };

    api.quote.deleteProducts(this.quoteId, selectedProductsIds, this.source)
      .then(response => {
        if (response.data) {
          const productsIndexes = this.products
            .map((product, index) => ({index, loading: product.loading}))
            .filter(product => product.loading === promiseId)
            .map(product => product.index);
          this.deleteProducts(productsIndexes);
          this.deleteSelectedProductsState = { loading: false, error: null, success: true };
        }
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          const productsIndexes = this.products
            .map((product, index) => ({index, loading: product.loading}))
            .filter(product => product.loading === promiseId)
            .map(product => product.index);
          this.setProductsData({loading: false, error: true}, productsIndexes);
          runInAction(() => {
            this.deleteSelectedProductsState = { loading: false, error: true, success: false };
          });
        }
      });
  }

  reindex() {
    this.reindexState = {loading: true, error: null, success: false};
    api.quote.reindexQuoteProducts(this.quoteId, this.source)
      .then(action('fetchReindexSuccess', response => {
        this.setProducts(response.data);
        this.reindexState = {loading: false, error: null, success: true};
      }))
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error reindexing products', err);
          runInAction(() => this.reindexState = {loading: false, error: err, success: false});
        }
      });
  }

  fetchProducts() {
    this.fetchState = {loading: true, error: null, success: false};
    api.quote.getProducts(this.quoteId, this.isOptional)
      .then(action(
        'fetchProductsSuccess',
        response => {
          this.fetchState = {loading: false, error: null, success: true};
          this.configure(this.quoteId, response.data.sub_total, response.data.products);
        }
      ))
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error reindexing products', err);
          runInAction(() => this.fetchState = {loading: false, error: err, success: false});
        }
      });
  }
}

decorate(
  OnlineProductsStore,
  {
    productsModified: observable,
    quoteId: observable,
    offerTotal: observable,

    reindexState: observable,
    fetchState: observable,
    deleteSelectedProductsState: observable,

    total: computed,

    setProducts: action,

    retryOperation: action.bound,
    addProduct: action.bound,
    productChanged: action.bound,
    deleteProductOverride: action.bound,
    setProductValueById: action.bound,
    setProductValueByLoadingId: action.bound,
    deleteProductById: action.bound,
    deleteSelectedProducts: action.bound,

    addProductFromTransfer: action.bound,

    transferProduct: action.bound,

    reindex: action,
    fetchProducts: action,
    moveProducts: action.bound
  }
);

export default OnlineProductsStore;
