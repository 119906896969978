
import './Link.scss';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

class Link extends React.Component {
  render() {
    const { to, onClick, children } = this.props;
    return (
      <RouterLink className="dropdown__link" to={to} onClick={onClick}>{children}</RouterLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

Link.defaultProps = {
  to: '/'
};

export default Link;
