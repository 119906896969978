import './Button.scss';


import React from 'react';
import PropTypes from 'prop-types';

class Button extends React.Component {
  render() {
    const { onClick, children } = this.props;
    return (
      <button className="dropdown__button" onClick={event => {
        onClick(event);
        event.stopPropagation();
      }}>{children}</button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

Button.defaultProps = {
  onClick: () => {}
};

export default Button;
