
import './index.scss';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';

import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import settings from '../../settings';
import protect from '../../components/protect';
import api from '../../api';
import {
  PageHeader,
  TextInput,
  LabeledInformation,
  LoadingLabel,
  PackagesList,
  EmptyNotification,
  SelectInput,
  SelectOption,
  LoadingButton
} from '../../components';
import { Card } from '../../containers';
import { validateInputNotEmpty, parseDateReadable, parseToDecimal } from '../../utils';
import FileSaver from 'file-saver';
import { authStore } from '../../stores';
import ModalItemProductSplitter from '../../components/ModalItemProductSplitter';

class Shipment extends React.Component {
  state = {
    shipment: null,
    noShippedProducts: [],
    product:{},
    loadingDownload: false,
    loadingShipment: false,
    errorLoadingShipment: false,
    modifyingField: false,
    errorModifyingField: false,
    successModifyingField: false,
    packageIndex: null,
    productIndex: null,
    packageId: null,
    isOpen: false
  };
  source = api.source.createSource();
  MODIFY_NOTIFICATION_DURATION = 3000;
  MODIFY_SUCCESS_MESSAGE = 'Guardado';
  modifyNotificationTimeout;

  constructor(props) {
    super(props);

    // Helper methods
    this.getShipment = this.getShipment.bind(this);
    this.onDownloadExcel = this.onDownloadExcel.bind(this);

    // Form actions
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onInputDone = this.onInputDone.bind(this);

    // Package actions
    this.onAddPackage = this.onAddPackage.bind(this);
    this.onAddProduct = this.onAddProduct.bind(this);
    this.onPackageChanged = this.onPackageChanged.bind(this);

    // Modal actions
    this.onSetShowModal = this.onSetShowModal.bind(this);
    this.onProductChangedSplitted = this.onProductChangedSplitted.bind(this);
    this.onAddProductToPackageSplitted = this.onAddProductToPackageSplitted.bind(this);

    // Products actions
    this.onProductChanged = this.onProductChanged.bind(this);
    this.calculatePackageWeight = this.calculatePackageWeight.bind(this);
  }

  componentDidMount() {
    this.getShipment();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.modifyNotificationTimeout);
  }

  getShipment() {
    const id = this.props.match.params.id;
    this.setState({loadingShipment: true, errorLoadingShipment: false});

    Promise.all([
      api.shipment.getShipment(id, this.source),
      api.shipment.getNoShippedProducts(id, this.source)
    ])
      .then(responses => {
        const shipment = responses[0];
        const noShippedProducts = responses[1].data.map(product => ({...product, quote_invoice_id: product.id, id: undefined}));
        this.setState({shipment, noShippedProducts, loadingShipment: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error(err);
          this.setState({loadingShipment: false, errorLoadingShipment: err});
        }
      });
    /*api.shipment.getShipment(id, this.source)
      .then(shipment => {

      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingShipment: false, errorLoadingShipment: err});
        }
      });*/
  }

  // Actions

  onValueChanged(value, name, error) {
    this.setState({
      shipment: {...this.state.shipment, [name]: value}
    });
  }

  onInputDone(fieldName, value, modified) {
    if (modified) {
      let dataToChange = {[fieldName]: value};

      if (fieldName === 'type') {
        if (value === 1) {
          dataToChange = {...dataToChange, airline: null, manifesto: null};
        } else if (value === 2) {
          dataToChange = {...dataToChange, shipping_company: null, vessel: null, container: null};
        }
        this.setState({shipment: {...this.state.shipment, ...dataToChange}});
      }

      // Set loading
      this.setState({modifyingField: fieldName, errorModifyingField: false, successModifyingField: false});
      clearTimeout(this.modifyNotificationTimeout);

      api.shipment.updateShipment(
        this.props.match.params.id,
        dataToChange,
        this.source
      )
        .then(() => {
          if (this.state.modifyingField === fieldName) {
            this.setState({modifyingField: false, errorModifyingField: false, successModifyingField: this.MODIFY_SUCCESS_MESSAGE});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({successModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            this.setState({modifyingField: false, errorModifyingField: err, successModifyingField: false});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({errorModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        })
    }
  }

  // Package actions

  onAddPackage(packageInfo) {
    const id = new Date().getTime();
    this.setState({
      shipment: {
        ...this.state.shipment,
        packages: [
          ...this.state.shipment.packages,
          {...packageInfo, loading: true, id}
        ]
      }
    });
    api.shipment.addPackage(
      this.props.match.params.id, {...packageInfo, package: this.state.shipment.packages.length + 1, id},
      this.source
    )
      .then(response => {
        const packageIndex = this.state.shipment.packages.findIndex(packageInfo => packageInfo.id === id);
        if (packageIndex || packageIndex === 0) {
          this.onPackageChanged(false, 'loading', null, packageIndex);
          this.onPackageChanged(response.data, 'id', null, packageIndex);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  onPackageChanged(value, name, error, index) {
    const packages = [...this.state.shipment.packages];
    packages[index][name] = value;
    this.setState({shipment: {...this.state.shipment, packages}});
  }

  // Show actiopns
  onSetShowModal({isOpen, productIndex, packageId, packageIndex}){
    const noShippedProducts = [...this.state.noShippedProducts];
    const product = {...noShippedProducts[productIndex]};
    this.setState({isOpen: isOpen, product:product, packageId: packageId, productIndex: productIndex})

  }
  onProductChangedSplitted(product, quantity){
    product["quantity"] = quantity
    console.log(product)
    this.setState({product: product})
  }
  onAddProductToPackageSplitted(product,packageIndex){
    const noShippedProducts = [...this.state.noShippedProducts];
    const productNoShipped = {...noShippedProducts[this.state.productIndex]};
    noShippedProducts[this.state.productIndex].loading = true;
    productNoShipped.quantity = product.quantity
    delete productNoShipped["available"]
    this.setState({noShippedProducts});

    api.shipment.addProduct(
      this.props.match.params.id,
      this.state.packageId,
      productNoShipped,
      this.source
    )
      .then(response => {
        const packages = [...this.state.shipment.packages];
        const packageIndex = packages.findIndex(packageInfo => packageInfo.id === this.state.packageId);
        noShippedProducts[this.state.productIndex].available = parseFloat(noShippedProducts[this.state.productIndex].available) - parseFloat(product.quantity) 
        noShippedProducts[this.state.productIndex].loading = false

        if (packageIndex > -1) {
          if (noShippedProducts[this.state.productIndex].available <= 0) {
            noShippedProducts.splice(this.state.productIndex, 1);
          }
          packages[packageIndex].products.push({...product, loading: false, selected: false, id: response.data});
          this.setState({shipment: {...this.state.shipment, packages}, noShippedProducts, isOpen: false});
          // this.setState({noShippedProducts, isOpen:false})
        }
      })
  }
  // Products actions

  onAddProduct(packageIndex, packageId, productIndex) {
    const noShippedProducts = [...this.state.noShippedProducts];
    const product = {...noShippedProducts[productIndex]};
    noShippedProducts[productIndex].loading = true;
    this.setState({noShippedProducts});

    api.shipment.addProduct(
      this.props.match.params.id,
      packageId,
      product,
      this.source
    )
      .then(response => {
        const packages = [...this.state.shipment.packages];
        const packageIndex = packages.findIndex(packageInfo => packageInfo.id === packageId);
        if (packageIndex > -1) {
          const noShippedProducts = [...this.state.noShippedProducts];
          const productIndex = noShippedProducts.findIndex(prd => prd.quote_invoice_id === product.quote_invoice_id);
          if (productIndex > -1) {
            noShippedProducts.splice(productIndex, 1);
          }
          packages[packageIndex].products.push({...product, loading: false, selected: false, id: response.data});
          this.setState({shipment: {...this.state.shipment, packages}, noShippedProducts});
        }
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          const noShippedProducts = [...this.state.noShippedProducts];
          const productIndex = noShippedProducts.findIndex(prd => prd.quote_invoice_id === product.quote_invoice_id);

          if (productIndex > -1) {
            noShippedProducts[productIndex].loading = false;
            this.setState({noShippedProducts});
          }
        }
      });
  }

  onProductChanged(value, name, error, packageIndex, productIndex) {
    const packages = [...this.state.shipment.packages];
    packages[packageIndex].products[productIndex][name] = value;

    if (name === 'net_weight' || name === 'gross_weight' || name === 'quantity') {
      packages[packageIndex].net_weight = parseToDecimal(this.calculatePackageWeight('net_weight', packageIndex, packages), 3);
      packages[packageIndex].gross_weight = parseToDecimal(this.calculatePackageWeight('gross_weight', packageIndex, packages), 3);
    }
    this.setState({shipment: {...this.state.shipment, packages}});
  }

  calculatePackageWeight(fieldName, packageIndex, packages) {
    const basePackages = packages || this.state.shipment.packages;
    return basePackages[packageIndex].products.reduce((total, product) => total + ((Number(product[fieldName]) || 0) * (Number(product.quantity) || 0)), 0);
  }

  onDownloadExcel() {
    const id = this.props.match.params.id;

    this.setState({loadingDownload: true});
    api.shipment.downloadExcel(id, this.source)
      .then(response => {
        const fileName = `Embarque ${id}.xlsx`;
        this.setState({loadingDownload: false});
        FileSaver.saveAs(response.data, fileName);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingDownload: false});
        }
      });
  }

  render() {
    if (this.state.errorLoadingShipment === 'not_found') {
      return <Redirect to="/shipments/not-found"/>
    }
    return (
      <div className="shipment page">
        <Helmet>
          <title>{this.props.match.params.id} | Metallica Caribbean</title>
          <meta name="description" content={`Vista a detalle del embarque ${this.props.match.params.id}`} />
          <meta name="keyboards" content="embarque,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">

          {this.state.errorLoadingShipment ? (
            <div className="col-12">
              <div className="card">
                <EmptyNotification icon={DisconnectIcon}
                                   title={this.state.errorLoadingShipment}
                                   details="Ha ocurrido un error obteniendo el embarque."
                                   action="Regresar"
                                   onAction={() => this.props.history.goBack()}/>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="col-12">
                <div className="page__heading">
                  <PageHeader.Container navigation={[
                    {title: 'Inicio', link: '/'},
                    {title: 'Embarques', link: '/shipments'},
                    {title: this.props.match.params.id, link: `/shipments/${this.props.match.params.id}`}
                  ]}
                                        loading={this.state.loadingShipment}
                                        title={this.props.match.params.id}
                                        onBack={() => this.props.history.goBack()}>


                    <PageHeader.Actions>
                      {this.state.modifyingField || this.state.errorModifyingField || this.state.successModifyingField ? (
                        <PageHeader.Action>
                          <LoadingLabel loading={!!(this.state.modifyingField)}
                                        error={this.state.errorModifyingField}
                                        success={this.state.successModifyingField}>Guardando cambios</LoadingLabel>
                        </PageHeader.Action>
                      ) : null}

                      <PageHeader.Action>
                        <LoadingButton loadingColor="black"
                                       loaderSize="small"
                                       className="btn--gray btn--icon btn--outline"
                                       loading={this.state.loadingDownload}
                                       onClick={this.onDownloadExcel}><DownloadIcon className="btn__icon"/></LoadingButton>
                      </PageHeader.Action>

                    </PageHeader.Actions>
                  </PageHeader.Container>
                </div>
                {this.state.isOpen && <ModalItemProductSplitter
                                showModal={this.onSetShowModal} 
                                Product={this.state.product} 
                                ProductChangedSplitted={this.onProductChangedSplitted} 
                                AddProductToPackageSplitted={this.onAddProductToPackageSplitted}/>}
              </div>

              <div className="col-8">
                <div className="row row--align-top">
                  <div className="col-12">
                    <Card loading={this.state.loadingShipment}>
                      <Card.Title>Información marítima</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col page__input">
                            <SelectInput label="Tipo de embarque"
                                         name="type"
                                         value={this.state.shipment && this.state.shipment.type}
                                         onChange={this.onValueChanged}
                                         onDone={this.onInputDone}
                                         loading={this.state.loadingShipment}
                                         disabled={!authStore.permissions.packingLists.edit}
                                         information={true}>
                              <SelectOption value={1}>Embarque marítimo</SelectOption>
                              <SelectOption value={2}>Embarque aéreo</SelectOption>
                            </SelectInput>
                          </div>
                          <div className="col page__input">
                            <LabeledInformation label="Cantidad de paquetes"
                                                value={this.state.shipment && this.state.shipment.packages.length}
                                                loading={this.state.loadingShipment}/>
                          </div>
                          <div className="col page__input">
                            <LabeledInformation label="Fecha de modificación"
                                                value={this.state.shipment && parseDateReadable(this.state.shipment.creation_date)}
                                                loading={this.state.loadingShipment}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.state.loadingShipment}>
                      <Card.Title>Información de embarque</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          {this.state.shipment && this.state.shipment.type === 1 ? (
                            <Fragment>
                              <div className="col-6 page__input">
                                <TextInput label="Naviera"
                                           name="shipping_company"
                                           value={this.state.shipment && this.state.shipment.shipping_company}
                                           onChange={this.onValueChanged}
                                           onDone={this.onInputDone}
                                           validateFn={validateInputNotEmpty}
                                           information={true}
                                           disabled={!authStore.permissions.packingLists.edit}
                                           loading={this.state.loadingShipment}/>
                              </div>
                              <div className="col-6 page__input">
                                <TextInput label="Buque"
                                           name="vessel"
                                           value={this.state.shipment && this.state.shipment.vessel}
                                           onChange={this.onValueChanged}
                                           onDone={this.onInputDone}
                                           information={true}
                                           disabled={!authStore.permissions.packingLists.edit}
                                           loading={this.state.loadingShipment}/>
                              </div>
                              <div className="col-6 page__input">
                                <TextInput label="Contenedor"
                                           name="container"
                                           value={this.state.shipment && this.state.shipment.container}
                                           onChange={this.onValueChanged}
                                           onDone={this.onInputDone}
                                           information={true}
                                           disabled={!authStore.permissions.packingLists.edit}
                                           loading={this.state.loadingShipment}/>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div className="col page__input">
                                <TextInput label="Aerolínea"
                                           name="airline"
                                           value={this.state.shipment && this.state.shipment.airline}
                                           placeholder="Aerolínea"
                                           onChange={this.onValueChanged}
                                           onDone={this.onInputDone}
                                           validateFn={validateInputNotEmpty}
                                           information={true}
                                           disabled={!authStore.permissions.packingLists.edit}
                                           loading={this.state.loadingShipment}/>
                              </div>
                              <div className="col page__input">
                                <TextInput label="Manifiesto"
                                           name="manifesto"
                                           value={this.state.shipment && this.state.shipment.manifesto}
                                           placeholder="Manifiesto"
                                           onChange={this.onValueChanged}
                                           onDone={this.onInputDone}
                                           information={true}
                                           disabled={!authStore.permissions.packingLists.edit}
                                           loading={this.state.loadingShipment}/>
                              </div>
                            </Fragment>
                          )}

                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                </div>
              </div>

              <div className="col-4">
                <div className="row row--align-top">

                  <div className="col-12">
                    <Card loading={this.state.loadingShipment}>
                      <Card.Title>Tiempos</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="col-6 page__input">
                            <TextInput type="date"
                                       label="ETS"
                                       name="ETS"
                                       value={this.state.shipment && this.state.shipment.ETS}
                                       placeholder="ETS"
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.packingLists.edit}
                                       loading={this.state.loadingShipment}/>
                          </div>
                          <div className="col-6 page__input">
                            <TextInput type="date"
                                       label="ETA"
                                       name="ETA"
                                       value={this.state.shipment && this.state.shipment.ETA}
                                       onChange={this.onValueChanged}
                                       onDone={this.onInputDone}
                                       placeholder="ETA"
                                       information={true}
                                       disabled={!authStore.permissions.packingLists.edit}
                                       loading={this.state.loadingShipment}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                </div>
              </div>

              <div className="col-12">
                <PackagesList packages={this.state.shipment ? this.state.shipment.packages : []}
                              products={this.state.noShippedProducts}
                              searchVisible={false}
                              disabled={!authStore.permissions.packingLists.edit}
                              addPackage={packageInfo => this.onAddPackage({
                                ...packageInfo,
                                gross_weight: parseToDecimal(packageInfo.gross_weight, 3),
                                net_weight: parseToDecimal(packageInfo.net_weight, 3),
                              })}
                              packageChanged={this.onPackageChanged}
                              packageChangeDone={(name, value, modified, index) => {
                                if (modified) {
                                  const packageId = this.state.shipment.packages[index].id;
                                  const shouldParseToDecimal = name === 'gross_weight' || name === 'net_weight';

                                  if (shouldParseToDecimal)
                                    this.onPackageChanged(parseToDecimal(value, 3), name, null, index);

                                  this.onPackageChanged(true, 'loading', null, index);
                                  api.shipment.updatePackage(
                                    this.props.match.params.id,
                                    packageId,
                                    { [name]: shouldParseToDecimal ? parseToDecimal(value, 3) : value },
                                    this.source,
                                  )
                                    .then(() => {})
                                    .catch(err => console.error(err))
                                    .finally(() => {
                                      const packageIndex = this.state.shipment.packages.findIndex(packageInfo => packageInfo.id === packageId);
                                      if (packageIndex || packageIndex === 0) {
                                        this.onPackageChanged(false, 'loading', null, packageIndex);
                                      }
                                    });
                                }
                              }}
                              deletePackage={(index) => {
                                const packageId = this.state.shipment.packages[index].id;
                                this.onPackageChanged(true, 'loading', null, index);
                                api.shipment.deletePackage(this.props.match.params.id, packageId, this.source)
                                  .then(() => {
                                    const packages = [...this.state.shipment.packages];
                                    packages.splice(index, 1);
                                    this.setState({shipment: {...this.state.shipment, packages}});
                                  })
                                  .catch(err => {
                                    if (!api.source.errorIsCancel(err)) {
                                      const packageIndex = this.state.shipment.packages.findIndex(packageInfo => packageInfo.id === packageId);
                                      if (packageIndex || packageIndex === 0) {
                                        this.onPackageChanged(false, 'loading', null, packageIndex)
                                      }
                                    }
                                  });
                              }}
                              addProduct={this.onAddProduct}
                              showModal={this.onSetShowModal}
                              addProducts={(packageIndex, packageId) => {
                                const products = [...this.state.noShippedProducts].map((product, index) => ({...product, index})).filter(product => product.selected);
                                products.forEach(product => this.onAddProduct(packageIndex, packageId, product.index));
                              }}
                              selectProduct={(index) => {
                                if (authStore.permissions.packingLists.edit) {
                                  const products = [...this.state.noShippedProducts];
                                  products[index].selected = !products[index].selected;
                                  this.setState({noShippedProducts: products});
                                }
                              }}
                              productChanged={this.onProductChanged}
                              productChangeDone={(name, value, modified, packageIndex, productIndex) => {
                                if (modified) {
                                  const packageInfo = this.state.shipment.packages[packageIndex];
                                  const packageId = packageInfo.id;
                                  const productId = packageInfo.products[productIndex].id;
                                  const shouldParseToDecimal = name === 'gross_weight' || name === 'net_weight';

                                  if (shouldParseToDecimal && value !== null)
                                    this.onProductChanged(parseToDecimal(value, 3), name, null, packageIndex, productIndex);

                                  console.log(name, value);

                                  this.onProductChanged(true, 'loading', null, packageIndex, productIndex);
                                  api.shipment.updateProduct(
                                    this.props.match.params.id,
                                    packageId,
                                    productId,
                                    { [name]: shouldParseToDecimal ? parseToDecimal(value, 3) : value },
                                    this.source,
                                  )
                                    .then(() => {})
                                    .catch(err => console.error(err))
                                    .finally(() => {
                                      const packageIndex = this.state.shipment.packages.findIndex(packageInfo => packageInfo.id === packageId);
                                      if (packageIndex || packageIndex === 0) {
                                        const productIndex = this.state.shipment.packages[packageIndex].products.findIndex(product => product.id === productId);
                                        if (productIndex || productIndex === 0) {
                                          this.onProductChanged(false, 'loading', null, packageIndex, productIndex);
                                        }
                                      }
                                    });
                                }
                              }}

                              deleteProduct={(packageIndex, productIndex) => {
                                const packageInfo = this.state.shipment.packages[packageIndex];
                                const packageId = packageInfo.id;
                                const productId = packageInfo.products[productIndex].id;
                                this.onProductChanged(true, 'loading', null, packageIndex, productIndex);
                                api.shipment.deleteProduct(this.props.match.params.id, packageId, productId, this.source)
                                  .then(() => {
                                    const packages = this.state.shipment.packages;
                                    const noShippedProducts = [...this.state.noShippedProducts];
                                    const packageIndex = packages.findIndex(packageInfo => packageInfo.id === packageId);
                                    if (packageIndex || packageIndex === 0) {
                                      const productIndex = packages[packageIndex].products.findIndex(product => product.id === productId);
                                      if (productIndex || productIndex === 0) {
                                        if (noShippedProducts.length != 0){
                                          const notduplicatesproducts = noShippedProducts.map((product)=>{
                                            if (packages[packageIndex].products[productIndex].product_id == product.product_id){
                                              product.available = parseFloat(packages[packageIndex].products[productIndex].quantity) + parseFloat(product.quantity)
                                              return product
                                            }
                                          })
                                          packages[packageIndex].products.splice(productIndex, 1);
                                          this.setState({shipment: {...this.state.shipment, packages}, noShippedProducts:notduplicatesproducts});
                                        }else{
                                          noShippedProducts.push({...packages[packageIndex].products[productIndex], loading: false});
                                          packages[packageIndex].products.splice(productIndex, 1);
                                          this.setState({shipment: {...this.state.shipment, packages}, noShippedProducts});
                                        }
                                      }
                                    }
                                  })
                                  .catch(err => console.error(err));
                              }}
                              initialLoading={this.state.loadingShipment}/>
              </div>
                      

            </Fragment>
          )}

        </div>
      </div>
    );
  }
}

/**
 * <div className="col-12 page__card">
 <Card title="Relación de ventas"
 loading={this.state.loadingShipment}>
 {this.state.loadingShipment || 'Próximamente'}
 </Card>
 </div>
 */

export default protect(observer(Shipment), settings.permissions.packingLists.read);
