
import React from 'react';
import PropTypes from 'prop-types';

const TableBodyRow = ({className, selected, loading, children, onSelect}) => (
  <tr className={`${className || ''} ${loading ? 'table__row--loading' : ''} ${selected ? 'table__row--selected' : ''}`}
      onClick={event => {
        if (onSelect) onSelect();
        event.stopPropagation();
      }}>{children}</tr>
);

TableBodyRow.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node,
  onSelect: PropTypes.func
};

export default TableBodyRow;