
export const mapClientToAPI = client => ({
  name: client.name,
  address: client.address
});

export const mapContactToAPI = contact => ({
  name: contact.name,
  position: contact.position,
  phone: contact.phone,
  email: contact.email
});

const methods = {
  mapClientToAPI,
  mapContactToAPI
};
export default methods;
