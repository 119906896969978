
import { observable, action, decorate, toJS } from 'mobx';

import { mapProductToAPI } from '../../api/quote/mappers';

class OperationsStore {
  operations = [];
  baseProducts = [];

  constructor(baseProducts) {
    this.baseProducts = baseProducts;
  }

  addOperation(id, type, data) {
    this.operations.push({id, type, data});
  }

  getProductChanges(newProduct, oldProduct) {
    const changes = [];
    let changed = false;
    if (newProduct.cost !== oldProduct.cost) {
      changes.push({name: 'cost', value: newProduct.cost});
      changed = true;
    }
    if (newProduct.margin !== oldProduct.margin) {
      changes.push({name: 'margin', value: newProduct.margin});
      changed = true;
    }
    if (newProduct.price !== oldProduct.price) {
      changes.push({name: 'price', value: newProduct.price});
      changed = true;
    }
    if (newProduct.quantity !== oldProduct.quantity) {
      changes.push({name: 'quantity', value: newProduct.quantity});
      changed = true;
    }
    return changed ? changes : null
  }

  calculateOperations(products = []) {
    products.forEach((product, prdIndex) => {
      if (product.id) {
        const baseProduct = this.baseProducts.find(prd => prd.id === product.id);
        const productChanges = this.getProductChanges(product, baseProduct);
        if (productChanges) {
          productChanges.forEach(change => {
            this.addOperation(new Date().getTime(), 'update', {product_id: product.id, ...change, sub_product: false})
          });
        }
        if (product.sub_products) {
          product.sub_products.forEach((subProduct, idx) => {
            const baseSubProduct = baseProduct.sub_products.find(prd => prd.id === subProduct.id);
            if (baseSubProduct) {
              const subProductChanges = this.getProductChanges(subProduct, baseSubProduct);
              if (subProductChanges) {
                subProductChanges.forEach(change => {
                  this.addOperation(new Date().getTime(), 'update', {product_id: subProduct.id, sub_product: true, ...change});
                })
              }
            } else {
              this.addOperation(new Date().getTime(), 'add', {product: mapProductToAPI(subProduct, false), product_id: product.id});
            }
          });
        }
      } else {
        const newOperation = {product: mapProductToAPI(product, false)};
        if (product.sub_products && product.sub_products.length > 0) {
          newOperation.products = product.sub_products.map(subProduct => mapProductToAPI(subProduct, false));
        }
        this.addOperation(new Date().getTime(), 'add', newOperation);
      }
    });
  }

  getJSON() {
    return toJS(this.operations);
  }
}

decorate(
  OperationsStore,
  {
    operations: observable,
    addOperation: action,
    calculateOperations: action,
    getProductChanges: action,
    getJSON: action
  }
);

export default OperationsStore;