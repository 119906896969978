
import React from 'react';
import PropTypes from 'prop-types';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';

import { parseInputValue, parseTextToDate, parseDateToText } from '../../utils';

class CustomDateInput extends React.Component {
  render() {
    const {displayText, value, onClick} = this.props;
    return (
      <input className="input-group__input" value={value} placeholder={displayText} onChange={event => event} onClick={onClick}/>
    );
  }
}

class TextInput extends React.Component {
  state = {
    error: null,
    focus: false,
    modified: false,
    showTooltip: false
  };
  inputReference;

  constructor(props) {
    super(props);

    this.valueChange = this.valueChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onDone = this.onDone.bind(this);
    this.onEnterPressed = this.onEnterPressed.bind(this);
  }

  valueChange(event) {
    const name = event.target.name;
    const value = parseInputValue(event);
    const errorText = this.props.validateFn(value);

    this.setState({error: errorText, modified: true});
    this.props.onChange(value, name, errorText);
  }

  setFocus() {
    this.inputReference.focus();
  }

  onFocus(event) {
    this.setState({focus: true});
  }

  onBlur(event) {
    this.setState({focus: false});
    this.onDone(parseInputValue(event));
  }

  onDone(value) {
    this.props.onDone(this.props.name, value, this.state.modified);
    this.setState({modified: false});
  }

  onEnterPressed(event) {
    const code = event.keyCode || event.which || event.code;
    if (code === 13) {
      this.props.onEnterPressed();
      this.onDone(parseInputValue(event));
    }
  }

  render() {
    const { information, label, type, placeholder, value, disabled, name, flat, inline, tooltipIcon: TooltipIcon, children } = this.props;
    let displayClassNames = 'input-group__display';

    if (information && !this.state.focus) {
      displayClassNames += ' input-group__display--information';
    }
    if (flat) {
      displayClassNames += ' input-group__display--flat';
    }

    if (this.state.error || this.props.error) {
      displayClassNames += ' input-group__display--error';
    } else if (this.state.focus) {
      displayClassNames += ' input-group__display--focus';
    }

    if (this.props.loading) {
      return (
        <div className={`input-group input-group--text ${inline ? 'input-group--inline' : ''}`}>
          <div className={displayClassNames}>
            {label ? (
              <div className="input-group__label">
                <div className="loading-block loading-block--small" style={{width: '25%'}}/>
              </div>
            ) : null}
            <div className="input-group__input">
              <div className="loading-block loading-block--mid"/>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`input-group input-group--text ${inline ? 'input-group--inline' : ''}`}>
        <div className={displayClassNames}>
          <label className="input-group__label"
                 onClick={() => type !== 'date' ? this.setFocus() : null}>{label}</label>
          {type === 'multiline' ? (
            <textarea className="input-group__input"
                      ref={input => this.inputReference = input}
                      name={name}
                      value={value || value === 0 ? value.toString() : ''}
                      placeholder={placeholder}
                      disabled={disabled}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.valueChange}/>
          ) : (type === 'date' ? (
            <DatePicker selected={parseTextToDate(value)}
                        dateFormat="yyyy-MM-dd"
                        disabled={disabled}
                        onChange={date => {
                          const newDate = parseDateToText(date);
                          this.props.onChange(newDate, name, null);
                          this.props.onDone(name, newDate, true);
                        }}
                        onCalendarClose={() => this.setState({focus: false})}
                        onCalendarOpen={() => this.setState({focus: true})}
                        customInput={<CustomDateInput displayText={placeholder}/>}/>
          ) : (
            <input className="input-group__input"
                   ref={input => this.inputReference = input}
                   type={type}
                   name={name}
                   value={value || value === 0 ? value.toString() : ''}
                   placeholder={placeholder}
                   disabled={disabled}
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onChange={this.valueChange}
                   onKeyPress={this.onEnterPressed}/>
          ))}
          {TooltipIcon && children ? <TooltipIcon className="input-group__icon"
                                      onClick={() => this.setState({showTooltip: this.state.showTooltip === 'click' ? false : 'click'})}
                                      onMouseEnter={() => this.state.showTooltip !== 'click' && this.setState({showTooltip: true})}
                                      onMouseLeave={() => this.state.showTooltip !== 'click' && this.setState({showTooltip: false})}/> : null}
        </div>
        {this.state.error || this.props.error ? <div className="input-group__error">{this.state.error || this.props.error}</div> : null}
        {children && this.state.showTooltip ? <div className="input-group__tooltip input-group__tooltip--right">{children}</div> : null}
      </div>
    );
  }
}

TextInput.propTypes = {
  inline: PropTypes.bool.isRequired,
  information: PropTypes.bool.isRequired,
  tooltipIcon: PropTypes.any,
  children: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'number', 'multiline', 'password', 'date']).isRequired,
  flat: PropTypes.bool,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any.isRequired,
  validateFn: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.any,
  name: PropTypes.string,
  loading: PropTypes.bool,
  onDone: PropTypes.func,
  onEnterPressed: PropTypes.func
};

TextInput.defaultProps = {
  inline: false,
  information: false,
  type: 'text',
  flat: false,
  validateFn: () => false, // False means that no error has ocurred
  disabled: false,
  error: null,
  onDone: () => {},
  onEnterPressed: () => {}
};

export default TextInput;
