
export const mapPhoneToAPI = phone => ({
  _type: phone.type,
  phone: phone.phone
});

export const mapContactToAPI = contact => ({
  name: contact.name,
  position: contact.position
});

export const mapSupplierToAPI = supplier => ({
  name: supplier.name,
  address: supplier.address,
  email: supplier.email,
  phones: supplier.phones ? supplier.phones.map(mapPhoneToAPI) : undefined,
  contacts: supplier.contacts ? supplier.contacts.map(mapContactToAPI) : undefined
});

const methods = {
  mapPhoneToAPI,
  mapContactToAPI,
  mapSupplierToAPI
};
export default methods;
