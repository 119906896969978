
import './index.scss';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';
import { ReactComponent as RetryIcon } from '../../assets/images/retry.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';

import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import FileSaver from 'file-saver';

import settings from '../../settings';
import api from '../../api';
import { authStore, utilsStore, OnlineOfferStore } from '../../stores';
import {
  PageHeader,
  SelectInput,
  SelectOption,
  TextInput,
  LabeledInformation,
  Dropdown,
  ProductsListOld,
  LoadingLabel,
  protect,
  LoadingButton,
  EmptyNotification
} from '../../components';
import { Card } from '../../containers';
import { parseDateReadable, validators } from '../../utils';
import ProductsList from '../../components/ProductsList/ProductsList';
import { ReactComponent as DeleteIcon } from '../../assets/images/close.svg';
import { ReactComponent as TransferIcon } from '../../assets/images/transfer.svg';

class Offer extends React.Component {
  state = {
    modifyingField: false,
    errorModifyingField: false,
    successModifyingField: false,
    loadingDownload: false
  };
  source = api.source.createSource();
  offerStore = new OnlineOfferStore();
  MODIFY_NOTIFICATION_DURATION = 3000;
  MODIFY_SUCCESS_MESSAGE = 'Guardado';
  modifyNotificationTimeout;

  constructor(props) {
    super(props);

    this.goRelatedQuote = this.goRelatedQuote.bind(this);
    this.onDownloadExcel = this.onDownloadExcel.bind(this);

    // Form actions
    this.onInputDone = this.onInputDone.bind(this);
  }

  componentDidMount() {
    this.offerStore.fetchOffer(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const oldId = prevProps.match.params.id;
    const newId = this.props.match.params.id;

    if (newId && newId !== oldId) {
      this.offerStore.fetchOffer(newId);
    }
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.modifyNotificationTimeout);
  }

  goRelatedQuote(id) {
    this.props.history.push(`/offers/${id}`);
  }

  // Actions

  onInputDone(fieldName, value, modified) {
    const modifyPromise = this.offerStore.setValueDone(fieldName, value, modified);
    if (modifyPromise) {
      this.setState({modifyingField: fieldName, errorModifyingField: false, successModifyingField: false});
      modifyPromise
        .then(() => {
          if (this.state.modifyingField === fieldName) {
            this.setState({modifyingField: false, errorModifyingField: false, successModifyingField: this.MODIFY_SUCCESS_MESSAGE});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({successModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            console.error('Cannot update quote', err);
            this.setState({modifyingField: false, errorModifyingField: err, successModifyingField: false});
            this.modifyNotificationTimeout = setTimeout(() =>
              this.setState({errorModifyingField: false}), this.MODIFY_NOTIFICATION_DURATION);
          }
        });
    }
  }

  onDownloadExcel() {
    const id = this.props.match.params.id;

    this.setState({loadingDownload: true});
    api.quote.downloadExcel(id, this.source)
      .then(response => {
        const fileName = `Oferta ${id}.xlsx`;
        this.setState({loadingDownload: false});
        FileSaver.saveAs(response.data, fileName);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loadingDownload: false});
        }
      });
  }

  render() {
    if (this.offerStore.fetchState.error && this.offerStore.fetchState.error.message === 'not_found') {
      return <Redirect to="/offers/not-found"/>
    }

    return (
      <div className="offer page">
        <Helmet>
          <title>{this.props.match.params.id} | Metallica Caribbean</title>
          <meta name="description" content={`Vista a detalle de la oferta ${this.props.match.params.id}`} />
          <meta name="keyboards" content="oferta,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          {this.offerStore.fetchState.error ? (
            <div className="col-12">
              <div className="card">
                <EmptyNotification icon={DisconnectIcon}
                                   title={this.offerStore.fetchState.error}
                                   details="Ha ocurrido un error obteniendo la oferta."
                                   action="Regresar"
                                   onAction={() => this.props.history.goBack()}/>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="col-12">
                <div className="page__heading">
                  <PageHeader.Container navigation={[{title: 'Inicio', link: '/'}, {title: 'Ofertas', link: '/offers'}, {title: this.offerStore.offer.id, link: `/offers/${this.offerStore.offer.id}`}]}
                                        title={this.props.match.params.id}
                                        loading={this.offerStore.fetchState.loading}
                                        onBack={() => this.props.history.goBack()}>
                    {this.offerStore.related_quotes && this.offerStore.related_quotes.length > 0 ? (
                      <PageHeader.Types>
                        <Dropdown.Container title={this.offerStore.offer.id}
                                            color="gray"
                                            size="small"
                                            outline={true}
                                            disabled={this.offerStore.related_quotes.length < 1}>
                          {this.offerStore.related_quotes.map((quote, index) => (
                            <Dropdown.Button key={index} onClick={() => this.goRelatedQuote(quote.id)}>{quote.id}</Dropdown.Button>
                          ))}
                        </Dropdown.Container>
                      </PageHeader.Types>
                    ) : null}

                    {this.offerStore.offer.official_id ? (
                      <PageHeader.Filters>
                        <PageHeader.Filter>
                          <button className="btn btn--small btn--outline btn--error" onClick={() => this.goRelatedQuote(this.offerStore.official_id)}>{this.offerStore.official_id}</button>
                        </PageHeader.Filter>
                      </PageHeader.Filters>
                    ) : null}

                    <PageHeader.Actions>

                      {this.state.modifyingField || this.state.errorModifyingField || this.state.successModifyingField ? (
                        <PageHeader.Action>
                          <LoadingLabel loading={!!(this.state.modifyingField)}
                                        error={this.state.errorModifyingField}
                                        success={this.state.successModifyingField}>Guardando cambios</LoadingLabel>
                        </PageHeader.Action>
                      ) : null}

                      <PageHeader.Action>
                        <LoadingButton loadingColor="black"
                                       loaderSize="small"
                                       className="btn--gray btn--icon btn--outline"
                                       loading={this.state.loadingDownload}
                                       onClick={this.onDownloadExcel}><DownloadIcon className="btn__icon"/></LoadingButton>
                      </PageHeader.Action>

                      {authStore.permissions.quotes.create ? (
                        <PageHeader.Action>
                          <button className="btn btn--secondary btn--outline"
                                  onClick={() => this.props.history.push(`/offers/new/from/${this.props.match.params.id}`)}>Nueva revisión</button>
                        </PageHeader.Action>
                      ) : null}
                      {
                        authStore.permissions.invoices.create
                        && this.offerStore.offer.invoice.length > 0
                        && (
                          this.offerStore.offer.invoice.length > 1
                            ? (
                              <PageHeader.Action>
                                <Dropdown.Container
                                  title="Facturas"
                                  color="success"
                                >
                                  {
                                    this.offerStore.offer.invoice.map((invoice) => (
                                      <Dropdown.Button
                                        key={invoice}
                                        onClick={() => this.props.history.push(`/invoices/${invoice}`)}
                                      >
                                        {invoice}
                                      </Dropdown.Button>
                                    ))
                                  }
                                </Dropdown.Container>
                              </PageHeader.Action>
                            )
                            : (
                              <PageHeader.Action>
                                <button
                                  className="btn btn--success"
                                  onClick={() => this.props.history.push(`/invoices/${this.offerStore.offer.invoice[0]}`)}
                                >
                                  {this.offerStore.offer.invoice[0]}
                                </button>
                              </PageHeader.Action>
                            )
                        )
                      }
                      <PageHeader.Action>
                        <button
                          className="btn btn--primary"
                          onClick={() => this.props.history.push(`/offers/${this.props.match.params.id}/invoices/new`)}
                        >
                          Crear factura
                        </button>
                      </PageHeader.Action>
                    </PageHeader.Actions>

                  </PageHeader.Container>
                </div>
              </div>

              <div className="col-8">
                <div className="row row--align-top">
                  <div className="col-12">
                    <Card loading={this.offerStore.fetchState.loading}>
                      <Card.Title>Información general</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="page__input col-6">
                            <LabeledInformation label="Cliente"
                                                value={this.offerStore.offer.client}
                                                link={`/admin/clients/${this.offerStore.offer.client_id}`}
                                                loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <LabeledInformation label="Fecha de modificación"
                                                value={parseDateReadable(this.offerStore.offer.creation_date)}
                                                loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Referencia"
                                       name="reference"
                                       value={this.offerStore.offer.reference}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.offerStore.fetchState.loading}>
                      <Card.Title>Solicitud</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="page__input col-12">
                            <TextInput label="Pedido"
                                       name="order"
                                       value={this.offerStore.offer.order}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Modelo"
                                       name="model"
                                       value={this.offerStore.offer.model}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Número de serie"
                                       name="serial_number"
                                       value={this.offerStore.offer.serial_number}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.offerStore.fetchState.loading}>
                      <Card.Title>Pago</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="page__input col-4">
                            <LabeledInformation label="Moneda"
                                                value={this.offerStore.offer.currency}
                                                loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-4">
                            <LabeledInformation label="Condición"
                                                value={this.offerStore.offer.incoterm}
                                                loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-4">
                            <LabeledInformation label="Condición Año"
                                                value={this.offerStore.offer.incoterm_year}
                                                loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-12">
                            <SelectInput label="Forma de pago"
                                         name="payment_method"
                                         value={this.offerStore.offer.payment_method}
                                         onChange={this.offerStore.setValue}
                                         onDone={this.onInputDone}
                                         information={true}
                                         disabled={!authStore.permissions.quotes.edit}
                                         loading={this.offerStore.fetchState.loading}>
                              {settings.PAYMENT_METHODS.map((method, index) => <SelectOption value={method} key={index}>{method}</SelectOption>)}
                              <SelectOption type="text">OTRO</SelectOption>
                            </SelectInput>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card loading={this.offerStore.fetchState.loading}>
                      <Card.Title>Entrega</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="page__input col-6">
                            <SelectInput label="Punto de embarque"
                                         name="boarding_place"
                                         value={this.offerStore.offer.boarding_place}
                                         onChange={this.offerStore.setValue}
                                         onDone={this.onInputDone}
                                         information={true}
                                         disabled={!authStore.permissions.quotes.edit}
                                         loading={this.offerStore.fetchState.loading}>
                              {settings.boardingPlaces.map(place => <SelectOption key={place}
                                                                                  value={place}>{place}</SelectOption>)}
                              <SelectOption type="text">OTRO</SelectOption>
                            </SelectInput>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Tiempo de entrega"
                                       name="delivery_time"
                                       value={this.offerStore.offer.delivery_time}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}
                                       validateFn={validators.validateInputNotEmpty}
                                       error={this.offerStore.errors.delivery_time}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Validez de la oferta"
                                       name="duration"
                                       value={this.offerStore.offer.duration}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}
                                       validateFn={validators.validateInputNotEmpty}
                                       error={this.offerStore.errors.duration}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Garantía"
                                       name="warranty"
                                       value={this.offerStore.offer.warranty}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}
                                       validateFn={validators.validateInputNotEmpty}
                                       error={this.offerStore.errors.warranty}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                </div>
              </div>
              <div className="col-4">
                <div className="row row--align-top">

                  <div className="col-12 page__card">
                    <Card loading={this.offerStore.fetchState.loading}>
                      <Card.Title>Costos</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="page__input col-6">
                            <TextInput type="number"
                                       label="Flete"
                                       name="freight"
                                       value={this.offerStore.offer.freight}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput type="number"
                                       label="Seguro"
                                       name="insurance"
                                       value={this.offerStore.offer.insurance}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput type="number"
                                       label={`Costo ${this.offerStore.offer.incoterm}`}
                                       name="incoterm_price"
                                       value={this.offerStore.offer.incoterm_price}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput type="number"
                                       label="Inspección en origen"
                                       name="origin_inspection"
                                       value={this.offerStore.offer.origin_inspection}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput type="number"
                                       label="Descuento (%)"
                                       name="discount"
                                       value={this.offerStore.offer.discount}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-12 page__card">
                    <Card loading={this.offerStore.fetchState.loading}>
                      <Card.Title>Financiamiento</Card.Title>
                      <Card.Body>
                        <div className="row row--align-top">
                          <div className="page__input col-6">
                            <TextInput label="Período"
                                       name="financing_period"
                                       value={this.offerStore.offer.financing_period}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                          <div className="page__input col-6">
                            <TextInput label="Porciento (%)"
                                       name="financing_percentage"
                                       value={this.offerStore.offer.financing_percentage}
                                       onChange={this.offerStore.setValue}
                                       onDone={this.onInputDone}
                                       information={true}
                                       disabled={!authStore.permissions.quotes.edit}
                                       loading={this.offerStore.fetchState.loading}/>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                </div>
              </div>

              <div className="col-12 page__card">
                <Card loading={this.offerStore.fetchState.loading}>
                  <Card.Title col="8">Productos</Card.Title>
                  <Card.Actions col="4">
                    {
                      this.offerStore.productsStore.containsSelectedProducts > 0 && (
                        <Card.Action>
                          <LoadingButton loadingColor="black"
                                         className="btn--outline btn--gray btn--small btn--icon"
                                         loading={this.offerStore.productsStore.deleteSelectedProductsState.loading}
                                         loaderSize="small"
                                         onClick={this.offerStore.deleteSelectedProducts}>
                            Eliminar productos
                          </LoadingButton>
                        </Card.Action>
                      )
                    }
                    {
                      this.offerStore.offer.old && (
                        <Card.Action>
                          <LoadingButton loadingColor="black"
                                         className="btn--outline btn--gray btn--small btn--icon"
                                         loading={this.offerStore.productsStore.reindexState.loading}
                                         loaderSize="small"
                                         onClick={this.offerStore.reindexProducts}>Re-indexar</LoadingButton>
                        </Card.Action>
                      )
                    }
                    <Card.Action>
                      <LoadingButton loadingColor="black"
                                     className="btn--outline btn--gray btn--small btn--icon"
                                     loading={this.offerStore.fetchProductsState.loading}
                                     loaderSize="small"
                                     onClick={this.offerStore.refreshProducts}><RetryIcon className="btn__icon"/></LoadingButton>
                    </Card.Action>
                  </Card.Actions>
                  <Card.Body>
                    <ProductsListOld
                      allProductsSelected={this.offerStore.productsStore.allProductsSelected}
                      itemNumberVisible={true}
                      transferVisible={true}
                      invoiceVisible={true}
                      disabled={!authStore.permissions.quotes.edit}
                      costVisible={!this.offerStore.offer.old}
                      loading={
                        this.offerStore.fetchState.loading ||
                        this.offerStore.fetchProductsState.loading ||
                        this.offerStore.productsStore.reindexState.loading
                      }
                      eligibleProducts={utilsStore.products}
                      products={this.offerStore.products}
                      invoices={this.offerStore.offer.invoice}
                      margin={this.offerStore.offer.margin}
                      totalProducts={this.offerStore.productsTotal}
                      totalExtras={this.offerStore.extras}
                      discounted={this.offerStore.discounted}
                      total={this.offerStore.total}
                      productChanged={this.offerStore.setProductValue}
                      productChangeDone={this.offerStore.productsStore.productChanged}
                      addProduct={this.offerStore.addProduct}
                      deleteProduct={this.offerStore.deleteProduct}
                      retry={this.offerStore.productsStore.retryOperation}
                      toggleProductsSelection={this.offerStore.toggleProductsSelection}
                      clientId={this.offerStore.offer.client_id}
                      transferProduct={this.offerStore.productsStore.transferProduct}
                      moveProduct={this.offerStore.moveProduct}
                    />
                  </Card.Body>
                </Card>
              </div>

              <div className="col-12 page__card">
                <Card loading={this.offerStore.fetchState.loading}>
                  <Card.Title col="8">Productos Opcionales</Card.Title>
                  <Card.Actions col="4">
                    {
                      this.offerStore.containsSelectedOptionalProducts > 0 && (
                        <Card.Action>
                          <LoadingButton loadingColor="black"
                                         className="btn--outline btn--gray btn--small btn--icon"
                                         loading={this.offerStore.deleteSelectedOptionalProductsState.loading}
                                         loaderSize="small"
                                         onClick={this.offerStore.deleteSelectedOptionalProducts}>
                            Eliminar productos
                          </LoadingButton>
                        </Card.Action>
                      )
                    }
                    <Card.Action>
                      <LoadingButton loadingColor="black"
                                     className="btn--outline btn--gray btn--small btn--icon"
                                     loading={this.offerStore.fetchOptionalProductsState.loading}
                                     loaderSize="small"
                                     onClick={this.offerStore.refreshOptionalProducts}><RetryIcon className="btn__icon"/></LoadingButton>
                    </Card.Action>
                  </Card.Actions>
                  <Card.Body>
                    <ProductsListOld isOptional
                                     allProductsSelected={this.offerStore.optionalProductsStore.allProductsSelected}
                                     allowSubItem={false}
                                     summaryVisible={false}
                                     transferVisible={true}
                                     itemNumberVisible={false}
                                     invoiceVisible={true}
                                     disabled={!authStore.permissions.quotes.edit}
                                     costVisible={!this.offerStore.offer.old}
                                     loading={
                                       this.offerStore.fetchState.loading ||
                                       this.offerStore.fetchOptionalProductsState.loading
                                     }
                                     eligibleProducts={utilsStore.products}
                                     products={this.offerStore.optionalProducts}
                                     margin={this.offerStore.offer.margin}

                                     productChanged={this.offerStore.setOptionalProductValue}
                                     productChangeDone={this.offerStore.optionalProductsStore.productChanged}
                                     addProduct={this.offerStore.addOptionalProduct}
                                     deleteProduct={this.offerStore.deleteOptionalProduct}
                                     transferProduct={this.offerStore.transferOptionalProduct}
                                     retry={this.offerStore.optionalProductsStore.retryOperation}
                                     toggleProductsSelection={this.offerStore.toggleOptionalProductsSelection}
                                     clientId={this.offerStore.offer.client_id}
                                     
                                     />
                  </Card.Body>
                </Card>
              </div>
            </Fragment>
          )}
        </div>

      </div>
    );
  }
}

/*
{authStore.permissions.invoices.read ? (
                  <div className="col-12">
                    <div className="offer__block card">
                      <h2>Factura</h2>
                      Proximamente
                    </div>
                  </div>
                ) : null}
 */

export default protect(observer(Offer), settings.permissions.quotes.read);
