
import React from 'react';
import PropTypes from 'prop-types';

import TableBodyRow from './BodyRow';
import TableBodyCell from './BodyCell';

const LoadingRow = ({loadingCells}) => (
  <TableBodyRow loading={true}>
    {[...Array(loadingCells).keys()].map((_, index) => <TableBodyCell key={index} loading={true}><div className="loading-block"/></TableBodyCell>)}
  </TableBodyRow>
);

LoadingRow.propTypes = {
  loadingCells: PropTypes.number.isRequired
};

export default LoadingRow;