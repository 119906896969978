
import './base';

import source from './source';
import supplier from './supplier';
import client from './client';
import product from './product';
import quote from './quote';
import invoice from './invoice';
import shipment from './shipment';
import auth from './auth';
import roles from './roles';
import user from './user';

import dashboard from './dashboard';

const methods = {
    source,
    supplier,
    client,
    product,
    quote,
    invoice,
    shipment,
    auth,
    roles,
    user,
    dashboard
};
export default methods;
