
import axios from 'axios';

import settings from '../settings';

/**
 * Configure axios with base url
 */
let baseUrl = settings.baseUrl.local;

if (process.env.REACT_APP_ENV === 'prod') {
    baseUrl = settings.baseUrl.production;
} else if (process.env.REACT_APP_ENV === 'dev') {
    baseUrl = settings.baseUrl.development;
}

axios.defaults.baseURL = baseUrl;
axios.defaults.responseType = 'json';

/**
 * Add token to every request
 */
axios.interceptors.request.use(
    config => {
      if (config.headers.Authorization !== false) {
        config.headers.Authorization =  `Bearer ${localStorage.getItem('token')}`;
      }
      return config;
    }
);

/**
 * Handle responses and errors
 */
axios.interceptors.response.use(
  response => {
    if (response.config.responseType === 'json') {
      response.data = response.data ? response.data.data : null;
    }
    return response;
  },
  error => {
    if (error.config.headers.Authorization !== false &&
      error.response &&
      error.response.status &&
      (error.response.status === 401 || error.response.status === 422)) {
      window.location.href = settings.unauthorizedUrl;
      return;
    }
    return Promise.reject(error.response && error.response.data ? new Error(error.response.data.error) : error);
  }
);


export default baseUrl;