
import React from 'react';
import PropTypes from 'prop-types';

import { SingleActionCard } from '../containers';
import { ReactComponent as DeleteIcon } from '../assets/images/close.svg';
import { SelectInput, SelectOption, TextInput } from './index';

const Phone = ({loading, disabled, type, phone, phoneChange, phoneChanged, deletePressed}) => (
  <SingleActionCard icon={DeleteIcon}
                    loading={loading}
                    disabled={disabled}
                    actionEvent={() => deletePressed()}>
    <div className="row row--align-top">
      <div className="col-6 page__input">
        <SelectInput label="Nombre"
                     loading={loading === 'initial'}
                     information={true}
                     name="type"
                     value={type}
                     disabled={loading || disabled}
                     onChange={phoneChange}
                     onDone={(name, value, modified) => modified && phoneChanged && phoneChanged(name, value)}>
          <SelectOption value="mobile">Móvil</SelectOption>
          <SelectOption value="phone">Teléfono</SelectOption>
          <SelectOption value="fax">Fax</SelectOption>
        </SelectInput>
      </div>
      <div className="col-6 page__input">
        <TextInput label="Número de teléfono"
                   loading={loading === 'initial'}
                   information={true}
                   name="phone"
                   value={phone}
                   disabled={loading || disabled}
                   onChange={phoneChange}
                   onDone={(name, value, modified) => modified && phoneChanged && phoneChanged(name, value)}/>
      </div>
    </div>
  </SingleActionCard>
);

Phone.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  type: PropTypes.string,
  phone: PropTypes.string,
  phoneChange: PropTypes.func.isRequired,
  phoneChanged: PropTypes.func,
  deletePressed: PropTypes.func.isRequired
};

export default Phone;