
import { observable, action, decorate, computed } from 'mobx';

import { parseNumericValueIn } from '../../utils';

class OfferStore {
  newOffer = true;
  modifiedOffer = false;
  oldQuote = false;

  id = null;
  invoice_id = null;
  official_id = null;

  client_id = null;
  duration = '60 DÍAS';
  order = null;
  model = null;
  serial_number = null;
  currency = null;
  incoterm = null;
  payment_method = null;
  boarding_place = null;
  delivery_time = null;
  reference = null;
  warranty = '12 MESES';
  financing_period = null;
  financing_percentage = null;
  freight = '0';
  insurance = '0';
  incoterm_price = '0';
  origin_inspection = '0';
  margin = '0';
  discount = '0';

  client = null;
  date = null;
  related_quotes = [];

  offerTotalProducts = '0.00';
  offerTotalExtras = '0.00';
  discounted = '0.00';
  total = '0.00';

  autosave = false;
  AUTOSAVE_PREFIX = 'autosave/offers/new/';

  errors = {};

  get totalExtras() {
    if (this.newOffer || this.modifiedOffer) {
      return (Number(this.freight) || 0) +
        (Number(this.insurance) || 0) +
        (Number(this.origin_inspection) || 0) +
        (Number(this.incoterm_price) || 0);
    } else {
      return Number(this.offerTotalExtras) || 0;
    }
  }

  configure(offer, newOffer = false, autosave = false) {
    if (offer) {
      this.newOffer = newOffer;
      this.modifiedOffer = false;
      this.autosave = autosave;

      this.client_id = offer.client_id;
      this.client = offer.client;
      this.model = offer.model;
      this.serial_number = offer.serial_number;
      this.currency = offer.currency;
      this.incoterm = offer.incoterm;
      this.boarding_place = offer.boarding_place;
      this.warranty = offer.warranty;
      this.payment_method = offer.payment_method;
      this.delivery_time = offer.delivery_time;
      this.freight = parseNumericValueIn(offer.freight);
      this.insurance = parseNumericValueIn(offer.insurance);
      this.origin_inspection = parseNumericValueIn(offer.origin_inspection);
      this.financing_period = offer.financing_period;
      this.financing_percentage = parseNumericValueIn(offer.financing_percentage);
      this.incoterm_price = parseNumericValueIn(offer.incoterm_price);
      this.duration = offer.duration;
      this.order = offer.order;
      this.reference = offer.reference;
      this.related_quotes = offer.related_quotes;
      this.date = offer.date;
      this.discount = parseNumericValueIn(offer.discount);
      this.official_id = offer.official_id;
      this.invoice_id = offer.invoice;
      this.oldQuote = offer.old;

      this.offerTotalProducts = offer.total_product;
      this.offerTotalExtras = offer.total_extras;
      this.discounted = offer.discounted;
      this.total = offer.total;

      this.id = offer.id;
    }
  }

  setValue(name, value, error) {
    this[name] = value;
    this.errors[name] = error;
    if (this.autosave) {
      this.autosaveValue(name, value);
    }
    this.modifiedOffer = true;
  }

  getQuoteNumber() {
    const match = this.id && this.id.toString().match(/^MCSA-(\d+)/);
    return match.length > 1 ? Number(match[1]) : null;
  }

  autosaveValue(name, value) {
    localStorage.setItem(`${this.AUTOSAVE_PREFIX}${name}`, value);
  }

  recoverFromAutosave() {
    this.newOffer = true;

    this.recoverValueFromAutosave('client_id');
    this.recoverValueFromAutosave('client');
    this.recoverValueFromAutosave('model');
    this.recoverValueFromAutosave('serial_number');
    this.recoverValueFromAutosave('currency');
    this.recoverValueFromAutosave('incoterm');
    this.recoverValueFromAutosave('boarding_place');
    this.recoverValueFromAutosave('warranty');
    this.recoverValueFromAutosave('payment_method');
    this.recoverValueFromAutosave('delivery_time');
    this.recoverValueFromAutosave('freight');
    this.recoverValueFromAutosave('insurance');
    this.recoverValueFromAutosave('origin_inspection');
    this.recoverValueFromAutosave('financing_period');
    this.recoverValueFromAutosave('financing_percentage');
    this.recoverValueFromAutosave('incoterm_price');
    this.recoverValueFromAutosave('duration');
    this.recoverValueFromAutosave('order');
    this.recoverValueFromAutosave('reference');
    this.recoverValueFromAutosave('date');
    this.recoverValueFromAutosave('discount');
  }

  recoverValueFromAutosave(name) {
    const recoveredValue = localStorage.getItem(`${this.AUTOSAVE_PREFIX}${name}`);
    if (recoveredValue) {
      this[name] = recoveredValue;
    }
  }

  getCreationJSON() {
    const quote = {
      client_id: this.client_id,
      model: this.model,
      serial_number: this.serial_number,
      currency: this.currency,
      incoterm: this.incoterm,
      boarding_place: this.boarding_place,
      warranty: this.warranty,
      payment_method: this.payment_method,
      delivery_time: this.delivery_time,
      freight: this.freight,
      insurance: this.insurance,
      discount: this.discount,
      origin_inspection: this.origin_inspection,
      financing_period: this.financing_period,
      financing_percentage: this.financing_percentage,
      incoterm_price: this.incoterm_price,
      duration: this.duration,
      order: this.order,
      reference: this.reference
    };

    if (this.id) {
      quote.quote_number = this.getQuoteNumber();
    }
    return quote;
  }
}

decorate(
  OfferStore,
  {
    newOffer: observable,
    modifiedOffer: observable,
    oldQuote: observable,
    autosave: observable,

    id: observable,
    official_id: observable,
    invoice_id: observable,

    client_id: observable,
    duration: observable,
    order: observable,
    model: observable,
    serial_number: observable,
    currency: observable,
    incoterm: observable,
    payment_method: observable,
    boarding_place: observable,
    delivery_time: observable,
    reference: observable,
    warranty: observable,
    financing_period: observable,
    financing_percentage: observable,
    products: observable,
    freight: observable,
    insurance: observable,
    incoterm_price: observable,
    origin_inspection: observable,
    margin: observable,
    discount: observable,

    client: observable,
    date: observable,
    related_quotes: observable,

    offerTotalProducts: observable,
    offerTotalExtras: observable,
    discounted: observable,
    total: observable,

    errors: observable,
    formIsValid: computed,

    totalExtras: computed,

    configure: action,
    setValue: action,
    getQuoteNumber: action,
    recoverFromAutosave: action,
    recoverValueFromAutosave: action
  }
);

export default OfferStore;