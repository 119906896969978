
import React from 'react';
import { Helmet } from 'react-helmet';
import {observer} from 'mobx-react';

import { validateInputNotEmpty } from '../utils';
import { Subtitle, LoadingButton, TextInput, PageHeader, protect } from '../components';
import api from '../api';
import settings from '../settings';
import { NewContact, Contact } from '../components/clients';
import { Card } from '../containers';

class ClientForm extends React.Component {
  state = {
    name: null,
    address: null,
    contacts: [],
    errors: {
      name: false,
      address: false
    },
    createLoading: false,
    createError: false,
    createSuccess: false
  };
  source = api.source.createSource();
  notificationTimeout = null;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Clientes', link: '/admin/clients'},
    {title: 'Nuevo cliente', link: '/admin/clients/new'}
  ];

  constructor(props) {
    super(props);

    this.inputChange = this.inputChange.bind(this);
    this.createClient = this.createClient.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  inputChange(value, name, error) {
    this.setState({[name]: value, errors: {...this.state.errors, [name]: error}});
  }

  setNotificationTimeout(success = false) {
    this.notificationTimeout = setTimeout(
      () => {
        if (success) {
          this.props.history.goBack();
        } else {
          this.setState({createError: false});
        }
      },
      settings.notificationTimeout
    );
  }

  createClient() {
    const { name, address, contacts } = this.state;
    this.setState({createLoading: true});
    api.client.createClient(
      {
        name,
        address,
        contacts
      },
      this.source
    )
      .then(data => {
        this.setState({ createLoading: false, createSuccess: true });
        this.setNotificationTimeout(true);
      })
      .catch(err => {
        if (!(api.source.errorIsCancel(err))) {
          this.setState({ createLoading: false, createError: 'Error inesperado' });
          this.setNotificationTimeout();
        }
      });
  }

  render() {
    return (
      <div className="client-form page page--form">

        <Helmet>
          <title>Nuevo cliente | Metallica Caribbean</title>
          <meta name="description" content="Formulario de creación de nuevo cliente" />
          <meta name="keyboards" content="cliente,metallica,crear,nuevo" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title="Nuevo cliente"
                                    onBack={() => this.props.history.goBack()}>
              </PageHeader.Container>
            </div>
          </div>

          <div className="col-12 page__card">
            <Card title="Información general">
              <div className="row row--align-top">
                <div className="col-12 page__input">
                  <TextInput label="Nombre"
                             name="name"
                             value={this.state.name}
                             onChange={this.inputChange}
                             validateFn={validateInputNotEmpty}
                             disabled={this.state.createLoading}
                             error={this.state.errors.name} />
                </div>
                <div className="col-12 page__input">
                  <TextInput label="Dirección"
                             name="address"
                             value={this.state.address}
                             onChange={this.inputChange}
                             validateFn={validateInputNotEmpty}
                             disabled={this.state.createLoading}
                             error={this.state.errors.address}/>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-12"><Subtitle title="Personas de contacto"/></div>
          {this.state.contacts.map((contact, index) => (
            <div className="col-12 page__card" key={index}>
              <Contact name={contact.name}
                       position={contact.position}
                       phone={contact.phone}
                       email={contact.email}
                       change={(value, name, error) => {
                         const contacts = [...this.state.contacts];
                         contacts[index][name] = value;
                         this.setState({contacts});
                       }}
                       deletePressed={() => {
                         const contacts = [...this.state.contacts];
                         contacts.splice(index, 1);
                         this.setState({contacts});
                       }}/>
            </div>
          ))}
          <div className="col-12 page__card">
            <NewContact add={contact => this.setState({contacts: [...this.state.contacts, contact]})}/>
          </div>

          <div className="col-12">
            <div className="page__submit">
              <LoadingButton className="btn--primary btn--large"
                             loading={this.state.createLoading}
                             loadingColor="black"
                             onClick={this.createClient}
                             error={this.state.createError}
                             success={this.state.createSuccess ? 'Cliente creado' : null}>Crear cliente</LoadingButton>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default protect(observer(ClientForm), settings.permissions.admin);
