
import { observable, action, decorate, computed, toJS, runInAction } from 'mobx';

import api from '../../api';
import LocalProductsStore from './LocalList';
import { validators, parseNumericValueIn } from '../../utils';

class LocalOfferStore {
  AUTOSAVE_PREFIX = 'autosave/offers/new/';
  autosaveEnabled = true;

  offer = {
    client_id: null,
    reference: null,

    order: null,
    model: null,
    serial_number: null,

    currency: null,
    incoterm: null,
    payment_method: null,

    boarding_place: null,
    delivery_time: null,
    duration: '60 DÍAS',
    warranty: '12 MESES',

    financing_period: null,
    financing_percentage: null,

    freight: null,
    insurance: null,
    incoterm_price: null,
    origin_inspection: null,
    margin: null,
    discount: null,
  };
  errors = {
    client_id: false,
    currency: false,
    incoterm: false,
    payment_method: false,
    boarding_place: false,
    delivery_time: false,
    duration: false,
    warranty: false
  };

  productsStore = new LocalProductsStore([], true);
  optionalProductsStore = new LocalProductsStore([], true, 'autosave/offers/new/optional_products');

  source = api.source.createSource();
  createState = {
    loading: false,
    error: null,
    success: false
  };

  get products() {
    return this.productsStore.products || [];
  }

  get optionalProducts() {
    return this.optionalProductsStore.products || [];
  }

  get productsTotal() {
    return this.productsStore.total;
  }

  get extras() {
    return (Number(this.offer.freight) || 0) +
      (Number(this.offer.insurance) || 0) +
      (Number(this.offer.origin_inspection) || 0) +
      (Number(this.offer.incoterm_price) || 0);
  }

  get discounted() {
    return (this.productsTotal + this.extras) * (Number(this.offer.discount) || 0) / 100;
  }

  get total() {
    return this.productsTotal + this.extras - this.discounted;
  }

  get requiredFieldsFilled() {
    return this.offer.client_id &&
      this.offer.currency &&
      this.offer.incoterm &&
      this.offer.payment_method &&
      this.offer.boarding_place &&
      this.offer.warranty &&
      this.offer.delivery_time &&
      this.offer.duration;
  }

  get formContainsErrors() {
    return this.errors.client_id &&
      this.errors.currency &&
      this.errors.incoterm &&
      this.errors.payment_method &&
      this.errors.boarding_place &&
      this.errors.warranty &&
      this.errors.delivery_time &&
      this.errors.duration;
  }

  get formIsValid() {
    return this.requiredFieldsFilled && !this.formContainsErrors;
  }

  get JSON() {
    const offerData = toJS(this.offer);
    delete offerData.margin;
    return {
      ...offerData,
      products: this.productsStore.JSON,
      optional_products: this.optionalProductsStore.JSON,
    };
  }

  constructor(autosaveEnabled = true) {
    this.autosaveEnabled = autosaveEnabled;
  }

  setOffer(offer) {
    this.offer = {
      client_id: offer.client_id,
      reference: offer.reference,
      quote_number: this.getQuoteNumber(offer.id),

      order: offer.order,
      model: offer.model,
      serial_number: offer.serial_number,

      currency: offer.currency,
      incoterm: offer.incoterm,
      payment_method: offer.payment_method,

      boarding_place: offer.boarding_place,
      delivery_time: offer.delivery_time,
      duration: offer.duration,
      warranty: offer.warranty,

      financing_period: offer.financing_period,
      financing_percentage: parseNumericValueIn(offer.financing_percentage),

      freight: parseNumericValueIn(offer.freight),
      insurance: parseNumericValueIn(offer.insurance),
      incoterm_price: parseNumericValueIn(offer.incoterm_price),
      origin_inspection: parseNumericValueIn(offer.origin_inspection),
      margin: '0.00',
      discount: parseNumericValueIn(offer.discount),
      incoterm_year: offer.incoterm_year
    };
    this.productsStore.configure(offer.products, true);
    this.optionalProductsStore.configure(offer.optional_products, true);
  }

  setValue(value, name, error) {
    this.offer[name] = value;

    switch(name) {
      case 'client_id':
      case 'currency':
      case 'incoterm':
      case 'payment_method':
      case 'boarding_place':
      case 'delivery_time':
      case 'duration':
      case 'warranty':
        this.setError(name, error);
        break;
      case 'margin':
        this.productsStore.updateMargin(value);
        this.optionalProductsStore.updateMargin(value);
        this.setError(name, error);
        break;
      default: break;
    }

    if (this.autosaveEnabled) {
      this.autosaveValue(name, value);
    }
  }

  setError(name, error) {
    this.errors[name] = error;
  }

  validate() {
    this.errors = {
      client_id: validators.validateSelectInputNotEmpty(this.offer.client_id),
      currency: validators.validateSelectInputNotEmpty(this.offer.currency),
      incoterm: validators.validateSelectInputNotEmpty(this.offer.incoterm),
      payment_method: validators.validateSelectInputNotEmpty(this.offer.payment_method),
      boarding_place: validators.validateSelectInputNotEmpty(this.offer.boarding_place),
      delivery_time: validators.validateInputNotEmpty(this.offer.delivery_time),
      duration: validators.validateInputNotEmpty(this.offer.duration),
      warranty: validators.validateInputNotEmpty(this.offer.warranty),
    };
  }

  setProductValue(name, value, index, subIndex) {
    this.productsStore.setProductValue(name, value, index, subIndex);
  }

  moveProduct(index, newIndex){
    this.productsStore.moveProducts(index,newIndex)
  }

  moveOptionalProduct(index, newIndex){
    this.optionalProductsStore.moveProducts(index, newIndex)
  }

  addProduct(product, index) {
    this.productsStore.addProduct(product, index);
  }

  deleteProduct(index, subIndex) {
    this.productsStore.deleteProduct(index, subIndex);
  }

  transferProduct(index) {
    const product = this.optionalProducts[index];
    this.productsStore.addProduct(product);
    this.optionalProductsStore.deleteProduct(index);
  }

  setOptionalProductValue(name, value, index, subIndex) {
    this.optionalProductsStore.setProductValue(name, value, index, subIndex);
  }

  addOptionalProduct(product, index) {
    this.optionalProductsStore.addProduct(product, index);
  }

  deleteOptionalProduct(index, subIndex) {
    this.optionalProductsStore.deleteProduct(index, subIndex);
  }

  create() {
    this.createState = {loading: true, error: null, success: null};
    return api.quote.createQuote(this.JSON, this.source)
      .then(
        action(
          'createSuccess',
            response => {
              this.createState = {loading: false, error: null, success: true};
              console.log('Offer id received', response.data);
              return response?.data;
            }
          )
      )
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Ocurred an error saving quote', err);
          runInAction(() => this.createState = {loading: false, error: err, success: null});
        }
      });
  }

  getQuoteNumber(id) {
    const match = id && id.toString().match(/^MCSA-(\d+)/);
    return match.length > 1 ? Number(match[1]) : null;
  }

  autosaveValue(name, value) {
    localStorage.setItem(`${this.AUTOSAVE_PREFIX}${name}`, value);
  }

  recoverFromAutosave() {
    this.recoverValueFromAutosave('client_id');
    this.recoverValueFromAutosave('client');
    this.recoverValueFromAutosave('model');
    this.recoverValueFromAutosave('serial_number');
    this.recoverValueFromAutosave('currency');
    this.recoverValueFromAutosave('incoterm');
    this.recoverValueFromAutosave('boarding_place');
    this.recoverValueFromAutosave('warranty');
    this.recoverValueFromAutosave('payment_method');
    this.recoverValueFromAutosave('delivery_time');
    this.recoverValueFromAutosave('freight');
    this.recoverValueFromAutosave('insurance');
    this.recoverValueFromAutosave('origin_inspection');
    this.recoverValueFromAutosave('financing_period');
    this.recoverValueFromAutosave('financing_percentage');
    this.recoverValueFromAutosave('incoterm_price');
    this.recoverValueFromAutosave('duration');
    this.recoverValueFromAutosave('order');
    this.recoverValueFromAutosave('reference');
    this.recoverValueFromAutosave('date');
    this.recoverValueFromAutosave('discount');
    this.productsStore.recoverFromAutosave();
    this.optionalProductsStore.recoverFromAutosave();
  }

  recoverValueFromAutosave(name) {
    const recoveredValue = localStorage.getItem(`${this.AUTOSAVE_PREFIX}${name}`);
    if (recoveredValue) {
      this.offer[name] = recoveredValue;
    }
  }
}

decorate(
  LocalOfferStore,
  {
    autosaveEnabled: observable,

    offer: observable,
    errors: observable,

    createState: observable,

    productsTotal: computed,
    extras: computed,
    discounted: computed,
    total: computed,
    products: computed,
    optionalProducts: computed,
    JSON: computed,

    requiredFieldsFilled: computed,
    formContainsErrors: computed,
    formIsValid: computed,

    setOffer: action,
    setValue: action.bound,
    setError: action,
    validate: action,
    create: action.bound,

    addProduct: action.bound,
    moveProduct: action.bound,
    moveOptionalProduct: action.bound,
    setProductValue: action.bound,
    deleteProduct: action.bound,
    addOptionalProduct: action.bound,
    setOptionalProductValue: action.bound,
    deleteOptionalProduct: action.bound,

    transferProduct: action.bound,

    getQuoteNumber: action,
    autosaveValue: action,
    recoverFromAutosave: action,
    recoverValueFromAutosave: action
  }
);

export default LocalOfferStore;
