
import './index.scss';
import logo from '../../assets/images/logo.png';
import { ReactComponent as userIcon } from '../../assets/images/profile.svg';
import { ReactComponent as addIcon } from '../../assets/images/add.svg';

import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import authStore from '../../stores/auth';
import Menu from '../Menu';
import Dropdown from '../Dropdown';

class Header extends React.Component {
  render() {
    const showNewActions = authStore.permissions.quotes.create || authStore.permissions.invoices.create || authStore.permissions.packingLists.create;
    return (
      <header className="header">
        <Link className="header__logo" to="/"><img src={logo} alt="Logo" /></Link>
        <div className="header__menu"><Menu/></div>
        {showNewActions ? (
          <div className="header__actions">
            <Dropdown.Container buttonKey="new-action"
                                size="nav"
                                icon={addIcon}>
              {authStore.permissions.quotes.create ? (<Dropdown.Link to="/offers/new">Nueva Oferta</Dropdown.Link>) : null}
              {authStore.permissions.invoices.create ? (<Dropdown.Link to="/invoices/new">Nueva Factura</Dropdown.Link>) : null}
              {authStore.permissions.packingLists.create ? (<Dropdown.Link to="/shipments/new">Nuevo embarque</Dropdown.Link>) : null}
            </Dropdown.Container>
          </div>
        ) : null}
        <div className="header__profile">
          <Dropdown.Container buttonKey="profile-options"
                              size="nav"
                              icon={userIcon}
                              title={authStore.displayName}>
            <Dropdown.Link to="/change-password">Cambiar contraseña</Dropdown.Link>
            <Dropdown.Button onClick={() => authStore.clearUser()}>Cerrar sesión</Dropdown.Button>
          </Dropdown.Container>
        </div>
      </header>
    );
  }
}

export default observer(Header);
