
import { ReactComponent as OffersIcon } from '../../assets/images/offers.svg';

import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import EmptyNotification from '../../components/EmptyNotification';

const NoOfferFound = (props) => {
  if (props.history.action === 'REPLACE') {
    return (
      <div className="offer page">
        <Helmet>
          <title>Oferta no encontrada | Metallica Caribbean</title>
          <meta name="description" content="No se encontró la oferta solicitada"/>
          <meta name="keyboards" content="oferta,metallica,detalle" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="card">
              <EmptyNotification icon={OffersIcon}
                                 title="Oferta no encontrada"
                                 details="Verifique que el código de oferta sea el correcto."
                                 action="Regresar"
                                 onAction={() => props.history.goBack()}/>
            </div>
          </div>
        </div>

      </div>
    );
  } else {
    return <Redirect to="/"/>
  }
};

export default NoOfferFound;