
import './SelectType.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as AirplaneIcon } from '../../assets/images/airplane.svg';
import { ReactComponent as VesselIcon } from '../../assets/images/cargo-ship.svg';

import React from 'react';
import PropTypes from 'prop-types';

import api from '../../api';

const Type = ({icon: Icon, label, information}) => (
  <div className={`select-type__type ${information ? 'select-type__type--information' : 'select-type__type--selectable'}`}>
    <div className="row row--align-center">
      <div className="col"><Icon className="select-type__icon"/></div>
      <div className="col-10 select-type__label">{label}</div>
    </div>
  </div>
);

Type.propTypes = {
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  information: PropTypes.bool.isRequired
};

Type.defaultProps = {
  information: false
};

class SelectType extends React.Component {
  state = {

  };
  source = api.source.createSource();

  constructor(props) {
    super(props);

    this.selectType = this.selectType.bind(this);
    this.deselectType = this.deselectType.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  selectType(type) {
    this.props.onSelectType(type);
  }

  deselectType() {
    this.props.onDeselectType();
  }

  render() {
    if (this.props.selectedType) {
      return (
        <div className="card page__card">
          <div className="row row--align-top">
            <div className="col-6"><h2>Tipo de embarque</h2></div>
            <div className="col-6 select-type__close">
              <button className="btn btn--icon btn--small btn--transparent"
                      onClick={() => this.deselectType()}><CloseIcon className="btn__icon"/></button>
            </div>
            <div className="col-6 select-type__types">
              {this.props.selectedType === 2 ? (
                <Type label="Embarque aéreo" icon={AirplaneIcon} information={true}/>
              ) : (
                <Type label="Embarque marítimo" icon={VesselIcon} information={true}/>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card card--instructions page__card select-type">
          <h2>Tipo de embarque</h2>
          <span className="card__instructions">Selecciona el tipo de embarque</span>
          <div className="row row--align-center select-type__types">
            <div className="col-6"
                 onClick={() => this.selectType(2)}>
              <Type label="Embarque aéreo" icon={AirplaneIcon}/>
            </div>
            <div className="col-6"
                 onClick={() => this.selectType(1)}>
              <Type label="Embarque marítimo" icon={VesselIcon}/>
            </div>
          </div>
        </div>
      );
    }
  }
}

SelectType.propTypes = {
  selectedType: PropTypes.any,
  onSelectType: PropTypes.func.isRequired,
  onDeselectType: PropTypes.func.isRequired
};

SelectType.defaultProps = {
  selectedType: null,
  onSelectType: () => {},
  onDeselectType: () => {}
};

export default SelectType;