
const calculateMargin = (price, cost) => {
  const parsedPrice = Number(price) || 0;
  const parsedCost = Number(cost) || 0;
  if (parsedPrice !== 0) {
    return 100 - (100 * parsedCost / parsedPrice);
    // return (parsedPrice / parsedCost - 1) * 100;
  } else {
    return 0;
  }
};

const calculatePrice = (cost, margin) => {
  const parsedCost = Number(cost) || 0;
  const parsedMargin = Number(margin) || 0;
  return parsedCost / (1 - (parsedMargin / 100));
  // return parsedCost + parsedCost * parsedMargin / 100;
};

const calculateTotal = (price, quantity) => {
  const parsedPrice = Number(price) || 0;
  const parsedQuantity = Number(quantity) || 0;
  return parsedPrice * parsedQuantity;
};


/**
 * Calculate product total import by its given values
 * @param cost
 * @param margin
 * @param quantity
 * @returns {number}
 */
const calculateProductTotal = (cost, margin, quantity) => {
  const parsedCost = Number(cost);
  const parsedMargin = Number(margin);
  const parsedQuantity = Number(quantity);
  const unitPrice = calculatePrice(parsedCost, parsedMargin);
  // const unitPrice = parsedCost + (parsedCost * parsedMargin / 100);
  return parsedQuantity * unitPrice;
};

/**
 * Calculate product margin by its given values
 * @param total
 * @param cost
 * @param quantity
 * @returns {number}
 */
const calculateProductMargin = (total, cost, quantity) => {
  const parsedCost = Number(cost);
  const parsedTotal = Number(total);
  const parsedQuantity = Number(quantity);
  if (parsedCost !== 0 && parsedTotal !== 0 && parsedQuantity !== 0) {
    return 100 - (100 * parsedCost * parsedQuantity / parsedTotal);
    // return (parsedTotal / (parsedCost * parsedQuantity) - 1) * 100;
  } else {
    return 0;
  }
};

export {
  calculateMargin,
  calculatePrice,
  calculateTotal,
  calculateProductTotal,
  calculateProductMargin
};
