
import './index.scss';

import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import settings from '../../settings';
import api from '../../api';
import utilsStore from '../../stores/utils';
import { TextInput, SelectInput, SelectOption, LoadingButton, PageHeader, protect } from '../../components';
import { Card } from '../../containers';
import { validateInputNotEmpty, validateNumberInput } from '../../utils';

class ProductForm extends React.Component {
  state = {
    part_number: null,
    harmonized_code: null,
    description: null,
    manufacturer_id: null,
    price: '0.00',
    gross_weight: '0.00',
    net_weight: '0.00',
    errors: {
      part_number: false,
      price: false,
      gross_weight: false,
      net_weight: false
    },
    createLoading: false,
    createError: false,
    createSuccess: false
  };
  source = api.source.createSource();
  notificationTimeout = null;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Productos', link: '/products'},
    {title: 'Nuevo producto', link: '/products/new'}
  ];

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.showFormErrors = this.showFormErrors.bind(this);
    this.formIsValid = this.formIsValid.bind(this);

    this.createProduct = this.createProduct.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  handleInputChange(value, name, error) {
    this.setState({[name]: value, errors: {...this.state.errors, [name]: error}});
  }

  formIsValid() {
    return this.state.part_number &&
      this.state.manufacturer_id &&
      this.state.price &&
      this.state.gross_weight &&
      this.state.net_weight &&
      !this.state.errors.part_number &&
      !this.state.errors.price &&
      !this.state.errors.net_weight &&
      !this.state.errors.gross_weight;
  }

  showFormErrors() {
    this.setState({
      errors: {
        part_number: validateInputNotEmpty(this.state.part_number),
        price: validateNumberInput(this.state.price),
        gross_weight: validateNumberInput(this.state.gross_weight),
        net_weight: validateNumberInput(this.state.net_weight)
      }
    });
  }

  setNotificationTimeout(success = false) {
    this.notificationTimeout = setTimeout(
      () => {
        if (success) {
          this.props.history.goBack();
        } else {
          this.setState({createError: false});
        }
      },
      settings.notificationTimeout
    );
  }

  createProduct() {
    if (this.formIsValid()) {
      this.setState({
        createLoading: true,
        createError: false,
        createSuccess: false
      });
      api.product.createProduct(
        {
          part_number: this.state.part_number,
          description: this.state.description,
          manufacturer_id: parseInt(this.state.manufacturer_id),
          price: this.state.price,
          harmonized_code: this.state.harmonized_code,
          gross_weight: this.state.gross_weight,
          net_weight: this.state.net_weight
        },
        this.source
      )
        .then(() => {
          this.setState({ createLoading: false, createSuccess: true});
          this.setNotificationTimeout(true);
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            this.setState({ createLoading: false, createError: 'Error inesperado' });
            this.setNotificationTimeout();
          }
        });
    } else {
      this.showFormErrors();
    }
  }

  render() {
    return (
      <div className="product-form page page--form">

        <Helmet>
          <title>Nuevo producto | Metallica Caribbean</title>
          <meta name="description" content="Formulario de creación de nuevo producto" />
          <meta name="keyboards" content="producto,metallica,crear,nuevo" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title="Nuevo producto"
                                    onBack={() => this.props.history.goBack()}>
              </PageHeader.Container>
            </div>
          </div>

          <div className="col-12 page__card">
            <Card title="Información general">
              <div className="row row--align-top">
                <div className="col-6 page__input">
                  <TextInput label="Código de producto"
                             name="part_number"
                             value={this.state.part_number}
                             onChange={this.handleInputChange}
                             validateFn={validateInputNotEmpty}
                             disabled={this.state.createLoading}
                             error={this.state.errors.part_number}/>
                </div>
                <div className="col-6 page__input">
                  <TextInput label="Código armonizado"
                             name="harmonized_code"
                             value={this.state.harmonized_code}
                             onChange={this.handleInputChange}
                             disabled={this.state.createLoading}/>
                </div>
                <div className="col-12 page__input">
                  <TextInput label="Descripción"
                             type="multiline"
                             name="description"
                             value={this.state.description}
                             onChange={this.handleInputChange}
                             disabled={this.state.createLoading}/>
                </div>
                <div className="col-6 page__input">
                  <SelectInput label="Proveedor"
                               name="manufacturer_id"
                               value={this.state.manufacturer_id}
                               onChange={this.handleInputChange}
                               disabled={this.state.createLoading}
                               searchEnabled={true}>
                    {utilsStore.suppliers.map((supplier, index) => (
                      <SelectOption value={supplier.id} key={supplier.id}>{supplier.name}</SelectOption>
                    ))}
                  </SelectInput>
                </div>
                <div className="col-6 page__input">
                  <TextInput type="number"
                             label="Costo (€)"
                             name="price"
                             value={this.state.price}
                             onChange={this.handleInputChange}
                             validateFn={validateNumberInput}
                             disabled={this.state.createLoading}
                             error={this.state.errors.price}/>
                </div>
                <div className="col-6 page__input">
                  <TextInput type="number"
                             label="Peso bruto (kg)"
                             name="gross_weight"
                             value={this.state.gross_weight}
                             onChange={this.handleInputChange}
                             validateFn={validateNumberInput}
                             disabled={this.state.createLoading}
                             error={this.state.errors.gross_weight}/>
                </div>
                <div className="col-6 page__input">
                  <TextInput type="number"
                             label="Peso neto (kg)"
                             name="net_weight"
                             value={this.state.net_weight}
                             onChange={this.handleInputChange}
                             validateFn={validateNumberInput}
                             disabled={this.state.createLoading}
                             error={this.state.errors.net_weight}/>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-12 page__card">
            <div className="page__submit">
              <LoadingButton className="btn--primary btn--large"
                             loading={this.state.createLoading}
                             loadingColor="black"
                             onClick={this.createProduct}
                             error={this.state.createError}
                             success={this.state.createSuccess ? 'Producto creado' : null}>Crear producto</LoadingButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default protect(observer(ProductForm), settings.permissions.read_admin);
