
import React from 'react';
import { Redirect } from 'react-router-dom';

import settings from '../settings';
import authStore from '../stores/auth';

/***
 * Protect view by permission
 * @param Component
 * @param permission
 * @param redirect
 * @returns {Function}
 */
const protect = (Component, permission, redirect=settings.unauthorizedUrl) => (props) => {
  if (authStore.validatePermission(permission)) {
    return <Component {...props}/>
  } else {
    return <Redirect to={redirect}/>
  }
};

export default protect;
