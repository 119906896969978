
import { ReactComponent as DeleteIcon } from '../../assets/images/close.svg';

import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '../../components';
import { SingleActionCard } from '../../containers';

const Contact = ({loading, disabled, name, position, change, changeDone, deletePressed}) => (
  <SingleActionCard icon={DeleteIcon}
                    loading={loading}
                    disabled={disabled}
                    actionEvent={() => deletePressed()}>
    <div className="row row--align-top">
      <div className="col page__input">
        <TextInput label="Nombre"
                   loading={loading === 'initial'}
                   name="name"
                   value={name}
                   onChange={change}
                   onDone={(name, value, modified) => modified && changeDone && changeDone(name, value)}
                   disabled={loading || disabled}
                   information={true}/>
      </div>
      <div className="col page__input">
        <TextInput label="Posición"
                   name="position"
                   loading={loading === 'initial'}
                   value={position}
                   onChange={change}
                   onDone={(name, value, modified) => modified && changeDone && changeDone(name, value)}
                   disabled={loading || disabled}
                   information={true}/>
      </div>
    </div>
  </SingleActionCard>
);

Contact.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  name: PropTypes.string,
  position: PropTypes.string,
  change: PropTypes.func.isRequired,
  changeDone: PropTypes.func,
  deletePressed: PropTypes.func.isRequired
};

export default Contact;