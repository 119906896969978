
import './index.scss';

import React from 'react';
import { Helmet } from 'react-helmet';

import api from '../../api';
import { authStore } from '../../stores';
import { PageHeader, TextInput, LoadingButton, DotLabel } from '../../components';
import { validateInputNotEmpty } from '../../utils';
import { validateLength, validateUpperCase, validateNumber, validateLowerCase } from '../../utils/validatePassword';

class ChangePassword extends React.Component {
  state = {
    password: null,
    newPassword: null,
    confirmationPassword: null,
    errors: {
      password: null,
      newPassword: {
        number: false,
        lowerLetter: false,
        upperLetter: false,
        length: false
      },
      confirmationPassword: null
    },
    createLoading: false,
    createError: false,
    createSuccess: false
  };
  source = api.source.createSource();
  notificationTimeout = null;
  NOTIFICATION_DURATION = 3000;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Cambiar contraseña', link: '/change-password'}
  ];

  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  onValueChange(value, name, error) {
    switch(name) {
      case 'newPassword':
        this.setState({
          newPassword: value,
          errors: {
            ...this.state.errors,
            newPassword: {
              number: !validateNumber(value),
              lowerLetter: !validateLowerCase(value),
              upperLetter: !validateUpperCase(value),
              length: !validateLength(value)
            }
          }
        });
        break;
      case 'confirmationPassword':
        this.setState({
          confirmationPassword: value,
          errors: {
            ...this.state.errors,
            confirmationPassword: value !== this.state.newPassword ? 'Debe coincidir con la nueva contraseña ingresada.' : null
          }
        });
        break;
      default:
        this.setState({[name]: value, errors: {...this.state.errors, [name]: error}});
        break;
    }
  }

  changePassword() {
    if (this.state.password &&
      this.state.newPassword &&
      this.state.confirmationPassword &&
      !this.state.errors.password &&
      !this.state.errors.confirmationPassword &&
      !this.state.errors.newPassword.length &&
      !this.state.errors.newPassword.upperLetter &&
      !this.state.errors.newPassword.lowerLetter &&
      !this.state.errors.newPassword.number) {

      this.setState({createLoading: true});
      api.auth.changePassword(authStore.email, this.state.password, this.state.newPassword, this.source)
        .then(response => {
          if (response.data) {
            this.setState({createLoading: false});
            this.setNotificationTimeout(true, 'Contraseña cambiada exitosamente');
          }
        })
        .catch(err => {
          if (!api.source.errorIsCancel(err)) {
            this.setState({createLoading: false});
            this.setNotificationTimeout(false, err === 'wrong_password' ? 'Contraseña incorrecta' : err.toString());
          }
        });
    }
  }

  setNotificationTimeout(success = false, value = true) {
    const fieldName = success ? 'createSuccess' : 'createError';
    this.setState({[fieldName]: value});

    if (success) {
      this.notificationTimeout = setTimeout(
        () => this.props.history.goBack(),
        this.NOTIFICATION_DURATION
      );
    } else {
      this.notificationTimeout = setTimeout(
        () => this.setState({[fieldName]: false}),
        this.NOTIFICATION_DURATION
      );
    }
  }

  render() {
    return (
      <div className="change-password page page--form">
        <Helmet>
          <title>Cambiar contraseña | Metallica Caribbean</title>
          <meta name="description" content="Formulario para cambiar la contraseña de un usuario" />
          <meta name="keyboards" content="usuario,metallica,contraseña,cambiar" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title="Cambiar contraseña"
                                    onBack={() => this.props.history.goBack()}>
              </PageHeader.Container>
            </div>
          </div>

          <div className="col-12 page__card">
            <div className="card card--instructions">
              <h2>Contraseña actual</h2>
              <div className="row row--align-top">
                <div className="col-12 page__input">
                  <TextInput type="password"
                             label="Contraseña actual"
                             name="password"
                             value={this.state.password}
                             error={this.state.errors.password}
                             onChange={this.onValueChange}
                             validateFn={validateInputNotEmpty}/>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 page__card">
            <div className="card card--instructions">
              <h2>Nueva contraseña</h2>
              <div className="row row--align-top">
                <div className="col-12 page__input">
                  <TextInput type="password"
                             label="Nueva contraseña"
                             name="newPassword"
                             value={this.state.newPassword}
                             onChange={this.onValueChange}/>
                </div>
                <div className="col-12 page__input">
                  <TextInput type="password"
                             label="Confirmar contraseña"
                             name="confirmationPassword"
                             value={this.state.confirmationPassword}
                             error={this.state.errors.confirmationPassword}
                             onChange={this.onValueChange}/>
                </div>
                <div className="col-12 change-password__instructions">
                  La contraseña debe contener:
                  <div className="row row--align-top change-password__advises">
                    <div className="col-6 change-password__advise">
                      <DotLabel color={this.state.newPassword ? (this.state.errors.newPassword.lowerLetter ? 'error' : 'success') : 'black'}
                                size="mid"
                                label="Letra minúsculas"/>
                    </div>
                    <div className="col-6 change-password__advise">
                      <DotLabel color={this.state.newPassword ? (this.state.errors.newPassword.number ? 'error' : 'success') : 'black'}
                                size="mid"
                                label="Números"/>
                    </div>
                    <div className="col-6 change-password__advise">
                      <DotLabel color={this.state.newPassword ? (this.state.errors.newPassword.upperLetter ? 'error' : 'success') : 'black'}
                                size="mid"
                                label="Letra mayúsculas"/>
                    </div>
                    <div className="col-6 change-password__advise">
                      <DotLabel color={this.state.newPassword ? (this.state.errors.newPassword.length ? 'error' : 'success') : 'black'}
                                size="mid"
                                label="8 - 16 caractéres"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="page__submit">
              <LoadingButton loading={this.state.createLoading}
                             className="btn btn--primary"
                             success={this.state.createSuccess}
                             error={this.state.createError}
                             onClick={() => this.changePassword()}
                             loadingColor="black">Cambiar contraseña</LoadingButton>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ChangePassword;