
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import api from '../../api';
import { parseDateReadable } from '../../utils';

class InvoicePreview extends React.Component {
  state = {
    loading: false,
    error: false
  };
  source = api.source.createSource();

  componentDidMount() {
    this.getInvoice();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  getInvoice() {
    this.setState({loading: true, error: false});
    api.invoice.getInvoice(this.props.invoiceId, this.source)
      .then(response => {
        const invoice = response.data;
        this.setState({loading: false, ...invoice});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({error: err, loading: false});
        }
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="invoice-preview row row--align-top">
          <div className="col-12 invoice-preview__title">
            <div className="loading-block loading-block--small" style={{width: '35%'}}/>
          </div>
          <div className="col-12 invoice-preview__data">
            <div className="loading-block loading-block--small"/>
          </div>
          <div className="col-12 invoice-preview__title">
            <div className="loading-block loading-block--small" style={{width: '35%'}}/>
          </div>
          <div className="col-12 invoice-preview__data">
            <div className="loading-block loading-block--small"/>
          </div>
          <div className="col-12 invoice-preview__title">
            <div className="loading-block loading-block--small" style={{width: '35%'}}/>
          </div>
          <div className="col-12 invoice-preview__data">
            <div className="loading-block loading-block--small"/>
          </div>
          <div className="col-12 invoice-preview__title">
            <div className="loading-block loading-block--small" style={{width: '35%'}}/>
          </div>
          <div className="col-12 invoice-preview__data">
            <div className="loading-block loading-block--small"/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="invoice-preview row row--align-top">
          <div className="col-12 invoice-preview__title">Contrato</div>
          <div className="col-12 invoice-preview__data">{this.state.contract_number}</div>
          <div className="col-12 invoice-preview__title">Mercancía</div>
          <div className="col-12 invoice-preview__data">{this.state.description}</div>
          <div className="col-12 invoice-preview__title">Monto total</div>
          <div className="col-12 invoice-preview__data">{this.state.total}</div>
          <div className="col-12 invoice-preview__title">Fecha</div>
          <div className="col-12 invoice-preview__data">{parseDateReadable(this.state.date)}</div>
          <div className="col-12 invoice-preview__link">
            <button className="btn btn--link btn--table"
                    onClick={event => {
                      this.props.history.push(`/invoices/${this.props.invoiceId}`);
                      event.stopPropagation();
                    }}>Ver más</button>
          </div>
        </div>
      );
    }
  }
}

InvoicePreview.propTypes = {
  invoiceId: PropTypes.string.isRequired
};

export default withRouter(InvoicePreview);