
import './index.scss';
import { ReactComponent as TruckIcon } from '../../assets/images/delivery.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import settings from '../../settings';
import api from '../../api';
import protect from '../../components/protect';
import { parseDateReadable, parseToMoney } from '../../utils';
import { ListPagination, PageHeader, TextInput, Badge, Dropdown, EmptyNotification, QuotePreview, Tooltip } from '../../components';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell, TableLinkBodyCell } from '../../components/Table';
import authStore from '../../stores/auth';
import FileSaver from 'file-saver';

class InvoicesList extends React.Component {
  state = {
    invoices: [],
    filters: {
      description: null,
      perPage: 10,
      initialDate: null,
      finalDate: null
    },
    page: 1,
    pages: 1,
    loading: false,
    error: false
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Facturas', link: '/invoices'}
  ];

  constructor(props) {
    super(props);

    this.getFilters = this.getFilters.bind(this);
    this.filtersChanged = this.filtersChanged.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
  }

  componentDidMount() {
    this.getInvoices();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  pageChanged(page) {
    this.setState({page});
    this.getInvoices(page);
  }

  filtersChanged(value, name) {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value
      }
    });
  }

  getFilters(selectedPage) {
    const { perPage, description, finalDate, initialDate } = this.state.filters;
    return {
      per_page: perPage,
      page: selectedPage || 1,
      description,
      initial_date: initialDate,
      final_date: finalDate
    };
  }

  getInvoices(selectedPage = null, filters = {}) {
    this.setState({loading: true});

    api.invoice.getInvoices(this.source, {...this.getFilters(selectedPage), ...filters})
      .then(response => {
        const invoices = response.data.data;
        const pages = response.data.pages;

        this.setState({invoices, pages, loading: false});
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          this.setState({loading: false, error: err});
        }
      });
  }

  downloadExcel(id) {
    api.invoice.downloadExcel(id, this.source)
      .then(response => {
        const fileName = `Factura ${id}.xlsx`;
        FileSaver.saveAs(response.data, fileName);
      })
      .catch(err => {
        if (!api.source.errorIsCancel(err)) {
          console.error('Error downloading invoice', err);
        }
      });
  }

  render() {
    return (
      <div className="invoices page page--list">
        <Helmet>
          <title>Facturas | Metallica Caribbean</title>
          <meta name="description" content="Listado de facturas" />
          <meta name="keyboards" content="facturas,metallica,listado" />
        </Helmet>

        <div className="page__heading">
          <PageHeader.Container navigation={this.NAVIGATION}
                                title="Facturas">
            <PageHeader.Filters>
              <PageHeader.Filter>
                <TextInput name="description"
                           placeholder="Buscar..."
                           value={this.state.filters.description}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getInvoices()}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput type="date"
                           name="initialDate"
                           placeholder="Desde..."
                           value={this.state.filters.initialDate}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getInvoices(null, {initial_date: value})}/>
              </PageHeader.Filter>
              <PageHeader.Filter>
                <TextInput type="date"
                           name="finalDate"
                           placeholder="Hasta..."
                           value={this.state.filters.finalDate}
                           onChange={this.filtersChanged}
                           onDone={(fieldName, value, modified) => modified && this.getInvoices(null, {final_date: value})}/>
              </PageHeader.Filter>
            </PageHeader.Filters>

            {authStore.permissions.invoices.create ? (
              <PageHeader.Actions>
                <PageHeader.Action>
                  <button className="btn btn--primary"
                          onClick={() => this.props.history.push('/invoices/new')}>Nueva factura</button>
                </PageHeader.Action>
              </PageHeader.Actions>
            ) : null}
          </PageHeader.Container>
        </div>

        <div className="card">
          <Table loadingRows={10}
                 loadingCells={8}
                 loading={this.state.loading}
                 emptyState={<EmptyNotification icon={NotFoundIcon}
                                                title="No se encontrarón facturas"
                                                details="Puedes cambiar los filtros de búsqueda en la parte superior o crear una factura."
                                                action={authStore.permissions.invoices.create ? 'Nueva factura' : null}
                                                onAction={() => authStore.permissions.invoices.create && this.props.history.push(`/invoices/new`)}/>}
                 errorState={<EmptyNotification icon={DisconnectIcon}
                                                title={this.state.error}
                                                details="Ha ocurrido un error obteniendo las facturas."/>}
                 headerCells={
                   <Fragment>
                     <TableHeaderCell>Código</TableHeaderCell>
                     <TableHeaderCell>Oferta</TableHeaderCell>
                     <TableHeaderCell className="invoices__header-cell--client-name">Cliente</TableHeaderCell>
                     <TableHeaderCell>Fecha</TableHeaderCell>
                     <TableHeaderCell>Contrato</TableHeaderCell>
                     <TableHeaderCell className="invoices__header-cell--description">Mercancía</TableHeaderCell>
                     <TableHeaderCell>Monto</TableHeaderCell>
                     <TableHeaderCell/>
                   </Fragment>
                 }
                 pagination={<ListPagination margin="page"
                                             currentPage={this.state.page}
                                             totalPages={this.state.pages}
                                             onChange={this.pageChanged}/>}>
            {this.state.error || this.state.invoices.map((invoice, index) => {
              const hasPackages = invoice.shipments.length > 0;
              return (
                <TableBodyRow key={invoice.id}
                              onSelect={() => authStore.permissions.invoices.read && this.props.history.push(`/invoices/${invoice.id}`)}>
                  <TableBodyCell>
                    {invoice.id}
                    {invoice.paid_date ? <Badge color="success" size="small" fill={false}>Pagada</Badge> : null}
                  </TableBodyCell>
                  <TableBodyCell>
                    <Tooltip title={invoice.quote_id}
                             size="mid"
                             position="dropdown-left"
                             trigger={<button className="btn btn--link btn--table btn--nowrap"
                                              onClick={event => {
                                                this.props.history.push(`/offers/${invoice.quote_id}`);
                                                event.stopPropagation();
                                              }}>{invoice.quote_id}</button>}>
                      <QuotePreview quoteId={invoice.quote_id}/>
                    </Tooltip>
                  </TableBodyCell>
                  <TableLinkBodyCell url={`/admin/clients/${invoice.client_id}`}>{invoice.client}</TableLinkBodyCell>
                  <TableBodyCell nowrap={true}>{parseDateReadable(invoice.date, true)}</TableBodyCell>
                  <TableBodyCell>{invoice.contract_number}</TableBodyCell>
                  <TableBodyCell className="invoices__body-cell--description">{invoice.description}</TableBodyCell>
                  <TableBodyCell>{parseToMoney(invoice.total)} €</TableBodyCell>
                  <TableBodyCell className="invoices__body-cell--actions" nowrap={true}>
                    {authStore.permissions.packingLists.read && hasPackages ? (
                      <Dropdown.Container icon={TruckIcon}
                                          color="primary"
                                          size="small"
                                          fill={true}
                                          showCaret={true}
                                          inline={true}>
                        {invoice.shipments.map(packing => <Dropdown.Button key={packing} onClick={() => this.props.history.push(`/shipments/${packing}`)}>{packing}</Dropdown.Button>)}
                      </Dropdown.Container>
                    ) : null}
                    {authStore.permissions.invoices.read || (authStore.permissions.packingLists.create && !hasPackages) ? (
                      <Dropdown.Container icon={MenuIcon}
                                          color="gray"
                                          size="small"
                                          outline={false}
                                          showCaret={false}
                                          inline={true}>
                        {authStore.permissions.invoices.read ? (
                          <Fragment>
                            <Dropdown.Button onClick={() => this.downloadExcel(invoice.id)}>Descargar</Dropdown.Button>
                          </Fragment>
                        ) : null}
                        {authStore.permissions.packingLists.create && !hasPackages ? <Dropdown.Button onClick={() => this.props.history.push(`/invoices/${invoice.id}/shipments/new`)}>Nuevo packing list</Dropdown.Button> : null}
                      </Dropdown.Container>
                    ) : null}
                  </TableBodyCell>
                </TableBodyRow>
              );
            })}
          </Table>
        </div>
      </div>
    );
  }
}

export default protect(observer(InvoicesList), settings.permissions.invoices.list);
