
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Dot = ({size, color}) => (
  <div className={`dot__dot dot__dot--${size} dot__dot--${color}`}/>
);

Dot.propTypes = {
  size: PropTypes.oneOf(['small', 'mid']).isRequired,
  color: PropTypes.oneOf(['black', 'success', 'error']).isRequired
};

Dot.defaultProps = {
  size: 'small',
  color: 'black'
};

const DotLabel = ({label, size, color}) => (
  <div className="dot">
    <Dot size={size} color={color}/>
    <div className={`dot__label dot__label--${size}`}>{label}</div>
  </div>
);

DotLabel.propTypes = {
  size: PropTypes.oneOf(['small', 'mid']).isRequired,
  color: PropTypes.oneOf(['black', 'success', 'error']).isRequired,
  label: PropTypes.any.isRequired
};

DotLabel.defaultProps = {
  size: 'small',
  color: 'black'
};

export default DotLabel;
export {
  Dot,
  DotLabel
};