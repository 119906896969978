
import './index.scss';
import { ReactComponent as DisconnectIcon } from '../../assets/images/disconnect.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/images/search.svg';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';

import authStore from '../../stores/auth';
import api from '../../api';
import { parseToMoney } from '../../utils';
import { PageHeader, EmptyNotification } from '../../components';
import { Card } from '../../containers';
import { Table, TableHeaderCell, TableBodyRow, TableBodyCell, TableLinkBodyCell } from '../../components/Table';

class Dashboard extends React.Component {
  state = {
    loadingOffers: false,
    loadingInvoices: false,
    loadingProducts: false,
    offers: [],
    invoices: [],
    products: [],
    errorOffers: null,
    errorInvoices: null,
    errorProducts: null,
  };
  source = api.source.createSource();
  NAVIGATION = [
    {title: 'Inicio', link: '/'}
  ];

  constructor(props) {
    super(props);

    this.getOffers = this.getOffers.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.getProducts = this.getProducts.bind(this);
  }

  componentDidMount() {
    this.getOffers();
    this.getInvoices();
    this.getProducts();
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
  }

  getOffers() {
    this.setState({loadingOffers: true});

    api.dashboard.getQuotes(this.source)
      .then(response => {
        const offers = response.data;
        this.setState({offers, loadingOffers: false});
      })
      .catch(err => !api.source.errorIsCancel(err) && this.setState({loadingOffers: false, errorOffers: err, offers: []}));
  }

  getInvoices() {
    this.setState({loadingInvoices: true});

    api.dashboard.getInvoices(this.source)
      .then(response => {
        const invoices = response.data;
        this.setState({invoices, loadingInvoices: false});
      })
      .catch(err => !api.source.errorIsCancel(err) && this.setState({loadingInvoices: false, errorInvoices: err, invoices: []}));
  }

  getProducts() {
    this.setState({loadingProducts: true});

    api.dashboard.getProducts(this.source)
      .then(response => {
        const products = response.data;
        this.setState({products, loadingProducts: false});
      })
      .catch(err => !api.source.errorIsCancel(err) && this.setState({loadingProducts: false, errorProducts: err, products: []}));
  }

  render() {
    return (
      <div className="offers page page--list">
        <Helmet>
          <title>Inicio | Metallica Caribbean</title>
          <meta name="description" content="Inicio de panel de control de Metallica Caribbean S.A." />
          <meta name="keyboards" content="inicio,metallica,dashboard" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title={`¡Bienvenido${authStore.name ? ` ${authStore.name}` : ''}!`}>
              </PageHeader.Container>
            </div>
          </div>

          <div className="col-6">
            <Card loading={this.state.loadingOffers}>
              <Card.Title>Últimas ofertas</Card.Title>
              <Card.Body>
                <div className="row row--align-top">
                  <div className="col-12">
                    <Table className="dashboard__offers"
                           loading={this.state.loadingOffers}
                           loadingRows={5}
                           loadingCells={4}
                           headerCells={
                             <Fragment>
                               <TableHeaderCell>Código</TableHeaderCell>
                               <TableHeaderCell className="dashboard__offers__header--client">Cliente</TableHeaderCell>
                               <TableHeaderCell>Monto</TableHeaderCell>
                               <TableHeaderCell>Factura</TableHeaderCell>
                             </Fragment>
                           }
                           errorState={<EmptyNotification icon={DisconnectIcon}
                                                          title={this.state.errorOffers}
                                                          details="Ha ocurrido un error obteniendo las últimas ofertas."/>}
                           emptyState={<EmptyNotification icon={NotFoundIcon}
                                                          title="No se encontrarón ofertas"
                                                          details="Puedes cambiar los filtros de búsqueda en la parte superior o crear una oferta."
                                                          action={authStore.permissions.quotes.create ? 'Nueva oferta' : null}
                                                          onAction={() => authStore.permissions.quotes.create && this.props.history.push(`/offers/new`)}/>}>
                      {this.state.errorOffers || this.state.offers.map(offer => (
                        <TableBodyRow key={offer.id}
                                      onSelect={() => authStore.permissions.quotes.read && this.props.history.push(`/offers/${offer.id}`)}>
                          <TableBodyCell>{offer.id}</TableBodyCell>
                          <TableLinkBodyCell url={`/admin/clients/${offer.client_id}`}>{offer.client}</TableLinkBodyCell>
                          <TableBodyCell>{parseToMoney(offer.total_product)} €</TableBodyCell>
                          <TableBodyCell>
                            {
                              offer.invoice
                              && (offer.invoice || []).length > 0
                              && (offer.invoice || []).map((invoice) => (
                                <button
                                  key={invoice}
                                  className="btn btn--link btn--table"
                                  onClick={(event) => {
                                    this.props.history.push(`/invoices/${invoice}`);
                                    event.stopPropagation();
                                  }}
                                >
                                  {invoice}
                                </button>
                              ))
                            }
                          </TableBodyCell>
                        </TableBodyRow>
                      ))}
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-6">
            <Card loading={this.state.loadingInvoices}>
              <Card.Title>Últimas facturas</Card.Title>
              <Card.Body>
                <div className="row row--align-top">
                  <div className="col-12">
                    <Table className="dashboard__invoices"
                           loading={this.state.loadingInvoices}
                           loadingRows={5}
                           loadingCells={4}
                           headerCells={
                             <Fragment>
                               <TableHeaderCell>Código</TableHeaderCell>
                               <TableHeaderCell className="dashboard__invoices__header--client">Cliente</TableHeaderCell>
                               <TableHeaderCell className="dashboard__invoices__header--description">Descripción</TableHeaderCell>
                               <TableHeaderCell>Monto</TableHeaderCell>
                             </Fragment>
                           }
                           errorState={<EmptyNotification icon={DisconnectIcon}
                                                          title={this.state.errorInvoices || ''}
                                                          details="Ha ocurrido un error obteniendo las últimas facturas."/>}
                           emptyState={<EmptyNotification icon={NotFoundIcon}
                                                          title="No se encontrarón facturas"
                                                          details="Puedes cambiar los filtros de búsqueda en la parte superior o crear una factura."
                                                          action={authStore.permissions.invoices.create ? 'Nueva factura' : null}
                                                          onAction={() => authStore.permissions.invoices.create && this.props.history.push(`/invoices/new`)}/>}>
                      {this.state.errorInvoices || this.state.invoices.map(invoice => (
                        <TableBodyRow key={invoice.id}
                                      onSelect={() => authStore.permissions.invoices.read && this.props.history.push(`/invoices/${invoice.id}`)}>
                          <TableBodyCell>{invoice.id}</TableBodyCell>
                          <TableLinkBodyCell url={`/admin/clients/${invoice.client_id}`}>{invoice.client}</TableLinkBodyCell>
                          <TableBodyCell>{invoice.description}</TableBodyCell>
                          <TableBodyCell>{parseToMoney(invoice.total_product)} €</TableBodyCell>
                        </TableBodyRow>
                      ))}
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12">
            <Card loading={this.state.loadingProducts}>
              <Card.Title>Productos más ofertados</Card.Title>
              <Card.Body>
                <div className="row row--align-top">
                  <div className="col-12">
                    <Table className="dashboard__products"
                           loading={this.state.loadingProducts}
                           loadingRows={5}
                           loadingCells={3}
                           emptyState={<EmptyNotification icon={NotFoundIcon}
                                                          title="No se encontrarón productos"
                                                          details="Puedes cambiar los filtros de búsqueda en la parte superior o crear un producto."
                                                          action={authStore.permissions.admin ? 'Nuevo producto' : null}
                                                          onAction={() => authStore.permissions.admin && this.props.history.push(`/products/new`)}/>}
                           errorState={<EmptyNotification icon={DisconnectIcon}
                                                          title={this.state.errorProducts || ''}
                                                          details="Ha ocurrido un error obteniendo los productos más ofertados."/>}
                           headerCells={
                             <Fragment>
                               <TableHeaderCell>Código</TableHeaderCell>
                               <TableHeaderCell className="dashboard__products__header--description">Descripción</TableHeaderCell>
                               <TableHeaderCell>Cantidad</TableHeaderCell>
                             </Fragment>
                           }>
                      {this.state.errorProducts || this.state.products.map(product => (
                        <TableBodyRow key={product.id}
                                      onSelect={() => authStore.permissions.admin && this.props.history.push(`/products/${product.id}`)}>
                          <TableBodyCell nowrap={true}>{product.part_number}</TableBodyCell>
                          <TableBodyCell>{product.product}</TableBodyCell>
                          <TableBodyCell className="table__cell--nowrap">{parseToMoney(product.quantity)}</TableBodyCell>
                        </TableBodyRow>
                      ))}
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

      </div>
    );
  }
}

export default observer(Dashboard);
