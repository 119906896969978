
import React from 'react';
import { Helmet } from 'react-helmet';

import api from '../api';
import { Subtitle, LoadingButton, TextInput, PageHeader } from '../components';
import { validateEmailInput, validateInputNotEmpty } from '../utils';
import settings from '../settings';

import { Phone, NewPhone } from '../components';
import { Contact, NewContact } from '../components/suppliers';

class SupplierForm extends React.Component {
  state = {
    name: null,
    email: null,
    address: null,
    phones: [],
    contacts: [],
    errors: {
      name: false,
      email: false,
      address: false
    },
    createLoading: false,
    createError: false,
    createSuccess: false
  };
  source = api.source.createSource();
  notificationTimeout = null;
  NAVIGATION = [
    {title: 'Inicio', link: '/'},
    {title: 'Proveedores', link: '/admin/suppliers'},
    {title: 'Nuevo proveedor', link: '/admin/suppliers/new'}
  ];

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.validatePhones = this.validatePhones.bind(this);
    this.validateContacts = this.validateContacts.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.createSupplier = this.createSupplier.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
  }

  componentWillUnmount() {
    this.source.cancel('unmount');
    clearTimeout(this.notificationTimeout);
  }

  onChange(value, name, error) {
    this.setState({[name]: value, errors: {...this.state.errors, [name]: error}});
  }

  setNotificationTimeout(success = false) {
    this.notificationTimeout = setTimeout(
      () => {
        if (success) {
          this.props.history.goBack();
        } else {
          this.setState({createError: false});
        }
      },
      settings.notificationTimeout
    );
  }

  validatePhones() {
    return this.state.phones.reduce(
      (result, phone) => result &&
        phone.type &&
        phone.phone &&
        (phone.errors ? !phone.errors.type && !phone.errors.phone : true),
      true
    );
  }

  validateContacts() {
    return this.state.contacts.reduce(
      (result, contact) => result &&
        contact.name &&
        contact.position &&
        (contact.errors ? !contact.errors.name && !contact.errors.position : true),
      true
    );
  }

  validateForm() {
    return this.state.name &&
      !this.state.errors.name &&
      this.validatePhones() &&
      this.validateContacts();
  }

  createSupplier() {
    if (this.validateForm()) {
      const {name, email, address, phones, contacts} = this.state;
      this.setState({createLoading: true});
      api.supplier.createSupplier(
        {name, email, address, phones, contacts},
        this.source
      )
        .then(response => {
          this.setState({
            createLoading: false,
            createSuccess: true,
            name: null, email: null, address: null, phones: [], contacts: []
          });
          this.setNotificationTimeout(true);
        })
        .catch(err => {
          if (!(api.source.errorIsCancel(err))) {
            if (err === 'duplicated_client') {
              this.setState({
                createLoading: false,
                createError: 'Proveedor duplicado',
                errors: {...this.state.errors, name: 'Ya existe un proveedor con este nombre'}
              });
            } else {
              this.setState({
                createLoading: false,
                createError: 'Error inesperado'
              });
            }
            this.setNotificationTimeout();
          }
        });
    }
  }

  render() {
    return (
      <div className="supplier-form page page--form">

        <Helmet>
          <title>Nuevo proveedor | Metallica Caribbean</title>
          <meta name="description" content="Formulario de creación de nuevo proveedor" />
          <meta name="keyboards" content="proveedor,metallica,crear,nuevo" />
        </Helmet>

        <div className="row row--align-top">
          <div className="col-12">
            <div className="page__heading">
              <PageHeader.Container navigation={this.NAVIGATION}
                                    title="Nuevo proveedor"
                                    onBack={() => this.props.history.goBack()}>
              </PageHeader.Container>
            </div>
          </div>

          <div className="col-12 page__card">
            <div className="card">
              <h2>Información general</h2>
              <div className="row row--align-top">
                <div className="col-12 page__input">
                  <TextInput label="Nombre"
                             name="name"
                             value={this.state.name}
                             onChange={this.onChange}
                             validateFn={validateInputNotEmpty}
                             disabled={this.state.createLoading}
                             error={this.state.errors.name}/>
                </div>
                <div className="col-12 page__input">
                  <TextInput label="Correo electrónico"
                             name="email"
                             value={this.state.email}
                             onChange={this.onChange}
                             validateFn={validateEmailInput}
                             disabled={this.state.createLoading}
                             error={this.state.errors.email}/>
                </div>
                <div className="col-12 page__input">
                  <TextInput label="Dirección"
                             name="address"
                             value={this.state.address}
                             onChange={this.onChange}
                             validateFn={validateInputNotEmpty}
                             disabled={this.state.createLoading}
                             error={this.state.errors.address}/>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12"><Subtitle title="Números telefónicos"/></div>
          {this.state.phones.map((phone, index) => (
            <div className="col-12 page__card" key={index}>
              <Phone type={phone.type}
                     phone={phone.phone}
                     loading={phone.loading}
                     phoneChange={(value, name, error) => {
                       const phones = [...this.state.phones];
                       phones[index] = {...phones[index], [name]: value, errors: {...phones[index].errors, [name]: error}};
                       this.setState({phones});
                     }}
                     deletePressed={() => {
                       const phones = [...this.state.phones];
                       phones.splice(index, 1);
                       this.setState({phones});
                     }}/>
            </div>
          ))}
          <div className="col-12 page__card"><NewPhone add={phone => this.setState({phones: [...this.state.phones, phone]})}/></div>

          <div className="col-12"><Subtitle title="Personas de contacto"/></div>
          {this.state.contacts.map((contact, index) => (
            <div className="col-12 page__card" key={index}>
              <Contact name={contact.name}
                       position={contact.position}
                       loading={contact.loading}
                       change={(value, name, error) => {
                         const contacts = [...this.state.contacts];
                         contacts[index] = {...contacts[index], [name]: value, errors: {...contacts[index].errors, [name]: error}};
                         this.setState({contacts});
                       }}
                       deletePressed={() => {
                         const contacts = [...this.state.contacts];
                         contacts.splice(index, 1);
                         this.setState({contacts});
                       }}/>
            </div>
          ))}
          <div className="col-12 page__card"><NewContact add={contact => this.setState({contacts: [...this.state.contacts, contact]})}/></div>

          <div className="col-12 page__card">
            <div className="page__submit">
              <LoadingButton className="btn--primary btn--large"
                             loading={this.state.createLoading}
                             loadingColor="black"
                             onClick={this.createSupplier}
                             error={this.state.createError}
                             success={this.state.createSuccess ? 'Proveedor creado' : null}>Crear proveedor</LoadingButton>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default SupplierForm;
