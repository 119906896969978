
import validateNumber from './validateNumber';

/**
 * Validate number inputs and provide well error message
 * @param value
 * @returns {string|boolean}
 */
const fn = value => {
  if (!value || value === '') {
    return 'Debe introducir un valor';
  } else if (!validateNumber(value)) {
    return 'Debe introducir un número válido';
  } else {
    return false;
  }
};
export default fn;
